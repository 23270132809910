import { Flex, VStack } from '@chakra-ui/react';
import { FlexCard } from 'atoms';
import React, { FC } from 'react';
import { useNexusBoardContext } from '../../store/context';
import {
  YourTodoHeading,
  FocusArea,
  AllDocuments,
  TasksTraining,
} from './components';

const RightSection: FC = () => {
  const { locationsCount } = useNexusBoardContext((state) => ({
    locationsCount: state?.locationDetailsData?.locationCount,
  }));
  return (
    <Flex flexDir='column' w='full'>
      <YourTodoHeading />
      <VStack align='flex-start' w='full'>
        <FlexCard flexDir='column' w='full' gap={6}>
          {locationsCount > 1 ? <FocusArea /> : null}
          <AllDocuments />
        </FlexCard>
        <TasksTraining />
      </VStack>
    </Flex>
  );
};

RightSection.displayName =
  'sub-components/nexus/NexusBase/components/RightSection';

export default RightSection;
