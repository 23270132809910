import {
  Box,
  Heading,
  ListItem,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';

const ExtensionsInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Extensions (Browser & VSCode)</Heading>
      <VStack align='flex-start'>
        <Box>
          Extensions used in VSCode:
          <UnorderedList>
            <ListItem>eslint</ListItem>
            <ListItem>Git Graph</ListItem>
            <ListItem>GitLens</ListItem>
            <ListItem>Prettier</ListItem>
            <ListItem>Docket</ListItem>
            <ListItem>Superflex</ListItem>
          </UnorderedList>
        </Box>
      </VStack>
      <VStack align='flex-start'>
        <Box>
          Extensions used in Browser:
          <UnorderedList>
            <ListItem>GraphQL Network Inspector</ListItem>
            <ListItem>React Developer Tools</ListItem>
            <ListItem>FakeData</ListItem>
          </UnorderedList>
        </Box>
      </VStack>
    </VStack>
  );
};

export default ExtensionsInfo;
