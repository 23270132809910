import React, { FC } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import ExpiryDateSelectField from './ExpiryDateSelectField';
import CategorySelectField from './CategorySelectField';
import LocationSelectField from './LocationSelectField';
import { DocumentReminder } from './document-reminder';

interface IProps {}

const UpdateDocumentForm: FC<IProps> = () => {
  return (
    <>
      <CategorySelectField />

      <LocationSelectField />

      <ExpiryDateSelectField isEditing />

      <DocumentReminder />
    </>
  );
};

export default UpdateDocumentForm;
