import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const CustomLoginPageInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Custom Login Page JSON Info</Heading>
      <Box fontSize='16px'>
        This file contains the json for custom login page for specific clients.
        If ever requirement comes to create custom login page for a new client,
        so first "svg" logo has to be asked for that client and ideally better
        option would be 3 different icons be provided for 3 different screen
        sized: "large", "medium" and "small". And also text data for "titles"
        and "descriptions" for all 3 different screen sizes should also be
        asked. Based on the requested data, a new json will be created and the
        "key" will be {'<CLIENT_SUB_DOMAIN_NAME>.delightree'} and then all the
        key/value pairs have to be added whose reference can be found in the
        same file.
      </Box>
    </VStack>
  );
};

export default CustomLoginPageInfo;
