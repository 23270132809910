import { Box, Center, Flex } from '@chakra-ui/react';
import { FlexCard } from 'atoms';
import React, { FC } from 'react';
import Loader from 'sub-components/Loader';
import { NoCustomerFeedback } from '../NexusBase/components/LeftSection/components/CustomerFeedback/components';
import { LoadingSkeleton, RatingSection } from './components';
import { useControl } from './layers';

const LocationRating: FC = () => {
  const { graphData, loading } = useControl();

  return (
    <FlexCard flexDir='column' mt={6} gap={5} width='full'>
      <Flex>
        <Box
          bg='rgba(239, 239, 239, 1)'
          borderRadius='8px'
          p='8px 16px'
          fontWeight={600}
          fontSize='15px'
        >
          Google
        </Box>
      </Flex>
      {loading ? (
        <Center h='65vh'>
          <Loader />
        </Center>
      ) : graphData?.length > 0 ? (
        <RatingSection graphData={graphData} />
      ) : (
        <NoCustomerFeedback />
      )}
    </FlexCard>
  );
};

export default LocationRating;
