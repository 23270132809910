import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { LOCATIONS_PAGE } from 'appRoutes';
import { HeaderColors } from 'configs';
import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'routes';
import { useBulkLocationsUpload } from 'sub-components/BulkLocationsUpload/useBulkLocationsUpload';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import ModalContent from './ModalContent';

interface IProps {}

type AddLocation = (props: IProps) => void;

export const useAddLocation = (): AddLocation => {
  const initRef = useRef<ReturnType<UseConfirm>>();
  const history = useHistory();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const bulkLocationsUpload = useBulkLocationsUpload();

  const init = useCallback(({}) => {
    if (initRef.current) {
      initRef.current.destroy();
    }

    const redirectHandler = (redirectObj: {
      pathname: string;
      state?: any;
    }) => {
      initRef?.current?.destroy();
      history.push(redirectObj);
    };

    const onBulkUploadSuccess = () => {
      history.push({
        pathname: LOCATIONS_PAGE,
      });
    };

    const onImportClick = () => {
      initRef?.current?.destroy();
      bulkLocationsUpload({
        type: 'existing',
        onBulkUploadError: () => {},
        onBulkUploadSuccess: onBulkUploadSuccess,
      });
    };

    initRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex flex={1} align='center' justify='space-between' w='full'>
          <BoxHeader
            // @ts-ignore
            title='Add location'
            color={HeaderColors.Green}
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <ModalContent
          redirectHandler={redirectHandler}
          onImportClick={onImportClick}
        />
      ),
      size: '2xl',
      isCentered: false,
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '16px',
      },
      footer: null,
      okText: null,
      onOK: undefined,
      cancelText: null,
    });
  }, []);
  return init;
};
