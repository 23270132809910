import React, { FC } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { FormInput } from '../../atoms';
import { Authorize } from '../../authorization';
import { canAccessAiSearch } from '../../configs';
import { BaseSearchModal } from '../../modal';

import SearchTypeSelect from './SearchTypeSelect';
import SearchListContainer from './SearchListContainer';

const Wrapper = styled.div`
  img {
    margin: unset;
  }
  ul {
    padding-left: initial;
  }
`;

interface IProps {
  searchQuery?: string;
  onChange?: (value: string) => void;
  onSelectedItem?: (item: BaseSearchModal, isAddRecentSearch: boolean) => void;

  searchType?: string;
  onTypeChange?: (value: string) => void;
}

const SearchModalData: FC<IProps> = ({
  searchQuery,
  onChange,
  onSelectedItem,
  searchType,
  onTypeChange,
}) => {
  const { t } = useTranslation(['common', 'header']);

  return (
    <Wrapper>
      <Flex flexDir='column' px='32px' gap={3}>
        <FormInput
          size='lg'
          placeholder={t('header:jump_to')}
          value={searchQuery}
          onChange={(e) => onChange?.(e?.target?.value)}
          variant='filled'
          autoFocus
          rightIcon={
            <IconButton
              size='sm'
              aria-label='next'
              variant='solid'
              colorScheme='blue'
              isDisabled={!searchQuery?.trim()}
            >
              <ArrowForwardIcon boxSize='22px' />
            </IconButton>
          }
        />

        <Authorize canAccess={canAccessAiSearch}>
          <SearchTypeSelect value={searchType} onChange={onTypeChange} />
        </Authorize>
      </Flex>

      <SearchListContainer
        searchQuery={searchQuery?.trim()}
        onSelectedItem={onSelectedItem}
      />
    </Wrapper>
  );
};

export default SearchModalData;
