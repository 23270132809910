import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DateInput, {
  DateInputButton,
} from '../../../../../../pages/Locations/Components/DateInput';
import { IDocumentInput } from './compliance.input';
import TitleHeader from '../../../../../CardEditor/TitleHeader';

import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';

interface IProps {
  isEditing?: boolean;
}

const ExpiryDateSelectField: FC<IProps> = ({ isEditing }) => {
  const { t } = useTranslation(['nexus']);

  return (
    <Controller<IDocumentInput, 'expiryDate'>
      name='expiryDate'
      // rules={{ required: t('nexus:documents.validations.expDateRequired') }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={fieldState.invalid}>
          <TitleHeader
            title={
              isEditing
                ? 'New expiry date of document'
                : t('nexus:documents.addExpDate')
            }
            titleStyle={{ marginBottom: '8px' }}
          />
          <Flex>
            <DateInput
              customInput={
                <DateInputButton
                  variant='outline'
                  bg='white'
                  rightIcon={<CalendarIcon />}
                  paddingInline={4}
                  sx={{
                    '.chakra-button__icon': {
                      color: '#6F767E',
                    },
                  }}
                  w={'190px'}
                />
              }
              value={field.value}
              onChange={field.onChange}
              dateFormat='dd-MM-yyyy'
            />
          </Flex>
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default ExpiryDateSelectField;
