import { Box, Heading, VStack } from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';
import {
  useQueryCode,
  useQueryCodeAdvanced,
  useQueryCodeWithVars,
} from './examples';

const useQueryExamples: FC = () => {
  return (
    <VStack align='flex-start' w='full'>
      <Heading as='h2'>useQuery Examples</Heading>
      <Box as='span'>
        The useQuery hook is used to execute GraphQL queries and manage their
        results in your React components.
      </Box>
      <Heading as='h3'>Example 1 (Basic)</Heading>
      {/* @ts-ignore */}
      <SyntaxHighlighter language='typescript' style={materialDark}>
        {useQueryCode}
      </SyntaxHighlighter>
      <Heading as='h3'>Example 2 (With Variables)</Heading>
      {/* @ts-ignore */}
      <SyntaxHighlighter language='typescript' style={materialDark}>
        {useQueryCodeWithVars}
      </SyntaxHighlighter>
      <Heading as='h3'>Example 3 (Advanced)</Heading>
      {/* @ts-ignore */}
      <SyntaxHighlighter language='typescript' style={materialDark}>
        {useQueryCodeAdvanced}
      </SyntaxHighlighter>
    </VStack>
  );
};

export default useQueryExamples;
