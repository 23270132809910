import React, { FC, useContext } from 'react';
import { CloseButton, Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { AccessibilityType, AccessibleTransKeys } from 'types';
import {
  NotAccessibleBase,
  NotAccessibleContainer,
} from '../../../ui-components';
import { MessagesDesc } from '../../../sub-components/forms';

import { TrainingPlayContext } from './context';

export const Messages: Record<AccessibilityType, AccessibleTransKeys> = {
  NOT_FOUND: 'training:thisTrainingIsDeleted',
  UNAUTHORISED: 'training:trainingNotAvailableToYou',
};

interface IProps {}

const TrainingAccessibleWrapper: FC<IProps> = ({ children }) => {
  const { userProgress, loading, visibility } = useContext(TrainingPlayContext);
  const history = useHistory();

  if (!userProgress && !loading && visibility !== 'public') {
    const type = 'UNAUTHORISED';

    return (
      <Flex py={1} px={3} justify='center'>
        <NotAccessibleContainer>
          <CloseButton ml='auto' onClick={history.goBack} />
          <NotAccessibleBase
            type={type}
            titleKey={Messages[type]}
            descKey={MessagesDesc[type]}
          />
        </NotAccessibleContainer>
      </Flex>
    );
  }

  return <>{children}</>;
};

export default TrainingAccessibleWrapper;
