import React, { FC, useMemo } from 'react';
import { Tabs, TabList, TabPanels, TabPanel, VStack } from '@chakra-ui/react';
import { FlexCard } from 'atoms';
import { BoxHeader } from 'ui-components';
import { HeaderColors } from 'configs';
import {
  Attrition,
  AuditScore,
  CustomTab,
  OverallScore,
  RevenueAnnumPanel,
  RoyaltyPanel,
  TaskComplete,
  TrainingCompletionPanel,
} from './components';
import { useUserEntity } from 'hooks';

interface TabConfig {
  label: string;
  minWidth: string;
  hasNewBadge?: boolean;
}

const LocationLeaderboard: FC = () => {
  const auditsEnabled = useUserEntity((entity) => entity?.features?.audit);
  const tabsConfig = useMemo((): TabConfig[] => {
    return [
      { label: 'Overall Score', minWidth: '176px', hasNewBadge: false },
      { label: 'Task Completion', minWidth: '176px' },
      // { label: 'Revenue/annum', minWidth: '176px' },
      { label: 'Attrition', minWidth: '176px' },
      // { label: 'Royalty', minWidth: '85px' },
      // { label: 'Training Completion', minWidth: '174px' },
      ...(auditsEnabled ? [{ label: 'Audits Score', minWidth: '124px' }] : []),
    ];
  }, [auditsEnabled]);
  return (
    <FlexCard className='location-leaderboard-container' w='full'>
      <VStack spacing={6} align='start' w='full'>
        <BoxHeader title='Location Leaderboard' color={HeaderColors.Yellow} />

        <Tabs variant='unstyled' w='full'>
          <TabList flexWrap='wrap' gap='.5rem'>
            {tabsConfig.map((tab, index) => (
              <CustomTab
                key={index}
                label={tab.label}
                minWidth={tab.minWidth}
                hasNewBadge={tab.hasNewBadge}
              />
            ))}
          </TabList>

          <TabPanels>
            {tabsConfig.map((tab, index) => (
              <TabPanel key={index}>
                {tab.label === 'Overall Score' && <OverallScore />}
                {tab.label === 'Task Completion' && <TaskComplete />}
                {tab.label === 'Revenue/annum' && <RevenueAnnumPanel />}
                {tab.label === 'Attrition' && <Attrition />}
                {tab.label === 'Royalty' && <RoyaltyPanel />}
                {tab.label === 'Training Completion' && (
                  <TrainingCompletionPanel />
                )}
                {tab.label === 'Audits Score' && <AuditScore />}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </VStack>
    </FlexCard>
  );
};

export default LocationLeaderboard;
