import { Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const ZustandEmitInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Event Emit with zustand</Heading>
    </VStack>
  );
};

export default ZustandEmitInfo;
