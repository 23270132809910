import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const ConfigInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Config Folder Overview</Heading>
      <Box>
        The Configuration Folder is a centralized collection of configuration
        files that define various settings, utilities, and constants used across
        the application. By organizing these configurations into dedicated
        files, the module promotes maintainability, scalability, and
        consistency. This approach allows us to manage feature access, audit
        events, default assets, form behaviors, and signature styles
        efficiently. Any other configs that has to be shared across application
        will come here.
      </Box>
    </VStack>
  );
};

export default ConfigInfo;
