import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import RemindsUserLists from './RemindsUserLists';

interface IProps {
  admins?: string[];
  owners?: string[];
  removeAdmin?: (userid: string) => void;
  removeOwner?: (userid: string) => void;
  locationId?: string;
}

const UserListContainer: FC<IProps> = ({
  admins = [],
  owners = [],
  removeAdmin,
  removeOwner,
  locationId,
}) => {
  const isEmpty = !(admins?.length + owners?.length);

  return (
    <Flex
      flexDir='column'
      padding='20px 16px'
      border='1px solid #EFEFEF'
      bg='#FCFCFC'
      borderRadius='9px'
      marginBottom='16px'
    >
      <Box fontSize='12px' fontWeight='500' color='#6F767E'>
        Reminder will be sent to
      </Box>
      {isEmpty ? (
        <Box fontSize='12px' fontWeight='500' color='#6F767E' opacity='0.5'>
          (Select recipients to display their names here.)
        </Box>
      ) : null}

      <RemindsUserLists
        admins={admins}
        owners={owners}
        removeAdmin={removeAdmin}
        removeOwner={removeOwner}
        locationId={locationId}
      />
    </Flex>
  );
};

export default UserListContainer;
