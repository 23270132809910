import { gql } from '@apollo/client';

export const CUSTOMER_RATING = gql`
  query LocationGoogleReviews($locationIds: [String]!) {
    LocationGoogleReviews(locationIds: $locationIds) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        locationId
        uniqueId
        rating
        text
        originalText
        author
        publishDate
        eid
      }
    }
  }
`;

export const LOCATIONS_RATING = gql`
  query LocationsRating(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyLocationMetaInput
  ) {
    LocationMetaPagination(page: $page, perPage: $perPage, filter: $filter) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        locationId
        google {
          placeId
          googleReviewCount
          googleRating
          lastUpdated
          updatedBy
        }
        yelp {
          businessId
          yelpReviewCount
          yelpRating
          lastUpdated
          updatedBy
        }
        createdAt
        updatedAt
        eid
      }
    }
  }
`;
