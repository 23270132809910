import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { useViewAll } from './useViewAll';
import React, { FC } from 'react';
import { ViewType } from './types';
import { ILocationPerformance } from '../../types';

interface IProps {
  type: ViewType;
  data: ILocationPerformance[];
}

const ViewAll: FC<IProps> = ({ data, type }) => {
  const viewAll = useViewAll();
  if (data?.length < 4) {
    return null;
  }
  return (
    <Box
      as='span'
      cursor='pointer'
      color={HeaderColors.DarkBlue}
      fontSize='15px'
      fontWeight={700}
      onClick={() => {
        viewAll({
          data,
          type,
        });
      }}
    >
      View all <ArrowForwardIcon />
    </Box>
  );
};

export default ViewAll;
