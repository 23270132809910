import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface IProps {
  title: string;
  color: string;
}

const Heading: FC<IProps> = ({ title, color }) => {
  return (
    <Flex gap={'16px'} alignItems={'center'}>
      <Box
        borderRadius={'4px'}
        w={'16px'}
        h={'28px'}
        bgColor={color ? color : '#CABDFF'}
      ></Box>
      <Text fontWeight={600} fontSize={'16px'}>
        {title}
      </Text>
    </Flex>
  );
};

export default Heading;
