import { FC } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  Flex,
  Box,
  ModalFooter,
} from '@chakra-ui/react';
import { Heading } from './Filter';
import Loader from 'sub-components/Loader';

interface IProps {
  eid: string;
  handleOperation: (
    operation: string,
    eid: string,
    renameCategoryVisibility?: boolean
  ) => void;
  item: {
    title: string;
    id: number;
    icon: any;
  };
  deleteLoader: any;
  complianceLoader: any;
  isOpen: any;
  onClose: any;
  onOpen?: any;
}

const DeleteCategoryModal: FC<IProps> = ({
  item,
  handleOperation,
  eid,
  deleteLoader,
  complianceLoader,
  isOpen,
  onClose,
  onOpen,
}) => {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      {/* <Flex onClick={onOpen} gap={2} alignItems={'center'}>
        {item?.icon}
        <Text fontWeight={600} fontSize={'13px'}>
          {item?.title}
        </Text>
      </Flex> */}
      <Box backgroundColor={'red.100'}>
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Heading color='#B5E4CA' title='Delete doc category?' />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex>
                <Text color={'#6F767E'}>
                  This will permanently delete the uploaded documents in the
                  category
                </Text>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Flex width={'100%'} gap={2} justifyContent={'center'}>
                <Button onClick={onClose} width={'190px'}>
                  Cancel
                </Button>
                <Button
                  onClick={() => handleOperation('Delete', eid)}
                  width={'190px'}
                  colorScheme='blue'
                  mr={3}
                >
                  {deleteLoader ? <Loader /> : 'Yes'}
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default DeleteCategoryModal;
