import { Box, Center, Flex, Text, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faLocationDot,
  faArrowDownShortWide,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'atoms';
import { AuthRole } from 'authorization';
import { HeaderColors } from 'configs';
import { useUserDataSelector } from 'hooks';
import React, { FC, useState, useMemo } from 'react';
import { ILocationPerformance } from '../../types';
import { ViewType } from './types';

interface IProps {
  data: ILocationPerformance[];
  type: ViewType;
}

const ModalContent: FC<IProps> = ({ data, type }) => {
  const { loggedInUserAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      loggedInUserAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations || [],
    })
  );
  const [sortColumn, setSortColumn] =
    useState<keyof ILocationPerformance>('rank');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const sortedData = useMemo(() => {
    return [...data].sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (sortOrder === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  }, [data, sortColumn, sortOrder]);

  const handleSort = (column: keyof ILocationPerformance) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const getFlexBg = (rate: number) => {
    if (type === 'attrition') {
      return rate < 50
        ? 'rgba(181, 228, 202, 0.4)'
        : 'rgba(228, 181, 181, 0.4)';
    }
    return rate < 50 ? 'rgba(228, 181, 181, 0.4)' : 'rgba(181, 228, 202, 0.4)';
  };

  const getCenterBg = (rate: number) => {
    if (type === 'attrition') {
      return rate < 50 ? 'rgba(131, 191, 110, 1)' : 'rgba(255, 106, 85, 1)';
    }
    return rate < 50 ? 'rgba(255, 106, 85, 1)' : 'rgba(131, 191, 110, 1)';
  };

  return (
    <Flex mt={4} flexDir='column' gap={4}>
      <Box fontWeight={600} fontSize='15px'>
        All your locations at a glance
      </Box>
      <Table
        dataSource={sortedData}
        columns={[
          {
            key: 'rank',
            dataIndex: 'rank',
            title: (
              <Flex
                align='center'
                onClick={() => handleSort('rank')}
                cursor='pointer'
                gap={4}
              >
                <Text ml={1}>Rank</Text>
                <FontAwesomeIcon
                  icon={faArrowDownShortWide as IconProp}
                  color={
                    sortColumn === 'rank' && sortOrder === 'desc'
                      ? HeaderColors.DarkBlue
                      : 'inherit'
                  }
                />
              </Flex>
            ),
            width: 100,
            render: (_, record) => <Box>{record?.rank}</Box>,
          },
          {
            key: 'location',
            dataIndex: 'location',
            title: 'Location',
            render: (_, record) => (
              <Tooltip hasArrow label={record?.locationName}>
                <Flex align='center' gap='5px'>
                  <Flex
                    p='5px 10px'
                    align='center'
                    gap='5px'
                    w='fit-content'
                    borderRadius='7px'
                    cursor='pointer'
                    bg={getFlexBg(record?.rate)}
                  >
                    <Center
                      boxSize='24px'
                      borderRadius='5px'
                      bg={getCenterBg(record?.rate)}
                    >
                      <FontAwesomeIcon
                        icon={faLocationDot as IconProp}
                        color='white'
                      />
                    </Center>
                    <Box as='span' isTruncated maxW='200px'>
                      {record?.locationName}
                    </Box>
                  </Flex>
                  {loggedInUserAuthRole === AuthRole.LOCATION_OWNER &&
                    loggedInUserLocations
                      ?.map((loc) => loc?.eid)
                      ?.includes(record?.locationId) && (
                      <Flex>
                        <Box color={getCenterBg(record?.rate)}>&bull;</Box>
                        <Box>Your location</Box>
                      </Flex>
                    )}
                </Flex>
              </Tooltip>
            ),
          },
          {
            key: 'rate',
            dataIndex: 'rate',
            title: (
              <Flex
                align='center'
                onClick={() => handleSort('rate')}
                cursor='pointer'
              >
                <Text>Rate {type === 'overall' ? '' : '(%)'}</Text>
              </Flex>
            ),
            render: (_, record) => <Box>{record?.rate}</Box>,
          },
        ]}
        pagination={{
          pageSize: sortedData?.length || 10,
          hideOnSinglePage: true,
        }}
      />
    </Flex>
  );
};

export default ModalContent;
