import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { chakraComponents } from 'chakra-react-select';

import { AuthRole, useAuthorization } from 'authorization';
import Dropdown, { SelectOption } from 'atoms/Dropdown';
import {
  AmplitudeEventNames,
  deployEvent,
  CommonAmplitudeEvents,
} from 'shared/amplitudeEvents';

import SearchInput from '../../../atoms/SearchInput';
import { ToggleButton } from './Component';
import TrainingPathList from './TrainingPathList/TrainingPathList';
import MemberTrainingNew from './MemberTrainingNew';
import { AuthRoleFilter } from '../../Teams/members';
import { useLocation } from 'react-router-dom';

export type IButtonValue = 'paths' | 'members';

const Placeholder: {
  [key in IButtonValue]: 'searchTrainingMember' | 'searchTrainingStatus';
} = {
  paths: 'searchTrainingStatus',
  members: 'searchTrainingMember',
};

export type PathShowValue = 'all' | 'supervisedByMe' | 'createdByMe';

interface PathShowProps {
  showValue?: SelectOption<PathShowValue>;
  onShowChange?: (value: SelectOption<PathShowValue>) => void;
}

const PathShowingFilter: FC<PathShowProps> = ({ showValue, onShowChange }) => {
  const { t, i18n } = useTranslation('training');
  const authorize = useAuthorization({});

  const options = useMemo((): SelectOption<PathShowValue>[] => {
    const commonOptions = [
      { label: t('all'), value: 'all' },
      { label: t('supervisedByMe'), value: 'supervisedByMe' },
    ];
    return authorize(
      {
        // permittedFor: 'user',
        permittedRoles: [
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.LOCATION_OWNER,
        ],
      },
      [
        ...commonOptions,
        {
          label: t('createdByMe'),
          value: 'createdByMe',
        },
      ],
      commonOptions
    ) as unknown as SelectOption<PathShowValue>[];
  }, [authorize, i18n.language]);

  return (
    <Box minW='217px' zIndex='6'>
      <Dropdown
        placeholder={t('showing')}
        value={showValue}
        onChange={(e) => {
          deployEvent(AmplitudeEventNames.TRAINING_PATH_FILTER);
          onShowChange?.(e);
        }}
        options={options}
        selectStyles={{
          singleValue: {
            color: '#6f767e',
            fontWeight: '600',
            fontSize: '14px',
          },
          control: {
            borderRadius: '12px',
          },
        }}
        components={{
          // DropdownIndicator: undefined,
          SingleValue: (valProps) => {
            return (
              <chakraComponents.SingleValue {...valProps}>
                {/*// @ts-ignore*/}
                {t('showingBy', {
                  value: valProps.children,
                })}
              </chakraComponents.SingleValue>
            );
          },
        }}
      />
    </Box>
  );
};

interface HProps extends PathShowProps {
  value: IButtonValue;
  onClick: (newValue: IButtonValue) => void;
  query: string;
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;

  filterValue?: SelectOption | null;
  onFilterChange: (value: SelectOption | null) => void;
}

const Header: FC<HProps> = ({
  query,
  onSearch,
  value,
  onClick,
  showValue,
  onShowChange,
  filterValue,
  onFilterChange,
}) => {
  const { t } = useTranslation('training');

  const onSearchFocus = useCallback(() => {
    switch (value) {
      case 'members':
        deployEvent(AmplitudeEventNames.TRAINING_PATH_MEMBER_SEARCH);
        break;
      case 'paths':
        deployEvent(AmplitudeEventNames.TRAINING_PATH_SEARCH);
        break;
    }
  }, [value]);

  return (
    <Flex align='center' gap='12px' paddingBlock='8px'>
      <ToggleButton<IButtonValue>
        button1='paths'
        button2='members'
        permission2={{
          restrictedRoles: AuthRole.WORKER,
        }}
        value={value}
        onClick={onClick}
      />
      <Box flex={1} />
      <SearchInput
        disabled={true}
        size='md'
        width='320px'
        hideShortcuts={true}
        placeholder={t(Placeholder[value])}
        onChange={onSearch}
        value={query}
        onFocus={onSearchFocus}
      />
      {value === 'paths' && (
        <PathShowingFilter showValue={showValue} onShowChange={onShowChange} />
      )}
      {value === 'members' && (
        <Box minW='217px' zIndex='6'>
          <AuthRoleFilter
            filterBy={filterValue}
            onFilterChange={onFilterChange}
          />
        </Box>
      )}
    </Flex>
  );
};

interface ILocState {
  tabSelected: IButtonValue;
}

interface IProps {}

const TrackingContainer: FC<IProps> = () => {
  const { t } = useTranslation(['common']);

  const [value, setValue] = useState<IButtonValue>('paths');
  const [filterValue, setFilterValue] = useState<SelectOption | null>(() => {
    return {
      label: t('common:all'),
      value: 'all',
    };
  });
  const [showValue, setShowValue] = useState<SelectOption<PathShowValue>>();
  const [query, setQuery] = useState('');

  const locState = useLocation<ILocState>();

  useEffect(() => {
    if (locState?.state?.tabSelected) {
      setValue(locState?.state?.tabSelected);
    }
  }, [locState?.state]);

  const onClick = (newValue: IButtonValue) => {
    if (newValue === 'paths') {
      setFilterValue({
        label: t('common:all'),
        value: 'all',
      });
    }
    setValue(newValue);
    setQuery('');

    switch (newValue) {
      case 'members':
        deployEvent(CommonAmplitudeEvents.TRACKING_MEMBERS_TAB);
        break;
      case 'paths':
        deployEvent(CommonAmplitudeEvents.TRACKING_PATH_TAB);
        break;
    }
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  return (
    <div>
      <Header
        value={value}
        onClick={onClick}
        showValue={showValue}
        onShowChange={setShowValue}
        filterValue={filterValue}
        onFilterChange={setFilterValue}
        query={query}
        onSearch={onSearch}
      />

      {value === 'paths' && (
        <TrainingPathList query={query} showValue={showValue} />
      )}
      {value === 'members' && (
        <MemberTrainingNew query={query} filterValue={filterValue} />
      )}
    </div>
  );
};

export default TrackingContainer;
