import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const AssetsFolderInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Assets Folder Overview</Heading>
      <Box fontSize='16px'>
        This folder contains all the assets in the form of files, images,
        videos, lottie animation json files that are used across the project.
      </Box>
    </VStack>
  );
};

export default AssetsFolderInfo;
