import { Box, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';

const YourTodoHeading: FC = () => {
  return (
    <Flex
      w='full'
      justifyContent='space-between'
      alignItems='center'
      pb={4}
      h='80px'
    >
      <Box fontSize='20px' fontWeight={600}>
        Your To-Do's
      </Box>
    </Flex>
  );
};

export default YourTodoHeading;
