import { TooltipProps } from 'recharts-new';
import {
  NameType,
  ValueType,
} from 'recharts-new/types/component/DefaultTooltipContent';
import React, { FC } from 'react';
import { Box, Flex, VStack } from '@chakra-ui/react';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const CustomTooltip: FC<
  TooltipProps<ValueType, NameType> & { hoveredBar: string | null }
> = ({ active, payload, label, hoveredBar }) => {
  if (active && payload && payload.length && hoveredBar) {
    const dataEntry = payload.find((entry) => entry.name === hoveredBar);
    if (dataEntry) {
      return (
        <VStack
          border='.5px solid rgba(239, 239, 239, 1)'
          bg='rgba(252, 252, 252, 1)'
          w='fit-content'
          align='flex-start'
          p='12px 16px'
          borderRadius='8px'
        >
          <Box color='rgba(111, 118, 126, 1)' fontSize='13px' fontWeight={500}>
            {dataEntry?.payload?.location}: Google rating
          </Box>
          <Flex align='center' gap='5px'>
            <Box fontSize='20px' fontWeight={600}>
              {dataEntry?.value}
            </Box>
            <FontAwesomeIcon
              icon={faStar as IconProp}
              color='rgba(244, 228, 105, 1)'
            />
          </Flex>
        </VStack>
      );
    }
  }

  return null;
};

export default CustomTooltip;
