import { Center } from '@chakra-ui/react';
import React, { FC } from 'react';

interface IProps {
  children: React.ReactNode;
}

const Container: FC<IProps> = ({ children }) => {
  return (
    <Center
      flexDir='column'
      bg='#FCFCFC'
      pt='56px'
      pb='16px'
      px='24px'
      borderRadius='20px'
      border='1px solid rgba(239, 239, 239, 1)'
      gap={8}
    >
      {children}
    </Center>
  );
};

export default Container;
