import { AuthRole } from 'authorization';
import { useUserDataSelector, useUserEntity } from 'hooks';
import { useMemo } from 'react';

const ADMIN_ROLES = [
  AuthRole.SUPER_ADMIN,
  AuthRole.ADMIN,
  AuthRole.LOCATION_OWNER,
];

export const useGetLocationIds = (excludeLocationOwner = false) => {
  const locations = useUserEntity((entity) => entity?.locations || []);

  const { loggedInUserAuthRole, loggedInUserLocations = [] } =
    useUserDataSelector((state) => ({
      loggedInUserAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations || [],
    }));

  const effectiveAdminRoles = useMemo(() => {
    if (excludeLocationOwner) {
      return [AuthRole.SUPER_ADMIN, AuthRole.ADMIN];
    }
    return [AuthRole.SUPER_ADMIN, AuthRole.ADMIN, AuthRole.LOCATION_OWNER];
  }, [excludeLocationOwner]);

  const locationIds = useMemo(() => {
    const relevantLocations = effectiveAdminRoles.includes(loggedInUserAuthRole)
      ? locations?.filter((loc) => !loc?.isRoot) || []
      : loggedInUserLocations;

    return relevantLocations.map((loc) => loc.eid);
  }, [
    locations,
    loggedInUserAuthRole,
    loggedInUserLocations,
    effectiveAdminRoles,
  ]);

  return locationIds;
};
