import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const WrappersInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Wrappers used in project</Heading>
      <Box>
        This section will list down the important wrappers being used in the
        project.
      </Box>
    </VStack>
  );
};

export default WrappersInfo;
