import { Box, Flex } from '@chakra-ui/react';
import { useUserDataSelector } from 'hooks';
import React, { FC } from 'react';
import { ExportReportButton } from './components/ExportReport';

interface LocationData {
  location: string;
  overallScore: number; // 0-100
  taskCompletion: number; // 0-100
  attrition: number; // 0-100
  trainingCompletion: number; // 0-100
  age: string; // e.g., "3 months"
  googleRating: number;
  yelpRating: number;
  npsRating: number;
  auditScore: number; // 0-100
}

export interface ReportData {
  month: string;
  duration: string;
  data: LocationData[];
}

const Heading: FC<{
  exportRef: React.RefObject<HTMLDivElement>;
}> = ({ exportRef }) => {
  const { loggedInUserName } = useUserDataSelector((state) => ({
    loggedInUserName: state?.name,
    loggedInUserAuthRole: state?.authRole,
    loggedInUserLocations: state?.locations,
  }));

  // const description = () => {
  //   if (
  //     [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(loggedInUserAuthRole)
  //   ) {
  //     return (
  //       <Box color='#6F767E' fontSize='15px'>
  //         What would you like to view today?
  //       </Box>
  //     );
  //   } else {
  //     const locations = loggedInUserLocations ?? [];
  //     const firstLocation = locations[0]?.name;
  //     const additionalLocations = locations.slice(1);
  //     const additionalCount = additionalLocations.length;
  //     return (
  //       <Flex fontWeight={500} fontSize='15px'>
  //         <Box color='rgba(111, 118, 126, 1)'>
  //           {locations?.length === 1 ? 'Your Location' : 'Your Locations'}:
  //         </Box>
  //         &nbsp;
  //         <Box>{firstLocation}</Box>
  //         {additionalCount > 0 && (
  //           <Tooltip
  //             hasArrow
  //             label={additionalLocations.map((loc) => loc?.name).join(', ')}
  //           >
  //             <Box cursor='pointer'>&nbsp;(+{additionalCount})</Box>
  //           </Tooltip>
  //         )}
  //       </Flex>
  //     );
  //   }
  // };

  return (
    <Flex
      w='full'
      justifyContent='space-between'
      alignItems='center'
      pb={4}
      h='80px'
    >
      <Box fontSize='32px' fontWeight={600}>
        Welcome back {loggedInUserName}!
      </Box>
      {/* <Flex flexDir='column' gap={4}>
        {description()}
      </Flex> */}
      <ExportReportButton exportRef={exportRef} />
    </Flex>
  );
};

Heading.displayName = 'dashboard/Heading';

export default Heading;
