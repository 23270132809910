import { List, Text } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { SharedProps } from './share-member.types';
import { ShareMemberEntity } from './share-member.graphql';
import { TFuncKey } from 'i18next';
import ShareMemberSkeleton from './ShareMemberSkeleton';
import ShareLocItem from './ShareLocItem';
import NoData from './NoData';

interface IProps extends SharedProps {
  search?: string;
  loading?: boolean;
  members: ShareMemberEntity[];
  i18NotFoundKey: TFuncKey<('form' | 'location')[], undefined>;
  i18NotFoundDescKey: TFuncKey<('form' | 'location')[], undefined>;
}

const ShareLocationList: FC<IProps> = ({
  search,
  loading,
  i18NotFoundDescKey,
  i18NotFoundKey,
  members,
  formCreatorId,
  onSendClick,
  onClickHandler,
}) => {
  const locations = useMemo(() => {
    return members.filter((member) => member.type === 'branch');
  }, [members, search]);

  return (
    <ShareMemberSkeleton isLoaded={!loading}>
      {locations.length ? (
        <List overflow='scroll' spacing={6} pl={0} maxHeight='22%'>
          {locations.map((location) => {
            return (
              <ShareLocItem
                key={location.eid}
                data={location}
                onSendClick={onSendClick}
              />
            );
          })}
        </List>
      ) : (
        <NoData
          search={search}
          onClickHandler={onClickHandler}
          formCreatorId={formCreatorId}
          i18NotFoundKey={i18NotFoundKey}
          i18NotFoundDescKey={i18NotFoundDescKey}
          message='No locations found'
        />
      )}
    </ShareMemberSkeleton>
  );
};

export default ShareLocationList;
