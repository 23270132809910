import React, { createContext, FC, useContext, useRef } from 'react';

import { useStore } from '../../../../zustandStore';
import { createNexusBoardStore, NexusBoardAction } from './nexus-board.store';

type NexusBoardStore = ReturnType<typeof createNexusBoardStore>;

const NexusBoardContext = createContext<NexusBoardStore | null>(null);

const NexusBoardProvider: FC = ({ children }) => {
  const storeRef = useRef<NexusBoardStore>();
  if (!storeRef.current) {
    storeRef.current = createNexusBoardStore();
  }
  return (
    <NexusBoardContext.Provider value={storeRef.current}>
      {children}
    </NexusBoardContext.Provider>
  );
};

function useNexusBoardContext<T>(selector: (state: NexusBoardAction) => T): T {
  const store = useContext(NexusBoardContext);
  if (!store) throw new Error('Missing NexusBoardContext.Provider in the tree');
  return useStore(store, selector);
}

export { NexusBoardProvider, useNexusBoardContext };
