import React from 'react';
import {
  Box,
  Center,
  Flex,
  forwardRef,
  useFormControl,
} from '@chakra-ui/react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectOption } from '../../../../../../../atoms';

interface IProps {
  value?: SelectOption;
  placeholder?: string;
}

const CategorySelectButton = forwardRef<IProps, 'div'>(
  ({ value, placeholder, ...rest }, ref) => {
    const controlProps = useFormControl(rest);

    return (
      <Flex
        ref={ref}
        gap={2}
        width='100%'
        alignItems='center'
        bg='#FFFFFF'
        minH='48px'
        borderRadius='12px'
        borderWidth={2}
        borderColor='inherit'
        paddingInline='12px'
        color={value?.label ? undefined : '#A3AFC1'}
        _expanded={{
          // borderColor: 'rgba(42, 133, 255, 0.35)',
          // boxShadow: '0 0 0 1px #2A85FF',
          svg: {
            transform: 'rotate(180deg)',
          },
        }}
        _hover={{ bg: 'gray.100', borderColor: 'gray.300' }}
        _invalid={{
          bg: 'rgba(255, 188, 153, 0.3)',
          color: 'red.500',
          borderWidth: 0,
          boxShadow: '0 0 0 1px #FF6A55',
        }}
        {...controlProps}
      >
        <Box flex={1} fontWeight={value?.label ? '500' : undefined} isTruncated>
          {value?.label || placeholder}
        </Box>
        <Center boxSize='24px'>
          <FontAwesomeIcon
            fontSize={16}
            color='#6F767E'
            icon={faChevronDown as IconProp}
          />
        </Center>
      </Flex>
    );
  }
);

export default CategorySelectButton;
