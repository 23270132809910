import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  InputGroup,
  Text,
  InputLeftElement,
  HStack,
  Badge,
  Tooltip,
  useOutsideClick,
  Center,
  VStack,
} from '@chakra-ui/react';
import { SelectOption } from 'atoms';
import { CheckIcon, SearchIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  options: SelectOption[];
  selectedOptions: SelectOption[];
  onSelectionChange: (selected: SelectOption[]) => void;
}

const ExportLocationSelect: React.FC<IProps> = ({
  options,
  onSelectionChange,
  selectedOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [tempSelectedIds, setTempSelectedIds] = useState<string[]>(
    selectedOptions.map((loc) => loc?.value)
  );

  const [searchTerm, setSearchTerm] = useState<string>('');

  const inputRef = useRef<HTMLInputElement>(null);
  const inputGroupRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: popoverRef,
    handler: () => setIsOpen(false),
  });

  const [inputGroupWidth, setInputGroupWidth] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (isOpen && inputGroupRef.current) {
      const width = inputGroupRef.current.getBoundingClientRect().width;
      setInputGroupWidth(width);
    }
  }, [isOpen]);

  useEffect(() => {
    setTempSelectedIds(selectedOptions.map((loc) => loc?.value));
  }, [selectedOptions]);

  const filteredOptions = options?.filter((loc) =>
    loc?.label?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleToggleOption = (id: string) => {
    setTempSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const allIds = filteredOptions.map((loc) => loc?.value);
      setTempSelectedIds((prev) => Array.from(new Set([...prev, ...allIds])));
    } else {
      const filteredIds = filteredOptions.map((loc) => loc?.value);
      setTempSelectedIds((prev) =>
        prev.filter((id) => !filteredIds.includes(id))
      );
    }
  };

  const isAllSelected =
    filteredOptions.length > 0 &&
    filteredOptions.every((loc) => tempSelectedIds.includes(loc?.value));

  const handleSelectButton = () => {
    const selected = options.filter((loc) =>
      tempSelectedIds.includes(loc.value)
    );
    onSelectionChange(selected);
    setIsOpen(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const selectedBadges = selectedOptions.slice(0, 3);
  const extraCount = selectedOptions.length - 3;
  const extraLocations = selectedOptions.slice(3);

  return (
    <Flex flexDir='column' w='full' gap={4}>
      <Popover
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        placement='bottom-start'
        closeOnBlur={false}
        initialFocusRef={inputRef}
      >
        <PopoverTrigger>
          <InputGroup w='full' ref={inputGroupRef}>
            <InputLeftElement pointerEvents='none'>
              <SearchIcon color='gray.500' />
            </InputLeftElement>
            <Input
              ref={inputRef}
              w='full'
              placeholder='Search locations'
              value={searchTerm}
              onChange={handleSearchChange}
              bg='rgba(244, 244, 244, 1)'
              onClick={() => setIsOpen(true)}
            />
          </InputGroup>
        </PopoverTrigger>
        {isOpen && (
          <PopoverContent
            ref={popoverRef}
            p={4}
            width={inputGroupWidth ? `${inputGroupWidth}px` : 'auto'}
            maxW='none'
            borderRadius='12px'
          >
            {filteredOptions.length > 0 && (
              <>
                <HStack align='center' justify='space-between' p={2}>
                  <Box fontSize='13px' fontWeight={500}>
                    SELECT ALL
                  </Box>
                  <Checkbox
                    isChecked={isAllSelected}
                    onChange={handleSelectAll}
                    mb={2}
                  />
                </HStack>
                <VStack
                  maxH='15vh'
                  overflowY='auto'
                  mb={4}
                  align='flex-start'
                  w='full'
                >
                  {filteredOptions.map((loc, index) => (
                    <Box key={loc?.value} w='full'>
                      <Flex
                        align='center'
                        p={2}
                        _hover={{ bg: 'gray.100' }}
                        cursor='pointer'
                        onClick={() => handleToggleOption(loc?.value)}
                      >
                        <Flex align='center' flex='1' gap={2}>
                          <Center
                            boxSize='32px'
                            borderRadius='50%'
                            bg='rgba(255, 216, 141, 1)'
                          >
                            <FontAwesomeIcon
                              icon={faLocationDot as IconProp}
                              color='white'
                            />
                          </Center>
                          <Text>{loc?.label}</Text>
                        </Flex>
                        <Checkbox
                          isChecked={tempSelectedIds.includes(loc?.value)}
                          onChange={() => handleToggleOption(loc?.value)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Flex>
                      {index < filteredOptions.length - 1 && <Divider />}
                    </Box>
                  ))}
                </VStack>
                <Button
                  colorScheme='blue'
                  width='100%'
                  onClick={handleSelectButton}
                >
                  Select
                </Button>
              </>
            )}
            {/* If no data, display a message */}
            {filteredOptions.length === 0 && (
              <Box textAlign='center' color='gray.500'>
                No locations found.
              </Box>
            )}
          </PopoverContent>
        )}
      </Popover>
      {/* Badges for selected locations */}
      <Box mb={4}>
        <Flex id='badge-hstack' flexWrap='wrap' gap={1}>
          {selectedBadges?.map((loc) => (
            <Badge
              key={loc?.value}
              bg='rgba(181, 228, 202, 1)'
              borderRadius='8px'
              height='32px'
              px={3}
              display='flex'
              alignItems='center'
            >
              <CheckIcon color='black' mr={2} />
              <Text>{loc?.label}</Text>
            </Badge>
          ))}
          {extraCount > 0 && (
            <Tooltip
              label={extraLocations?.map((loc) => loc?.label).join(', ')}
              aria-label='More selected locations'
            >
              <Badge
                bg='rgba(181, 228, 202, 1)'
                borderRadius='8px'
                height='32px'
                px={3}
                display='flex'
                alignItems='center'
                cursor='pointer'
              >
                +{extraCount} selected
              </Badge>
            </Tooltip>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export default ExportLocationSelect;
