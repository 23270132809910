import React, { FC, useEffect } from 'react';
import { List, ListItem } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import StepListingItem, { ICompletedTask } from './StepListingItem';
import { TaskItemEntity } from '../../../../../TaskDetails/components/TaskOverview/task-item.types';
import { updateTaskProgress } from '../../../../services/MyTasks.service';
import {
  SESSION_BY_ID_FOR_MY_TASK_DETAILS,
  TASK_BY_ID_FOR_MY_TASK_DETAILS,
} from '../../../../services/my-tasks.graphql';
import { ISessionByIdForMyTasks } from 'pages/TasksDashboard/MyTasks/services/my-tasks-types';
import LoaderOverlay from 'ui-components/LoaderOverlay';
import { ApolloQueryResult, QueryLazyOptions } from '@apollo/client';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

import { taskRepeat } from 'utils/taskRepeat';
import { RepeatDetails } from 'sub-components/tasks/create-task/task.types';
import { useUserDataSelector, useUserEntity } from 'hooks';

interface IProps {
  event?: any;
  data: ISessionByIdForMyTasks | undefined;
  id: string;
  isLoading: boolean;
  shouldTriggerRefetch: boolean;
  setShouldTriggerRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refetch:
    | ((
        variables?:
          | Partial<{
              eid: string;
            }>
          | undefined
      ) => Promise<ApolloQueryResult<ISessionByIdForMyTasks>>)
    | undefined;
}

const StepListing: FC<IProps> = ({
  data,
  id,
  isLoading,
  shouldTriggerRefetch,
  setIsLoading,
  setShouldTriggerRefetch,
  refetch,
  event,
}) => {
  const history = useHistory();
  const loggedInUserLocations = useUserDataSelector(
    (state) => state?.locations
  );
  const businessName = useUserEntity((entity) => entity?.name);

  const {
    execute,
    data: updateTaskProgressData,
    loading,
  } = updateTaskProgress();

  const taskItems: TaskItemEntity[] = data?.SessionById?.taskItems || [];

  const completedTask: ICompletedTask[] =
    data?.SessionById?.currentUserProgress?.completedTasks || [];

  useEffect(() => {
    if (
      updateTaskProgressData &&
      updateTaskProgressData?.UpdateUserTaskProgress &&
      updateTaskProgressData?.UpdateUserTaskProgress?.eid &&
      updateTaskProgressData?.UpdateUserTaskProgress?.currentUserProgress
        ?.completedPercent === 100
    ) {
      let totalTasksCompleted =
        updateTaskProgressData?.UpdateUserTaskProgress?.currentUserProgress
          ?.completedTasks?.length || 0;
      const baseProperties = {
        task_id: id,
        task_completion_funnel_id: 2,
        total_tasks:
          updateTaskProgressData?.UpdateUserTaskProgress?.taskItems?.length ||
          0,
        total_forms:
          updateTaskProgressData?.UpdateUserTaskProgress?.taskItems?.filter(
            (item) => item?.type === 'form'
          )?.length || 0,
        total_chapters:
          updateTaskProgressData?.UpdateUserTaskProgress?.taskItems?.filter(
            (item) => item?.type === 'sop'
          )?.length || 0,
        total_to_do_items:
          updateTaskProgressData?.UpdateUserTaskProgress?.taskItems?.filter(
            (item) => item?.type === 'todo'
          )?.length || 0,
        due_date: updateTaskProgressData?.UpdateUserTaskProgress?.dueDate || '',
        days_till_due_date: '',
        repeat: taskRepeat(
          updateTaskProgressData?.UpdateUserTaskProgress?.task
            ?.repeatDetails as RepeatDetails<Date>
        ),
        total_tasks_completed: totalTasksCompleted,
      };
      deployEvent(AmplitudeEventNames.TASK_COMPLETE, {
        event: baseProperties,
        profile: {
          total_tasks_completed: totalTasksCompleted,
        },
        super: {
          total_tasks_completed: totalTasksCompleted,
          location_name:
            loggedInUserLocations?.map((loc) => loc?.name)?.join(', ') || '',
          business_name: businessName,
        },
      });
    }
  }, [updateTaskProgressData, loggedInUserLocations, businessName]);

  /**
   * Handles click events on task items.
   *
   * For 'navigate' type, redirects to the task's detailed page unless it's a 'todo' task because todo task does not have redirection.
   * For 'update' type, updates the task progress for non-completed 'todo' or 'sop' tasks.
   *
   * @param {TaskItemEntity} item - The task item that was clicked.
   * @param {'navigate' | 'update'} type - The type of action to be performed.
   * @param {boolean} [isTaskCompleted=false] - Indicates if the task is already completed.
   */
  const onClick = async (
    item: TaskItemEntity,
    type: 'navigate' | 'update',
    isTaskCompleted?: boolean
  ) => {
    if (type === 'navigate') {
      if (item.type !== 'todo') {
        history.push(
          `/tasks/my-tasks/play/${id}/${item?.eid}/${data?.SessionById?.eid!}/${
            item?.type
          }`
        );
      }
      return;
    }

    if ((item.type === 'todo' || item.type === 'sop') && !isTaskCompleted) {
      try {
        let res = await execute({
          // awaitRefetchQueries: true,
          // refetchQueries: [
          //   {
          //     query: SESSION_BY_ID_FOR_MY_TASK_DETAILS,
          //     variables: { eid: data?.SessionById?.eid! },
          //   },
          // ],
          variables: {
            input: {
              contentId: item?.eid,
              sessionId: data?.SessionById?.eid!,
            },
          },
        });
        if (res?.data?.UpdateUserTaskProgress?.eid) {
          deployEvent(AmplitudeEventNames.TASK_STEP_COMPLETE, {
            task_completion_funnel_id: 2,
            task_id: id,
            task_type: item?.type || '',
            percent_completion: +(
              res?.data?.UpdateUserTaskProgress?.currentUserProgress
                ?.completedPercent || 0
            )?.toFixed(0),
            total_steps:
              res?.data?.UpdateUserTaskProgress?.taskItems?.length || 0,
            steps_completed:
              (
                res?.data?.UpdateUserTaskProgress?.currentUserProgress
                  ?.completedTasks || []
              )?.length || 0,
          });
        }
        setShouldTriggerRefetch(true);
        setIsLoading(true);
        refetch?.();
      } catch (e) {
        console.error('Error updating task progress:', e);
      }
    }
  };

  return (
    <LoaderOverlay isLoading={loading || isLoading}>
      <List
        id='my-task-details-step-listing'
        overflowY='auto'
        maxH='52vh'
        pt={2}
      >
        {taskItems?.map((item, index: number) => (
          <ListItem
            px={6}
            key={item.eid}
            id={`my-task-details-step-listing-${index}`}
            _notLast={{
              '> div ': {
                borderBottom: '1px solid #EFEFEF',
              },
            }}
            _hover={{
              bg: '#EFEFEF80',
            }}
          >
            <StepListingItem
              item={item}
              createdByName={data?.SessionById?.task?.createdByUser?.name}
              completedTasks={completedTask}
              onClick={(type: 'update' | 'navigate', isTaskCompleted) =>
                onClick?.(item, type, isTaskCompleted)
              }
              event={event}
            />
          </ListItem>
        ))}
      </List>
    </LoaderOverlay>
  );
};

StepListing.displayName = 'MyTasks/Details/SC/StepListing';

export default StepListing;
