import React, { FC } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { useSafeState } from '../../../hooks';
import { useScrollHandler } from './useScrollHandler';

type Direction = 'left' | 'right';

interface IProps {}

const Navigation: FC<IProps> = () => {
  const [status, setStatus] = useSafeState<Direction>();

  function handleResize(ref: HTMLElement) {
    const { scrollLeft, clientWidth, scrollWidth } = ref;
    if (Math.floor(scrollLeft) === Math.floor(scrollWidth - clientWidth)) {
      setStatus('right');
    } else if (scrollLeft === 0) {
      setStatus('left');
    } else {
      setStatus(undefined);
    }
  }

  useScrollHandler(handleResize, () =>
    document.getElementById('sources-citations')
  );

  const getScrollAmount = (
    direction: Direction,
    element: HTMLElement
  ): number => {
    const newLeft = element.clientWidth - (element.clientWidth % 260);
    return direction === 'right' ? newLeft : -newLeft;
  };

  const handleScrollButton = (direction: Direction) => {
    const element = document.getElementById('sources-citations');

    if (element) {
      const scrollAmount = getScrollAmount(direction, element);
      element.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <Flex gap={1}>
      <IconButton
        size='sm'
        isDisabled={status === 'left'}
        style={{
          backgroundColor: 'white',
        }}
        aria-label='prev'
        icon={
          <FontAwesomeIcon icon={faChevronLeft as IconProp} color='#6F767E' />
        }
        onClick={() => handleScrollButton('left')}
      />

      <IconButton
        size='sm'
        isDisabled={status === 'right'}
        style={{
          backgroundColor: 'white',
        }}
        aria-label='next'
        icon={
          <FontAwesomeIcon icon={faChevronRight as IconProp} color='#6F767E' />
        }
        onClick={() => handleScrollButton('right')}
      />
    </Flex>
  );
};

export default Navigation;
