/**
 * Handles special characters in email addresses for URL encoding/decoding
 */
export class EmailEncoder {
  /**
   * Encodes an email address for safe URL transmission
   * @param email - The email address to encode
   * @returns Encoded email string
   */
  static encode(email: string): string {
    if (!email) return '';

    return encodeURIComponent(email)
      .replace(/\+/g, '%2B')
      .replace(/\!/g, '%21')
      .replace(/\*/g, '%2A')
      .replace(/\'/g, '%27')
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29')
      .replace(/\~/g, '%7E');
  }

  /**
   * Decodes an encoded email address back to its original form
   * @param encodedEmail - The encoded email string
   * @returns Decoded email address
   */
  static decode(encodedEmail: string): string {
    if (!encodedEmail) return '';

    return decodeURIComponent(encodedEmail)
      .replace(/ /g, '+')
      .replace(/%21/g, '!')
      .replace(/%2A/g, '*')
      .replace(/%27/g, "'")
      .replace(/%28/g, '(')
      .replace(/%29/g, ')')
      .replace(/%7E/g, '~');
  }
}
