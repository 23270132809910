import { useEffect } from 'react';
import { AI_SEARCH_INTRO, getAiSearchPrefer } from '../configs';
import { isNullOrUndefined } from '../utils/objectHelper';
import useSafeState from './useSafeState';

type AiSearchPrefer = readonly [
  boolean | undefined,
  (nextValue: boolean) => void
];

export const useAiSearchPrefer = (): AiSearchPrefer => {
  const [state, updateState] = useSafeState<boolean>();

  useEffect(() => {
    const callback = () => updateState(getAiSearchPrefer());

    callback();

    window.addEventListener('storage', callback);
    return () => window.removeEventListener('storage', callback);
  }, []);

  const update = (value: boolean) => {
    updateState(value);

    if (isNullOrUndefined(value)) {
      window.localStorage.removeItem(AI_SEARCH_INTRO);
      window.dispatchEvent(
        new StorageEvent('storage', {
          key: AI_SEARCH_INTRO,
          newValue: null,
        })
      );
    } else {
      window.localStorage.setItem(AI_SEARCH_INTRO, value?.toString());
      window.dispatchEvent(
        new StorageEvent('storage', {
          key: AI_SEARCH_INTRO,
          newValue: value?.toString(),
        })
      );
    }
  };

  return [state, update] as const;
};
