import React, { FC } from 'react';
import { Accordion } from '@chakra-ui/react';

import ResultItem from './ResultItem';
import { AiResponse } from '../ai-search.types';

interface IProps {
  results?: AiResponse[];
}

const RecentSearchList: FC<IProps> = ({ results }) => {
  return (
    <Accordion reduceMotion allowToggle>
      {results?.map((item) => (
        <ResultItem key={item.eid} data={item} />
      ))}
    </Accordion>
  );
};

export default RecentSearchList;
