// @ts-ignore
import React, { FC, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { getTemplateById, TemplateDataResponse } from './form.graphql';
import { CreateFormComponent, FormCreationRef } from './create-form';
import { templateToForm } from './dataConvert';

interface IProps {}

const CreateFromTemplate: FC<IProps> = () => {
  const toast = useToast({
    position: 'top-right',
  });
  const formRef = useRef<FormCreationRef>(null);
  const eid = useParams<{ id: string }>().id;

  const { loading } = useQuery<TemplateDataResponse>(getTemplateById, {
    variables: {
      eid: eid,
    },

    onCompleted: (response) => {
      const newValues = templateToForm(response.FormById);
      formRef.current?.initForm(newValues);
    },
    onError: (error) => {
      error?.message &&
        toast({
          status: 'warning',
          title: error?.message,
        });
    },
  });

  return (
    <CreateFormComponent ref={formRef} fromTemplate showInitialize={loading} />
  );
};

CreateFromTemplate.displayName = 'sc/f/cft/CreateFromTemplate';

export default CreateFromTemplate;
