import { FC, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';
import { VersionHistoryType } from '../../../types/compliance.types';
import { Heading } from '../Filter';
import VersionHistoryList from './VersionHistoryList';
import { useLazyQuery } from '@apollo/client';
import { GET_DOCUMENT_VERSION } from 'sub-components/nexus/Compliance/query/compliance.graphql';
import { Loader } from 'sub-components';

interface IProps {
  complianceId: string;
}

const VersionHistoryModal: FC<IProps> = ({ complianceId }) => {
  const [complianceData, setComplianceData] = useState<VersionHistoryType>({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [getVersionHistory, { loading: versionLoader }] = useLazyQuery(
    GET_DOCUMENT_VERSION,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setComplianceData(data?.EntityComplianceById);
      },
    }
  );

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
          !complianceData?.eid &&
            getVersionHistory({
              variables: {
                eid: complianceId,
              },
            });
        }}
        fontSize={'12px'}
        p={'8px 12px'}
        borderRadius={'8px'}
        border={'1px solid #EFEFEF'}
        bgColor={'#FCFCFC'}
        minW={'fit-content'}
        h={'28px'}
      >
        View doc
      </Button>
      <Box>
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader mt={'1.5rem'}>
              <Heading color='#B5E4CA' title='Version history' />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {versionLoader || !complianceData?.eid ? (
                <Flex position={'relative'} h={'500px'}>
                  <Flex
                    height={'100%'}
                    width={'100%'}
                    left={'50%'}
                    top={'50%'}
                    position={'fixed'}
                    h={'500px'}
                  >
                    <Loader />
                  </Flex>
                </Flex>
              ) : (
                <Flex flexDir={'column'}>
                  <Text fontWeight={600} fontSize={'18px'}>
                    {`${
                      complianceData?.location?.name
                        ? complianceData?.location?.name
                        : 'HQ'
                    }, ${complianceData?.category?.category}`}
                  </Text>
                  <Flex py={'14px'} overflowY={'auto'} h={'500px'}>
                    <VersionHistoryList data={complianceData} />
                  </Flex>
                </Flex>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default VersionHistoryModal;
