import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';

const data = [
  'How to bake a cake?',
  'What are the steps to turn on the oven?',
  'Why did the cooker turn off?',
];

interface IProps {
  onClick?: (value: string) => void;
}

const PopularSearches: FC<IProps> = ({ onClick }) => {
  return (
    <>
      <Box fontSize='12px' fontWeight='500' color='#6F767E'>
        Popular searches:
      </Box>

      <Flex gap={4} fontSize='15px' fontWeight='500'>
        {data.map((item, i) => (
          <Flex
            flex={1}
            align='center'
            key={i}
            padding='16px'
            bg='#F6F3FF'
            border='1px solid #EFEFEF'
            borderRadius='12px'
            boxShadow='0px 0px 2.3px 0px #00000029'
            cursor='pointer'
            _hover={{
              boxShadow: '0px 0px 5.3px 0px #00000029',
            }}
            onClick={() => onClick?.(item)}
          >
            {item}
          </Flex>
        ))}
      </Flex>
    </>
  );
};

export default PopularSearches;
