import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const SharedFolderOverview: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Shared Folder Overview</Heading>
      <Box>
        The shared folder serves as a centralized repository for actions/events
        tracking for mixpanel and amplitude, common graphql queries used across
        application, color codes used across entire project, json file for
        custom login page for specific clients, event names that use eventBus
        based architecture.
      </Box>
    </VStack>
  );
};

export default SharedFolderOverview;
