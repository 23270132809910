import React, { FC } from 'react';
import { Checkbox, Flex, Image, Text, Box, Divider } from '@chakra-ui/react';
import { SelectedMember } from '../../ComplianceListContainer';
import { getImage } from 'utils';
import { UserEntityData } from 'shared/graphql/shared-types';

interface MemberListProps {
  members: UserEntityData[];
  selectedUsers: SelectedMember[];
  onMemberChange: (eid: string) => void;
}

const MemberList: FC<MemberListProps> = ({
  members,
  selectedUsers,
  onMemberChange,
}) => {
  return (
    <Flex h='250px' overflowY='auto' flexDir='column' mt={2}>
      {members.map((member) => {
        const isChecked = selectedUsers.some((user) => user.eid === member.eid);
        return (
          <Box key={member.eid} mt={2}>
            <Flex justifyContent='space-between' alignItems='center'>
              <Flex alignItems='center' gap={2}>
                <Image
                  src={getImage('', member.name)}
                  alt={member.name}
                  width='36px'
                  height='36px'
                  borderRadius='50%'
                  backgroundColor='gray.200'
                />
                <Flex flexDir='column'>
                  <Text fontWeight={600} fontSize='14px' lineHeight={'16.94px'}>
                    {member.name}
                  </Text>
                  <Flex>
                    <Text
                      color={'#92929D'}
                      fontWeight={500}
                      lineHeight={'14.52px'}
                      fontSize={'13px'}
                    >
                      {member.role} &bull;{' '}
                      {member.authRole.charAt(0).toUpperCase() +
                        member.authRole.substring(1)}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Checkbox
                isChecked={isChecked}
                onChange={() => onMemberChange(member.eid)}
                iconSize={'24px'}
                size={'lg'}
              />
            </Flex>
            <Divider mt='10px' />
          </Box>
        );
      })}
    </Flex>
  );
};

export default MemberList;
