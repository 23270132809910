import { Metrics } from '../types';

export const selectedColumnsFn = (metrics: Metrics) => {
  const { age, attrition, auditScore, customerFeedback, taskCompletion } =
    metrics;
  const columns: string[] = [];

  if (taskCompletion) {
    columns.push('Task Completion');
  }
  if (attrition) {
    columns.push('Attrition');
  }
  if (customerFeedback) {
    columns.push('Customer Feedback');
  }
  if (age) {
    columns.push('Age');
  }
  if (auditScore) {
    columns.push('Audit Score');
  }

  if (columns.length > 0) {
    columns.unshift('Location');
  }

  return columns;
};
