import { useUserEntity } from 'hooks';
import React, { FC, useMemo } from 'react';
import { useNexusBoardContext } from 'sub-components/nexus/NexusBase/store/context';
import { EntityAttritionAnalyticsItem } from 'sub-components/nexus/NexusBase/types';
import Container from './Container';
import { ILocationPerformance } from '../types';
import ViewAll from './ViewAll';
import SingleLocationFooter from './SingleLocationFooter';
import { RenderLowest, RenderScoreCards } from '.';

const Attrition: FC = () => {
  const attritionData = useNexusBoardContext((state) => state.attritionData);

  const locations = useUserEntity((entity) => entity?.locations);

  const filteredAttritionData = useMemo(() => {
    let filteredLocations =
      locations?.filter((loc) => loc?.locationStatus !== 'preLaunch') || [];
    return (
      attritionData?.filter((attr) =>
        filteredLocations?.some((loc) => attr?.eid === loc?.eid)
      ) || []
    );
  }, [locations, attritionData]);

  const processAttritionData = (
    attritionData: EntityAttritionAnalyticsItem[]
  ): {
    completeData: ILocationPerformance[];
    highestPerforming: ILocationPerformance[];
    lowestPerforming: ILocationPerformance[];
  } => {
    if (!attritionData || attritionData.length === 0) {
      return {
        completeData: [],
        highestPerforming: [],
        lowestPerforming: [],
      };
    }

    const validAttritionData = attritionData.filter(
      (item) => item.attrition !== undefined && item.attrition !== null
    );

    const performanceData: ILocationPerformance[] = validAttritionData.map(
      (item, index) => ({
        locationId: item.eid,
        locationName: item.name,
        rate: item.attrition,
        rank: 0,
      })
    );

    performanceData.sort((a, b) => a.rate - b.rate);

    performanceData.forEach((item, index) => {
      item.rank = index + 1;
    });

    const highestPerforming = performanceData.slice(0, 3) || [];

    const lowestPerforming = performanceData.slice(3) || [];

    return {
      completeData: performanceData,
      highestPerforming,
      lowestPerforming,
    };
  };
  const { completeData, highestPerforming, lowestPerforming } =
    processAttritionData(filteredAttritionData);

  return (
    <Container>
      <RenderScoreCards completeData={highestPerforming} type='attrition' />
      <RenderLowest remainingLocations={lowestPerforming} />
      {completeData?.length < 3 && <SingleLocationFooter />}
      <ViewAll data={completeData} type='attrition' />
    </Container>
  );
};

export default Attrition;
