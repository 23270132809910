import { useReactiveVar } from '@apollo/client';
import { Box, Checkbox, Divider, Flex, Image } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { userObj } from 'sop-commons/src/client';
import useCombinedStore from 'zustandStore/store';
import SetSeeDataContext from './SetSeeModalStore/set-see-data-context';

const PublicCheckbox: FC = () => {
  const loggedInUserAuthRole = useUserDataSelector((state) => state?.authRole);
  const { t } = useTranslation(['common']);
  const setSeeCtx = useContext(SetSeeDataContext);
  const {
    customSectionExpand,
    sameAsParent,
    visibilityScope,
    visibilitySelection,
    updateCustomSectionExpand,
    updateVisibilitySelection,
  } = useCombinedStore();

  useEffect(() => {
    if (
      loggedInUserAuthRole === AuthRole?.LOCATION_OWNER &&
      visibilitySelection
    ) {
      updateVisibilitySelection({
        ...visibilitySelection,
        visibility: 'private',
      });
    }
  }, []);

  const publicCheckboxHandler = () => {
    if (
      (sameAsParent &&
        (visibilityScope === 'subFolder' || visibilityScope === 'chapter')) ||
      loggedInUserAuthRole === AuthRole?.LOCATION_OWNER
    ) {
      return;
    }
    let selectionType = visibilitySelection?.visibility;
    if (selectionType === 'public') {
      updateVisibilitySelection({
        ...visibilitySelection,
        locations: [],
        roles: [],
        users: [],
        visibility: 'private',
      });
      updateCustomSectionExpand(true);
      // setSeeCtx?.updateExpandAccordion('1');
    } else {
      updateCustomSectionExpand(false);
      // setSeeCtx?.updateExpandAccordion('0');
      updateVisibilitySelection({
        ...visibilitySelection,
        locations: [],
        roles: [],
        users: [],
        visibility: 'public',
      });
    }
    setSeeCtx?.updateSelectionType(
      selectionType === 'private' ? 'public' : 'private'
    );
    setSeeCtx?.updateConditionType('or');
    setSeeCtx?.updateShowCondition(false);
    setSeeCtx?.updateSelectedFirstDropdown({
      label: 'Location',
      value: 'Location',
    });
    setSeeCtx?.updateSelectedLocationsList([]);
    setSeeCtx?.updateSelectedMembersList([]);
    setSeeCtx?.updateSelectedRolesList([]);
  };
  // if (
  //   (userData?.authRole === AuthRole.SUPER_ADMIN ||
  //     userData?.authRole === AuthRole.ADMIN) &&
  //   setSeeCtx?.modalType === 'write'
  // ) {
  //   return (
  //     <>
  //       <Flex
  //         justify='space-between'
  //         align='center'
  //         mt='1.5rem'
  //         mb='1rem'
  //         cursor='pointer'
  //       >
  //         <Flex
  //           align='center'
  //           w='full'
  //           opacity={`${
  //             visibilitySelection?.visibility === 'public' ? 1 : 0.6
  //           }`}
  //           onClick={publicCheckboxHandler}
  //         >
  //           <Box
  //             as='span'
  //             mr='10px'
  //             minW='10px'
  //             maxW='20px'
  //             minH='20px'
  //             maxH='20px'
  //             className='pub_icon'
  //           >
  //             <img src={internet} alt='internet' title='internet' />
  //           </Box>
  //           <Box
  //             as='span'
  //             fontSize='20px'
  //             fontWeight='600'
  //             className='pub_name'
  //           >
  //             {t('common:public')}
  //           </Box>
  //         </Flex>
  //         <Checkbox
  //           size='lg'
  //           isChecked={
  //             visibilitySelection?.visibility === 'public' && !sameAsParent
  //           }
  //           disabled={sameAsParent && visibilityScope === 'subFolder'}
  //           onChange={publicCheckboxHandler}
  //         />
  //       </Flex>
  //       <Divider />
  //     </>
  //   );
  // } else {
  //   return null;
  // }
  return (
    <>
      <Flex
        justify='space-between'
        align='center'
        mt='1.5rem'
        mb='1rem'
        cursor={
          loggedInUserAuthRole === AuthRole?.LOCATION_OWNER
            ? 'no-drop'
            : 'pointer'
        }
      >
        <Flex
          align='center'
          w='full'
          gap='10px'
          opacity={`${
            visibilitySelection?.visibility === 'public' && !sameAsParent
              ? 1
              : 0.6
          }`}
          onClick={publicCheckboxHandler}
        >
          <FontAwesomeIcon icon={faGlobe as IconProp} fontSize='17px' />
          <Box as='span' fontSize='20px' fontWeight='600'>
            {t('common:public')}
          </Box>
        </Flex>
        <Checkbox
          size='lg'
          isChecked={
            visibilitySelection?.visibility === 'public' && !sameAsParent
          }
          disabled={
            (sameAsParent &&
              (visibilityScope === 'subFolder' ||
                visibilityScope === 'chapter')) ||
            loggedInUserAuthRole === AuthRole?.LOCATION_OWNER
          }
          onChange={publicCheckboxHandler}
        />
      </Flex>
      <Divider />
    </>
  );
};

PublicCheckbox.displayName = 'p/H/sVM/SSM/PublicCheckbox';

export default PublicCheckbox;
