import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Center, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TASK_DASHBOARD } from 'appRoutes';
import { Button } from 'atoms';
import { HeaderColors } from 'configs';
import { useUserDataSelector, useUserEntity } from 'hooks';
import moment from 'moment';
import React, { FC, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthRole } from 'sop-commons/src/client';
import { useNexusBoardContext } from 'sub-components/nexus/NexusBase/store/context';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import {
  MonthData,
  TaskSupervisedLocationWiseTrend,
} from 'sub-components/nexus/NexusBase/types';
import { AllClear } from 'sub-components/nexus/NexusBase/components/common';
import { useService } from 'sub-components/nexus/NexusBase/layers';
import { useGetLocationIds } from 'sub-components/nexus/NexusBase/utils';
import { Loader } from 'sub-components';

interface TaskPerformance {
  locationId: string;
  locationName: string;
  totalComplete: number;
  totalIncomplete: number;
  rank: number;
  hasSameIncompleteTasksLastTwoMonths: boolean;
}

interface IProps {}

export const getTopIncompleteTasks = (
  locationMap: { [locationId: string]: string },
  taskData?: TaskSupervisedLocationWiseTrend,
  topN: number = 3
): TaskPerformance[] => {
  if (!taskData || Object.keys(taskData).length === 0) {
    return [];
  }

  const currentDate = moment();
  const currentMonth = currentDate.format('M');
  const currentYear = currentDate.format('YYYY');

  const previousMonthDate = currentDate.clone().subtract(1, 'months');
  const previousMonth = previousMonthDate.format('M');
  const previousYear = previousMonthDate.format('YYYY');

  const twoMonthsAgoDate = currentDate.clone().subtract(2, 'months');
  const twoMonthsAgoMonth = twoMonthsAgoDate.format('M');
  const twoMonthsAgoYear = twoMonthsAgoDate.format('YYYY');

  const performanceData: TaskPerformance[] = Object.entries(taskData)
    .filter(([locationId]) => locationId !== 'average')
    .map(([locationId, monthlyData]) => {
      let currentMonthData: MonthData | undefined;
      let previousMonthData: MonthData | undefined;
      let twoMonthsAgoData: MonthData | undefined;

      Object.values(monthlyData).forEach((data) => {
        const dataStartDate = moment(data.start);
        const dataMonth = dataStartDate.format('M');
        const dataYear = dataStartDate.format('YYYY');

        if (dataMonth === currentMonth && dataYear === currentYear) {
          currentMonthData = data;
        } else if (dataMonth === previousMonth && dataYear === previousYear) {
          previousMonthData = data;
        } else if (
          dataMonth === twoMonthsAgoMonth &&
          dataYear === twoMonthsAgoYear
        ) {
          twoMonthsAgoData = data;
        }
      });

      const totalComplete = currentMonthData ? currentMonthData.complete : 0;
      const totalIncomplete = currentMonthData
        ? currentMonthData.inComplete
        : 0;

      const hasSameIncompleteTasksLastTwoMonths =
        (previousMonthData &&
          twoMonthsAgoData &&
          currentMonthData &&
          currentMonthData.inComplete === previousMonthData.inComplete &&
          currentMonthData.inComplete === twoMonthsAgoData.inComplete) ||
        false;

      return {
        locationId,
        locationName: locationMap[locationId] || 'Unknown Location',
        totalComplete,
        totalIncomplete,
        rank: 0,
        hasSameIncompleteTasksLastTwoMonths,
      };
    })
    ?.filter(
      (performance) =>
        performance?.totalComplete + performance?.totalIncomplete > 0
    );

  performanceData.sort((a, b) => b.totalIncomplete - a.totalIncomplete);

  performanceData.forEach((item, index, arr) => {
    if (index === 0) {
      item.rank = 1;
    } else if (item.totalIncomplete === arr[index - 1].totalIncomplete) {
      item.rank = arr[index - 1].rank;
    } else {
      item.rank = index + 1;
    }
  });

  const topLocations = performanceData.slice(0, topN);

  return topLocations;
};

const OverdueTasks: FC<IProps> = ({}) => {
  const history = useHistory();
  // const taskDataResponse = useNexusBoardContext((state) => state.taskData);
  const locationsToFetch = useGetLocationIds(true);
  const locations = useUserEntity((entity) => entity?.locations);

  const {
    taskService: { taskData, taskRes },
  } = useService({});

  useEffect(() => {
    taskData({
      variables: {
        locationIds: locationsToFetch,
        type: 'monthly',
        startDate: moment
          ?.utc()
          ?.subtract(2, 'months')
          ?.startOf('month')
          ?.startOf('day')
          ?.toISOString(),
        endDate: moment?.utc()?.endOf('month')?.endOf('day')?.toISOString(),
      },
    });
  }, []);

  const taskDataResponse = useMemo(() => {
    return taskRes?.data?.TaskSupervisedLocationWiseTrend;
  }, [taskRes?.data]);

  const { loggedInUserAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      loggedInUserAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
    })
  );

  const businessLocations = useMemo(() => {
    if ([AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(loggedInUserAuthRole)) {
      return (
        locations?.map((loc) => ({ eid: loc?.eid, name: loc?.name })) || []
      );
    }
    return (
      loggedInUserLocations?.map((loc) => ({
        eid: loc?.eid,
        name: loc?.name,
      })) || []
    );
  }, [loggedInUserAuthRole, locations, loggedInUserLocations]);

  const locationMap = useMemo(() => {
    const map = businessLocations.reduce<{ [eid: string]: string }>(
      (map, loc) => {
        map[loc.eid] = loc.name;
        return map;
      },
      {}
    );
    return map;
  }, [businessLocations]);

  const filteredTaskDataResponse = useMemo(():
    | TaskSupervisedLocationWiseTrend
    | undefined => {
    if (
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(loggedInUserAuthRole)
    ) {
      return taskDataResponse;
    }
    if (!taskDataResponse) return undefined;
    const { average, ...filteredTaskDataResponse } = taskDataResponse;
    return businessLocations.reduce((acc, location) => {
      if (filteredTaskDataResponse.hasOwnProperty(location.eid)) {
        acc[location.eid] = filteredTaskDataResponse[location.eid];
      }
      return acc;
    }, {} as TaskSupervisedLocationWiseTrend);
  }, [taskDataResponse]);

  let incompleteTasks = getTopIncompleteTasks(
    locationMap,
    filteredTaskDataResponse
  );

  if (taskRes?.loading) {
    return (
      <Center h='30vh'>
        <Loader />
      </Center>
    );
  }

  if (incompleteTasks?.length === 0) {
    return (
      <Center h='30vh'>
        <AllClear desc='There are no overdue or incomplete tasks' />
      </Center>
    );
  }

  return (
    <Flex flexDir='column' gap={4}>
      {incompleteTasks?.map((incomplete) => (
        <Flex
          borderRadius='12px'
          bg='#fcfcfc'
          border='1px solid #efefef'
          p='12px'
          flexDir='column'
        >
          <Box fontWeight={600}>{incomplete?.locationName}</Box>
          <Flex>
            <Box color={HeaderColors.Red.Mid} fontWeight={600}>
              {incomplete?.totalIncomplete}
            </Box>
            &nbsp;
            <Box fontWeight={600} color={HeaderColors.Gray.Primary}>
              /&nbsp;
              {incomplete?.totalIncomplete + incomplete?.totalComplete} overdue
            </Box>
          </Flex>
          {incomplete?.hasSameIncompleteTasksLastTwoMonths && (
            <Flex
              color='rgba(111, 118, 126, 1)'
              mt={2}
              align='center'
              gap='5px'
              bg='rgba(244, 244, 244, 1)'
              borderRadius='12px'
              p='4px 8px'
            >
              <FontAwesomeIcon icon={faCircleExclamation as IconProp} />
              <Box fontSize='12px'>Overdue since 2 consecutive months</Box>
            </Flex>
          )}
        </Flex>
      ))}
      <Button
        variant='outline'
        size='lg'
        onClick={() => history.push(TASK_DASHBOARD)}
        rightIcon={<ArrowForwardIcon fontSize='20px' />}
      >
        View all
      </Button>
    </Flex>
  );
};

export default OverdueTasks;
