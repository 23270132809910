import React, { FC } from 'react';
import { IconImage } from '../Image';
import { BaseSearchModal } from '../../modal';
import { getImage } from '../../utils';

import locationIcon from '../../assets/images/search/location.svg';
import cardIcon from '../../assets/images/search/card.svg';
import categoryIcon from '../../assets/images/search/category.svg';
import deckIcon from '../../assets/images/search/deck.svg';
import trainingIcon from '../../assets/images/search/training.svg';
import userIcon from '../../assets/images/search/user.svg';
import formIcon from '../../assets/images/search/form.svg';
import chapterIcon from '../../assets/images/search/chapter.svg';
import auditIcon from '../../assets/images/search/audit.svg';
import taskIcon from '../../assets/images/search/task.svg';
import ChapterIconComponent from 'pages/Chapters/ChapterIconComponent';

export const IconsPlaceholder: Record<string, string> = {
  user: userIcon,
  branch: locationIcon,
  location: locationIcon,
  card: cardIcon,
  deck: deckIcon,
  training: trainingIcon,
  category: categoryIcon,
  form: formIcon,
  sop: chapterIcon,
  audit: auditIcon,
  task: taskIcon,
};

interface IProps {
  type: string;
  item: BaseSearchModal;
}

const SearchItemImage: FC<IProps> = ({ item, type }) => {
  const image = item?.meta?.profilePic || item?.meta?.thumbnail;
  if (type === 'category') {
    return (
      <ChapterIconComponent
        chapterName={item?.meta?.name || ''}
        minWidth='48px'
        minHeight='48px'
        height='48px'
        fontSize='20px'
        chapterFileThumbnail={item?.meta?.icon as unknown as string}
        type={'folder'}
      />
    );
  }
  if (type === 'sop') {
    return (
      <IconImage
        icon={item?.meta?.icon}
        name={item?.meta?.title}
        files={item?.meta?.files}
        thumbnail={item?.meta?.thumbnail}
        boxSize={48}
        iconFontSize='26px'
        borderRadius='6px'
      />
    );
  }

  return (
    <IconImage
      name=''
      thumbnail={image ? getImage(image) : IconsPlaceholder[type]}
      boxSize={48}
      borderRadius='6px'
      objectFit='cover'
    />
  );
};

export default SearchItemImage;
