import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  ListItem,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SuperAdminOverview from 'assets/images/walkthrough-docs/superadmin-overview-diagram.png';
import { Table } from 'atoms';
import { HeaderColors } from 'configs';
import React, { FC } from 'react';

const GeneralInfo: FC = () => {
  const colRender = (val: boolean) => {
    return (
      <Center>
        {val ? (
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            color={HeaderColors.GreenDark}
          />
        ) : (
          <FontAwesomeIcon
            icon={faCircleXmark as IconProp}
            color={HeaderColors.Red.Mid}
          />
        )}
      </Center>
    );
  };
  return (
    <VStack align='flex-start'>
      <VStack align='flex-start'>
        <Heading as='h1'>General Info</Heading>
        <Box fontSize='16px'>
          Delightree is a SaaS product mainly targeting Franchise business and
          in that also, the primary target is francisee. There is one{' '}
          <Box as='span' fontWeight={600}>
            Headquarter (HQ)
          </Box>{' '}
          location which can be considered as the first ever location for that
          business.
          <br />
          And we have 4 different auth roles:
          <br />
          <UnorderedList>
            <ListItem>Superadmin</ListItem>
            <ListItem>Admin</ListItem>
            <ListItem>Location Owner</ListItem>
            <ListItem>Worker</ListItem>
          </UnorderedList>
          Superadmin and Admin can only be added to HQ location.
          <br />
          Location owner can be added to multiple locations meaning, a single
          person can be owner of multiple locations.
          <br />
          Worker can be added to a single location at a time.
        </Box>
      </VStack>
      <VStack w='full'>
        <Table
          columns={[
            { dataIndex: 'module', key: 'module', title: 'Module', width: 200 },
            {
              dataIndex: 'superAdmin',
              key: 'superAdmin',
              width: 100,
              title: () => <Center>Super Admin</Center>,
              render: (value: boolean) => colRender(value),
            },
            {
              dataIndex: 'admin',
              key: 'admin',
              width: 100,
              title: () => <Center>Admin</Center>,
              render: (value: boolean) => colRender(value),
            },
            {
              dataIndex: 'locationOwner',
              key: 'locationOwner',
              width: 100,
              title: <Center>Location Owner</Center>,
              render: (value: boolean) => colRender(value),
            },
            {
              dataIndex: 'worker',
              key: 'worker',
              width: 100,
              title: <Center>Worker</Center>,
              render: (value: boolean) => colRender(value),
            },
            {
              dataIndex: 'location',
              key: 'location',
              width: 100,
              title: <Center>Location</Center>,
              render: (value: boolean) => colRender(value),
            },
          ]}
          dataSource={[
            {
              module: 'Home Page',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: true,
              location: true,
            },
            {
              module: 'Tasks: Dashboard',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: false,
              location: false,
            },
            {
              module: 'Tasks: My Tasks',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: true,
              location: true,
            },
            {
              module: 'Tasks: Supervised by me',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: false,
              location: false,
            },
            {
              module: 'Chat',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: true,
              location: true,
            },
            {
              module: 'Location Launcher',
              superAdmin: true,
              admin: true,
              locationOwner: false,
              worker: false,
              location: false,
            },
            {
              module: 'Knowledge Base',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: true,
              location: true,
            },
            {
              module: 'Forms & Checklists: All Forms',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: true,
              location: true,
            },
            {
              module: 'Forms & Checklists: Form Responses',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: false,
              location: false,
            },
            {
              module: 'Training: Tracking',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: false,
              location: false,
            },
            {
              module: 'Training: Tracking: Paths',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: false,
              location: false,
            },
            {
              module: 'Training: Tracking: Members',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: false,
              location: false,
            },
            {
              module: 'Training: My Trainings',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: true,
              location: true,
            },
            {
              module: 'Training: My Trainings: Public',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: true,
              location: true,
            },
            {
              module: 'Training: My Trainings: Assigned to me',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: true,
              location: true,
            },
            {
              module: 'Members',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: true,
              location: true,
            },
            {
              module: 'Jobs',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: false,
              location: false,
            },
            {
              module: 'Locations',
              superAdmin: true,
              admin: true,
              locationOwner: true,
              worker: false,
              location: false,
            },
            {
              module: 'Setting',
              superAdmin: true,
              admin: true,
              locationOwner: false,
              worker: false,
              location: false,
            },
          ]}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 100,
          }}
        />
      </VStack>
    </VStack>
  );
};

export default GeneralInfo;
