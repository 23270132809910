import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LocationFilled from 'assets/images/form/LocationFilled.svg';
import { ActionButton } from '../Confirm';
import Image from '../Image';
import { ShareMemberEntity } from './share-member.graphql';

interface IProps {
  data: ShareMemberEntity;
  onSendClick?: (value: ShareMemberEntity) => PromiseLike<void>;
}

const ShareLocItem: FC<IProps> = ({ data, onSendClick }) => {
  const [sentSuccess, setSendSuccess] = useState(false);
  const { t } = useTranslation(['common']);

  const _onSendClick = async () => {
    if (!onSendClick || sentSuccess) {
      return null;
    }
    try {
      await onSendClick?.(data);
      setSendSuccess(true);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log('==', err?.message);
    }
  };

  const getLocationAddress = () => {
    const city = data.address?.city;
    const state = data.address?.state;

    if (!city && !state) return '--';
    else {
      return `${city || '-'}, ${state || '-'}`;
    }
  };

  return (
    <Flex justifyContent='space-between'>
      <Flex gap={2} alignItems='center'>
        <Image
          src={LocationFilled}
          width={40}
          height={40}
          alt='Location icon'
        />

        <Flex flexDirection='column' gap={1}>
          <Box as='span' fontSize={14} fontWeight={600}>
            {data.name}
          </Box>

          <Tooltip
            cursor='pointer'
            hasArrow
            placement='top'
            label={getLocationAddress()}
          >
            <Box
              as='span'
              fontSize={12}
              fontWeight={600}
              color='#92929D'
              lineHeight='14px'
            >
              {getLocationAddress()}
            </Box>
          </Tooltip>
        </Flex>
      </Flex>

      <ActionButton
        size='sm'
        minW='82px'
        fontSize='12px'
        colorScheme={sentSuccess ? 'green' : 'blue'}
        borderRadius='7px'
        actionFn={_onSendClick}
        rightIcon={
          sentSuccess ? (
            <FontAwesomeIcon icon={faCheck as IconProp} />
          ) : undefined
        }
      >
        {t(sentSuccess ? 'common:sent' : 'common:send')}
      </ActionButton>
    </Flex>
  );
};

export default ShareLocItem;
