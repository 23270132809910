import React, { FC, useMemo } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../../../atoms';
import { toArray } from '../../../../../../utils';
import TitleHeader from '../../../../../CardEditor/TitleHeader';
import { EntityComplianceCategoriesResponse } from '../../../types/compliance.types';
import { GET_COMPLIANCE_CATEGORIES } from '../../../query/compliance.graphql';
import { IDocumentInput } from './compliance.input';
import { CategorySelect } from './category-select';

interface IProps {}

const CategorySelectField: FC<IProps> = () => {
  const { t } = useTranslation(['nexus']);

  const { data } = useQuery<EntityComplianceCategoriesResponse>(
    GET_COMPLIANCE_CATEGORIES
  );

  const categoryList = useMemo((): SelectOption[] => {
    return toArray(data?.EntityComplianceCategories)?.map((value) => {
      return { label: value.category, value: value.eid };
    });
  }, [data?.EntityComplianceCategories]);

  return (
    <Controller<IDocumentInput, 'category'>
      name='category'
      rules={{
        required: t('nexus:documents.validations.documentCategoryRequired'),
      }}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={fieldState.invalid}>
          <TitleHeader
            title={t('nexus:documents.addDocumentCategory')}
            desc='Create a new category or select from existing ones'
            descStyle={{ marginBottom: '8px' }}
          />
          <CategorySelect
            options={categoryList}
            placeholder='Document category'
            {...field}
          />
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default CategorySelectField;
