import { Box, Flex, Heading, HStack, VStack } from '@chakra-ui/react';
import {
  CarryOutOutlined,
  CheckOutlined,
  FormOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import { Tree, TreeDataNode } from 'antd';
import React, { FC, useState } from 'react';
import { AuthorizationWrapper } from '../wrappersInfo';
import {
  AppFeatureConfig,
  AssetsFolderInfo,
  AtomsInfo,
  AuditConfig,
  AuthorizationHook,
  AuthorizationInfo,
  AuthorizationUtils,
  AvatarInfo,
  CheckPermission,
  ConfigInfo,
  LocalesFolder,
  SubComponentsFolder,
  WithAuthorization,
  PagesInfo,
  HooksFolderInfo,
  RouteHooksInfo,
  ChatInitializeHookInfo,
  ChatMessageCountHookInfo,
} from './components';
import { DataNode } from 'antd/es/tree';
import {
  AmplitudeEventsInfo,
  CommonGraphQL,
  CustomLoginPageInfo,
  EventEmitInfo,
  HeaderColorsInfo,
  SharedFolderOverview,
} from './components/sharedFolder';
import UtilsFolderInfo from './components/utilsFolder/UtilsFolderInfo';

interface CustomTreeDataNode extends DataNode {
  component?: React.ReactNode;
}

const treeData: CustomTreeDataNode[] = [
  {
    title: 'src',
    key: '0-0',
    icon: <CarryOutOutlined />,
    component: <>src</>,
    children: [
      {
        title: 'assets',
        key: 'assets-info',
        icon: <FolderOutlined />,
        switcherIcon: <FolderOutlined />,
        component: <AssetsFolderInfo />,
      },
      {
        title: 'atoms',
        key: '0-0-1',
        icon: <FolderOutlined />,
        switcherIcon: <FolderOutlined />,
        component: <AtomsInfo />,
      },
      {
        title: 'authorization',
        key: 'authorization-info',
        icon: <CarryOutOutlined />,
        component: <AuthorizationInfo />,
        children: [
          {
            title: 'Authorization.tsx',
            key: 'authorization-file-info',
            icon: <CarryOutOutlined />,
            component: <AuthorizationWrapper />,
          },
          {
            title: 'authorization.utils.ts',
            key: 'authorization-utils-info',
            icon: <CarryOutOutlined />,
            component: <AuthorizationUtils />,
          },
          {
            title: 'checkPermission.ts',
            key: 'authorization-check-permission-info',
            icon: <CarryOutOutlined />,
            component: <CheckPermission />,
          },
          {
            title: 'useAuthorization.ts',
            key: 'use-authorization-info',
            icon: <CarryOutOutlined />,
            component: <AuthorizationHook />,
          },
          {
            title: 'withAuthorization.tsx',
            key: 'with-authorization-info',
            icon: <CarryOutOutlined />,
            component: <WithAuthorization />,
          },
        ] as CustomTreeDataNode[],
      },
      // {
      //   title: 'cometchat-pro-react-ui-kit',
      //   key: '0-0-3',
      //   icon: <CarryOutOutlined />,
      //   children: [
      //     { title: 'INFO', key: '0-0-3-0', icon: <CarryOutOutlined /> },
      //   ],
      // },
      {
        title: 'configs',
        key: '0-0-4',
        icon: <CarryOutOutlined />,
        component: <ConfigInfo />,
        children: [
          {
            title: 'app-features.config.ts',
            key: '0-0-4-0',
            icon: <CarryOutOutlined />,
            component: <AppFeatureConfig />,
          },
          {
            title: 'audit.config.ts',
            key: '0-0-4-1',
            icon: <CarryOutOutlined />,
            component: <AuditConfig />,
          },
          { title: 'common.ts', key: '0-0-4-2', icon: <CarryOutOutlined /> },
          {
            title: 'default-image.ts',
            key: '0-0-4-3',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'form-config.ts',
            key: '0-0-4-4',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'signature-config.ts',
            key: '0-0-4-5',
            icon: <CarryOutOutlined />,
          },
        ] as CustomTreeDataNode[],
      },
      {
        title: 'hooks',
        key: '0-0-5',
        icon: <CarryOutOutlined />,
        component: <HooksFolderInfo />,
        children: [
          {
            title: 'routeHooks.ts',
            key: '0-0-5-1',
            icon: <CarryOutOutlined />,
            component: <RouteHooksInfo />,
          },
          {
            title: 'useChatInitialize.ts',
            key: '0-0-5-2',
            icon: <CarryOutOutlined />,
            component: <ChatInitializeHookInfo />,
          },
          {
            title: 'useChatMessageCount.ts',
            key: '0-0-5-3',
            icon: <CarryOutOutlined />,
            component: <ChatMessageCountHookInfo />,
          },
          {
            title: 'useChatRestriction.ts',
            key: '0-0-5-4',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useCountDown.ts',
            key: '0-0-5-5',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useCurrentLanguage.ts',
            key: '0-0-5-6',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useCustomLoginPage.tsx',
            key: '0-0-5-7',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useFolderSubFolder.tsx',
            key: '0-0-5-8',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useHideIntercomButton.ts',
            key: '0-0-5-9',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useLargeFileUpload.ts',
            key: '0-0-5-10',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useLatest.ts',
            key: '0-0-5-11',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useLogout.ts',
            key: '0-0-5-12',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useMountedRef.ts',
            key: '0-0-5-13',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'usePageNumber.tsx',
            key: '0-0-5-14',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'usePaginateData.tsx',
            key: '0-0-5-15',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useQueryStore.tsx',
            key: '0-0-5-16',
            icon: <CarryOutOutlined />,
          },
          { title: 'useRect.tsx', key: '0-0-5-17', icon: <CarryOutOutlined /> },
          {
            title: 'useSafeState.tsx',
            key: '0-0-5-18',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useSetCurrentURLToLocalStorage.tsx',
            key: '0-0-5-19',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useUploadImage.ts',
            key: '0-0-5-20',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useUploadImage.ts',
            key: '0-0-5-21',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useUploadMultipleFilesWithCancel.ts',
            key: '0-0-5-22',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useUploadMultipleImages.ts',
            key: '0-0-5-23',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useUploadToAws.ts',
            key: '0-0-5-24',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useUserDataQuery.ts',
            key: '0-0-5-25',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useUserFlowInit.ts',
            key: '0-0-5-26',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useUsersEntity.tsx',
            key: '0-0-5-27',
            icon: <CarryOutOutlined />,
          },
          {
            title: 'useVisibilityModule.tsx',
            key: '0-0-5-28',
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: 'locales',
        key: '0-0-6',
        icon: <FolderOutlined />,
        switcherIcon: <FolderOutlined />,
        component: <LocalesFolder />,
      },
      {
        title: 'pages',
        key: '0-0-7',
        icon: <FolderOutlined />,
        switcherIcon: <FolderOutlined />,
        component: <PagesInfo />,
      },
      // {
      //   title: 'routes',
      //   key: '0-0-8',
      //   icon: <FolderOutlined />,
      //   switcherIcon: <FolderOutlined />,
      // },
      {
        title: 'shared',
        key: '0-0-9',
        icon: <CarryOutOutlined />,
        component: <SharedFolderOverview />,
        children: [
          {
            title: 'amplitudeEvents',
            key: '0-0-9-1',
            icon: <FolderOutlined />,
            switcherIcon: <FolderOutlined />,
            component: <AmplitudeEventsInfo />,
          },
          {
            title: 'eventEmit',
            key: '0-0-9-2',
            icon: <FolderOutlined />,
            switcherIcon: <FolderOutlined />,
            component: <EventEmitInfo />,
          },
          {
            title: 'graphql',
            key: '0-0-9-3',
            icon: <FolderOutlined />,
            switcherIcon: <FolderOutlined />,
            component: <CommonGraphQL />,
          },
          {
            title: 'headerColors',
            key: '0-0-9-4',
            icon: <FolderOutlined />,
            switcherIcon: <FolderOutlined />,
            component: <HeaderColorsInfo />,
          },
          {
            title: 'login-page-static-json',
            key: '0-0-9-5',
            icon: <CarryOutOutlined />,
            component: <CustomLoginPageInfo />,
          },
        ],
      },
      {
        title: 'sub-components',
        key: '0-0-10',
        icon: <FolderOutlined />,
        switcherIcon: <FolderOutlined />,
        component: <SubComponentsFolder />,
      },
      // {
      //   title: 'ui-components',
      //   key: '0-0-11',
      //   icon: <FolderOutlined />,
      //   switcherIcon: <FolderOutlined />,
      // },
      // {
      //   title: 'utils',
      //   key: '0-0-12',
      //   icon: <CarryOutOutlined />,
      //   component: <UtilsFolderInfo />,
      //   children: [
      //     { title: 'avatar.ts', key: '0-0-12-1', icon: <CarryOutOutlined />, component: <AvatarInfo /> },
      //     {
      //       title: 'blobHelper.ts',
      //       key: '0-0-12-2',
      //       icon: <CarryOutOutlined />,
      //     },
      //     {
      //       title: 'checkChatAccess.ts',
      //       key: '0-0-12-3',
      //       icon: <CarryOutOutlined />,
      //     },
      //     {
      //       title: 'constants.ts',
      //       key: '0-0-12-4',
      //       icon: <CarryOutOutlined />,
      //     },
      //     {
      //       title: 'encodeSpaces.ts',
      //       key: '0-0-12-5',
      //       icon: <CarryOutOutlined />,
      //     },
      //     {
      //       title: 'file-icon.ts',
      //       key: '0-0-12-6',
      //       icon: <CarryOutOutlined />,
      //     },
      //     { title: 'fileSize.ts', key: '0-0-12-7', icon: <CarryOutOutlined /> },
      //     { title: 'getRole.ts', key: '0-0-12-8', icon: <CarryOutOutlined /> },
      //     {
      //       title: 'getUrlFileSize.ts',
      //       key: '0-0-12-9',
      //       icon: <CarryOutOutlined />,
      //     },
      //     {
      //       title: 'iconHelper.ts',
      //       key: '0-0-12-10',
      //       icon: <CarryOutOutlined />,
      //     },
      //     {
      //       title: 'image-crop.ts',
      //       key: '0-0-12-11',
      //       icon: <CarryOutOutlined />,
      //     },
      //     { title: 'image.ts', key: '0-0-12-12', icon: <CarryOutOutlined /> },
      //     {
      //       title: 'isDeepEqualObjects.ts',
      //       key: '0-0-12-13',
      //       icon: <CarryOutOutlined />,
      //     },
      //     {
      //       title: 'multipartUpload.ts',
      //       key: '0-0-12-14',
      //       icon: <CarryOutOutlined />,
      //     },
      //     {
      //       title: 'nFormatter.ts',
      //       key: '0-0-12-15',
      //       icon: <CarryOutOutlined />,
      //     },
      //     {
      //       title: 'objectHelper.ts',
      //       key: '0-0-12-16',
      //       icon: <CarryOutOutlined />,
      //     },
      //     {
      //       title: 'pdfThumbnail.ts',
      //       key: '0-0-12-17',
      //       icon: <CarryOutOutlined />,
      //     },
      //     {
      //       title: 'phaseTaskDuration.ts',
      //       key: '0-0-12-18',
      //       icon: <CarryOutOutlined />,
      //     },
      //     {
      //       title: 'queryParams.ts',
      //       key: '0-0-12-19',
      //       icon: <CarryOutOutlined />,
      //     },
      //     {
      //       title: 'randomColor.ts',
      //       key: '0-0-12-20',
      //       icon: <CarryOutOutlined />,
      //     },
      //     { title: 'regex.ts', key: '0-0-12-21', icon: <CarryOutOutlined /> },
      //     {
      //       title: 'shallowEqual.ts',
      //       key: '0-0-12-22',
      //       icon: <CarryOutOutlined />,
      //     },
      //     { title: 'sorting.ts', key: '0-0-12-23', icon: <CarryOutOutlined /> },
      //     {
      //       title: 'taskRepeat.ts',
      //       key: '0-0-12-24',
      //       icon: <CarryOutOutlined />,
      //     },
      //     {
      //       title: 'userRoles.ts',
      //       key: '0-0-12-25',
      //       icon: <CarryOutOutlined />,
      //     },
      //     { title: 'utils.ts', key: '0-0-12-26', icon: <CarryOutOutlined /> },
      //     {
      //       title: 'videoThumbnail.ts',
      //       key: '0-0-12-27',
      //       icon: <CarryOutOutlined />,
      //     },
      //   ],
      // },
      // {
      //   title: 'zustandStore',
      //   key: '0-0-13',
      //   icon: <FolderOutlined />,
      //   switcherIcon: <FolderOutlined />,
      // },
    ] as CustomTreeDataNode[],
  },
];

const FolderStructure: FC = () => {
  const [showLine, setShowLine] = useState<boolean>(true);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [showLeafIcon, setShowLeafIcon] = useState<React.ReactNode>(true);
  const [selectedComponent, setSelectedComponent] =
    useState<React.ReactNode | null>(null);

  const onSelect = (selectedKeys: React.Key[], info: any) => {
    console.log('selected', selectedKeys, info);

    const node = info.node as CustomTreeDataNode;
    if (node && node.component) {
      setSelectedComponent(node.component);
    } else {
      setSelectedComponent(null);
    }
  };
  return (
    <VStack align='flex-start' w='full'>
      <Heading as='h1'>Folder Structure</Heading>
      <Box>
        Below is the folder structure tree on the left side and on the right
        side, the selected folder/subfolder details will be shown.
      </Box>
      <HStack w='full' h='88vh' gap={4}>
        <Flex
          p={2}
          overflowY='scroll'
          h='88vh'
          border='1px solid gray'
          minW='300px'
          maxW='fit-content'
        >
          <Tree<CustomTreeDataNode>
            showLine={showLine ? { showLeafIcon } : false}
            showIcon={false}
            defaultExpandedKeys={['0-0']}
            treeData={treeData}
            onSelect={onSelect}
          />
        </Flex>
        <Flex
          h='full'
          flex={1}
          p={4}
          border='1px solid gray'
          overflow='scroll'
          maxH='87vh'
        >
          {selectedComponent ? (
            selectedComponent
          ) : (
            <div>Select a node to display its component.</div>
          )}
        </Flex>
      </HStack>
    </VStack>
  );
};

export default FolderStructure;
