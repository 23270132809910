import { Flex, IconButton, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowTrendUp,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  handlePrev: () => void;
  handleNext: () => void;
}

const PaginationComponent: FC<PaginationProps> = ({
  currentPage,
  totalPages,
  handlePrev,
  handleNext,
}) => {
  return (
    <Flex alignItems='center'>
      <IconButton
        icon={<FontAwesomeIcon icon={faArrowLeft as IconProp} />}
        onClick={handlePrev}
        isDisabled={currentPage === 1}
        aria-label='Previous Page'
        mr={2}
      />
      <Text>
        {currentPage} / {totalPages}
      </Text>
      <IconButton
        icon={<FontAwesomeIcon icon={faArrowRight as IconProp} />}
        onClick={handleNext}
        isDisabled={currentPage === totalPages}
        aria-label='Next Page'
        ml={2}
      />
    </Flex>
  );
};

export default PaginationComponent;
