import { chakra } from '@chakra-ui/react';

export const BaseAiContent = chakra('div', {
  baseStyle: {
    display: 'flex',
    padding: '24px',
    borderRadius: '12px',
    backgroundColor: '#F6F3FF',
  },
});
