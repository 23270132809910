import {
  Box,
  Heading,
  ListItem,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';

const IssueTrackInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Issues Tracking, Roadmap & Design Tickets Info</Heading>
      <VStack align='flex-start' fontSize='16px'>
        <Box>
          To track the issues, check or add items to roadmap and to work on
          design tickets, we use JIRA board.
        </Box>
        <Box>"delightree-issue-tracker (DIT)" is used for issues tracking.</Box>
        <Box>
          "delightree-design-dev-tracker (DDDT)" is used for design tickets
          tracking.
        </Box>
        <Box>"Delightree Product (DP)" is used to check the roadmap.</Box>
        <Box>Mainly we have to focus on DIT and DP.</Box>
        <VStack align='flex-start'>
          <Box>
            In DIT, ideally QA team will create tickets and developers will have
            to pick the tickets or team lead will assign accordingly. Highest
            priority to Lowest priority will be the sequence in which issues
            have to be prioritized by dev team. There is status along with each
            ticket,
          </Box>
          <UnorderedList pl={4}>
            <ListItem>
              <Box>
                <Box as='span'>NOT A BUG:</Box>&nbsp;
                <Box as='span'>
                  If the created issue is not a bug, then this will be added as
                  status. But one has to be confident enough that it is not a
                  bug but rather a desired functionality.
                </Box>
              </Box>
            </ListItem>
            <ListItem>
              <Box>
                <Box as='span'>REOPENED:</Box>&nbsp;
                <Box as='span'>
                  If the implemented fix doesn't work as required or is not
                  working at all or something else has to be done, then QA team
                  will mark it as Reopened.
                </Box>
              </Box>
            </ListItem>
            <ListItem>
              <Box>
                <Box as='span'>TODO:</Box>&nbsp;
                <Box as='span'>
                  The initial status for each ticket when created by QA.
                </Box>
              </Box>
            </ListItem>
            <ListItem>
              <Box>
                <Box as='span'>IN PROGRESS:</Box>&nbsp;
                <Box as='span'>
                  When dev picks up the ticket, he has to mark it as IN PROGRESS
                  to let everyone viewing the board know that this ticket is
                  picked by whom and that dev has to assign the ticket to
                  himself.
                </Box>
              </Box>
            </ListItem>
            <ListItem>
              <Box>
                <Box as='span'>DEV DONE:</Box>&nbsp;
                <Box as='span'>
                  When dev fixes the issue and that fix is deployed to
                  respective environment, then only the ticket will be marked as
                  DEV DONE, only then QA will test the ticket again.
                </Box>
              </Box>
            </ListItem>
            <ListItem>
              <Box>
                <Box as='span'>CLOSED/RESOLVED:</Box>&nbsp;
                <Box as='span'>
                  When the bug is fixed, then QA will mark the ticket as
                  CLOSED/RESOLVED.
                </Box>
              </Box>
            </ListItem>
          </UnorderedList>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default IssueTrackInfo;
