import React, { FC, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

// import scss
import './SearchModal.scss';

import { AuthRole } from '../../authorization';
import { useUserDataSelector } from '../../hooks';
import { getAiSearchPrefer } from '../../configs';

import Header from './component/header';
import ContentBody from './ContentBody';
import {
  memberMinWidth,
  modalWidth,
  searchMinWidth,
} from './search-modal.styles';
import { ADD_RECENT_SEARCH } from './search-modal.graphql';
import CardPreviewModal from '../CardPreview/CardPreviewModal';
import SearchFormView from './component/SearchFormView';
import LocationPseudoMember from 'pages/Training/LocationPseudoMemberModal';
// @ts-ignore
import { TRAINING_BY_ID_COMPLETE } from 'sop-commons/Query/Training';
import { TrainingData } from 'pages/Training/training.types';
import { RecentSearchModal, BaseSearchModal } from 'modal';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';
import { CategoryEntity } from 'sop-commons/src/client';
import { GET_FOLDERS_LIST } from 'pages/Chapters/chapters.graphql';
import { foldersListVar } from 'pages/Chapters/ChapterContainer/ChapterContainer';
import { CustomCategoryEntity } from 'pages/Chapters/ChapterHeader/components/BulkMoveContainer';
import useCombinedStore from 'zustandStore/store';
import SearchModalData from './SearchModalData';
import AiSearchModalData from './AiSearchModalData';

const Wrapper = styled.div`
  img {
    margin: unset;
  }
  ul {
    padding-left: initial;
  }
`;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const SearchModel: FC<IProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation(['common', 'header']);
  const {
    updateIsValidated,
    updateHierarchyData,
    updateSelectedFolderId,
    updateStatusType,
  } = useCombinedStore();
  const history = useHistory();
  // let categoriesList = useUserData()?.entity?.category;
  let categoriesList = useReactiveVar(foldersListVar);
  const [searchQuery, setSearchQuery] = useState('');
  const [userView, setUserDetailView] = useState(false);
  const [searchType, setSearchType] = useState(() => {
    return getAiSearchPrefer() ? 'AI' : 'standard';
  });
  const [showLocationPseudoMemberModal, setShowLocationPseudoMemberModal] =
    useState(false);
  const [searchSelectedData, setSearchSelectedData] =
    useState<RecentSearchModal>();
  const [clickedType, setClickedType] = useState<
    'searchList' | 'recentSearchList' | null
  >(null);

  const [selectedCard, setSelectedCard] = useState<string>();
  const [selectedForm, setSelectedForm] = useState<{
    eid: string;
    title: string;
  }>();

  useQuery<{ EntityCategories: CategoryEntity[] }, never>(GET_FOLDERS_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      foldersListVar(data?.EntityCategories || []);
    },
  });

  const [viewLocationMember, setViewLocationMember] = useState<string>();

  const user = useUserDataSelector((state) => ({
    eid: state.eid,
    authRole: state.authRole,
    type: state.type,
  }));

  const [addRecentSearch] = useMutation(ADD_RECENT_SEARCH);

  const [singleTrainingHandler] = useLazyQuery(TRAINING_BY_ID_COMPLETE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _item: TrainingData = JSON.parse(JSON.stringify(data?.trainingById));
      if (
        ([
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.LOCATION_OWNER,
        ].includes(user?.authRole) &&
          !_item?.assignedTo?.some((assign) => assign?.eid === user?.eid)) ||
        _item?.createdBy?.eid === user?.eid ||
        _item?.supervisors?.includes(user?.eid)
      ) {
        setTimeout(() => {
          history.push(`/training/${_item?.eid}`);
        }, 0);
        history.push(`/`);
      } else {
        setTimeout(() => {
          history.push(`/view/${_item?.eid}?ref=search`);
        }, 0);
        history.push('/');
      }
      // if ([WORKER].includes(user.authRole)) {
      //   setTimeout(() => {
      //     history.push(`/view/${_item?.eid}?ref=search`);
      //   }, 0)
      //   history.push('/');
      // } else {
      //   setTimeout(() => {
      //     history.push(`/training/${_item?.eid}`);
      //   }, 0);
      //   history.push(`/`);
      // }
    },
    onError: (error) => {},
  });

  const getTrainingPathHandler = (id: string) => {
    singleTrainingHandler({
      variables: {
        eid: id,
      },
    });
  };

  const onSelectedItem = (
    item: BaseSearchModal,
    isAddRecentSearch: boolean
  ) => {
    // @ts-ignore
    const eid = isAddRecentSearch ? item.eid : item.recordId;
    // @ts-ignore
    setSearchSelectedData(item);
    if (isAddRecentSearch) {
      setClickedType('recentSearchList');
    } else {
      setClickedType('searchList');
    }
    if (isAddRecentSearch) {
      addRecentSearch({
        variables: {
          input: {
            searchQuery: searchQuery,
            viewedResult: item?.value,
            path: item?.path,
            eid: item?.eid,
            type: item?.type,
          },
        },
      });
    }
    if (['user', 'location', 'branch'].includes(item.type)) {
      setUserDetailView(true);
    } else if (item.type === 'deck') {
      history.push(`/${item.meta.category}/deck/${eid}`);
      onClose();
    } else if (item.type === 'card') {
      setSelectedCard(eid);
    } else if (item.type === 'form') {
      setSelectedForm({ eid: eid, title: item?.meta?.title });
    } else if (item.type === 'training') {
      if (user?.type === 'branch') {
        setShowLocationPseudoMemberModal(true);
      } else {
        getTrainingPathHandler(eid);
      }
    } else if (item.type === 'sop') {
      deployEvent(AmplitudeEventNames.CHAPTER_VIEW_FROM_SEARCH);
      history.push(`/chapters/view/${eid}`);
      onClose();
    } else if (item.type === 'category') {
      let allFoldersList: CustomCategoryEntity[] = [];
      categoriesList?.map((list) => {
        if (list?.children?.length > 0) {
          list?.children?.map((child) => {
            allFoldersList?.push({
              ...child,
              parentFolderId: list?.eid,
              parentFolderName: list?.name,
              type: 'subFolder',
            });
          });
          allFoldersList?.push({
            ...list,
            parentFolderId: undefined,
            parentFolderName: undefined,
            type: 'folder',
          });
        } else {
          allFoldersList?.push({
            ...list,
            parentFolderId: undefined,
            parentFolderName: undefined,
            type: 'folder',
          });
        }
      });
      let found = allFoldersList?.find(
        (list) => list?.eid === item?.meta?.categoryId
      );
      if (!found) {
        updateIsValidated(true);
        updateSelectedFolderId(item?.meta?.categoryId);
        updateStatusType('MID');
        updateHierarchyData(undefined);
        history.push(`/folders/${item?.meta?.categoryId}?status=RESTRICT`);
        onClose();
        return;
      }
      updateStatusType('INITIAL');
      if (item?.meta?.parentId && item?.meta?.categoryId) {
        history.push(
          `/folders/${item?.meta?.parentId}/${item?.meta?.categoryId}`
        );
        onClose();
        return;
      } else if (item?.meta?.categoryId) {
        history.push(`/folders/${item?.meta?.categoryId}`);
        onClose();
        return;
      }
    }
  };

  const backClickUser = () => {
    setSearchSelectedData(undefined);
    setUserDetailView(false);
  };

  function viewLocBack() {
    setViewLocationMember(undefined);
  }

  const userHeaderTitle = () => {
    return (
      <Header
        type={searchSelectedData?.type}
        title={t('search')}
        onClick={viewLocationMember ? viewLocBack : backClickUser}
        updatedTitle={viewLocationMember}
      />
    );
  };

  const searchHeaderTitle = () => {
    return <Header title={t('search')} />;
  };

  const showSearchModalData = () => {
    return (
      <Wrapper>
        {searchType === 'AI' ? (
          <AiSearchModalData
            searchQuery={searchQuery}
            onChange={setSearchQuery}
            searchType={searchType}
            onTypeChange={setSearchType}
          />
        ) : (
          <SearchModalData
            searchQuery={searchQuery}
            onChange={setSearchQuery}
            onSelectedItem={onSelectedItem}
            searchType={searchType}
            onTypeChange={setSearchType}
          />
        )}
      </Wrapper>
    );
  };

  function onShowMemberClick(data: any) {
    setViewLocationMember(data);
  }

  function onCardClose() {
    setSelectedCard(undefined);
  }

  function onFormClose() {
    setSelectedForm(undefined);
  }

  if (selectedCard) {
    return <CardPreviewModal cardId={selectedCard} onClose={onCardClose} />;
  }

  if (selectedForm?.eid) {
    return (
      <SearchFormView
        formId={selectedForm.eid}
        title={selectedForm?.title}
        onClose={onFormClose}
      />
    );
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        scrollBehavior='inside'
        isCentered={userView}
      >
        <ModalOverlay />
        <ModalContent
          borderRadius='16px'
          width={modalWidth}
          minWidth={viewLocationMember ? memberMinWidth : searchMinWidth}
        >
          <ModalHeader mt='10px' px='32px'>
            {userView ? userHeaderTitle() : searchHeaderTitle()}
          </ModalHeader>
          <ModalCloseButton
            style={{
              marginTop: 18,
              marginRight: 20,
              outline: 'none',
              boxShadow: 'none',
              borderRadius: '50%',
              backgroundColor: '#efefef',
              color: '#6f767e',
            }}
          />
          <ModalBody pb='20px' px={!userView ? 0 : '32px'}>
            {!userView ? (
              showSearchModalData()
            ) : (
              <ContentBody
                data={searchSelectedData}
                onShowMemberClick={onShowMemberClick}
                viewLocationMember={viewLocationMember}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <LocationPseudoMember
        isOpen={showLocationPseudoMemberModal}
        onClose={() => setShowLocationPseudoMemberModal(false)}
        locationId={user?.eid}
        selectedTrainingData={{
          eid:
            clickedType === 'recentSearchList'
              ? searchSelectedData?.eid
              : searchSelectedData?.recordId,
        }}
      />
    </>
  );
};

export default SearchModel;

SearchModel.displayName = 'SubComponent/SearchModal';
