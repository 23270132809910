import React, { useState, useCallback, useMemo } from 'react';
import {
  Box,
  Image,
  Center,
  Flex,
  AlertDialogCloseButton,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { LocationGooglePhotos } from 'sub-components/nexus/NexusBase/components/LeftSection/components/ImageGallery/types/api.types';

interface ImageCarouselProps {
  galleryData: LocationGooglePhotos[];
  locationName: string;
}

const Carousel: React.FC<ImageCarouselProps> = React.memo(
  ({ galleryData, locationName }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const showLeftArrow = currentIndex > 0;
    const showRightArrow = currentIndex < galleryData.length - 3;

    const handlePrev = useCallback(() => {
      if (loading) return;
      setLoading(true);
      setCurrentIndex((prev) => Math.max(prev - 1, 0));
      setTimeout(() => setLoading(false), 1000);
    }, []);

    const handleNext = useCallback(() => {
      if (loading) return;
      setLoading(true);
      setCurrentIndex((prev) => Math.min(prev + 1, galleryData.length - 3));
      setTimeout(() => setLoading(false), 1000);
    }, [galleryData.length]);

    const visibleImages = useMemo(() => {
      return galleryData.slice(currentIndex, currentIndex + 3);
    }, [galleryData, currentIndex]);

    return (
      <VStack align='flex-start' pos='relative' gap={4}>
        <Flex flex={1} align='center' justify='space-between' w='full'>
          <Box as='span' fontWeight={600} fontSize='20px'>
            {locationName}
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
        <Box
          width='100%'
          mx='auto'
          display='flex'
          alignItems='center'
          overflow='hidden'
        >
          {galleryData.length > 3 && showLeftArrow && (
            <Center
              bg='black'
              pos='absolute'
              left='-15px'
              borderRadius='50%'
              p='5px'
              onClick={handlePrev}
              cursor='pointer'
              zIndex={2}
            >
              <ArrowBackIcon color='white' fontSize='30px' />
            </Center>
          )}

          <Box
            display='flex'
            transition='transform 0.5s ease-in-out'
            width={`${visibleImages.length * 400}px`}
            justifyContent='center'
          >
            {visibleImages.map((image) => (
              <Image
                key={image.eid}
                src={`${image.photoUrl}&width=400&height=400`}
                boxSize='400px'
                objectFit='cover'
                mx='5px'
                loading='lazy'
                draggable={false}
                opacity={loading ? 0.5 : 1}
                transition='opacity 0.3s ease'
              />
            ))}
          </Box>

          {galleryData.length > 3 && showRightArrow && (
            <Center
              bg='black'
              pos='absolute'
              right='-20px'
              borderRadius='50%'
              p='5px'
              onClick={handleNext}
              cursor='pointer'
              zIndex={2}
            >
              <ArrowForwardIcon color='white' fontSize='30px' />
            </Center>
          )}
          {loading && (
            <Center
              pos='absolute'
              top='0'
              left='0'
              width='100%'
              height='100%'
              bg='rgba(255, 255, 255, 0.6)'
              zIndex={1}
            >
              <Spinner size='xl' color='blue.500' />
            </Center>
          )}
        </Box>
      </VStack>
    );
  }
);

export default Carousel;
