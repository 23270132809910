// components/MetricSelector.tsx
import React from 'react';
import { VStack, Checkbox, Box } from '@chakra-ui/react';
import { BoxHeader } from 'ui-components';
import { Metrics } from '../types';

interface MetricSelectorProps {
  metrics: Metrics;
  setMetrics: React.Dispatch<React.SetStateAction<Metrics>>;
}

const MetricSelector: React.FC<MetricSelectorProps> = ({
  metrics,
  setMetrics,
}) => {
  const { age, attrition, auditScore, customerFeedback, taskCompletion } =
    metrics;
  return (
    <VStack
      bg='rgba(244, 244, 244, 1)'
      borderRadius='12px'
      p='20px 24px'
      w='40%'
      align='flex-start'
    >
      <Box fontWeight={600} fontSize='15px'>
        Select the metrics to include in the report
      </Box>
      <BoxHeader
        title='Compare locations by performance-'
        color='rgba(201, 218, 242, 1)'
        fontSize='14px'
        boxWidth='14px'
      />
      <Checkbox
        borderRadius='12px'
        isChecked={taskCompletion}
        onChange={(e) =>
          setMetrics({ ...metrics, taskCompletion: e.target.checked })
        }
      >
        Task Completion
      </Checkbox>
      <Checkbox
        isChecked={attrition}
        onChange={(e) =>
          setMetrics({ ...metrics, attrition: e.target.checked })
        }
      >
        Attrition
      </Checkbox>
      <Checkbox
        isChecked={age}
        onChange={(e) => setMetrics({ ...metrics, age: e.target.checked })}
      >
        Age
      </Checkbox>
      <Checkbox
        isChecked={customerFeedback}
        onChange={(e) =>
          setMetrics({ ...metrics, customerFeedback: e.target.checked })
        }
      >
        Customer feedback
      </Checkbox>
      <Checkbox
        isChecked={auditScore}
        onChange={(e) =>
          setMetrics({ ...metrics, auditScore: e.target.checked })
        }
      >
        Audit score
      </Checkbox>
    </VStack>
  );
};

export default MetricSelector;
