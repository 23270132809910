import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownToBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'atoms';
import { useUserEntity } from 'hooks';
import React, { FC } from 'react';
import { usersEntityObj } from 'sub-components/Header';
import { LEADERBOARD_DATA } from 'sub-components/nexus/NexusBase/service/api.graphql';
import { useNexusBoardContext } from 'sub-components/nexus/NexusBase/store/context';
import {
  Leaderboard,
  LeaderboardResponse,
  LeaderboardVariables,
} from 'sub-components/nexus/NexusBase/types';
import { useGetLocationIds } from 'sub-components/nexus/NexusBase/utils';
import { useExport } from '../../useExport';

interface IProps {
  exportRef: React.RefObject<HTMLDivElement>;
}

const ExportReportButton: FC<IProps> = ({ exportRef }) => {
  const exportFn = useExport();
  const entityData = useReactiveVar(usersEntityObj);
  const locationIds = useGetLocationIds();

  const { businessLocations } = useUserEntity((entity) => ({
    businessLocations: entity?.locations,
  }));

  const [leaderboardData, { loading: gettingData }] = useLazyQuery<
    LeaderboardResponse,
    LeaderboardVariables
  >(LEADERBOARD_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      handleExportFn(data?.NexusDashboard);
    },
  });

  const getLeaderboardData = () => {
    leaderboardData({
      variables: {
        locationIds:
          locationIds?.filter((loc) =>
            businessLocations?.some((bus) => loc === bus?.eid && !bus?.isRoot)
          ) || [],
      },
    });
  };

  const { customerFeedback } = useNexusBoardContext((state) => ({
    customerFeedback: state?.locationRatingData,
  }));

  const handleExport = () => {
    // getLeaderboardData();
    exportFn({
      customerFeedback,
      exportRef,
    });
  };

  const handleExportFn = (data: Leaderboard) => {
    let attritionData = data?.attrition;
    let auditData = data?.audit;
    let taskData = data?.task;
    exportFn({
      customerFeedback,
      exportRef,
    });
  };
  return (
    <Button
      rightIcon={
        <FontAwesomeIcon
          icon={faArrowDownToBracket as IconProp}
          color='#6F767E'
        />
      }
      variant='outline'
      disabled={gettingData}
      isLoading={gettingData}
      onClick={handleExport}
    >
      Export Report
    </Button>
  );
};

ExportReportButton.displayName = 'dashboard/ExportReport';

export default ExportReportButton;
