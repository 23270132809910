import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import EmptyState from '../../../../sub-components/EmptyState';
import { MyTrainingModal } from './../modal';
import LocationPseudoMember from '../../LocationPseudoMemberModal';
import ProgressTimeline from '../../../../ui-components/ProgressTimeline';
// @ts-ignore
import MilestoneSuccess from '../../../../assets/images/milestone_success.png';
// @ts-ignore
import MedalIcon from '../../../../assets/images/medal.svg';
import { useServiceLayer } from './useServiceLayer';
import { useModelLayer } from './useModelLayer';
import { SortingTable } from '../../../../sub-components/ChakraTable/SortingTable';
import { Box, Center, Flex, Image } from '@chakra-ui/react';
import { Loader } from 'sub-components';
import { HeaderColors } from 'configs';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { getImage } from 'utils';
import { TRAINING_PLAY_URL } from 'appRoutes';
import { useHistory } from 'routes';

export type IButtonValue = 'public' | 'assignedToMe';

const EmptyTitle: {
  [key in IButtonValue]:
    | 'training:trainingNotStarted'
    | 'training:nothingToLearn';
} = {
  public: 'training:trainingNotStarted',
  assignedToMe: 'training:nothingToLearn',
};

const EmptyDescription: {
  [key in IButtonValue]:
    | 'training:trainingNotStartedDesc'
    | 'training:managerWillAssignDesc';
} = {
  public: 'training:trainingNotStartedDesc',
  assignedToMe: 'training:managerWillAssignDesc',
};

interface IProps {
  tabSelected: IButtonValue;
  searchQuery?: string;
  shouldHideAction?: boolean;
  shouldHideCertificateDownload?: boolean;
  shouldHidePagination?: boolean;
  shouldHideActionLabel?: boolean;
  perPage?: number;
  setShowViewAllBtn?: React.Dispatch<React.SetStateAction<boolean>>;
  customEmptyStateComponent?: React.ReactNode;
  backToTitle?: string;
  layout?: 'simple' | 'full';
}

const MyTrainingList: FC<IProps> = ({
  tabSelected,
  searchQuery,
  shouldHideAction,
  shouldHideCertificateDownload,
  shouldHidePagination,
  shouldHideActionLabel,
  perPage = 10,
  setShowViewAllBtn,
  customEmptyStateComponent,
  backToTitle,
  layout = 'full',
}) => {
  const { t } = useTranslation(['common', 'training']);
  const history = useHistory();
  const userObject = useReactiveVar(userObj);
  const [progressData, setProgressData] = useState<{
    allMilestoneData: any[];
    showEmptyState: boolean;
    userData: any;
    trainingName?: string;
  }>({ allMilestoneData: [], showEmptyState: false, userData: null });
  const [progressTimelineModal, setProgressTimelineModal] = useState(false);

  const [pseudoTrainingData, setPseudoTrainingData] = useState({
    show: false,
    item: {} as MyTrainingModal,
  });

  const {
    currentPage,
    hasNextPage,
    itemCount,
    dataList,
    loading,
    reassignTraining,
    setSelectedPage,
  } = useServiceLayer(perPage, searchQuery, tabSelected, setShowViewAllBtn);

  const reassignTrainingHandler = (trainingId: string) => {
    reassignTraining({
      variables: {
        userId: userObject?.eid,
        trainingId: trainingId,
      },
    });
  };

  const { columns, onPageChangeHandler } = useModelLayer(
    backToTitle,
    shouldHideActionLabel,
    shouldHideAction,
    shouldHideCertificateDownload,
    reassignTrainingHandler,
    setProgressData,
    setProgressTimelineModal,
    setPseudoTrainingData,
    setSelectedPage
  );

  const trainingStatus = (data: MyTrainingModal, index: number) => {
    let viewCount = data?.viewCount;
    let totalCount = data?.totalCount;
    const isDemoAccount = data?.isDemoAccount;
    if (isDemoAccount && !viewCount) {
      viewCount =
        index === 0
          ? totalCount
          : index === 1
          ? Math.round(totalCount / 2)
          : viewCount;
    }
    if (viewCount === totalCount) {
      return (
        <Box color='#6f767e' fontSize='12px' fontWeight={600}>
          {t('common:completed')}
        </Box>
      );
    } else {
      return (
        <Box color={HeaderColors.Red.Mid} fontSize='12px' fontWeight={600}>
          {t('common:pending')}
        </Box>
      );
    }
  };

  const trainingProgressCount = (data: MyTrainingModal, index: number) => {
    let maxLength = data?.maxCount;
    let viewedCount = data?.totalViewedCount;
    const isDemoAccount = data?.isDemoAccount;
    let totalViewedCount = viewedCount;
    console.log({ trainingProgressCount: { maxLength, viewedCount } });
    if (isDemoAccount && !viewedCount) {
      totalViewedCount =
        index === 0
          ? maxLength
          : index === 1
          ? Math.round(maxLength / 2)
          : viewedCount;
    }
    return `${maxLength - totalViewedCount}/${maxLength} left`;
  };

  if (layout === 'simple') {
    if (loading) {
      return (
        <Center h='30vh'>
          <Loader />
        </Center>
      );
    }
    if (dataList?.length === 0) {
      return (
        customEmptyStateComponent || (
          <EmptyState
            image='MyTraining'
            title={t(EmptyTitle[tabSelected])}
            description={t(EmptyDescription[tabSelected]) as string}
          />
        )
      );
    }
    return (
      <Flex flexDir='column' gap={4}>
        {dataList?.map((data, index) => (
          <Flex
            gap={2}
            borderRadius='12px'
            bg='#fcfcfc'
            border='1px solid #efefef'
            p='12px'
            cursor='pointer'
            flexDir='column'
            onClick={() =>
              history.push({
                pathname: TRAINING_PLAY_URL,
                params: {
                  trainingId: data?.eid,
                },
              })
            }
          >
            <Flex align='center' justify='space-between'>
              {trainingStatus(data, index)}
              <ArrowForwardIcon fontSize='2xl' color='#6f767e' />
            </Flex>
            <Flex gap={4}>
              <Image
                boxSize='44px'
                borderRadius='12px'
                src={getImage(data?.thumbnail, data?.title)}
              />
              <Flex flexDir='column'>
                <Box as='span' fontWeight={700}>
                  {data?.title}
                </Box>
                <Box as='span' fontWeight={600} color='#6f767e' fontSize='12px'>
                  {trainingProgressCount(data, index)}
                </Box>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
    );
  }

  return (
    <div style={{ marginBottom: '2rem', width: '100%' }}>
      <SortingTable
        colorScheme='blue'
        customWrapperStyles={{ marginTop: '1rem' }}
        emptyData={{
          content: customEmptyStateComponent ? (
            customEmptyStateComponent
          ) : (
            <EmptyState
              image='MyTraining'
              title={t(EmptyTitle[tabSelected])}
              description={t(EmptyDescription[tabSelected]) as string}
            />
          ),
        }}
        page={currentPage || 1}
        onPageChange={onPageChangeHandler}
        columns={columns}
        data={dataList}
        isLoading={loading}
        totalRegisters={itemCount}
        isResponsive
        isHidePagination={
          !((currentPage === 1 && hasNextPage) || (currentPage || 1) > 1) ||
          shouldHidePagination
        }
      />
      <LocationPseudoMember
        isOpen={pseudoTrainingData.show}
        onClose={() =>
          setPseudoTrainingData({
            ...pseudoTrainingData,
            show: false,
          })
        }
        locationId={userObject?.eid}
        selectedTrainingData={pseudoTrainingData.item}
      />

      {/* {trainingPlayOpen && (
        <CustomPathCarousel
          isOpen={trainingPlayOpen}
          onClose={onTrainingPlayClose}
          selectedTrainingData={trainingPlay}
          getSingleTrainingHandler={() => fetchTrainingData(trainingPlay?.eid)}
        />
      )} */}

      {progressTimelineModal && (
        <ProgressTimeline
          isOpen={progressTimelineModal}
          timelineData={progressData?.allMilestoneData}
          trainingName={progressData?.trainingName}
          onCloseClick={() => setProgressTimelineModal(false)}
          milestoneSuccessImg={MilestoneSuccess}
          medalIcon={MedalIcon}
          userName={userObject?.name}
          isMyTimeline
          showEmptyState={progressData?.showEmptyState}
          userData={progressData?.userData}
        />
      )}
    </div>
  );
};

export default MyTrainingList;
