import React, { FC, useContext, useEffect, useRef } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Center, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import PrimaryButton from 'atoms/PrimaryButton';
import DashboardContainer from 'sub-components/DashboardContainer';

import ChapterContainer, {
  ChapterContainerRef,
  foldersListVar,
} from './ChapterContainer/ChapterContainer';
import { Authorize, AuthRole } from '../../authorization';
import { useCreateChapter } from './CreateChapterModal';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { useFolderSubFolder, useUserData, useUserDataQuery } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useSubFolderCreate } from './CreateEditSubFolder/hooks';
import { useReactiveVar } from '@apollo/client';
import useCombinedStore from 'zustandStore/store';
import { IConditionType } from 'pages/Handbook/setVisibilityModal/SetSeeModal/set-see-modal-types';
import { CustomCategoryEntity } from './ChapterHeader/components/BulkMoveContainer';
import { getQueryParamValue } from 'utils/queryParams';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';

const Header: FC = () => {
  const { t } = useTranslation(['sidebar', 'chapter']);
  const category = useParams<{ category?: string }>()?.category;
  const history = useHistory();

  const goBack = () => history.replace('/folders');

  if (category) {
    return (
      <Flex flex='1' gap={3}>
        <Center height='42px' onClick={history.goBack} cursor='pointer'>
          <ArrowBackIcon color='#272B30' boxSize='22px' />
        </Center>
        <Box>
          <Box fontSize='28px' fontWeight='700' color='#272B30'>
            {category}
          </Box>

          <Box
            fontSize='15px'
            fontWeight='400'
            color='#6F767E'
            cursor='pointer'
            onClick={goBack}
          >
            {t('chapter:backToKnowledge')}
          </Box>
        </Box>
      </Flex>
    );
  }

  return (
    <Box fontSize='28px' fontWeight='700' flex='1'>
      {t('sidebar:knowledge_base')}
    </Box>
  );
};

const Chapters: FC = () => {
  const { t } = useTranslation(['common', 'sidebar', 'chapter']);
  const {
    hierarchyData,
    reset,
    updateVisibilityScope,
    updateHierarchyData,
    updateVisibilitySelection,
    updateOperationType,
  } = useCombinedStore();
  const { folderId, subFolderId } = useRouteMatch<{
    folderId?: string;
    subFolderId?: string;
  }>().params;

  const dashboardCtx = useContext(DashboardDataContext);
  const foldersList = useReactiveVar(foldersListVar);
  // const { foldersList } = useCombinedStore();
  const { folderName } = useFolderSubFolder(folderId);
  const foundFolder = foldersList?.filter((cat) => cat?.eid === folderId)?.[0];
  const containerRef = useRef<ChapterContainerRef>(null);
  const { getAllFolderSubFolderList } = useFolderSubFolder();

  const createChapter = useCreateChapter();
  const subFolderCreate = useSubFolderCreate();

  const onCompleted = () => {
    containerRef.current?.refetch();
  };
  const [fetchUserData] = useUserDataQuery({ onCompleted });

  const nameValue = getQueryParamValue('name');
  const typeValue = getQueryParamValue('type');

  useEffect(() => {
    if (!folderId) return;
    let navigationData = dashboardCtx?.navigationPersistData;
    let knowledgeBaseData = dashboardCtx?.navigationPersistData?.knowledgeBase;
    let allList = getAllFolderSubFolderList();
    dashboardCtx?.navigationPersistDataHandler({
      ...navigationData,
      knowledgeBase: {
        ...knowledgeBaseData,
        selectedFolder: folderId
          ? {
              eid: folderId,
              name: folderName,
              visibility: foundFolder?.visibility,
              visibleTo: {
                locations: foundFolder?.visibleTo?.locations,
                roles: foundFolder?.visibleTo?.roles,
                users: foundFolder?.visibleTo?.users,
                condition: foundFolder?.visibleTo?.condition as IConditionType,
              },
            }
          : undefined,
      },
    });
  }, [folderId]);

  const subFolderBtnComponent = () => {
    return (
      <Authorize
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        permittedFor='user'
      >
        <PrimaryButton
          leftIcon={<FontAwesomeIcon icon={faFolderPlus as IconProp} />}
          title='Add sub folder'
          width={'fit-content'}
          variant='outline'
          colorScheme='blue'
          style={{ backgroundColor: 'white' }}
          onClick={() => {
            reset();
            updateVisibilityScope('subFolder');
            updateOperationType('add');
            deployEvent(AmplitudeEventNames.CREATE_SUB_FOLDER_FROM_HEADER);
            updateHierarchyData({
              parentFolder: {
                details: foundFolder as CustomCategoryEntity,
                visibilityData: {
                  locations: foundFolder?.visibleTo?.locations,
                  roles: foundFolder?.visibleTo?.roles,
                  users: foundFolder?.visibleTo?.users,
                  ...(foundFolder?.visibleTo?.condition
                    ? {
                        condition: foundFolder?.visibleTo
                          ?.condition as IConditionType,
                      }
                    : {}),
                  visibility: foundFolder?.visibility,
                },
              },
            });
            // When creating sub folder, initially the visibility will be same as that of parent folder
            updateVisibilitySelection({
              locations: foundFolder?.visibleTo?.locations,
              roles: foundFolder?.visibleTo?.roles,
              users: foundFolder?.visibleTo?.users,
              condition: foundFolder?.visibleTo?.condition as IConditionType,
              visibility: foundFolder?.visibility,
            });
            subFolderCreate({
              folderDetails: {
                eid: folderId || '',
                name: foundFolder?.name!,
                visibility: foundFolder?.visibility,
                visibleTo: {
                  locations: foundFolder?.visibleTo?.locations,
                  roles: foundFolder?.visibleTo?.roles,
                  users: foundFolder?.visibleTo?.users,
                  condition: foundFolder?.visibleTo
                    ?.condition as IConditionType,
                },
              },
              onSubFolderCreated: () => {
                // fetchUserData();
                containerRef.current?.refetch();
              },
            });
          }}
        />
      </Authorize>
    );
  };

  const subFolderBtnRender = () => {
    if (typeValue) {
      if (typeValue === 'folder') {
        return subFolderBtnComponent();
      } else {
        return null;
      }
    } else if (folderId && !subFolderId) {
      return subFolderBtnComponent();
    }
    return null;
  };

  const addChapterHandler = () => {
    if (!typeValue && !nameValue) {
      reset();
    }
    updateVisibilityScope('chapter');
    updateOperationType('add');
    createChapter({
      onChapterCreated: containerRef.current?.refetch,
    });
  };

  return (
    <DashboardContainer>
      <Box overflowX='hidden'>
        <Flex align='center' mb='20px'>
          <Header />
          <Flex gap={2} align='center'>
            <Authorize
              permittedRoles={[
                AuthRole.SUPER_ADMIN,
                AuthRole.ADMIN,
                AuthRole.LOCATION_OWNER,
              ]}
              permittedFor='user'
            >
              <PrimaryButton
                leftIcon={<AddIcon />}
                title={t('chapter:addNewChapter')}
                width={'fit-content'}
                variant='outline'
                colorScheme='blue'
                style={{ backgroundColor: 'white' }}
                onClick={() => {
                  deployEvent(AmplitudeEventNames.ADD_NEW_CHAPTER_FROM_HEADER);
                  deployEvent(AmplitudeEventNames.ADD_NEW_CHAPTER_CLICK, {
                    add_chapter_funnel_id: 5,
                  });
                  addChapterHandler();
                }}
              />
            </Authorize>
            {subFolderBtnRender()}
          </Flex>
        </Flex>
        <ChapterContainer
          ref={containerRef}
          onAddChapterClick={() => {
            deployEvent(AmplitudeEventNames.ADD_NEW_CHAPTER_FROM_EMPTY_STATE);
            addChapterHandler();
          }}
        />
      </Box>
    </DashboardContainer>
  );
};

Chapters.displayName = 'p/C/Chapters';
Header.displayName = 'p/C/Header';

export default Chapters;
