import React, { FC } from 'react';
import { AspectRatio, Center, Image } from '@chakra-ui/react';

interface IProps {
  url: string;
  pathName: string;
  onClick?: () => void;
}

const AssetItem: FC<IProps> = ({ url, pathName, onClick }) => {
  return (
    <AspectRatio
      ratio={1}
      aria-invalid={/\.(png|jpe?g)$/.test(url)}
      border='1px solid #E2E8F0'
      borderRadius='4px'
      _invalid={{
        borderColor: 'red',
      }}
      onClick={onClick}
      cursor='pointer'
    >
      <Image
        src={url}
        borderRadius='4px'
        style={{ objectFit: 'contain' }}
        fallback={
          <Center
            textAlign='center'
            whiteSpace='break-spaces'
            bg='#E2E8F0'
            borderRadius='4px'
            border='1px solid red'
            paddingX='3px'
          >
            {pathName?.split(/[./]/).pop() || ''}
          </Center>
        }
      />
    </AspectRatio>
  );
};

export default AssetItem;
