import { Box, Flex, VStack } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import LocationItem from './LocationItem';
import { ILocationPerformance } from '../types';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';
import { HeaderColors } from 'configs';
import { ViewType } from './ViewAll/types';

interface IProps {
  remainingLocations: ILocationPerformance[];
  footer?: React.ReactNode;
  type?: ViewType;
}

const RenderLowest: FC<IProps> = ({ remainingLocations, footer, type }) => {
  const { loggedInUserAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      loggedInUserLocations: state?.locations,
      loggedInUserAuthRole: state?.authRole,
    })
  );
  const toShowLocations = [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(
    loggedInUserAuthRole
  )
    ? remainingLocations || []
    : remainingLocations?.filter((data) =>
        loggedInUserLocations?.some((loc) => loc?.eid === data?.locationId)
      ) || [];
  const locationsToRender = useMemo(() => {
    return toShowLocations?.slice(-3) || [];
  }, [toShowLocations]);
  if (locationsToRender?.length === 0) {
    return null;
  }

  // const lowestPerforming = [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(
  //   loggedInUserAuthRole
  // )
  //   ? remainingLocations?.slice(-3)
  //   : remainingLocations
  //       ?.filter((data) =>
  //         loggedInUserLocations?.some((loc) => loc?.eid === data?.locationId)
  //       )
  //       ?.slice(-3);

  // const firstItems = remainingLocations?.slice(0, 3);

  // const hasOverlap =
  //   remainingLocations?.length > 3 &&
  //   firstItems?.length + lowestPerforming?.length > remainingLocations?.length;

  // const adjustedLastItems = hasOverlap
  //   ? remainingLocations?.slice(firstItems?.length)
  //   : lowestPerforming;

  const labelRender = () => {
    if (
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(loggedInUserAuthRole)
    ) {
      return 'Lowest Performing Locations:';
    } else {
      if (loggedInUserLocations?.length === 1) {
        return 'Your Location:';
      } else {
        return 'Your Locations:';
      }
    }
  };

  return (
    <VStack
      align='stretch'
      spacing={3}
      bg='#efefef'
      w='80%'
      borderRadius='12px'
      p='16px 8px'
    >
      <Box color='#6f767e' fontSize='12px' fontWeight={500}>
        {labelRender()}
      </Box>
      {locationsToRender.map((location) => (
        <LocationItem
          key={location.locationId}
          location={location.locationName}
          score={
            type === 'overall' ? (
              <Flex>
                <Box
                  color={HeaderColors.Red.Mid}
                  fontSize='13px'
                  fontWeight={600}
                >
                  {Math.round(location.rate)}
                </Box>
                <Box color='rgba(111, 118, 126, 1)' fontSize='12px'>
                  &nbsp;pts
                </Box>
              </Flex>
            ) : (
              `${location.rate?.toFixed(2)}%`
            )
          }
          rank={location.rank}
        />
      ))}
      {footer ? footer : null}
    </VStack>
  );
};

export default RenderLowest;
