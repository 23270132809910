import {
  Box,
  Flex,
  Heading,
  ListItem,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';

const MVCInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>MVC Architecture Info</Heading>
      <VStack align='flex-start' fontSize='16px'>
        <Box>
          To create module, first of all it has to be created in
          'sub-components' folder. And components can be created in normal
          standard way, but using MVC architecture tends to be clean.
        </Box>
        <Box>The following folders have to be created:</Box>
        <UnorderedList pl={4}>
          <ListItem>
            <VStack align='flex-start'>
              <Box fontWeight={600}>layers</Box>
              <UnorderedList pl={4}>
                <ListItem>
                  <Flex>
                    <Box as='span' fontWeight={600}>
                      useService.tsx:
                    </Box>
                    &nbsp;
                    <Box as='span'>
                      This file will contain all the graphql queries and
                      mutations that will be returned.
                    </Box>
                  </Flex>
                </ListItem>
                <ListItem>
                  <Box>
                    <Box as='span' fontWeight={600}>
                      useControl.tsx:
                    </Box>
                    &nbsp;
                    <Box as='span'>
                      This fill will contain all the business logic, states
                      handling, importing 'useService' and querying and updating
                      the data as required. Only the required props will be
                      returned from this to the main file.
                    </Box>
                  </Box>
                </ListItem>
                <ListItem>
                  <Flex>
                    <Box as='span' fontWeight={600}>
                      index.ts:
                    </Box>
                    &nbsp;
                    <Box as='span'>
                      This file will export both 'useControl' and 'useService'
                      to be imported elsewhere.
                    </Box>
                  </Flex>
                </ListItem>
              </UnorderedList>
            </VStack>
          </ListItem>
          <ListItem>
            <VStack align='flex-start'>
              <Box fontWeight={600}>components</Box>
              <UnorderedList pl={4}>
                <ListItem>
                  <Flex>
                    <Box as='span' fontWeight={600}>
                      {'<COMPONENTs NAME>'}.tsx:
                    </Box>
                    &nbsp;
                    <Box as='span'>
                      All the components will be created here for the module.
                    </Box>
                  </Flex>
                </ListItem>
                <ListItem>
                  <Flex>
                    <Box as='span' fontWeight={600}>
                      index.ts:
                    </Box>
                    &nbsp;
                    <Box as='span'>
                      This file will export all the components created above.
                    </Box>
                  </Flex>
                </ListItem>
              </UnorderedList>
            </VStack>
          </ListItem>
          <ListItem>
            <VStack align='flex-start'>
              <Box fontWeight={600}>service</Box>
              <UnorderedList pl={4}>
                <ListItem>
                  <Box>
                    <Box as='span' fontWeight={600}>
                      api.graphql.ts:
                    </Box>
                    &nbsp;
                    <Box as='span'>
                      All the queries that will be used across the module will
                      be added in this file.
                    </Box>
                  </Box>
                </ListItem>
                <ListItem>
                  <Box>
                    <Box as='span' fontWeight={600}>
                      {"<COMPONENT's service NAME>"}.graphql.ts:
                    </Box>
                    &nbsp;
                    <Box as='span'>
                      All the graphql queries and mutations will de declared
                      here. Let's say there are some queries specific to some
                      component, so add using that component's name.
                    </Box>
                  </Box>
                </ListItem>
                <ListItem>
                  <Box>
                    <Box as='span' fontWeight={600}>
                      index.ts:
                    </Box>
                    &nbsp;
                    <Box as='span'>
                      All the files created above will have to be exported
                      through this file.
                    </Box>
                    &nbsp;
                  </Box>
                </ListItem>
              </UnorderedList>
            </VStack>
          </ListItem>
          <ListItem>
            <VStack align='flex-start'>
              <Box fontWeight={600}>types</Box>
              <UnorderedList pl={4}>
                <ListItem>
                  <Flex>
                    <Box as='span' fontWeight={600}>
                      helper.types.ts:
                    </Box>
                    &nbsp;
                    <Box as='span'>
                      All the types to be used for layers folder (useService and
                      useControl) will be declared here.
                    </Box>
                  </Flex>
                </ListItem>
                <ListItem>
                  <Box>
                    <Box as='span' fontWeight={600}>
                      api.types(folder) / api.types.ts(file):
                    </Box>
                    &nbsp;
                    <Box as='span'>
                      All the types to be used for layers folder (useService and
                      useControl) will be declared here. If there is only one
                      component's queries/mutations to be defined and is
                      manageable to be added without specifying the component to
                      which it belongs, then use api.types.ts (file) or else
                      create a folder 'api-types', then create a file that has
                      component name or name that defines for which the
                      query/mutation is present, and export that using index.ts
                      as is being done in all the other places.
                    </Box>
                  </Box>
                </ListItem>
                <ListItem>
                  <Flex>
                    <Box as='span' fontWeight={600}>
                      index.ts:
                    </Box>
                    &nbsp;
                    <Box as='span'>
                      All the types created above will have to be exported
                      through this file.
                    </Box>
                  </Flex>
                </ListItem>
              </UnorderedList>
            </VStack>
          </ListItem>
          <ListItem>
            <VStack align='flex-start'>
              <Flex>
                <Box fontWeight={600} as='span'>
                  {'ModuleName.tsx'}:
                </Box>
                &nbsp;
                <Box as='span'>
                  This file will act as Modal and View where the useControl will
                  be imported and JSX will be rendered.
                </Box>
              </Flex>
            </VStack>
          </ListItem>
        </UnorderedList>
      </VStack>
    </VStack>
  );
};

export default MVCInfo;
