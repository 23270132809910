import './Sidebar.scss';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';

import { useUserEntity } from '../../hooks';

import logo from '../../assets/images/logo.webp';
import { ReactComponent as FormIcon } from '../../assets/images/sidebar/form.svg';
import SidebarItem from './SidebarItem';
import SidebarFooter from './SidebarFooter';
import {
  MainSection,
  OthersSection,
  ResourcesSection,
  TeamSpaceSection,
} from './sections';
import { useRenderFlag } from './sections/helper';
import { AuthRole, Authorize } from 'authorization';

const Sidebar: FC = () => {
  const pathname = useLocation().pathname;
  const profilePic = useUserEntity((entity) => entity?.profilePic);

  let flag = useRenderFlag();

  return (
    <div className='dashboard-sidebar-container'>
      <div className='sidebar-logo-container'>
        <img alt='business icon' src={profilePic || logo} />
      </div>
      <div className='sidebar-content-container'>
        {pathname !== '/create-sop' && pathname !== '/search-sop' && (
          <Flex flexDir='column' gap='20px'>
            <MainSection />
            <ResourcesSection />
            <Authorize
              permittedRoles={[
                AuthRole.SUPER_ADMIN,
                AuthRole.ADMIN,
                AuthRole.LOCATION_OWNER,
              ]}
              permittedFor='user'
            >
              {flag && <TeamSpaceSection />}
            </Authorize>
            <OthersSection />
          </Flex>
        )}
        {(pathname === '/create-sop' || pathname === '/search-sop') && (
          <>
            <SidebarItem
              icon={FormIcon}
              title={'Create SOP'}
              link={'/create-sop'}
              isSelected={pathname === '/create-sop'}
            />
            <SidebarItem
              icon={FormIcon}
              title={'Search SOP'}
              link={'/search-sop'}
              isSelected={pathname === '/search-sop'}
            />
          </>
        )}
      </div>
      <div className='sidebar-footer-container'>
        <SidebarFooter />
      </div>
    </div>
  );
};

export default Sidebar;
