import React, { FC } from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { ExportData, LocationsRating } from './types';
import { DateRangeSelector, Footer, MetricSelector } from './components';
import ExportLocationSelect from '../ExportLocationSelect';
import { rangeOptions } from './utils';
import { useControl } from './layers';

interface CustomReportComponentProps {
  customerFeedback: LocationsRating[];
  onClose: () => void;
}

const CustomReportComponent: FC<CustomReportComponentProps> = ({
  customerFeedback,
  onClose,
}) => {
  const control = useControl(customerFeedback, onClose);

  return (
    <VStack w='full'>
      <HStack w='full' gap={4}>
        <MetricSelector
          metrics={control?.metrics}
          setMetrics={control?.setMetrics}
        />
        <VStack
          w='60%'
          border='1px solid rgba(239, 239, 239, 1)'
          p='24px 32px'
          borderRadius='12px'
          align='flex-start'
          gap={4}
        >
          <Box fontSize='15px' fontWeight={600}>
            Select the data range
          </Box>
          <DateRangeSelector
            rangeOptions={rangeOptions}
            selectedDataRange={control?.selectedDataRange}
            setSelectedDataRange={control?.setSelectedDataRange}
          />
          <Box fontSize='15px' fontWeight={600}>
            Select the locations to track
          </Box>
          <ExportLocationSelect
            options={control?.locations}
            selectedOptions={control?.selectedLocations}
            onSelectionChange={control?.handleSelectionChange}
          />
        </VStack>
      </HStack>
      <Footer
        disabled={control?.disabled}
        label={control?.getLabel()}
        loading={control?.loading}
        handleGenerate={control?.handleGenerate}
      />
    </VStack>
  );
};

export default CustomReportComponent;
