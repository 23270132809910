import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  ListItem,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import UseQueryExamples from './useQueryExamples';
import UseLazyQueryExamples from './useLazyQueryExamples';
import UseMutationExamples from './useMutationExamples';
import ReactiveVariables from './ReactiveVariables';
import React, { FC } from 'react';

const GraphQLInfo: FC = () => {
  return (
    <VStack align='flex-start' gap={4} w='full'>
      <Heading as='h1'>GraphQL for Delightree</Heading>
      <Box fontSize='16px'>
        In the frontend project, we are using the following provided by GraphQL:
        <UnorderedList>
          <ListItem fontWeight={600}>useQuery</ListItem>
          <ListItem fontWeight={600}>useLazyQuery</ListItem>
          <ListItem fontWeight={600}>useMutation</ListItem>
          <ListItem fontWeight={600}>makeVar</ListItem>
          <ListItem fontWeight={600}>useReactiveVar</ListItem>
        </UnorderedList>
      </Box>
      <Accordion w='full' allowMultiple allowToggle>
        <AccordionItem>
          <AccordionButton>
            <HStack justify='space-between' w='full'>
              <Box>useQuery Examples</Box>
              <AccordionIcon />
            </HStack>
          </AccordionButton>
          <AccordionPanel>
            <UseQueryExamples />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <HStack justify='space-between' w='full'>
              <Box>useLazyQuery Examples</Box>
              <AccordionIcon />
            </HStack>
          </AccordionButton>
          <AccordionPanel>
            <UseLazyQueryExamples />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <HStack justify='space-between' w='full'>
              <Box>useMutation Examples</Box>
              <AccordionIcon />
            </HStack>
          </AccordionButton>
          <AccordionPanel>
            <UseMutationExamples />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <HStack justify='space-between' w='full'>
              <Box>Reactive Variables</Box>
              <AccordionIcon />
            </HStack>
          </AccordionButton>
          <AccordionPanel>
            <ReactiveVariables />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <VStack align='flex-start' fontSize='16px'>
        <Box>
          Now you are familiar with all the reactive variables and hooks from
          graphql being used in the project, let's get to know about some
          reactive variables being used in project and what data they store and
          when are they called.
        </Box>
        <Box>
          <UnorderedList spacing={2}>
            <ListItem>
              <HStack align='flex-start'>
                <Box as='span' fontWeight={600}>
                  chatMessageCount:
                </Box>
                <Box as='span'>
                  This reactive variable is used across entire project to show
                  the total count of unread messages.
                </Box>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack align='flex-start'>
                <Box as='span' fontWeight={600}>
                  foldersListVar:
                </Box>
                <Box as='span'>
                  This reactive variable is used across knowledge base module to
                  save the folders list that is then used to perform various
                  actions associated with that module.
                </Box>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack align='flex-start'>
                <Box as='span' fontWeight={600}>
                  chaptersListVar:
                </Box>
                <Box as='span'>
                  This reactive variable is used across knowledge base module to
                  save the chapters list that is then used to perform various
                  actions associated with that module.
                </Box>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack align='flex-start'>
                <Box as='span' fontWeight={600}>
                  usersEntityObj:
                </Box>
                <Box as='span'>
                  This reactive variable is used across entire project to get
                  all the members + locations for the business. Where ever we
                  have to filter out all the members, or all the locations, or
                  members based on some auth role, this variable is being used.
                  <br />
                </Box>
              </HStack>
            </ListItem>
          </UnorderedList>
        </Box>
      </VStack>
    </VStack>
  );
};

export default GraphQLInfo;
