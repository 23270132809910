import React, { MouseEventHandler } from 'react';
import { Box, Flex, forwardRef } from '@chakra-ui/react';
import { SelectOption } from '../../../../../../../atoms';

interface IProps {
  option: SelectOption;
  onClick?: MouseEventHandler<HTMLDivElement>;
  isSelected?: boolean;
}

const CategoryItem = forwardRef<IProps, 'div'>(
  ({ option, onClick, isSelected }, ref) => {
    return (
      <Flex
        ref={ref}
        key={option.value}
        minH='42px'
        alignItems='center'
        paddingInline='12px'
        borderRadius='5px'
        cursor='pointer'
        color='#6F767E'
        role='listitem'
        tabIndex={0}
        aria-selected={isSelected}
        _hover={{ bg: '#F4F4F3' }}
        _selected={{ bg: 'blue.500', color: 'white' }}
        onClick={onClick}
      >
        <Box flex={1} fontSize='15px' fontWeight='600' isTruncated>
          {option.label}
        </Box>
      </Flex>
    );
  }
);

export default CategoryItem;
