import React from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  forwardRef,
  Stack,
  UseCheckboxGroupProps,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useRemindOtherUser } from './remind-to-user';

interface IProps extends UseCheckboxGroupProps {
  name?: string;
}

const RemindUserType = forwardRef<IProps, 'div'>(({ name, ...props }, ref) => {
  const { t } = useTranslation(['nexus']);

  const remindOtherUser = useRemindOtherUser();

  return (
    <CheckboxGroup {...props}>
      <Stack
        ref={ref}
        spacing={4}
        sx={{
          '.chakra-checkbox__label': {
            fontSize: 13,
            fontWeight: 600,
          },
          '.chakra-checkbox__control': {
            borderRadius: '6px',
          },
        }}
      >
        <Checkbox size='lg' value='self' name={name}>
          <Box>
            <span>{t('nexus:documents.reminders.choices.toMe')}</span>
            <Box fontWeight={500} fontSize='12px' color='#6F767E'>
              {t('nexus:documents.reminders.choices.toMeDesc')}
            </Box>
          </Box>
        </Checkbox>
        <Checkbox
          size='lg'
          value='users'
          name={name}
          onChange={(event) => {
            if (event.target.checked) {
              remindOtherUser();
            }
          }}
        >
          <Box>
            <span>{t('nexus:documents.reminders.choices.toOthers')}</span>
            <Box fontWeight={500} fontSize='12px' color='#6F767E'>
              {t('nexus:documents.reminders.choices.toOthersDesc')}
            </Box>
          </Box>
        </Checkbox>
      </Stack>
    </CheckboxGroup>
  );
});

export default RemindUserType;
