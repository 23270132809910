import { AuthRole } from 'authorization';
import { useUserDataSelector, useUserEntity } from 'hooks';
import moment from 'moment';
import React, { FC, useMemo } from 'react';
import { LocationsEntity } from 'sop-commons/src/client';
import { useNexusBoardContext } from 'sub-components/nexus/NexusBase/store/context';
import { TaskSupervisedLocationWiseTrend } from 'sub-components/nexus/NexusBase/types';
import { ILocationPerformance } from '../types';
import Container from './Container';
import ViewAll from './ViewAll';
import SingleLocationFooter from './SingleLocationFooter';
import { RenderLowest, RenderScoreCards } from '.';

const TaskComplete: FC = () => {
  const taskData = useNexusBoardContext((state) => state.taskData);
  const { loggedInUserAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      loggedInUserAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
    })
  );
  const locations = useUserEntity((entity) => entity?.locations);

  const businessLocations = useMemo(() => {
    if (
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN, AuthRole.LOCATION_OWNER].includes(
        loggedInUserAuthRole
      )
    ) {
      return locations || [];
    }
    return loggedInUserLocations || [];
  }, [loggedInUserAuthRole, locations, loggedInUserLocations]);

  const processTaskData = (
    businessLocations: LocationsEntity[],
    taskResponse?: TaskSupervisedLocationWiseTrend
  ): {
    completeData: ILocationPerformance[];
    highestPerforming: ILocationPerformance[];
    lowestPerforming: ILocationPerformance[];
  } => {
    if (!taskResponse) {
      return {
        completeData: [],
        highestPerforming: [],
        lowestPerforming: [],
      };
    }

    const currentMonth = moment().month() + 1;
    const locationIds = businessLocations?.map((loc) => loc?.eid);
    const locationMap: { [eid: string]: string } = {};
    businessLocations.forEach((loc) => {
      locationMap[loc.eid] = loc.name;
    });

    const performanceData: ILocationPerformance[] = [];

    locationIds.forEach((locationId) => {
      const monthlyTrend = taskResponse[locationId];
      if (!monthlyTrend || !monthlyTrend[currentMonth]) {
        return;
      }

      const monthData = monthlyTrend[currentMonth];
      const totalComplete = monthData?.complete || 0;
      const totalInComplete = monthData?.inComplete || 0;
      const totalTasks = totalComplete + totalInComplete;

      // if (totalTasks === 0) {
      //   return;
      // }

      const completionRate = (totalComplete / totalTasks) * 100 || 0;
      performanceData.push({
        locationId,
        locationName: locationMap[locationId] || 'Unknown Location',
        rate: parseFloat(completionRate.toFixed(2)),
        rank: 0,
      });
    });

    performanceData.sort((a, b) => b?.rate - a?.rate);
    performanceData.forEach((item, index) => {
      item.rank = index + 1;
    });

    const highestPerforming = performanceData.slice(0, 3) || [];
    const lowestPerforming = performanceData.slice(3) || [];

    return {
      completeData: performanceData,
      highestPerforming,
      lowestPerforming,
    };
  };

  const { completeData, highestPerforming, lowestPerforming } = processTaskData(
    businessLocations as LocationsEntity[],
    taskData
  );

  return (
    <Container>
      <RenderScoreCards type='task' completeData={highestPerforming} />
      <RenderLowest remainingLocations={lowestPerforming} />
      {completeData?.length < 3 && <SingleLocationFooter />}
      <ViewAll data={completeData} type='task' />
    </Container>
  );
};

export default TaskComplete;
