import React, { FC } from 'react';
import { useBoolean } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { Button } from '../../../../../../../atoms';
import AddCategoryForm from './AddCategoryForm';

interface IProps {}

const AddCategory: FC<IProps> = () => {
  const [value, action] = useBoolean(false);

  if (value) {
    return <AddCategoryForm close={action.off} />;
  }

  return (
    <Button
      w='full'
      leftIcon={<AddIcon />}
      onClick={action.on}
      minH='42px'
      justifyContent='start'
    >
      Add new
    </Button>
  );
};

export default AddCategory;
