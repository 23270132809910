// components/ExportModal/ExportCustomReportModal.tsx

import React, { FC, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  AlertDialogCloseButton,
  Spinner,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { ExportData } from '../useExport';
import CustomReportComponent from './CustomReportComponent';
import { LocationsRating } from './CustomReportComponent/types';

interface ExportCustomReportModalProps {
  customerFeedback: LocationsRating[];
  onBack: () => void;
  onClose: () => void;
}

const ExportCustomReportModal: FC<ExportCustomReportModalProps> = ({
  customerFeedback,
  onBack,
  onClose,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const handleGenerateReport = async () => {
    // setIsGenerating(true);
    // await new Promise((resolve) => setTimeout(resolve, 2000));
    // console.log('Custom Report Generated');
    // setIsGenerating(false);
    onClose();
  };

  return (
    <Box>
      <CustomReportComponent
        customerFeedback={customerFeedback}
        onClose={handleGenerateReport}
      />

      {isGenerating && (
        <Flex justify='center' mt={4}>
          <Spinner size='lg' />
        </Flex>
      )}
    </Box>
  );
};

export default ExportCustomReportModal;
