import React, { FC } from 'react';
import './Home.css';
import { Authorize, AuthRole } from 'authorization';
import DashboardContainer from '../../sub-components/DashboardContainer';
import { MobileExperienceContainer } from '../../sub-components/MobileExperience';
import { NexusBase } from 'sub-components/nexus';
import HomeBase from './HomeBase';
import { useRenderFlag } from 'sub-components/Sidebar/sections/helper';

const Home: FC = () => {
  let flag = useRenderFlag();
  return (
    <DashboardContainer>
      {flag ? (
        <Authorize
          permittedFor={'user'}
          permittedRoles={[
            AuthRole.SUPER_ADMIN,
            AuthRole.ADMIN,
            AuthRole.LOCATION_OWNER,
          ]}
          renderNoAccess={() => <HomeBase />}
        >
          <NexusBase />
        </Authorize>
      ) : (
        <HomeBase />
      )}
      <Authorize canAccess={(authUser) => authUser?.entity?.isDemo}>
        <MobileExperienceContainer />
      </Authorize>
    </DashboardContainer>
  );
};

export default Home;
