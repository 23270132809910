import React, { FC, ReactNode } from 'react';
import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  subTitle?: ReactNode;
  accept: DropzoneOptions['accept'];
  onFileDrop?: DropzoneOptions['onDrop'];
  renderOn?: string;
}

const DragDropComponent: FC<IProps> = ({
  subTitle,
  accept,
  onFileDrop,
  renderOn,
}) => {
  const { t } = useTranslation(['common', 'chapter', 'setting', 'nexus']);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onFileDrop,
    accept: accept,
    multiple: !renderOn ? false : true,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <Flex
      w='full'
      flexDir='column'
      gap={2}
      p={4}
      border='1px dashed #9A9FA5'
      borderRadius='11px'
    >
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <Center h={!renderOn ? 'full' : '60vh'} flexDirection='column' gap={1}>
          <FontAwesomeIcon
            icon={
              !renderOn ? (faCloudArrowUp as IconProp) : (faUpload as IconProp)
            }
            color='#BFC6CE'
            size={!renderOn ? '3x' : '2x'}
          />

          <Text mt={2} fontWeight={400} fontSize='18px' color='#212121'>
            {!renderOn ? (
              t('chapter:dragDropDocumentHere')
            ) : (
              <Trans
                t={t}
                i18nKey='nexus:documents.drapDropDocumentHere'
                components={{
                  span: (
                    <Button
                      variant='link'
                      colorScheme='blue'
                      fontSize='17px'
                      fontWeight='600'
                      onClick={open}
                    />
                  ),
                }}
              />
            )}
          </Text>
          <Text fontWeight={400} fontSize='14px' color='#212121B2'>
            {subTitle}
          </Text>
        </Center>
      </Box>
      {!renderOn && (
        <Center flexDirection='column'>
          <Text fontWeight={700} fontSize='15px' color='#1A1D1F'>
            {t('common:or')}
          </Text>
          <Button
            variant='solid'
            colorScheme='blue'
            fontSize='15px'
            fontWeight='700'
            size='lg'
            borderRadius='7px'
            onClick={open}
            my={2}
          >
            {t('setting:uploadFromDevice')}
          </Button>
        </Center>
      )}
    </Flex>
  );
};

export default DragDropComponent;
