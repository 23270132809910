import React, { FC, useMemo } from 'react';
import { Flex, Grid } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { DashboardContainer } from '../../sub-components';
import { useSafeState } from '../../hooks';
import { Button, FlexCard, Pagination } from '../../atoms';

import AssetBreadcrumb from './AssetBreadcrumb';
import AssetItem from './AssetItem';
import { useAssetDetails } from './useAssetDetails';
import { buildImageGroup } from './assets.helpers';

// @ts-ignore
const context = require.context('../../assets', true);

const useData = (pathName = '') => {
  return useMemo(() => {
    try {
      return buildImageGroup(context, pathName);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      return {};
    }
  }, [pathName]);
};

interface IProps {}

const AssetsPage: FC<IProps> = () => {
  const [gridSize, setGridSize] = useSafeState(10);
  const history = useHistory();

  const params = useParams<{ 0?: string }>();

  const data = useData(params?.[0]);

  const navigateTo = (directory: string) => {
    const pathname = history.location.pathname;
    history.replace(pathname.concat('/', directory));
  };

  const assetDetails = useAssetDetails();

  return (
    <DashboardContainer>
      <FlexCard flex={1} flexDir='column' gap={5}>
        <Pagination
          pageSize={1}
          current={gridSize}
          onChange={(page) => {
            setGridSize(page < 4 ? 5 : page);
          }}
          total={36}
        />

        <AssetBreadcrumb />

        <Flex
          gap={3}
          flexWrap='wrap'
          bg='#EFEFEF'
          padding={2}
          borderRadius='8px'
          maxW='max-content'
          _empty={{
            display: 'none',
          }}
        >
          {data?.directory?.map((directory) => {
            return (
              <Button
                variant='link'
                colorScheme='blue'
                key={directory}
                onClick={() => navigateTo(directory)}
              >
                {directory}
              </Button>
            );
          })}
        </Flex>

        <Grid templateColumns={`repeat(${gridSize}, 1fr)`} gap={3}>
          {data?.imageLists?.map((image, index) => {
            return (
              <AssetItem
                key={index}
                url={image.url}
                pathName={image.pathName}
                onClick={() => assetDetails(image)}
              />
            );
          })}
        </Grid>
      </FlexCard>
    </DashboardContainer>
  );
};

export default AssetsPage;
