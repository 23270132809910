import { Box, Button, HStack, Tooltip } from '@chakra-ui/react';
import React, { FC } from 'react';

interface IProps {
  disabled: boolean;
  label: string;
  loading: boolean;
  handleGenerate: () => void;
}

const Footer: FC<IProps> = ({ disabled, label, loading, handleGenerate }) => {
  return (
    <HStack justify='flex-end' w='full'>
      <Tooltip label={label} hasArrow placement='top'>
        <Box>
          <Button
            variant='solid'
            colorScheme='blue'
            isDisabled={disabled}
            isLoading={loading}
            onClick={handleGenerate}
          >
            Export report(xls)
          </Button>
        </Box>
      </Tooltip>
    </HStack>
  );
};

Footer.displayName =
  'sub-components/nexus/NexusBase/components/LeftSection/components/Heading/components/CustomReportComponent/components/Footer';

export default Footer;
