import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import { HeaderColors } from 'configs';
import { useLatest } from 'hooks';
import RemindToOtherContent from './RemindToOtherContent';
import { IDocumentInput } from '../../compliance.input';

interface IValues {
  admins?: string[];
  owners?: string[];
}

type RemindOtherUser = () => void;

export const useRemindOtherUser = (): RemindOtherUser => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const { getValues, setValue } = useFormContext<IDocumentInput>();

  const onSaveClick = async (values: IValues) => {
    setValue('reminder.remindAdmins', values?.admins || [], {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('reminder.remindOwners', values?.owners || [], {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const saveRef = useLatest(onSaveClick);

  return useCallback(() => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex justify='space-between' gap={3}>
          <BoxHeader
            title='Send reminder to others'
            color={HeaderColors.Purple}
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <RemindToOtherContent
          locationId={getValues('location.value')}
          initialValues={{
            admins: getValues('reminder.remindAdmins'),
            owners: getValues('reminder.remindOwners'),
          }}
          onSaveClick={saveRef.current}
        />
      ),
      isCentered: true,
      contentProps: {
        py: '12px',
        // px: '6px',

        minW: '600px',
        borderRadius: '12px',

        marginTop: 'auto',
        marginBottom: 'auto',
        containerProps: {
          // alignItems: 'start',
          paddingTop: '3.75rem',
          paddingBottom: '3.75rem',
        },
      },
      footer: null,
    });
  }, []);
};
