export const getFeatureAlertKey = <T>(userId: T): T => {
  // @ts-ignore
  return userId?.substring(4, 10).concat('-feature-alert-1');
};

export const AI_SEARCH_INTRO = 'AI_SEARCH_INTRO';

export const getAiSearchPrefer = (): boolean | undefined => {
  const nextValue = window.localStorage.getItem(AI_SEARCH_INTRO);
  return nextValue ? nextValue === 'true' : undefined;
};
