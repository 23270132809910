import { Box, Heading, VStack } from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';

const DashboardContainerWrapper: FC = () => {
  const dashboardContainerCode = `import { DashboardContainer } from 'sub-components';
const CompA = ({ children }) => {
    return <DashboardContainer>{children}</DashboardContainer>
}
export default CompA;
`;
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>{'<DashboardContainer />'}</Heading>
      <Box>
        This is the main wrapper which wraps all the components inside{' '}
        {'<Header />'} and {'<Sidebar />'}.<br />
        This will always be used if you have to show component wrapped inside{' '}
        {'<Header />'} and {'<Sidebar />'}.
      </Box>
      <SyntaxHighlighter language='typescript' style={materialDark}>
        {dashboardContainerCode}
      </SyntaxHighlighter>
    </VStack>
  );
};

export default DashboardContainerWrapper;
