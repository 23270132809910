import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import styled from '@emotion/styled';
import { cloneDeep } from '@apollo/client/utilities';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { DEFAULT_FORM_COVER } from '../../../configs/default-image';
import { Title } from '../../../ui-components/common';

import FormDetails from './FormDetails';
import { IFormInput, Question } from './form.types';
import { QuestionList } from './question';
import ActionButtons from './ActionButtons';
import { initialData } from './temp-data';
import DelightreeTips from './DelightreeTips';
import { createForm, UPDATE_FORM_QUERY } from '../form.graphql';
import PreviewHeader from '../component/PreviewHeader';
import FormCategoryComponent from '../component/FormCategoryComponent';
import Loader from '../../Loader';
import ExistingTemplate from './ExistingTemplate';
import MobilePreview from './MobilePreview';
import NullComp from '../component/NullComp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';
import { useTranslation } from 'react-i18next';
import { formDataFormat, formVisibilityFormat } from './formDataFormat';
import { useVisibility } from './visibility';
import {
  FormVisibility,
  IVisibilityInput,
} from './visibility/visibility.types';
import { useSearchParam } from '../../../hooks';
import { FORMS_URL } from '../../../appRoutes';
import { QuestionType } from '../../../types';

const Wrapper = styled.div<{ isReadOnly?: boolean }>`
  .form-container {
    background: #ffffff;
    padding: ${(props) => (props.isReadOnly ? '0px' : '44px')};
    border-radius: 8px;
    margin-top: 24px;
    margin-bottom: 72px;
  }

  .dropdown {
    font-size: 15px;
    background-color: var(--chakra-colors-gray-100);
    border-radius: 12px;
  }
`;

export interface FormCreationRef {
  initForm: (
    values: IFormInput,
    shouldDirty?: boolean,
    isReadOnly?: boolean
  ) => void;
}

interface LocationState {
  backToTitle?: string;
}

interface IProps {
  fromTemplate?: boolean;
  showInitialize?: boolean;
  title?: string;
  isEdit?: boolean;
}

const FormCreation = forwardRef<FormCreationRef, IProps>(
  ({ fromTemplate, showInitialize, title, isEdit }, ref) => {
    const { t, i18n } = useTranslation(['common', 'form']);
    const initialRef = useRef<IFormInput>();
    const submitRef = useRef<boolean>(false);
    const toast = useToast({
      position: 'top-right',
    });
    const history = useHistory();
    const location = useLocation<LocationState>();
    const formId = useParams<{ id: string }>()?.id;
    const searchParams = useSearchParam<{ from?: string }>();

    const visibilitySetting = useVisibility();

    const methods = useForm<IFormInput>({
      defaultValues: initialData,
    });

    const { control } = methods;

    const isReadOnly = useWatch<IFormInput, 'isReadOnly'>({
      name: 'isReadOnly',
      control,
    });

    const formatDataForAmplitudeEvents = (questions: Question[]) => {
      const categoryObj = {};
      const quesionDetails = [];
      questions?.forEach((question: Question, index: number) => {
        console.log('question --- ', question);

        if (categoryObj?.hasOwnProperty(`${question?.qType}`)) {
          categoryObj[`${question?.qType}`] += 1;
        } else {
          categoryObj[`${question?.qType}`] = 1;
        }

        let temp = Object.assign({
          number: index + 1,
          details: {
            category: question?.qType,
            required: question?.isRequired,
            enable_adding_image: '',
          },
        });
        quesionDetails?.push(temp);
      });

      return { categoryObj, quesionDetails };
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          initForm: (values, shouldDirty, isReadOnly) => {
            if (isReadOnly) {
              methods.setValue('isReadOnly', true);
            }
            if (!values.thumbnail) {
              values.thumbnail = DEFAULT_FORM_COVER;
            }
            initialRef.current = values;
            if (shouldDirty) {
              methods.reset({ ...values, title: '' });
              methods.setValue('title', values.title, {
                shouldDirty: true,
                shouldTouch: true,
              });
            } else {
              methods.reset(values);
            }
          },
        };
      },
      [methods.reset]
    );

    const onConfirmReset = () => {
      methods.reset(initialData);
    };

    const onBackClick = () => {
      if (searchParams?.from === 'powerup') {
        return window.close();
      }

      if (location.key) {
        history.goBack();
      } else {
        history.replace(FORMS_URL);
      }
    };

    const [submitForm, { loading: creating }] = useMutation(createForm, {
      onCompleted: () => {
        toast({
          title: t('common:success'),
          description: t('form:form_create_success_toast'),
          status: 'success',
        });
        onBackClick();
      },
      onError: (error) => {
        // TODO populate error to form field
        console.log(error);
        toast({
          title: t('common:error'),
          description: t('common:something_wrong'),
          status: 'error',
        });
      },
    });

    const [updateFormData, { loading: updating }] = useMutation(
      UPDATE_FORM_QUERY,
      {
        onCompleted: () => {
          toast({
            title: t('common:success'),
            description: t('form:form_update_success_toast'),
            status: 'success',
          });
          onBackClick();
        },
        onError: (error) => {
          // TODO populate error to form field
          console.log(error);
          toast({
            title: t('common:error'),
            description: t('common:something_wrong'),
            status: 'error',
          });
        },
      }
    );

    const validateNoQuestion = (values: IFormInput) => {
      const hasQuestion = values.questions?.filter(
        (value) => value.qType !== QuestionType.SECTION && !value.isArchieved
      );
      if (!hasQuestion?.length) {
        const questions = [
          ...values.questions,
          {
            qType: QuestionType.SHORT_TEXT,
            options: [],
            label: '',
            isRequired: false,
            hasAnalytics: false,
          },
        ];

        methods.setValue('questions', questions);

        methods.trigger(`questions.${values.questions.length}.label`);
        return true;
      } else {
        return false;
      }
    };

    const formSubmission = async (
      _data: IFormInput,
      visibility: IVisibilityInput
    ) => {
      const newData = cloneDeep(_data) as IFormInput & FormVisibility;

      newData.visibility = visibility.visibility;

      if (visibility.visibility === 'private') {
        newData.visibleTo = formVisibilityFormat(visibility);
      } else {
        newData.visibleTo = {
          roles: [],
          users: [],
          locations: [],
        };
      }

      if (isEdit) {
        newData.eid = formId;

        const updateRes = await updateFormData({
          variables: {
            input: newData,
          },
        });

        if (updateRes.errors) {
          return Promise.reject('Update form failed');
        }
      } else {
        const submitRes = await submitForm({
          variables: {
            input: newData,
          },
        });

        if (submitRes.errors) {
          return Promise.reject('Submit form failed');
        }
      }

      submitRef.current = false;
    };

    const settingVisibility = async (_data: IFormInput) => {
      visibilitySetting({
        isEdit: isEdit,
        initialValues: {
          visibility: methods.getValues('visibility'),
          visibleTo: methods.getValues('visibleTo'),
        },
        onApplyClick: (_values) => formSubmission(_data, _values),
      });
    };

    const onFinish = async (values: IFormInput) => {
      if (submitRef.current) {
        return;
      }
      // submitRef.current = true;
      const data = formDataFormat(
        JSON.parse(JSON.stringify(values), (_, it) =>
          it === null || it === '' ? undefined : it
        )
      );
      // @ts-ignore
      delete data.formCategory;
      // @ts-ignore
      delete data.height;
      // @ts-ignore
      delete data.isReadOnly;

      // @ts-ignore
      data.categoryId = values.formCategory.eid;

      if (validateNoQuestion(values)) {
        submitRef.current = false;
        return;
      }

      // deployEvent(AmplitudeEventNames.FORMS_ADD_NEW_FORM_BUTTON_PUBLISH_CLICK);

      let { categoryObj: categoryDetails, quesionDetails } =
        formatDataForAmplitudeEvents(values?.questions);

      deployEvent(AmplitudeEventNames?.PUBLISH_FORM, {
        form_id: values?.eid ? values?.eid : '',
        form_category: values?.formCategory?.name,
        total_forms_published: '',
        cover_image: values?.thumbnail,
        form_title: values?.title,
        form_description: values?.description,
        total_questions: values?.questions?.length,
        category_details: categoryDetails,
        question_details: quesionDetails,
        create_form_funnel_id: values?.eid ? '' : 5,
        template_category: fromTemplate
          ? initialRef?.current?.formCategory?.name
          : '',
        template_name: fromTemplate ? initialRef?.current?.title : '',
        edit_form_funnel_id: values?.eid ? 3 : '',
      });

      await settingVisibility(data);

      submitRef.current = false;
    };

    const backTitle = useMemo(() => {
      if (searchParams.from === 'powerup') {
        return t('common:back');
      }

      return location?.state?.backToTitle ?? t('form:back_to_forms');
    }, [location, i18n.language]);

    return (
      <Wrapper isReadOnly={isReadOnly}>
        <FormProvider {...methods}>
          {!isReadOnly && (
            <Flex justify='space-between' align='center'>
              <Box>
                <Title>{title || t('form:create_form')}</Title>
                <Box mt={1} cursor='pointer' onClick={onBackClick}>
                  <FontAwesomeIcon
                    icon={faChevronLeft as IconProp}
                    color='#6f767e'
                  />
                  <Text
                    as='span'
                    pl={2}
                    fontWeight={500}
                    fontSize='15px'
                    color='#6f767e'
                  >
                    {backTitle}
                  </Text>
                </Box>
              </Box>
              {!fromTemplate ? <ExistingTemplate /> : null}
            </Flex>
          )}
          <Flex className='form-container' position='relative' gap={16}>
            <Box flex='1' position='relative'>
              <form
                id='form-creation'
                onSubmit={methods.handleSubmit(onFinish)}
              >
                <FormCategoryComponent isReadOnly={isReadOnly} />
                <FormDetails isReadOnly={isReadOnly} />
                <QuestionList
                  hideAddQuestion={showInitialize}
                  isReadOnly={isReadOnly}
                />
                {!isReadOnly && <DelightreeTips />}
                {!isReadOnly && (
                  <ActionButtons
                    isEdit={isEdit}
                    submitting={creating || updating}
                    onPublish={onFinish}
                    confirmReset={onConfirmReset}
                  />
                )}
              </form>
            </Box>
            {!isReadOnly && (
              <Flex direction='column' pl={4}>
                <PreviewHeader title={t('preview')} />
                <MobilePreview />
              </Flex>
            )}

            {showInitialize ? (
              <Flex
                position='absolute'
                top='0'
                left={0}
                right={0}
                h='100%'
                borderRadius='8px'
                bg='blackAlpha.400'
              >
                <Loader size='lg' mx='auto' style={{ marginTop: '30%' }} />
              </Flex>
            ) : null}
          </Flex>
          <NullComp />
        </FormProvider>
      </Wrapper>
    );
  }
);

FormCreation.displayName = 'sc/f/cf/FormCreation';

export default FormCreation;
