import { useMemo } from 'react';
import { match } from 'ts-pattern';
import { AppRoute, useHistory } from '../../../routes';
import { useUserDataSelector } from '../../../hooks';
import { AuthRole } from '../../../authorization';
import { AUDIT_LIST } from '../../../appRoutes';

export const useGenerateLink = (
  type: string,
  docId: string
): string | undefined => {
  const history = useHistory();
  const authRole = useUserDataSelector((state) => state?.authRole);

  return useMemo(() => {
    return match(type)
      .with('sop', () =>
        history.createHref({
          pathname: AppRoute.CHAPTER_VIEW,
          params: {
            chapterId: docId,
          },
        })
      )
      .with('training', () =>
        history.createHref({
          pathname: AppRoute.TRAINING_PLAY_URL,
          params: {
            trainingId: docId,
          },
        })
      )
      .with('form', () =>
        history.createHref({
          pathname: AppRoute.FORM_SUBMIT,
          params: {
            id: docId,
          },
        })
      )
      .with('audit', () => {
        if (authRole === AuthRole.WORKER) {
          return undefined;
        }
        return history.createHref({
          pathname: AppRoute.AUDIT_LIST,
        });
      })
      .with('task', () => {
        if ([AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(authRole)) {
          return history.createHref({
            pathname: AppRoute.TASK_DETAILS,
            params: {
              id: docId,
            },
          });
        }
        return undefined;
      })
      .otherwise(() => undefined);
  }, [history, type, docId, authRole]);
};
