import React, { FC, useMemo, useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TabItem } from 'atoms';
import { AuthRole } from 'authorization';
import { useSearchParam, useUserDataSelector } from 'hooks';
import { shallowEqual } from 'utils';

import { ILocationDetails } from 'pages/LocationDetails/utils/location-details.types';

import { Activity, Documents, OwnersMembers } from './components';
import { ConversationLogs } from './components/ConversationLogs';

interface SearchParam {
  tab?: 'log';
}

interface IProps {
  details?: ILocationDetails;
  refetch:
    | ((
        variables?:
          | Partial<{
              eid: string;
            }>
          | undefined
      ) => Promise<
        ApolloQueryResult<{
          userById: ILocationDetails;
        }>
      >)
    | undefined;
}

const LocationAssetsCard: FC<IProps> = ({ details, refetch }) => {
  const { t } = useTranslation(['location']);

  const history = useHistory();
  const searchParam = useSearchParam<SearchParam>();

  const authUser = useUserDataSelector(
    (state) => ({
      type: state.type,
      authRole: state.authRole,
      locations: state.locations,
    }),
    shallowEqual
  );

  const canShowLog = useMemo(() => {
    if (!authUser?.type || authUser?.type === 'branch') {
      return false;
    }
    if ([AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(authUser?.authRole)) {
      return true;
    }

    return (
      authUser?.authRole === AuthRole.LOCATION_OWNER &&
      authUser?.locations?.some((loc) => loc?.eid === details?.eid)
    );
  }, [authUser]);

  const renderFlag = () => {
    if (authUser?.type === 'branch') {
      return false;
    }
    if (
      [AuthRole.LOCATION_OWNER, AuthRole.WORKER]?.includes(authUser?.authRole)
    ) {
      let flag = authUser?.locations?.some((loc) => loc?.eid === details?.eid);
      if (!flag) {
        return false;
      }
    }
    return true;
  };

  const [tabIndex, setTabIndex] = useState(() => {
    if (searchParam?.tab === 'log' && canShowLog) {
      if (details?.locationStatus === 'preLaunch') {
        return renderFlag() ? 1 : 0;
      }
      return renderFlag() ? 2 : 1;
    }
    return 0;
  });

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    if (searchParam?.tab) {
      history.replace(history.location.pathname);
    }
  };

  return (
    <Tabs w='full' isLazy index={tabIndex} onChange={handleTabsChange}>
      <TabList borderColor='#F5F5F5'>
        {details?.locationStatus !== 'preLaunch' ? (
          <TabItem>{t('location:ownersMembersTab')}</TabItem>
        ) : (
          <TabItem>{t('location:ownersPrelaunchLocations')}</TabItem>
        )}
        {renderFlag() && <TabItem>{t('location:documents')}</TabItem>}
        {canShowLog && <TabItem>{t('location:conversationLogsTab')}</TabItem>}
        {renderFlag() && <TabItem>{t('location:activity')}</TabItem>}
      </TabList>

      <TabPanels>
        {
          <TabPanel>
            <OwnersMembers
              locationMembers={details?.members}
              locationId={details?.eid}
              locationName={details?.name}
              refetch={refetch}
              locationStatus={details?.locationStatus}
            />
          </TabPanel>
        }
        {renderFlag() && (
          <TabPanel>
            <Documents documents={details?.files} refetch={refetch} />
          </TabPanel>
        )}
        {canShowLog && (
          <TabPanel>
            <ConversationLogs locationId={details?.eid!} />
          </TabPanel>
        )}
        {renderFlag() && (
          <TabPanel>
            <Activity locationId={details?.eid} />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};

export default LocationAssetsCard;
