import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react';
import { ReactComponent as RenameIcon } from 'assets/images/nexus/compliance/edit.svg';
import { ReactComponent as DuplicateIcon } from 'assets/images/nexus/compliance/copy.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/nexus/compliance/delete.svg';
import Loader from 'sub-components/Loader';
import DeleteCategoryModal from './DeleteCategoryModal';
import { boolean } from 'yup';
import { AuthRole } from 'sop-commons/src/client';

interface IProps {
  title: string;
  count: number;
  eid: string;
  isPopoverOpen: boolean;
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activeCategory: string;
  setActiveCategory: React.Dispatch<React.SetStateAction<string>>;
  isRenameFieldVisible: boolean;
  setIsRenameFieldVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleOperation: (
    item: string,
    eid: string,
    renameFieldVisibility: boolean
  ) => void;
  deleteLoader: any;
  complianceLoader: any;
  getComplianceListing: any;
  getComplianceFilters: any;
  category: any;
  loggedInUserId: string;
  userAuthRole: string;
}

const CategoryCard: FC<IProps> = ({
  title,
  count,
  setActiveCategory,
  eid,
  activeCategory,
  isPopoverOpen,
  setIsPopoverOpen,
  isRenameFieldVisible,
  setIsRenameFieldVisible,
  handleOperation,
  deleteLoader,
  complianceLoader,
  getComplianceFilters,
  getComplianceListing,
  category,
  loggedInUserId,
  userAuthRole,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const options = [
    { title: 'Rename', id: 1, icon: <RenameIcon /> },
    { title: 'Delete', id: 2, icon: <DeleteIcon /> },
  ];
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isSelected = eid === activeCategory;

  return (
    <>
      <Flex
        onClick={() => {
          setActiveCategory(isSelected ? '' : eid);
          getComplianceListing({
            variables: getComplianceFilters(1, isSelected ? '' : eid),
          });
        }}
        border={'1px solid #F4F4F4'}
        color={'#6F767E'}
        gap={2}
        justifyContent={'space-between'}
        minW={'fit-content'}
        cursor={'pointer'}
        height={'40px'}
        alignItems={'center'}
        px={'16px'}
        py={'8px'}
        borderRadius={'8px'}
        aria-selected={eid === activeCategory}
        _selected={{ bg: '#EFEFEF' }}
        _hover={{
          bg: '#EFEFEF',
          '.action': {
            display: 'flex',
          },
        }}
      >
        <Text
          noOfLines={1}
          fontSize={'14px'}
          fontWeight={600}
          color={eid === activeCategory ? '#1A1D1F' : '#6F767E'}
        >
          {title} ({count})
        </Text>
        <Popover>
          {userAuthRole === AuthRole?.SUPER_ADMIN ? (
            <PopoverTrigger>
              <Flex
                justifyContent={'center'}
                p={'2px'}
                w={'10px'}
                display={'none'}
                className='action'
                _expanded={{ display: 'flex' }}
                onClick={(e) => e.stopPropagation()}
              >
                <FontAwesomeIcon
                  fontSize={'18px'}
                  icon={faEllipsisVertical as IconProp}
                />
              </Flex>
            </PopoverTrigger>
          ) : (
            userAuthRole === AuthRole?.ADMIN &&
            loggedInUserId === category?.createdBy && (
              <PopoverTrigger>
                <Flex
                  justifyContent={'center'}
                  p={'2px'}
                  w={'10px'}
                  display={'none'}
                  className='action'
                  _expanded={{ display: 'flex' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <FontAwesomeIcon
                    fontSize={'18px'}
                    icon={faEllipsisVertical as IconProp}
                  />
                </Flex>
              </PopoverTrigger>
            )
          )}
          <PopoverContent borderRadius={'16px'} width={'185px'}>
            <PopoverArrow />
            <PopoverBody>
              <Flex>
                <ul style={{ listStyle: 'none', width: '100%' }}>
                  {options?.map((item) => {
                    return (
                      <Flex
                        gap={2}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          if (item?.title === 'Delete') {
                            // setShowDeleteModal(!showDeleteModal);
                            onOpen();
                          } else if (item?.title === 'Rename') {
                            handleOperation('Rename', eid, true);
                          }
                        }}
                        _hover={{ backgroundColor: '#F4F4F4' }}
                        key={item?.id}
                        style={{
                          borderRadius: '12px',
                          padding: '12px',
                        }}
                      >
                        {item?.icon}
                        <Text>{item?.title}</Text>

                        <DeleteCategoryModal
                          isOpen={isOpen}
                          onClose={onClose}
                          deleteLoader={deleteLoader}
                          complianceLoader={complianceLoader}
                          handleOperation={handleOperation}
                          item={item}
                          eid={eid}
                        />
                      </Flex>
                    );
                  })}
                </ul>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    </>
  );
};

export default CategoryCard;
