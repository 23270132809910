import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Tooltip } from '@chakra-ui/react';
import React, { FC, useMemo, useState } from 'react';
import { HeaderColors } from 'shared/headerColors';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { useAuditTabContext } from '../../store/context';
import { ProcessData } from '../types';
import moment from 'moment';
import { useUserDataSelector } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Button } from 'atoms';
import { AUDIT_LIST } from 'appRoutes';
import { Column } from './CollapseTable/types';
import CollapseTable from './CollapseTable/CollapseTable';
import Loader from 'sub-components/Loader';
import EmptyState from 'sub-components/EmptyState';
import { ReactComponent as AuditView } from '../../../../../assets/images/audit-view-eye.svg';
import { ReactComponent as AuditDownload } from '../../../../../assets/images/audit-download.svg';
import DownloadReport from 'sub-components/audits/common/download-report';

interface ScheduledData {
  noDeadLineAudits: ProcessData[];
  dueToday: ProcessData[];
  dueTomorrow: ProcessData[];
  dueThisWeek: ProcessData[];
}

export type FilteredData = ProcessData[] | ScheduledData;

const TableRenderNew: FC<{ loading: boolean; refetchHandler?: () => void }> = ({
  loading,
  refetchHandler,
}) => {
  const myAuditData = useAuditTabContext((state) => state.myAuditData);
  const chipFilter = useAuditTabContext((state) => state.auditChipsFilter);
  const history = useHistory();
  const loggedInUserEid = useUserDataSelector((state) => state?.eid);
  const selectedFilter = chipFilter?.find((chip) => chip?.selected)?.value;

  const isScheduled = selectedFilter === 'scheduled';

  const categorizeByDueDate = (locations: ProcessData['assignedLocations']) => {
    let scheduledAudits = locations?.filter(
      (loc) => loc?.auditStatus === 'upcoming'
    );
    const noDeadLineAudits = scheduledAudits?.filter(
      (loc) => !loc?.auditExpectedToFinish
    );
    let auditsWithDeadline = scheduledAudits?.filter(
      (loc) => loc?.auditExpectedToFinish
    );
    const dueToday = auditsWithDeadline.filter((loc) =>
      moment.utc(loc.auditExpectedToFinish).isSame(moment(), 'day')
    );

    const dueTomorrow = auditsWithDeadline.filter((loc) =>
      moment
        .utc(loc.auditExpectedToFinish)
        .isSame(moment().add(1, 'day'), 'day')
    );

    const dueThisWeek = auditsWithDeadline.filter((loc) =>
      moment
        .utc(loc.auditExpectedToFinish)
        .isBetween(
          moment.utc().startOf('isoWeek'),
          moment.utc().endOf('isoWeek'),
          null,
          '[]'
        )
    );

    return { noDeadLineAudits, dueToday, dueTomorrow, dueThisWeek };
  };

  const data: FilteredData = useMemo(() => {
    if (!myAuditData) return [];

    let mappedData: ProcessData[] = myAuditData.map((data) => ({
      ...data,
      key: data.auditSessionId,
    }));

    const selectedChip = chipFilter?.find((chip) => chip?.selected)?.value;

    if (!selectedChip || selectedChip === 'all') {
      return mappedData;
    } else if (selectedChip === 'scheduled') {
      // Handle 'Scheduled' filter by categorizing into dueToday, dueTomorrow, dueThisWeek
      const scheduledArr: ScheduledData = {
        noDeadLineAudits: [],
        dueToday: [],
        dueTomorrow: [],
        dueThisWeek: [],
      };

      mappedData.forEach((mapped) => {
        const { noDeadLineAudits, dueToday, dueTomorrow, dueThisWeek } =
          categorizeByDueDate(mapped.assignedLocations || []);

        if (noDeadLineAudits?.length > 0) {
          scheduledArr.noDeadLineAudits.push({
            ...mapped,
            assignedLocations: noDeadLineAudits,
          });
        }

        if (dueToday.length > 0) {
          scheduledArr.dueToday.push({
            ...mapped,
            assignedLocations: dueToday,
          });
        }

        if (dueTomorrow.length > 0) {
          scheduledArr.dueTomorrow.push({
            ...mapped,
            assignedLocations: dueTomorrow,
          });
        }

        if (dueThisWeek.length > 0) {
          scheduledArr.dueThisWeek.push({
            ...mapped,
            assignedLocations: dueThisWeek,
          });
        }
      });

      return scheduledArr;
    } else {
      // Existing status-based filtering
      let filteredMappedData: ProcessData[] = [];
      mappedData.forEach((mapped) => {
        const filteredLocations = mapped.assignedLocations.filter(
          (loc) => loc.auditStatus === selectedChip
        );
        if (filteredLocations.length > 0) {
          filteredMappedData.push({
            key: mapped.key,
            auditTitle: mapped.auditTitle,
            auditSessionId: mapped.auditSessionId,
            auditId: mapped.auditId,
            assignedLocations: filteredLocations,
            totalAssignments: mapped.totalAssignments,
          });
        }
      });
      return filteredMappedData;
    }
  }, [myAuditData, chipFilter]);

  const renderPanel = (item: ProcessData) => (
    <Flex flexDir='column'>
      {item.assignedLocations.map((location) => {
        const hasAuditTakeAccess =
          location.auditStartedBy === loggedInUserEid ||
          !location.auditStartedBy;

        const handleAuditAction = () => {
          history.push(
            `${AUDIT_LIST}/take-audit/lI/${location.locationId}/sI/${location.auditSessionId}`
          );
        };

        const downloadKey = `${location.locationId}-${location.auditSessionId}`;

        return (
          <Flex
            key={location.locationId}
            p='20px'
            borderBottom='1px solid #f0f0f0'
            align='center'
          >
            <Box w='30%' fontWeight={500}>
              {location.locationName}
            </Box>
            <Box w='20%' fontWeight={500}>
              {moment(location.auditAssignedOn).isValid()
                ? moment.utc(location.auditAssignedOn).format('DD MMM, YYYY')
                : '-'}
            </Box>
            <Box w='20%' fontWeight={500}>
              {moment(location.auditExpectedToFinish).isValid()
                ? moment
                    .utc(location.auditExpectedToFinish)
                    .format('DD MMM, YYYY')
                : 'No Deadline'}
            </Box>
            <Box w='20%'>
              <Box
                w='fit-content'
                bg={
                  location.auditStatus === 'completed'
                    ? 'rgba(181, 228, 202, 1)'
                    : location.auditStatus === 'ongoing'
                    ? 'rgba(255, 216, 141, 1)'
                    : location.auditStatus === 'upcoming'
                    ? 'rgba(202, 189, 255, 1)'
                    : 'rgba(255, 188, 153, 1)'
                }
                borderRadius='6px'
                p='6px 8px'
                fontWeight={500}
              >
                {(location?.auditStatus || '')?.charAt(0).toUpperCase() +
                  (location?.auditStatus || '')?.slice(1)}
              </Box>
            </Box>
            <Box w='fit-content'>
              {location.auditStatus === 'completed' ? (
                <Flex align='center' gap='36px'>
                  <Box
                    w='fit-content'
                    cursor='pointer'
                    onClick={() => {
                      history.push(
                        `/audits/report/lI/${location?.locationId}/sI/${location?.auditSessionId}`
                      );
                    }}
                  >
                    <Tooltip hasArrow placement='top' label='View Audit'>
                      <AuditView />
                    </Tooltip>
                  </Box>
                  <DownloadReport
                    locationId={location?.locationId}
                    sessionId={location?.auditSessionId}
                    callbackFn={refetchHandler}
                    reportsUrl={location?.reportsUrl}
                  >
                    <AuditDownload />
                  </DownloadReport>
                </Flex>
              ) : (
                <Tooltip
                  label={
                    !hasAuditTakeAccess ? 'Audit started by someone else' : ''
                  }
                  hasArrow
                  placement='top'
                >
                  <Box w='fit-content'>
                    <Button
                      variant='outline'
                      disabled={!hasAuditTakeAccess}
                      onClick={handleAuditAction}
                    >
                      {location.auditStatus === 'ongoing'
                        ? 'Continue'
                        : 'Start audit'}
                    </Button>
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Flex>
        );
      })}
    </Flex>
  );

  const columns: Column<ProcessData>[] = [
    {
      header: 'Location',
      accessor: 'auditTitle',
      width: '30%',
    },
    {
      header: 'Assigned on',
      accessor: (item) =>
        item.assignedLocations?.[0]?.auditAssignedOn
          ? moment
              .utc(item.assignedLocations[0].auditAssignedOn)
              .format('DD MMM, YYYY')
          : '-',
      width: '20%',
    },
    {
      header: 'Expected by',
      accessor: (item) =>
        item.assignedLocations?.[0]?.auditExpectedToFinish
          ? moment
              .utc(item.assignedLocations[0].auditExpectedToFinish)
              .format('DD MMM, YYYY')
          : '-',
      width: '20%',
    },
    {
      header: 'Status',
      accessor: (item) => (
        <Box
          bg={
            item.assignedLocations?.[0]?.auditStatus === 'completed'
              ? 'rgba(181, 228, 202, 1)'
              : item.assignedLocations?.[0]?.auditStatus === 'ongoing'
              ? 'rgba(255, 216, 141, 1)'
              : item.assignedLocations?.[0]?.auditStatus === 'upcoming'
              ? 'rgba(202, 189, 255, 1)'
              : 'rgba(255, 188, 153, 1)'
          }
          borderRadius='6px'
          p='6px 8px'
          fontWeight={500}
          w='fit-content'
        >
          {(item?.assignedLocations?.[0]?.auditStatus || '')
            ?.charAt?.(0)
            .toUpperCase?.() +
            (item?.assignedLocations?.[0]?.auditStatus || '')?.slice?.(1)}
        </Box>
      ),
      width: '20%',
    },
    {
      header: 'Action',
      accessor: (item) => (
        <Flex align='center' gap='20px'>
          <AuditView />
          {/* <FontAwesomeIcon
            icon={faEye as IconProp}
            cursor='pointer'
            onClick={() =>
              history.push(`/audits/report/${item.auditSessionId}`)
            }
          /> */}
          <FontAwesomeIcon
            icon={faArrowDownToLine as IconProp}
            color='#14B13B'
            fontSize='20px'
            cursor='pointer'
          />
        </Flex>
      ),
      width: 'fit-content',
    },
  ];

  const renderHeader = (item: ProcessData, isExpanded: boolean) => (
    <Flex align='center' p='24px' justify='space-between' w='full'>
      <Flex align='center' color='rgba(111, 118, 126, 1)'>
        {isExpanded ? (
          <ChevronDownIcon fontSize='20px' />
        ) : (
          <ChevronRightIcon fontSize='20px' />
        )}
        <Box textAlign='left' ml={2} fontWeight={600}>
          {item.auditTitle}
        </Box>
        <Box textAlign='left' ml={2} fontWeight={500}>
          - {item.totalAssignments}{' '}
          {item.totalAssignments === 1 ? 'location' : 'locations'}
        </Box>
      </Flex>
      <Flex
        color='rgba(42, 133, 255, 1)'
        fontSize='12px'
        fontWeight={500}
        onClick={(e) => {
          e?.stopPropagation();
          history.push(
            `/audits/details/${item?.auditId}/${item?.auditSessionId}`
          );
        }}
      >
        View Details
      </Flex>
    </Flex>
  );

  const filterByStatus = (
    status: ProcessData['assignedLocations'][number]['auditStatus']
  ): ProcessData[] => {
    if (isScheduled || !Array.isArray(data)) return [];
    let arr: ProcessData[] = [];
    data?.forEach((_data) => {
      let filteredLocations = _data?.assignedLocations?.filter(
        (loc) => loc?.auditStatus === status
      );
      if (filteredLocations?.length > 0) {
        arr.push({
          ..._data,
          assignedLocations: filteredLocations,
        });
      }
    });
    return arr;
  };
  const overdueArr = filterByStatus('overdue');
  const ongoingArr = filterByStatus('ongoing');
  const upcomingArr = filterByStatus('upcoming');
  const completedArr = filterByStatus('completed');

  if (loading) {
    return (
      <Center h='60vh'>
        <Loader />
      </Center>
    );
  }

  const renderScheduledTables = () => {
    const { noDeadLineAudits, dueToday, dueTomorrow, dueThisWeek } = data as {
      noDeadLineAudits: ProcessData[];
      dueToday: ProcessData[];
      dueTomorrow: ProcessData[];
      dueThisWeek: ProcessData[];
    };

    if (
      noDeadLineAudits?.length === 0 &&
      dueToday?.length === 0 &&
      dueTomorrow?.length === 0 &&
      dueThisWeek?.length === 0
    ) {
      return (
        <Center h='50vh'>
          <EmptyState image='Search' title='No upcoming audits found' />
        </Center>
      );
    }

    return (
      <Flex flexDir='column' gap={4}>
        <Flex mb='30px'>
          <GenericColorHeader title='Upcoming' color={HeaderColors.Purple} />
        </Flex>
        <>
          {noDeadLineAudits?.length > 0 && (
            <Flex flexDir='column' gap={4}>
              <CollapseTable
                data={noDeadLineAudits}
                columns={columns}
                renderPanel={renderPanel}
                renderHeader={renderHeader}
              />
            </Flex>
          )}
          {dueToday.length > 0 && (
            <Flex flexDir='column' gap={4}>
              <Box fontWeight={600} fontSize='18px'>
                Due today
              </Box>
              <CollapseTable
                data={dueToday}
                columns={columns}
                renderPanel={renderPanel}
                renderHeader={renderHeader}
              />
            </Flex>
          )}
          {dueTomorrow.length > 0 && (
            <Flex flexDir='column' gap={4}>
              <Box fontWeight={600} fontSize='18px'>
                Due tomorrow
              </Box>
              <CollapseTable
                data={dueTomorrow}
                columns={columns}
                renderPanel={renderPanel}
                renderHeader={renderHeader}
              />
            </Flex>
          )}
          {dueThisWeek.length > 0 && (
            <Flex flexDir='column' gap={4}>
              <Box fontWeight={600} fontSize='18px'>
                Due this week
              </Box>
              <CollapseTable
                data={dueThisWeek}
                columns={columns}
                renderPanel={renderPanel}
                renderHeader={renderHeader}
              />
            </Flex>
          )}
        </>
      </Flex>
    );
  };

  const renderStatusBasedTables = () => {
    if (
      (selectedFilter === 'overdue' && overdueArr?.length === 0) ||
      (selectedFilter === 'ongoing' && ongoingArr?.length === 0) ||
      (selectedFilter === 'completed' && completedArr?.length === 0)
    ) {
      return (
        <Center h='50vh'>
          <EmptyState
            image='Search'
            title='No audit found for selected filter'
          />
        </Center>
      );
    }
    return (
      <>
        {overdueArr?.length > 0 && (
          <Flex flexDir='column' gap={4}>
            <GenericColorHeader title='Overdue' color={HeaderColors.Orange} />
            <CollapseTable
              data={overdueArr}
              columns={columns}
              renderPanel={renderPanel}
              renderHeader={renderHeader}
            />
          </Flex>
        )}
        {ongoingArr?.length > 0 && (
          <Flex flexDir='column' gap={4}>
            <GenericColorHeader title='Ongoing' color={HeaderColors.Yellow} />
            <CollapseTable
              data={ongoingArr}
              columns={columns}
              renderPanel={renderPanel}
              renderHeader={renderHeader}
            />
          </Flex>
        )}
        {upcomingArr?.length > 0 && (
          <Flex flexDir='column' gap={4}>
            <GenericColorHeader title='Upcoming' color={HeaderColors.Purple} />
            <CollapseTable
              data={upcomingArr}
              columns={columns}
              renderPanel={renderPanel}
              renderHeader={renderHeader}
            />
          </Flex>
        )}
        {completedArr?.length > 0 && (
          <Flex flexDir='column' gap={4}>
            <GenericColorHeader title='Completed' color={HeaderColors.Green} />
            <CollapseTable
              data={completedArr}
              columns={columns}
              renderPanel={renderPanel}
              renderHeader={renderHeader}
            />
          </Flex>
        )}
      </>
    );
  };

  return (
    <Flex flexDir='column' gap={4}>
      {isScheduled ? renderScheduledTables() : renderStatusBasedTables()}
    </Flex>
  );
};

TableRenderNew.displayName =
  'sub-components/audits/audit-tab/my-audits/components/TableRenderNew';

export default TableRenderNew;
