import React, { FC } from 'react';
import { Flex, useControllableState } from '@chakra-ui/react';
import { Button } from 'atoms';

const styles = {
  variant: 'ghost',
  color: '#6F7E7C',
  _hover: {
    bg: 'gray.50',
  },
};

export type ViewType = 'internal' | 'customer';

interface IProps {
  value?: ViewType;
  onChange?: (value: ViewType) => void;
}

const GalleryViewType: FC<IProps> = ({ value, onChange }) => {
  const [viewType, updateValue] = useControllableState<ViewType>({
    value: value,
    onChange: onChange,
  });

  return (
    <Flex gap={4}>
      <Button
        {...(viewType === 'internal' ? {} : styles)}
        onClick={() => updateValue('internal')}
      >
        Internal Images
      </Button>
      <Button
        {...(viewType === 'customer' ? {} : styles)}
        onClick={() => updateValue('customer')}
      >
        Customer Uploaded
      </Button>
    </Flex>
  );
};

export default GalleryViewType;
