import { Box, Center } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import React, { FC } from 'react';

const SingleLocationFooter: FC = () => {
  return (
    <Center
      gap='5px'
      py={2}
      bg='rgba(239, 239, 239, 1)'
      w='80%'
      p='8px 12px'
      borderRadius='8px'
      mb={6}
    >
      <FontAwesomeIcon
        icon={faCircleExclamation as IconProp}
        fontSize='20px'
        color='rgba(111, 118, 126, 1)'
      />
      <Box as='span' fontSize='12px' fontWeight={500}>
        Add more locations to compare their performance!
      </Box>
    </Center>
  );
};

export default SingleLocationFooter;
