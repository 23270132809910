import { gql } from '@apollo/client';
import {
  isComplianceDocumentExpiringType,
  ReminderType,
  ComplianceExpiringSoonReminderType,
} from 'sub-components/nexus/Compliance/types/compliance.types';

export type GetExpiringDocsResponseType = {
  EntityCompliancePagination: {
    count: number;
    items: {
      entityId: string;
      locationId: string;
      expiryDate: Date;
      title: string;
      reminder: ComplianceExpiringSoonReminderType;
      files: {
        name: string;
      }[];
    }[];
  };
};
export type GetExpiringDocsInputType = {
  perPage: number;
  filter: {
    query?: string;
    locationId?: string;
    categoryId?: string;
    expiryDate?: string;
    createdBy?: string[];
    locationIds?: string[];
  };
};
export const GET_EXPIRING_DOCS = gql`
  query EntityCompliancePagination(
    $perPage: Int
    $filter: FilterFindManyEntityComplianceInput
  ) {
    EntityCompliancePagination(perPage: $perPage, filter: $filter) {
      count
      items {
        entityId
        locationId
        title
        expiryDate
        reminder {
          remindDate
          remindType
          remindBefore
        }
        files {
          name
        }
      }
    }
  }
`;
