import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useAiSearchPrefer } from '../../../hooks';

import { ReactComponent as AiIcon } from 'assets/images/AI.svg';

interface IProps {}

const AiSearchTag: FC<IProps> = () => {
  const [enabled] = useAiSearchPrefer();

  return (
    <Flex
      bg='#CABDFF4D'
      color='#8E59FF'
      whiteSpace='nowrap'
      gap='10px'
      aria-selected={enabled}
      _selected={{
        bg: '#8E59FF',
        color: '#FFFFFF',
      }}
    >
      <AiIcon color='currentColor' />
      <Box as='span' fontSize='16px' fontWeight='600'>
        Search with AI
      </Box>
    </Flex>
  );
};

export default AiSearchTag;
