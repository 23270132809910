import React, { FC } from 'react';
import { Flex, useModalContext, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { useUploadImage } from '../../../../../hooks';
import { ActionButton } from '../../../../../ui-components';
import { updateObject } from '../../../../../utils/objectHelper';

import UploadDocument from './common/UploadDocument';
import { IDocumentInput } from './common/compliance.input';
import {
  ADD_COMPLIANCE,
  AddComplianceInput,
  AddComplianceResponse,
} from './add-document.graphql';
import { toComplianceInput } from './compliance.helper';
import { eventBus } from '../../../../../shared/eventEmit';
import {
  GET_NEXUS_COMPLIANCES,
  GET_NEXUS_COMPLIANCES_CATEGORY,
} from 'pages/NexusDashboard/Compliance/query/nexus-compliance.graphql';

interface IProps {}

const NewDocumentContent: FC<IProps> = () => {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const { onClose } = useModalContext();

  const methods = useForm<IDocumentInput>({
    defaultValues: {
      expiryDate: null as never,
      reminder: {
        remindType: 'days',
        remindBefore: 1,
      },
    },
  });

  const [addCompliance] = useMutation<
    AddComplianceResponse,
    AddComplianceInput
  >(ADD_COMPLIANCE, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Compliance document successfully added.',
      });

      eventBus.emit('refetchCompliance');
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Compliance document creation failed!',
      });
    },
    refetchQueries: [
      GET_NEXUS_COMPLIANCES,
      'EntityCompliancePagination',
      GET_NEXUS_COMPLIANCES_CATEGORY,
      'EntityComplianceCategories',
    ],
  });

  const uploadImage = useUploadImage();

  const onSubmit = async (values: IDocumentInput) => {
    const input = toComplianceInput(cloneDeep(values));
    if (values.file?.rawFile) {
      const fileUrl = await uploadImage(values.file?.rawFile!);
      updateObject(input, ['file', 'url'], fileUrl);
    }

    const response = await addCompliance({ variables: { input: input } });

    if (response.errors) {
      return Promise.reject(response.errors);
    }

    setTimeout(onClose);
  };

  return (
    <FormProvider {...methods}>
      <Flex flexDir='column' gap={4}>
        <UploadDocument />
        <ActionButton
          isFullWidth
          colorScheme='blue'
          mt={2}
          actionFn={methods.handleSubmit(onSubmit)}
          isDisabled={!methods.formState.isDirty}
        >
          Add document
        </ActionButton>
      </Flex>
    </FormProvider>
  );
};

export default NewDocumentContent;
