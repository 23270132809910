import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useUserDataSelector } from '../../../hooks';
import { BaseAiContent } from './BaseAiContent';
import SourceCard from './SourceCard';
import { AiResponse } from './ai-search.types';
import ResponseFooter from './ResponseFooter';
import Navigation from './Navigation';

interface IProps {
  data: AiResponse;
  onRegenerate?: () => void;
}

const SearchResultView: FC<IProps> = ({ data, onRegenerate }) => {
  const authRole = useUserDataSelector((state) => state.authRole);
  const source = data?.searchResult.find((res) => res.id === data.documentId);

  return (
    <BaseAiContent flexDir='column' gap={3}>
      <Box
        fontSize='14px'
        fontWeight='500'
        color='#1A1D1F'
        whiteSpace='pre-line'
      >
        {data.response}
      </Box>

      <Flex align='center' justify='space-between' hidden={!source}>
        <Box fontSize='14px' fontWeight='500' color='#6F767E' hidden={!source}>
          Sources and citations:
        </Box>

        <Navigation />
      </Flex>

      <Flex overflowX='auto' gap={2} hidden={!source} id='sources-citations'>
        {source ? <SourceCard source={source} authRole={authRole} /> : null}

        {data?.searchResult?.map((res) => {
          if (res.id === data.documentId) {
            return null;
          }
          return <SourceCard key={res.id} source={res} authRole={authRole} />;
        })}
      </Flex>

      <ResponseFooter searchId={data.eid} onRegenerate={onRegenerate} />
    </BaseAiContent>
  );
};

export default SearchResultView;
