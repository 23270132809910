import { Box, Heading, VStack } from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';

const AuthWrapper: FC = () => {
  const authCode = `import Auth from 'auth';
const CompA = () => {
    return (
        <Auth allow='$authenticated' redirectTo='/login'>
            <CompB />
        </Auth>
    )
}
export default CompA;
`;
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>{'<Auth />'}</Heading>
      <Box>
        This is the wrapper that handled the auth based redirection. If user is
        logged in, he can access the components wrapped inside it, otherwise the
        user will be redirected to 'redirectTo' path defined.
      </Box>
      <SyntaxHighlighter language='typescript' style={materialDark}>
        {authCode}
      </SyntaxHighlighter>
    </VStack>
  );
};

export default AuthWrapper;
