import React, { FC, useMemo } from 'react';
import { List } from '@chakra-ui/react';

import { ShareMemberEntity } from './share-member.graphql';
import ShareMemItem from './ShareMemItem';
import ShareMemberSkeleton from './ShareMemberSkeleton';
import NoData from './NoData';

import { SharedProps } from './share-member.types';
import { TFuncKey } from 'i18next';

interface IProps extends SharedProps {
  search?: string;
  loading?: boolean;
  members: ShareMemberEntity[];
  i18NotFoundKey: TFuncKey<('form' | 'location')[], undefined>;
  i18NotFoundDescKey: TFuncKey<('form' | 'location')[], undefined>;
}

const ShareMemberList: FC<IProps> = ({
  members,
  loading,
  onSendClick,
  search,
  onClickHandler,
  formCreatorId,
  i18NotFoundKey,
  i18NotFoundDescKey,
}) => {
  const memberList = useMemo(() => {
    return members.filter((member) => member.type === 'user');
  }, [members, search]);

  return (
    <ShareMemberSkeleton isLoaded={!loading}>
      {!memberList.length ? (
        <NoData
          search={search}
          onClickHandler={onClickHandler}
          formCreatorId={formCreatorId}
          i18NotFoundKey={i18NotFoundKey}
          i18NotFoundDescKey={i18NotFoundDescKey}
          message='form:noMemberFound'
        />
      ) : (
        <List overflow='scroll' spacing={4} pl={0} flex={1}>
          {memberList.map((members) => {
            return (
              <ShareMemItem
                key={members.eid}
                data={members}
                onSendClick={onSendClick}
              />
            );
          })}
        </List>
      )}
    </ShareMemberSkeleton>
  );
};

export default ShareMemberList;
