import { gql } from '@apollo/client';

export const TASK_DATA = gql`
  query NexusTaskData(
    $locationIds: [String!]!
    $type: AnalyticsTrendType!
    $startDate: Date
    $endDate: Date
  ) {
    TaskSupervisedLocationWiseTrend(
      locationIds: $locationIds
      type: $type
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

export const AUDIT_DATA = gql`
  query NexusAuditData(
    $locationIds: [String!]!
    $startDate: Date
    $endDate: Date
  ) {
    AuditLocationsAnalytics(
      locationIds: $locationIds
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

export const ATTRITION_DATA = gql`
  query NexusAttritionData($startDate: Date!, $endDate: Date!) {
    EntityAttritionAnalytics(startDate: $startDate, endDate: $endDate)
  }
`;

export const LOCATIONS_DATA = gql`
  query NexusLocationsData($entityId: ID!) {
    EntityById(eid: $entityId) {
      locationCount
      openLocationCount
      pendingUserCount
      preLaunchLocationCount
      inDevLocationCount
      locations {
        name
        eid
        createdAt
      }
    }
  }
`;

export const LOCATIONS_ENTITY_DATA = gql`
  query EntityById($entityId: ID!) {
    EntityById(eid: $entityId) {
      locationCount
      openLocationCount
      pendingUserCount
      preLaunchLocationCount
      inDevLocationCount
      locations {
        name
        eid
        createdAt
      }
    }
  }
`;

export const LEADERBOARD_DATA = gql`
  query NexusDashboard(
    $locationIds: [String!]!
    $startDate: Date
    $endDate: Date
  ) {
    NexusDashboard(
      locationIds: $locationIds
      startDate: $startDate
      endDate: $endDate
    ) {
      attrition
      audit
      task
    }
  }
`;
