import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../atoms';
import { ActionButton } from 'ui-components';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';

import { ReactComponent as BackIcon } from '../../../../assets/images/chevronBackward.svg';
import { Authorize, AuthRole } from 'authorization';

const ComplianceHeading: FC = () => {
  const { t } = useTranslation(['home']);
  const history = useHistory();

  const complianceUpload = useComplianceUpload();

  return (
    <Flex w='full' justify='space-between' align='center'>
      <Flex flexDir='column' gap={2}>
        <Text fontWeight={600} fontSize='28px' minH={8}>
          {t('home:complianceListing')}
        </Text>

        <Button
          fontSize='14px'
          fontWeight={500}
          width='max-content'
          variant='link'
          leftIcon={<BackIcon width='16' height='16' />}
          _hover={{
            textDecoration: 'none',
          }}
          onClick={history.goBack}
        >
          Back to Dashboard
        </Button>
      </Flex>

      <Authorize
        permittedFor={'user'}
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <ActionButton
          colorScheme='blue'
          leftIcon={<AddIcon />}
          actionFn={() => complianceUpload()}
        >
          Add document
        </ActionButton>
      </Authorize>
    </Flex>
  );
};

export default ComplianceHeading;
