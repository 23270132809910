import React, { FC, useState } from 'react';
import {
  Divider,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PrimaryButton } from 'atoms';
import ManageChannels, { Access } from './components';
import { RestrictType } from './components/Access/Access';
import { AuthRole } from 'authorization';

export type ISelection = 'role' | 'job' | 'everyone' | undefined;

export interface CommonSelectionOptions {
  roles: string[] | undefined;
  jobs: string[] | undefined;
  members: string[] | undefined;
}

export interface CommonOptions {
  expanded: boolean;
  selection: RestrictType;
  loading: boolean;
  shouldSpecificMembers: boolean;
  selectedOptions: CommonSelectionOptions;
}

export interface GlobalChatSetting {
  restrictChat: {
    selection: boolean;
    loading: boolean;
  };
  whoCreateChannels: CommonOptions;
  whoDirectMessages: CommonOptions;
  locationsCanChat: {
    selection: boolean;
    loading: boolean;
  };
}

const GlobalChat: FC = () => {
  const [globalChatSetting, setGlobalChatSetting] = useState<GlobalChatSetting>(
    {
      restrictChat: {
        selection: false,
        loading: false,
      },
      whoCreateChannels: {
        expanded: false,
        loading: false,
        selection: undefined,
        shouldSpecificMembers: false,
        selectedOptions: {
          roles: undefined,
          jobs: [],
          members: [],
        },
      },
      whoDirectMessages: {
        expanded: false,
        loading: false,
        selection: undefined,
        shouldSpecificMembers: false,
        selectedOptions: {
          roles: undefined,
          jobs: [],
          members: [],
        },
      },
      locationsCanChat: {
        loading: false,
        selection: false,
      },
    }
  );
  return (
    <VStack divider={<Divider />} align='flex-start' gap={4} mt={4} ml={4}>
      <ManageChannels />
      <Access
        globalChatSetting={globalChatSetting}
        setGlobalChatSetting={setGlobalChatSetting}
      />
      {/* <HStack justify='space-between' w='full' cursor='pointer'>
                  <Flex flexDir='column'>
                    <Text fontWeight={600} color='rgba(111, 118, 126, 1)'>MANAGE CHANNELS</Text>
                    <Text fontWeight={500} fontSize='16px' color='rgba(26, 29, 31, 1)'>Manage channels</Text>
                    <Text color='rgba(111, 118, 126, 1)'>Create new channels, add/remove members, set restrictions on channels</Text>
                  </Flex>
                  <ChevronRightIcon fontSize='25px' color='rgba(111, 118, 126, 1)' />
                </HStack> */}
    </VStack>
  );
};

GlobalChat.displayName = 'p/S/CS/GC/GlobalChat';

export default GlobalChat;
