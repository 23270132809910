import React, { FC, useMemo } from 'react';
import { Box, Center } from '@chakra-ui/react';

import { AuditLocationsAnalytics } from 'sub-components/nexus/NexusBase/types';
import { useNexusBoardContext } from 'sub-components/nexus/NexusBase/store/context';
import { useUserDataSelector, useUserEntity } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';
import { ILocationPerformance } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Container from './Container';
import ViewAll from './ViewAll';
import SingleLocationFooter from './SingleLocationFooter';
import { RenderLowest, RenderScoreCards } from '.';

interface AuditPerformance {
  locationId: string;
  locationName: string;
  auditScoreRate: number;
  flaggedRate: number;
  rank: number;
}

const AuditScore: FC = () => {
  const auditData = useNexusBoardContext((state) => state.auditData);
  const { loggedInUserAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      loggedInUserAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
    })
  );
  const locations = useUserEntity((entity) => entity?.locations);
  const currentMonth = useMemo(() => {
    const now = new Date();
    return now.getMonth() + 1;
  }, []);
  const businessLocations: any[] = useMemo(() => {
    if (
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN, AuthRole.LOCATION_OWNER].includes(
        loggedInUserAuthRole
      )
    ) {
      return locations || [];
    }
    return loggedInUserLocations || [];
  }, [loggedInUserAuthRole, locations, loggedInUserLocations]);
  const locationMap = useMemo(() => {
    return businessLocations.reduce<{ [eid: string]: string }>((map, loc) => {
      map[loc.eid] = loc.name;
      return map;
    }, {});
  }, [businessLocations]);
  const processAuditData = (
    locationMap: { [locationId: string]: string },
    auditData?: AuditLocationsAnalytics
  ): {
    completeData: ILocationPerformance[];
    highestPerforming: ILocationPerformance[];
    lowestPerforming: ILocationPerformance[];
  } => {
    if (!auditData || Object.keys(auditData).length === 0) {
      return {
        completeData: [],
        highestPerforming: [],
        lowestPerforming: [],
      };
    }

    const performanceData: ILocationPerformance[] = Object.entries(
      auditData
    ).map(([locationId, monthlyData]) => {
      const monthData = monthlyData[currentMonth];
      let totalScoreSum = 0;
      let maxScoreSum = 0;

      if (monthData) {
        totalScoreSum += monthData.totalScoreSum;
        maxScoreSum += monthData.maxScoreSum;
      }

      const auditScoreRate =
        maxScoreSum > 0 ? (totalScoreSum / maxScoreSum) * 100 : 0;

      return {
        locationId,
        locationName: locationMap[locationId] || 'Unknown Location',
        rate: parseFloat(auditScoreRate.toFixed(2)),
        rank: 0,
      };
    });

    performanceData.sort((a, b) => {
      return b.rate - a.rate;
    });

    performanceData.forEach((item, index) => {
      item.rank = index + 1;
    });

    const highestPerforming = performanceData.slice(0, 3) || [];

    const lowestPerforming = performanceData.slice(3) || [];

    return {
      completeData: performanceData,
      highestPerforming,
      lowestPerforming,
    };
  };
  const { completeData, highestPerforming, lowestPerforming } =
    processAuditData(locationMap, auditData);

  const footer = (
    <Center gap='5px' py={2}>
      <FontAwesomeIcon
        icon={faCircleExclamation as IconProp}
        fontSize='20px'
        color='rgba(111, 118, 126, 1)'
      />
      <Box as='span' fontSize='12px' fontWeight={500}>
        Scores represent the average of all audit scores recorded within the
        month
      </Box>
    </Center>
  );

  return (
    <Container>
      <RenderScoreCards completeData={highestPerforming} type='audit' />
      <RenderLowest remainingLocations={lowestPerforming} footer={footer} />
      {completeData?.length < 3 && <SingleLocationFooter />}
      <ViewAll data={completeData} type='audit' />
    </Container>
  );
};

export default AuditScore;
