import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '../../../atoms/PrimaryButton';
import FormInput from '../../../atoms/FormInput';
import { ADD_ROLE, ROLES_MUTATION, UPDATE_ROLE } from './roles.graphql';
import { useTranslation } from 'react-i18next';
import { GET_USER } from 'pages/Login/login.graphql';

interface IFormInput {
  role: string;
}

export interface AddRoleRef {
  initialise: (values: IFormInput) => void;
}

interface IProps {
  open: boolean;
  onClose: () => void;
  onRoleCreate?: (value: string) => void;
  zIndex?: number;
  type: 'add' | 'edit';
  roleName?: string;
}

const AddRole = forwardRef<AddRoleRef, IProps>((props, ref) => {
  const { t } = useTranslation(['common', 'role']);
  const role = useReactiveVar(userObj)?.entity?.roles || [];
  const userData = useReactiveVar(userObj);

  const zIndex = props.zIndex || '1403';

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
    getValues,
    setValue,
  } = useForm<IFormInput>();

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  useEffect(() => {
    if (props?.type === 'edit' && props?.roleName) {
      setValue('role', props?.roleName);
    }
  }, [open]);

  const [moveUsersToRoles, { loading: movingRolesLoading }] = useMutation(
    ROLES_MUTATION,
    {
      onCompleted: (data) => {
        toast({
          status: 'success',
          title: t('role:created_success'),
        });
        props?.onRoleCreate?.(getValues('role'));
        onModalClosed?.();
      },
      onError: (error) => {
        toast({
          status: 'error',
          title: t('role:not_added'),
        });
      },
    }
  );

  const [addRole, { loading: addingRole }] = useMutation(ADD_ROLE, {
    onCompleted: (data) => {
      toast({
        status: 'success',
        title: t('role:created_success'),
      });
      props?.onRoleCreate?.(getValues('role'));
      onModalClosed?.();
    },
    onError: (error) => {
      toast({
        status: 'error',
        title: t('role:not_added'),
      });
    },
    refetchQueries: [{ query: GET_USER }],
    awaitRefetchQueries: true,
  });

  const [updateRole, { loading: updatingRolesLoading }] = useMutation(
    UPDATE_ROLE,
    {
      onCompleted: (data) => {
        toast({
          status: 'success',
          title: t('role:update_success'),
        });
        props?.onRoleCreate?.(getValues('role'));
        onModalClosed?.();
      },
      onError: (error) => {
        toast({
          status: 'error',
          title: t('role:not_updated'),
        });
      },
    }
  );

  const roleData = useMemo(() => {
    return role.map((data) => data.name?.toLowerCase());
  }, [role]);

  useImperativeHandle(
    ref,
    () => {
      return {
        initialise: (values: IFormInput) => {
          reset(values);
        },
      };
    },
    [reset]
  );

  const onModalClosed = () => {
    reset({
      role: '',
    });
    props.onClose?.();
  };

  const submitHandler = (value: IFormInput) => {
    if (props?.type === 'add') {
      return addRole({
        variables: {
          eid: userData?.entityId,
          name: value.role,
          color: '',
        },
      });
      // return moveUsersToRoles({
      //   variables: {
      //     input: {
      //       change: 'new',
      //       role: value.role,
      //       users: [],
      //     },
      //   },
      // });
    } else {
      updateRole({
        variables: {
          oldRole: props?.roleName,
          newRole: getValues('role'),
        },
      });
    }
  };

  return (
    <Modal
      isOpen={props.open}
      isCentered
      onClose={onModalClosed}
      closeOnOverlayClick={false}
    >
      <ModalOverlay zIndex={zIndex} />
      <ModalContent
        width={{
          sm: '340px',
          md: '510px',
          base: '510px',
          xl: '510px',
          ['2xl']: '510px',
        }}
        minWidth={{
          sm: '341px',
          md: '341px',
          base: '341px',
          xl: '341px',
          ['2xl']: '341px',
        }}
        borderRadius={{
          sm: '16px',
          md: '16px',
          base: '16px',
          xl: '16px',
          ['2xl']: '16px',
        }}
        containerProps={{
          zIndex: zIndex,
        }}
      >
        <ModalHeader px='50px' py='40px'>
          <Flex align='center' justify='space-between'>
            <Flex gap='10px' flex='1'>
              <Box h='32px' w='16px' borderRadius='4px' bg='#b5e4ca' />
              <div>
                {t(props?.type === 'edit' ? 'role:edit_role' : 'role:add_role')}
              </div>
            </Flex>
            <Flex>
              <ModalCloseButton
                pos='relative'
                top='unset'
                right='unset'
                borderRadius='full'
                bg='rgb(239, 239, 239, 0.6)'
              />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody px='50px' pt={0} pb='40px' id='add-role'>
          <form onSubmit={handleSubmit(submitHandler)}>
            <Controller
              name='role'
              control={control}
              defaultValue=''
              rules={{
                required: t('role:validation.name_required'),
                validate: (value) => {
                  if (value?.trim()?.length === 0) {
                    return t('validation.enter_title');
                  } else if (
                    roleData.some((cat) => cat === value?.toLowerCase())
                  ) {
                    return t('role:validation.already_exists', {
                      role: value,
                    });
                  }
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormInput
                      id='role'
                      size='lg'
                      placeholder={t('role:role_name')}
                      {...field}
                    />
                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
            <PrimaryButton
              type='submit'
              size='lg'
              title={t(
                props?.type === 'edit' ? 'role:update_btn' : 'role:add_btn'
              )}
              style={{ marginTop: '16px' }}
              variant='solid'
              colorScheme='blue'
              isLoading={addingRole || updatingRolesLoading}
              disabled={addingRole || updatingRolesLoading}
            />
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

AddRole.displayName = 'sc/SDC/RL/AddRole';

export default AddRole;
