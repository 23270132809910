import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { callAll } from '@chakra-ui/utils';

import { SelectOption } from '../../../../../../atoms';
import { useUserEntity } from '../../../../../../hooks';
import { toArray } from '../../../../../../utils';
import { useShallow } from '../../../../../../zustandStore';
import TitleHeader from '../../../../../CardEditor/TitleHeader';
import { IDocumentInput } from './compliance.input';
import { LocationSelect } from './location-select';

interface IProps {
  locationList?: SelectOption[];
}

const LocationSelectField: FC<IProps> = () => {
  const locationList = useUserEntity<SelectOption[]>(
    useShallow((entity) => {
      return toArray(entity?.locations)
        .map((location) => {
          return { label: location.name, value: location.eid };
        })
        .sort((a, b) => a?.label?.localeCompare(b?.label));
    })
  );

  const onLocationChange = (_: SelectOption) => {
    // TODO reset remind to location owner when document location is being changed, so that other location owner not get notified
  };

  return (
    <Controller<IDocumentInput, 'location'>
      name='location'
      render={({ field, fieldState }) => (
        <FormControl isInvalid={fieldState.invalid}>
          <Flex align='center' gap='4px' mb={2}>
            <TitleHeader title='Add location' desc='(Optional)' />
          </Flex>

          <LocationSelect
            options={locationList}
            placeholder='Location select'
            {...field}
            onChange={callAll(field.onChange, onLocationChange)}
          />
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default LocationSelectField;
