import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const ChatMessageCountHookInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>useChatMessageCount.ts</Heading>
      <Box fontSize='16px'>
        This hook is used to show the unread message count for the comet chat
        sdk. It uses reactive variable "chatMessageCount" to store the unread
        message count and uses "ChatMessageCountListener" to listen to the event
        and update the count accordingly.
      </Box>
      <Box fontSize='16px'>
        Refer to the code for proper understanding as there is no major example
        to show.
      </Box>
    </VStack>
  );
};

export default ChatMessageCountHookInfo;
