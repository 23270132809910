import React, { FC, useCallback, useRef } from 'react';
import {
  AlertDialogCloseButton,
  AspectRatio,
  Box,
  Center,
  Flex,
  Image,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react';
import { BoxHeader, useConfirm, UseConfirm } from '../../ui-components';
import { Button } from '../../atoms';

const isImageFile = (filename: string) => {
  const regex = /\.(jpg|jpeg|png|gif|bmp|tiff|webp|svg)$/i; // 'i' for case-insensitive
  return regex.test(filename);
};

const isSvgFile = (filename: string) => {
  const regex = /\.svg$/i; // 'i' for case-insensitive
  return regex.test(filename);
};

interface AssetImage {
  pathName: string;
  url: string;
}

interface IProps {
  data: AssetImage;
}

const CodeBlock: FC<{ value: string }> = ({ value }) => {
  const { hasCopied, onCopy } = useClipboard(value);
  return (
    <Flex flexDir='column' gap={1} bg='#EFEFEF' padding={2} borderRadius='4px'>
      <Button
        colorScheme='teal'
        alignSelf='end'
        width='max-content'
        size='xs'
        onClick={onCopy}
        fontSize='11px'
      >
        {hasCopied ? 'Copied' : 'Copy'}
      </Button>

      <Box
        as='code'
        bg='#1A1D1F'
        borderRadius='3px'
        p='6px 10px'
        color='#ADDB67'
        onClick={onCopy}
        cursor='pointer'
      >
        {value}
      </Box>
    </Flex>
  );
};

const Content: FC<IProps> = ({ data }) => {
  const name = data.pathName?.split('/').pop() || '';
  const { isOpen, onToggle } = useDisclosure();

  const lI = name.lastIndexOf('.');

  let _iconName = name
    .substring(0, lI)
    .split(/[,. -]/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  const isImage = isImageFile(data.pathName);

  if (!_iconName.at(-1)?.includes('Icon') && isImage) {
    _iconName = _iconName.concat('Icon');
  }

  const iconName = _iconName.join('');

  const exampleOne = `import { ReactComponent as ${iconName} } from '${data.pathName.replace(
    './',
    'assets/'
  )}';`;

  const exampleTwo = `import ${iconName} from '${data.pathName.replace(
    './',
    'assets/'
  )}';`;

  const isSvg = isSvgFile(data.pathName);

  return (
    <Flex flexDir='column' gap={3} pb={4}>
      <Button size='sm' fontSize='13px' alignSelf='end' onClick={onToggle}>
        Toggle background
      </Button>

      <AspectRatio
        aria-selected={isOpen}
        borderRadius='8px'
        _selected={{
          background: 'gray.700',
          color: 'white',
        }}
      >
        <Image
          src={data.url}
          style={{ objectFit: 'contain' }}
          fallback={
            <Center
              fontSize='15px'
              fontWeight='500'
              border='1px solid #E2E8F0'
              borderRadius='8px'
            >
              Preview not available
            </Center>
          }
        />
      </AspectRatio>
      <Box
        alignSelf='center'
        fontSize='16px'
        fontWeight='600'
        borderBottom='1.5px solid #E2E8F0'
        cursor='pointer'
        onClick={() => window.open(data.url, '_blank')}
        _hover={{
          borderBottomColor: 'blue.600',
        }}
      >
        {name}
      </Box>
      <Flex flexDir='column' gap={3}>
        {isSvg ? <CodeBlock value={exampleOne} /> : null}
        <CodeBlock value={exampleTwo} />
      </Flex>
    </Flex>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAssetDetails = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback((data: AssetImage) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex justify='space-between' flex={1}>
          <BoxHeader title='Asset details' fontSize='18px' boxWidth={3} />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: <Content data={data} />,
      footer: null,
    });
  }, []);
};
