import React, { FC } from 'react';
import { Center } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { ReactComponent as CommandSvg } from 'assets/images/commandIcon.svg';

const Wrapper = styled(Center)`
  position: absolute;

  gap: 6px;
  border-radius: 8px;
  box-shadow: inset 0 2px 0 0 rgba(255, 255, 255, 0.25),
    inset 0 -1px 1px 0 rgba(0, 0, 0, 0.04),
    inset 0 -1px 2px 0 rgba(0, 0, 0, 0.05);
  background-color: white;
  height: 32px;
  padding-inline: 12px;

  font-size: 16px;
  font-weight: 600;
  color: #1a1d1f;

  white-space: nowrap;
  user-select: none;
`;

interface IProps {}

const Command: FC<IProps> = () => {
  const isMac =
    typeof navigator !== 'undefined' && navigator.appVersion.indexOf('Mac');

  return (
    <Wrapper as='kbd' right={3}>
      {isMac ? <CommandSvg color='currentColor' /> : 'ctrl'}
      <span>K</span>
    </Wrapper>
  );
};

export default Command;
