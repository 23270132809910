import React, { FC } from 'react';
import {
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Box,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Filter } from '../../ComplianceListContainer';
interface IFilterChipProps {
  appliedFilter: Filter;
  setAppliedFilter: React.Dispatch<React.SetStateAction<Filter>>;
}
const FilterChip: FC<IFilterChipProps> = ({
  appliedFilter,
  setAppliedFilter,
}) => {
  const closeExpiredFilterdChip = () => {
    setAppliedFilter((prevFilters) => {
      const expiredIdx = appliedFilter.filterby.findIndex(
        (val) => val === 'expired'
      );
      if (expiredIdx !== -1) {
        const updatedFilterby = [...prevFilters.filterby];
        updatedFilterby.splice(expiredIdx, 1);
        return {
          ...prevFilters,
          filterby: updatedFilterby,
        };
      }
      return prevFilters;
    });
  };

  const closeUploadedByFilteredChip = (eid: string) => {
    setAppliedFilter((prevFilters) => {
      const usersIdx = prevFilters.filterby.findIndex((val) => val === 'users');

      if (usersIdx !== -1 && prevFilters.users.length === 1) {
        const updatedFilterby = [...prevFilters.filterby];
        updatedFilterby.splice(usersIdx, 1);

        return {
          filterby: updatedFilterby,
          users: prevFilters.users.filter((user) => user.eid !== eid),
        };
      }

      return {
        ...prevFilters,
        users: prevFilters.users.filter((user) => user.eid !== eid),
      };
    });
  };

  return (
    <Flex
      gap={'10px'}
      // flexWrap={'wrap'}
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      <Box
        color={'#6F767E'}
        fontWeight={500}
        fontSize={'12px'}
        flexWrap={'nowrap'}
        minW={'fit-content'}
      >
        Filters applied:
      </Box>
      <Flex
        overflowX={'scroll'}
        style={{ scrollBehavior: 'smooth' }}
        gap={'12px'}
        alignItems={'center'}
        css={{
          '&::-webkit-scrollbar': {
            maxWidth: '8px',
          },
          '::-webkit-scrollbar-track ': {
            display: 'flex',
            marginTop: '10px',
            maxWidth: '8px',
          },

          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#808080',
            marginTop: '8px',
            opacity: 0.7,
            cursor: 'pointer',
            maringTop: '10px',
            borderRadius: '12px',
            border: '5px solid transparent',
          },
        }}
      >
        {appliedFilter.filterby.includes('expired') && (
          <Tag
            key={'expired-docs'}
            borderRadius='lg'
            variant='outline'
            bgColor={'#E8DEF8'}
            minW={'fit-content'}
          >
            <TagLabel>
              <Flex gap={2} alignItems={'center'}>
                <FontAwesomeIcon icon={faCheck as IconProp} />
                <Text color={'#1D192B'} fontSize={'14px'}>
                  Expired documents only
                </Text>
              </Flex>
            </TagLabel>
            <TagCloseButton onClick={closeExpiredFilterdChip} />
          </Tag>
        )}

        {appliedFilter.users.map((selectedUser) => (
          <Tag
            key={selectedUser.eid}
            borderRadius='lg'
            variant='outline'
            bgColor={'#E8DEF8'}
            minW={'fit-content'}
          >
            <TagLabel>
              <Flex gap={2} alignItems={'center'}>
                <FontAwesomeIcon icon={faCheck as IconProp} />
                <Text color={'#1D192B'} fontSize={'14px'}>
                  Uploaded by: {selectedUser.name}
                </Text>
              </Flex>
            </TagLabel>
            <TagCloseButton
              onClick={() => closeUploadedByFilteredChip(selectedUser.eid)}
            />
          </Tag>
        ))}
      </Flex>
    </Flex>
  );
};

export default FilterChip;
