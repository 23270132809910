import { FC } from 'react';
import { VersionHistoryType } from 'sub-components/nexus/Compliance/types/compliance.types';
import VersionCard from './VersionCard';
import { Flex } from '@chakra-ui/react';
import { reverseArr } from '../../helper';

interface IProps {
  data: VersionHistoryType;
}

const VersionHistoryList: FC<IProps> = ({ data }) => {
  const sortedComplianceVersion =
    data?.files?.length > 1 ? reverseArr(data?.files) : data?.files;

  return (
    <Flex mt={4} gap={2} flexDir={'column'} w={'100%'}>
      {sortedComplianceVersion?.map((item, index) => {
        return <VersionCard key={index} index={index} data={item} />;
      })}
    </Flex>
  );
};

export default VersionHistoryList;
