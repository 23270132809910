import React, { FC, useState } from 'react';
import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts-new';
import { RatingData } from '../types';
import SingleLocationCard from './SingleLocationCard';
import LegendComponent from './LegendComponent';
import PaginationComponent from './PaginationComponent';
import CustomTooltip from './CustomTooltip';

export const GOOGLE_COLOR = '#CABDFF';
export const YELP_COLOR = '#B1E5FC';
export const NPS_COLOR = '#FFC9C9';

interface CustomerFeedbackChartProps {
  data: RatingData[];
}

const ITEMS_PER_PAGE = 15;

const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y + 5})`}>
      <text x={0} y={0} dy={4} textAnchor='middle' fill='#666'>
        {payload?.value?.length > 9
          ? `${payload?.value?.substring(0, 5)}...`
          : payload?.value}
      </text>
    </g>
  );
};

const CustomerFeedbackChart: FC<CustomerFeedbackChartProps> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredBar, setHoveredBar] = useState<string | null>(null);
  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);

  const handlePrev = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const paginatedData =
    data.length > ITEMS_PER_PAGE
      ? data.slice(
          (currentPage - 1) * ITEMS_PER_PAGE,
          currentPage * ITEMS_PER_PAGE
        )
      : data;

  if (data.length === 1) {
    const location = data[0];
    return <SingleLocationCard location={location} />;
  }

  const graphContainer = () => {
    return (
      <ResponsiveContainer width='100%' height={400}>
        <BarChart
          data={paginatedData}
          margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
        >
          <XAxis
            dataKey='location'
            tick={<CustomizedAxisTick />}
            interval={0}
          />
          <YAxis domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />
          <Tooltip content={<CustomTooltip hoveredBar={hoveredBar} />} />
          <Bar
            dataKey='googleRating'
            name='Google Rating'
            fill={GOOGLE_COLOR}
            barSize={20}
            onMouseEnter={() => setHoveredBar('Google Rating')}
            onMouseLeave={() => setHoveredBar(null)}
          />
          <ReferenceLine
            y={2.5}
            stroke='rgba(142, 89, 255, 1)'
            strokeDasharray='5 5'
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const verticalRender = () => (
    <VStack align='flex-start' w='full'>
      {graphContainer()}
      <Flex
        justifyContent='space-between'
        alignItems='center'
        mt={4}
        pl={4}
        w='full'
      >
        <LegendComponent />
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          handlePrev={handlePrev}
          handleNext={handleNext}
        />
      </Flex>
    </VStack>
  );

  const horizontalRender = () => (
    <HStack align='flex-start'>
      <Flex w='80%'>{graphContainer()}</Flex>
      <Flex w='20%' justify='flex-end' flexWrap='wrap'>
        <LegendComponent />
      </Flex>
    </HStack>
  );

  return (
    <Box width='100%'>
      {data.length > ITEMS_PER_PAGE ? verticalRender() : horizontalRender()}
    </Box>
  );
};

export default CustomerFeedbackChart;
