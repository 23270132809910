import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  ListItem,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';

const exampleOneCode = `import { Authorization } from 'authorization'
const CompA = ({ children }) => {
    return (
        <Authorization>
            {children}
        </Authorization>
    )
}
`;

const exampleTwoCode = `import { Authorization } from 'authorization'
const CompA = ({ children }) => {
    return (
        <Authorization permittedFor="user">
            {children}
        </Authorization>
    )
}

const CompB = ({ children }) => {
    return (
        <Authorization permittedFor="branch">
            {children}
        </Authorization>
    )
}
`;

const exampleThreeCode = `import { Authorization } from 'authorization'
const CompA = ({ children }) => {
    return (
        <Authorization permittedFor=["user", "branch"]>
            {children}
        </Authorization>
    )
}
`;

const exampleFourCode = `import { Authorization } from 'authorization'
const CompA = ({ children }) => {
    return (
        <Authorization permittedRoles={AuthRole.SUPER_ADMIN}>
            {children}
        </Authorization>
    )
}

const CompB = ({ children }) => {
    return (
        <Authorization permittedRoles={AuthRole.ADMIN}>
            {children}
        </Authorization>
    )
}

const CompC = ({ children }) => {
    return (
        <Authorization permittedRoles={AuthRole.LOCATION_OWNER}>
            {children}
        </Authorization>
    )
}

const CompD = ({ children }) => {
    return (
        <Authorization permittedRoles={AuthRole.WORKER}>
            {children}
        </Authorization>
    )
}
`;

const exampleFiveCode = `import { Authorization } from 'authorization'
const CompA = ({ children }) => {
    return (
        <Authorization permittedRoles=[{ AuthRole.SUPER_ADMIN, AuthRole.ADMIN, AuthRole.LOCATION_OWNER, AuthRole.WORKER }]>
            {children}
        </Authorization>
    )
}

const CompB = ({ children }) => {
    return (
        <Authorization permittedRoles=[{ AuthRole.ADMIN, AuthRole.LOCATION_OWNER, AuthRole.WORKER }]>
            {children}
        </Authorization>
    )
}

const CompC = ({ children }) => {
    return (
        <Authorization permittedRoles=[{ AuthRole.LOCATION_OWNER, AuthRole.WORKER }]>
            {children}
        </Authorization>
    )
}

const CompD = ({ children }) => {
    return (
        <Authorization permittedRoles=[{ AuthRole.SUPER_ADMIN, AuthRole.LOCATION_OWNER, AuthRole.WORKER }]>
            {children}
        </Authorization>
    )
}
`;

const exampleSixCode = `import { Authorization } from 'authorization'
const CompA = ({ children }) => {
    return (
        // Access only to authroles: superadmin, admin and having user's type as 'user'
        <Authorization permittedRoles=[{ AuthRole.SUPER_ADMIN, AuthRole.ADMIN }] permittedFor="user">
            {children}
        </Authorization>
    )
}

const CompB = ({ children }) => {
    return (
        // Access only to authroles: locationOwner, worker and having user's type as 'user'
         <Authorization permittedRoles=[{ AuthRole.LOCATION_OWNER, AuthRole.WORKER }] permittedFor="user">
            {children}
        </Authorization>
    )
}

const CompC = ({ children }) => {
    return (
        // Access only to superadmins and having user's type as 'branch'
        <Authorization permittedRoles=[{ AuthRole.SUPER_ADMIN }] permittedFor="branch">
            {children}
        </Authorization>
    )
}
`;

const exampleSevenCode = `import { Authorization } from 'authorization'
const CompA = ({ children }) => {
    return (
        <Authorization permittedRoles=[{ AuthRole.SUPER_ADMIN, AuthRole.ADMIN }] renderNoAccess={() => <Redirect to="/login" />}>
            {children}
        </Authorization>
    )
}
`;

const AuthorizationWrapper: FC = () => {
  return (
    <VStack align='flex-start' gap={4}>
      <Heading as='h1'>{'<Authorization />'}</Heading>
      <VStack align='flex-start'>
        <Box>
          This wrapper is used to hide/show components based on auth roles:
          'superadmin', 'admin', 'locationOwner', 'worker' and user type: 'user'
          and 'branch'.
        </Box>
        <Box>Mainly it takes 5 props:</Box>
        <UnorderedList pl={4}>
          <ListItem>
            <Flex gap={2}>
              <Box fontWeight={600}>permittedFor:</Box>
              <Flex flexDir='column'>
                <Box>
                  Which user type should be allowed to see the component.
                </Box>
                <Box
                  bg='blue.100'
                  w='fit-content'
                  p={1}
                  borderRadius='6px'
                  fontWeight={600}
                >
                  'user' | 'branch' | ['user'] | ['user', 'branch']
                </Box>
              </Flex>
            </Flex>
          </ListItem>
          <ListItem>
            <Flex gap={2}>
              <Box fontWeight={600}>permittedRoles:</Box>
              <Flex flexDir='column' gap={2}>
                <Box>
                  Which auth roles should be allowed to see the component.
                </Box>
                <Box
                  bg='blue.100'
                  w='fit-content'
                  p={1}
                  borderRadius='6px'
                  fontWeight={600}
                >
                  AuthRole.SUPER_ADMIN, AuthRole.ADMIN, AuthRole.LOCATION_OWNER,
                  AuthRole.WORKER
                </Box>
                <Box
                  bg='blue.100'
                  w='fit-content'
                  p={1}
                  borderRadius='6px'
                  fontWeight={600}
                >
                  combination of all the 4 in array like:
                  <br />
                  <UnorderedList pl={4}>
                    <ListItem>
                      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN,
                      AuthRole.LOCATION_OWNER, AuthRole.WORKER]
                    </ListItem>
                    <ListItem>
                      [AuthRole.LOCATION_OWNER, AuthRole.WORKER]
                    </ListItem>
                  </UnorderedList>
                </Box>
              </Flex>
            </Flex>
          </ListItem>
          <ListItem>
            <Flex gap={2}>
              <Box fontWeight={600}>restrictedFor:</Box>
              <Flex flexDir='column'>
                <Box>
                  Which user type should not be allowed to see the component. It
                  is just inverse of 'permittedFor'.
                </Box>
                <Box
                  bg='blue.100'
                  w='fit-content'
                  p={1}
                  borderRadius='6px'
                  fontWeight={600}
                >
                  'user' | 'branch' | ['user'] | ['user', 'branch']
                </Box>
              </Flex>
            </Flex>
          </ListItem>
          <ListItem>
            <Flex gap={2}>
              <Box fontWeight={600}>restrcitedRoles:</Box>
              <Flex flexDir='column' gap={2}>
                <Box>
                  Which auth roles should not be allowed to see the component.
                  It is just inverse of 'permittedRoles'.
                </Box>
                <Box
                  bg='blue.100'
                  w='fit-content'
                  p={1}
                  borderRadius='6px'
                  fontWeight={600}
                >
                  AuthRole.SUPER_ADMIN, AuthRole.ADMIN, AuthRole.LOCATION_OWNER,
                  AuthRole.WORKER
                </Box>
                <Box
                  bg='blue.100'
                  w='fit-content'
                  p={1}
                  borderRadius='6px'
                  fontWeight={600}
                >
                  combination of all the 4 in array like:
                  <br />
                  <UnorderedList pl={4}>
                    <ListItem>
                      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN,
                      AuthRole.LOCATION_OWNER, AuthRole.WORKER]
                    </ListItem>
                    <ListItem>
                      [AuthRole.LOCATION_OWNER, AuthRole.WORKER]
                    </ListItem>
                  </UnorderedList>
                </Box>
              </Flex>
            </Flex>
          </ListItem>
          <ListItem>
            <Flex gap={2}>
              <Box fontWeight={600}>renderNoAccess:</Box>
              <Box>It is the fallback component to render for no access.</Box>
            </Flex>
          </ListItem>
        </UnorderedList>
      </VStack>
      <Divider />
      <VStack align='flex-start' gap={2}>
        <Heading as='h2'>Examples</Heading>
        <VStack align='flex-start'>
          <Heading as='h3'>Example 1: Normal wrap</Heading>
          <SyntaxHighlighter language='typescript' style={materialDark}>
            {exampleOneCode}
          </SyntaxHighlighter>
        </VStack>
        <VStack align='flex-start'>
          <Heading as='h3'>Example 2: Permitted For (Single)</Heading>
          <SyntaxHighlighter language='typescript' style={materialDark}>
            {exampleTwoCode}
          </SyntaxHighlighter>
        </VStack>
        <VStack align='flex-start'>
          <Heading as='h3'>Example 3: Permitted For (Multiple)</Heading>
          <SyntaxHighlighter language='typescript' style={materialDark}>
            {exampleThreeCode}
          </SyntaxHighlighter>
        </VStack>
        <VStack align='flex-start'>
          <Heading as='h3'>Example 4: Permitted Roles (Single)</Heading>
          <SyntaxHighlighter language='typescript' style={materialDark}>
            {exampleFourCode}
          </SyntaxHighlighter>
        </VStack>
        <VStack align='flex-start'>
          <Heading as='h3'>Example 5: Permitted Roles (Multiple)</Heading>
          <SyntaxHighlighter language='typescript' style={materialDark}>
            {exampleFiveCode}
          </SyntaxHighlighter>
        </VStack>
        <VStack align='flex-start'>
          <Heading as='h3'>
            Example 6: Combination of permittedFor & permittedRoles
          </Heading>
          <SyntaxHighlighter language='typescript' style={materialDark}>
            {exampleSixCode}
          </SyntaxHighlighter>
        </VStack>
        <VStack align='flex-start'>
          <Heading as='h3'>Example 7: Render no access</Heading>
          <SyntaxHighlighter language='typescript' style={materialDark}>
            {exampleSevenCode}
          </SyntaxHighlighter>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default AuthorizationWrapper;
