// WalkthroughDocsSection.tsx
import { HStack, VStack, Box, Text } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { LeftSection } from './leftSection';

const WalkthroughDocsSection: FC = () => {
  const [SelectedComponent, setSelectedComponent] = useState<
    React.ReactNode | undefined
  >(undefined);
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(
    undefined
  );
  const [selectedSectionId, setSelectedSectionId] = useState<
    string | undefined
  >(undefined);

  const handleSelect = (
    component?: React.ReactNode,
    itemId?: string,
    sectionId?: string
  ) => {
    setSelectedComponent(component);
    setSelectedItemId(itemId);
    setSelectedSectionId(sectionId);
  };

  return (
    <HStack h='full' align='stretch'>
      <LeftSection
        onSelect={handleSelect}
        selectedItemId={selectedItemId}
        selectedSectionId={selectedSectionId}
      />
      <VStack
        bg='white'
        h='full'
        w='85%'
        p={4}
        align='stretch'
        overflowY='auto'
      >
        {SelectedComponent ? (
          SelectedComponent
        ) : (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            h='full'
            bg='gray.50'
            borderRadius='md'
            border='1px dashed gray'
          >
            <Text fontSize='lg' color='gray.500'>
              Select an item from the left to view details.
            </Text>
          </Box>
        )}
      </VStack>
    </HStack>
  );
};

export default WalkthroughDocsSection;
