import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { DashboardContainer } from '../../sub-components';
import { Button } from '../../atoms';
import { useHistory } from '../../routes';

import { ReactComponent as BackIcon } from '../../assets/images/chevronBackward.svg';
import GalleryContainer from 'sub-components/nexus/Gallery';

interface IProps {}

const GalleryPage: FC<IProps> = () => {
  const history = useHistory();

  return (
    <DashboardContainer>
      <Flex flexDir='column' gap={2}>
        <Text fontWeight={600} fontSize='28px' minH={8}>
          Image gallery
        </Text>

        <Button
          fontSize='14px'
          fontWeight={500}
          width='max-content'
          variant='link'
          leftIcon={<BackIcon width='16' height='16' />}
          _hover={{
            textDecoration: 'none',
          }}
          onClick={history.goBack}
        >
          Back to Dashboard
        </Button>
      </Flex>
      <GalleryContainer />
    </DashboardContainer>
  );
};

export default GalleryPage;
