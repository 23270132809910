import React, { FC, ReactNode } from 'react';
import { Center, FlexProps, Image, Text } from '@chakra-ui/react';

import dataCenterEmptyStateSvg from '../../assets/images/empty-state/date-center-empty-state.svg';
import reportEmptyStateSvg from '../../assets/images/empty-state/report-empty-state.svg';
import trainingDraftEmptyState from '../../assets/images/empty-state/training-draft-empty-state.svg';
import trainingPathEmptyState from '../../assets/images/empty-state/training-path-empty-state.svg';
import formEmptyState from '../../assets/images/empty-state/form-empty-state.svg';
import progressEmptyState from '../../assets/images/empty-state/training-progress-empty-state.svg';
import searchEmptyState from '../../assets/images/Illustrations.svg';
import handbookEmptyState from '../../assets/images/placeholder/empty-deck.svg';
import memberTrainingEmptyState from '../../assets/images/placeholder/empty-member-training.svg';
import myTrainingEmpty from '../../assets/images/placeholder/empty-my-training.svg';
import certificateEmpty from '../../assets/images/placeholder/empty-certificate.svg';
import formPermissionEmptyState from '../../assets/images/placeholder/empty-form-permission.svg';
import jobEmptyState from '../../assets/images/empty-state/job-empty-state.svg';
import emptyLocations from '../../assets/images/placeholder/empty-locations.svg';
import myTaskEmptyState from '../../assets/images/empty-state/my-task-empty-state.svg';
import chatGroupEmptyState from '../../assets/images/empty-state/chat-group-empty-state.svg';
import filterEmptyState from '../../assets/images/empty-state/filter-empty-state.svg';
import taskGraphEmptyState from '../../assets/images/empty-state/task-graph-empty-state.svg';
import trainingAssignedEmptyState from '../../assets/images/empty-state/training-assigned-home-empty-state.svg';
import launcherListEmptyState from '../../assets/images/placeholder/empty-laucnher-list.svg';
import locationMemberEmptyState from '../../assets/images/empty-state/location-member-empty-state.svg';
import locationOwnerEmptyState from '../../assets/images/empty-state/location-owner-empty-state.svg';
import noFormEmptyState from '../../assets/images/empty-state/no-form-empty-state.svg';
import logsEmptyState from '../../assets/images/placeholder/empty-conversation-logs.svg';
import noFlaggedResponseState from '../../assets/images/empty-state/no-flagged-response.svg';
import singleLocationState from '../../assets/images/empty-state/dashboard-single-location.svg';

export interface IEmptyStateProps extends Pick<FlexProps, 'height'> {
  title: string | ReactNode;
  description?: string;
  customDescription?: ReactNode;
  imageSize?: string;
  image:
    | 'DataCenter'
    | 'Report'
    | 'TrainingDraft'
    | 'TrainingPath'
    | 'Form'
    | 'Progress'
    | 'Search'
    | 'Handbook'
    | 'MemberTraining'
    | 'MyTraining'
    | 'Certificate'
    | 'NoForm'
    | 'FormPermission'
    | 'Job'
    | 'Location'
    | 'MyTask'
    | 'SupervisedByMe'
    | 'ChatGroup'
    | 'Filter'
    | 'TaskGraph'
    | 'LauncherList'
    | 'NoLocationMember'
    | 'NoLocationOwner'
    | 'TrainingAssignedHome'
    | 'NoConversationLogs'
    | 'NoFlaggedResponse'
    | 'SingleLocation';
  height?: string;
  showButton?: boolean;
  buttonProps?: ReactNode;
}

const EmptyState: FC<IEmptyStateProps> = ({
  title,
  description,
  customDescription,
  image,
  height,
  imageSize = '224px',
  showButton,
  buttonProps,
}) => {
  const getImage = () => {
    let img = '';
    switch (image) {
      case 'DataCenter':
        img = dataCenterEmptyStateSvg;
        break;
      case 'Report':
        img = reportEmptyStateSvg;
        break;
      case 'TrainingDraft':
        img = trainingDraftEmptyState;
        break;
      case 'TrainingPath':
        img = trainingPathEmptyState;
        break;
      case 'Form':
        img = formEmptyState;
        break;
      case 'Progress':
        img = progressEmptyState;
        break;
      case 'Search':
        img = searchEmptyState;
        break;
      case 'Handbook':
        img = handbookEmptyState;
        break;
      case 'MemberTraining':
        img = memberTrainingEmptyState;
        break;
      case 'MyTraining':
        img = myTrainingEmpty;
        break;
      case 'Certificate':
        img = certificateEmpty;
        break;
      case 'FormPermission':
        img = formPermissionEmptyState;
        break;
      case 'NoForm':
        img = noFormEmptyState;
        break;
      case 'Job':
        img = jobEmptyState;
        break;
      case 'Location':
        img = emptyLocations;
        break;
      case 'MyTask':
        img = myTaskEmptyState;
        break;
      case 'ChatGroup':
        img = chatGroupEmptyState;
        break;
      case 'Filter':
        img = filterEmptyState;
        break;
      case 'TaskGraph':
        img = taskGraphEmptyState;
        break;
      case 'TrainingAssignedHome':
        img = trainingAssignedEmptyState;
        break;
      case 'LauncherList':
        img = launcherListEmptyState;
        break;
      case 'NoLocationMember':
        img = locationMemberEmptyState;
        break;
      case 'NoLocationOwner':
        img = locationOwnerEmptyState;
        break;
      case 'NoConversationLogs':
        img = logsEmptyState;
        break;
      case 'NoFlaggedResponse':
        img = noFlaggedResponseState;
        break;
      case 'SingleLocation':
        img = singleLocationState;
        break;
      default:
        img = dataCenterEmptyStateSvg;
        break;
    }
    return img;
  };
  return (
    <Center flexDir='column' height={height || 'full'}>
      <Image src={getImage()} mb={4} boxSize={imageSize} />
      <Text as='b' fontWeight={700} fontSize='15px'>
        {title}
      </Text>
      {description ? (
        <Text fontWeight={500} fontSize='11px' color='rgba(158, 158, 158, 1)'>
          {description}
        </Text>
      ) : customDescription ? (
        customDescription
      ) : null}
      {showButton && buttonProps}
    </Center>
  );
};

EmptyState.displayName = 'sub-components/EmptyState';

export default EmptyState;
