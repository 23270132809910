import React from 'react';
import {
  forwardRef,
  Input as NativeInput,
  InputProps,
  SystemStyleObject,
} from '@chakra-ui/react';

export const sxInputStyle: SystemStyleObject = {
  '&[readonly]': {
    cursor: 'initial',
    '&:focus': {
      border: '1px',
      borderColor: 'var(--chakra-colors-gray-200) !important',
      color: 'inherit',
    },
  },
  '&[aria-invalid=true]': {
    borderWidth: 0,
    borderColor: 'rgba(42, 133, 255, 0.35) !important',
    background: 'rgba(255, 188, 153, 0.3)',
    color: 'var(--chakra-colors-red-500)',
  },
  '&:focus': {
    border: '2px solid',
    borderColor: 'rgba(42, 133, 255, 0.35) !important',
    color: 'inherit',
  },
};

const Input = forwardRef<InputProps, 'input'>((props, ref) => {
  return (
    <NativeInput
      fontSize='15px'
      borderRadius={12}
      {...props}
      ref={ref}
      sx={sxInputStyle}
    />
  );
});

// This is used in `input-group.tsx`
Input.id = 'Input';

export default Input;
