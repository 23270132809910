import { Box, Center, Flex, VStack } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faListOl, faRocketLaunch } from '@fortawesome/pro-regular-svg-icons';
import { faFileImport } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ADD_PRE_LAUNCH_LOCATION, LAUNCHER_ADD_LOCATION } from 'appRoutes';
import { HeaderColors } from 'configs';
import { useUserEntity } from 'hooks';
import React, { FC, useMemo } from 'react';

interface IProps {
  onImportClick: () => void;
  redirectHandler: (redirectObj: { pathname: string; state?: any }) => void;
}

const ModalContent: FC<IProps> = ({ onImportClick, redirectHandler }) => {
  const { launcherPublished, isLauncherEnabled } = useUserEntity((entity) => ({
    launcherPublished: entity?.launcher?.published,
    isLauncherEnabled: entity?.features?.launcher,
  }));

  const renderObj = useMemo(
    () => [
      ...(launcherPublished && isLauncherEnabled
        ? [
            {
              icon: faRocketLaunch,
              title: 'Launch with launcher',
              description: 'Launch locations with predefined tasks',
              redirectObj: { pathname: LAUNCHER_ADD_LOCATION },
            },
          ]
        : []),
      {
        icon: faListOl,
        title: 'Add Pre-launch locations',
        description: 'These will be added in pre-launch phase',
        redirectObj: {
          pathname: ADD_PRE_LAUNCH_LOCATION,
          state: { backToTitle: 'Back to Home' },
        },
      },
      {
        icon: faFileImport,
        title: 'Import via (.CSV, .XLS)',
        description: 'Easily import locations for future launch',
        onClickHandler: () => onImportClick(),
      },
    ],
    [launcherPublished, isLauncherEnabled]
  );

  return (
    <Flex flexDir='column' gap={4}>
      {renderObj?.map((render) => (
        <Flex
          align='center'
          gap='10px'
          border='2px solid rgba(239, 239, 239, 1)'
          bg='rgba(252, 252, 252, 1)'
          p='20px 24px'
          borderRadius='12px'
          cursor='pointer'
          onClick={() => {
            if (render?.onClickHandler) {
              render?.onClickHandler();
            } else {
              render?.redirectObj?.pathname &&
                redirectHandler(render?.redirectObj);
            }
          }}
        >
          <Center boxSize='52px' borderRadius='50%' bg='rgba(111, 118, 126, 1)'>
            <FontAwesomeIcon
              icon={render?.icon as IconProp}
              color='white'
              fontSize='20px'
            />
          </Center>
          <VStack align='flex-start'>
            <Box fontSize='15px' fontWeight={600}>
              {render?.title}
            </Box>
            <Box
              fontWeight={500}
              fontSize='12px'
              color='rgba(111, 118, 126, 1)'
            >
              {render?.description}
            </Box>
          </VStack>
        </Flex>
      ))}
    </Flex>
  );
};

export default ModalContent;
