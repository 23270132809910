import React, { FC, useMemo } from 'react';
import { UserLastActivity } from '../../../../types';
import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment';
import { LocationsEntity as memberLocationEntity } from 'ui-components/Members/members.types';
import { LocationsEntity } from 'sop-commons/src/client';
import { AuthRole } from 'authorization';

interface IProps {
  lastActivity: UserLastActivity;
  loggedInUserDetails: {
    loggedInUserLocation?: LocationsEntity[];
    authRole: AuthRole;
  };
  memberLocation?: memberLocationEntity[];
}

const LastActivityCell: FC<IProps> = ({
  lastActivity,
  loggedInUserDetails,
  memberLocation,
}) => {
  const { loggedInUserLocation, authRole } = loggedInUserDetails;

  const isLastActivityVisible = useMemo(() => {
    if (!loggedInUserLocation || !memberLocation) return false;

    if (authRole === AuthRole.ADMIN || authRole === AuthRole.SUPER_ADMIN)
      return true;

    return loggedInUserLocation.some((userLoc) =>
      memberLocation.some((memLoc) => userLoc.eid === memLoc.eid)
    );
  }, [memberLocation]);

  const shouldShowLastActivity =
    isLastActivityVisible && lastActivity?.activityTime;

  return (
    <Box marginBottom='15px'>
      {shouldShowLastActivity ? (
        <Flex flexDirection='column'>
          <Box as='span' fontSize='14px' fontWeight='500'>
            {moment(lastActivity.activityTime).format('hh:mm A')}
          </Box>
          <Box as='span' fontSize='12px' fontWeight='500' color='#9A9FA5'>
            {moment(lastActivity.activityTime).format('DD MMM YYYY')}
          </Box>
        </Flex>
      ) : (
        '-'
      )}
    </Box>
  );
};

export default LastActivityCell;
