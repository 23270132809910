import React, { FC } from 'react';
import { NexusBase } from 'sub-components/nexus';

const NexusDashboard: FC = () => {
  return <NexusBase />;
};

NexusDashboard.displayName = 'pages/NexusDashboard';

export default NexusDashboard;
