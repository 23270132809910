import React, { FC } from 'react';
import {
  Box,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useUserDataSelector, useUserEntity } from 'hooks';
import DashboardContainer from 'sub-components/DashboardContainer';

import CompanySettings from './CompanySettings';
import { LocationSetting } from './LcoationSetting';
import GlobalChat from './ChatSetting/GlobalChat';
import { TabItem } from 'atoms';
import { AuthRole } from 'authorization';

export type SettingFormValues = {
  companyName: string;
  headquarters: string;
  email: string;
  phoneNumber: string;
  profilePic: string;
  brandColor: string;
};

const Setting: FC = () => {
  const { t } = useTranslation(['common', 'setting', 'sidebar']);
  const isChatEnabled = useUserEntity((entity) => entity?.features?.chat);
  const { loggedInUserAuthRole } = useUserDataSelector((state) => ({
    loggedInUserAuthRole: state?.authRole,
  }));

  return (
    <DashboardContainer>
      <Flex mb='20px'>
        <Box fontSize='28px' fontWeight='700' color='#272B30'>
          {t('setting:setting_heading')}
        </Box>
      </Flex>
      <Box
        pos='relative'
        flex={1}
        p='20px 32px 30px'
        bg='white'
        borderRadius='12px'
      >
        <Tabs isLazy>
          <TabList borderColor='#F5F5F5' gap={5}>
            <TabItem>{t('setting:company')}</TabItem>
            {loggedInUserAuthRole === AuthRole.SUPER_ADMIN && (
              <TabItem>{t('setting:location')}</TabItem>
            )}
            {isChatEnabled && <TabItem>{t('sidebar:chat')}</TabItem>}
          </TabList>
          <TabPanels>
            <TabPanel p='20px 0 16px'>
              <CompanySettings />
            </TabPanel>
            {loggedInUserAuthRole === AuthRole.SUPER_ADMIN && (
              <TabPanel p='20px 0 16px'>
                <LocationSetting />
              </TabPanel>
            )}
            <TabPanel p='20px 0 16px'>
              <GlobalChat />
            </TabPanel>
            {/* {isChatEnabled && (
              <TabPanel p='20px 0 16px'>
                <ChatSetting />
              </TabPanel>
            )} */}
          </TabPanels>
        </Tabs>
      </Box>
    </DashboardContainer>
  );
};

Setting.displayName = 'p/SSetting';

export default Setting;
