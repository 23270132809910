import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const AuthorizationInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Authorization Folder Overview</Heading>
      <Box fontSize='16px'>
        This module provides a comprehensive authorization system for React
        applications, enabling developers to control access to UI components and
        routes based on user roles and types. It leverages Apollo Client for
        state management, React Context for passing user data, and integrates
        seamlessly with React Router for route protection. The module offers
        various tools, including: Types and Enums: Define user roles, types, and
        permission structures. Permission Checking Logic: Determine if a user
        has the necessary permissions. Custom Hooks: Simplify authorization
        checks within components. Higher-Order Components (HOCs): Protect entire
        components or routes based on permissions.
      </Box>
    </VStack>
  );
};

export default AuthorizationInfo;
