import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const PagesInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Pages Overview</Heading>
      <Box>
        This folder contains all the pages defined in the routes for the
        project. The base root for any module will be the component inside this
        folder and then this will continue with "sub-components" to create DOM
        tree for the modules.
      </Box>
      <Box>
        Inside the components defined in this folder, only container has to be
        defined that calls container component from "sub-components" and try not
        to add any logic in the files written in this folder.
      </Box>
    </VStack>
  );
};

export default PagesInfo;
