// LeftSection.tsx
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  VStack,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Text,
} from '@chakra-ui/react';
import React, { FC, useState, useMemo, useEffect } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import {
  accordionData,
  AccordionItemType,
  AccordionSectionType,
} from '../utils/accordionData';
import { SearchInput } from 'atoms';

interface LeftSectionProps {
  onSelect: (
    component?: React.ReactNode,
    itemId?: string,
    sectionId?: string
  ) => void;
  selectedItemId: string | undefined;
  selectedSectionId: string | undefined;
}

const LeftSection: FC<LeftSectionProps> = ({
  onSelect,
  selectedItemId,
  selectedSectionId,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedSections, setExpandedSections] = useState<string[]>([]);

  // Initialize expandedSections with all sections that have items
  useEffect(() => {
    const sectionsWithItems = accordionData
      .filter((section) => section.items.length > 0)
      .map((section) => section.id);
    setExpandedSections(sectionsWithItems);
  }, []);

  // Helper function to find the parent section ID of a given item ID
  const findParentSectionId = (
    itemId: string | undefined
  ): string | undefined => {
    if (!itemId) return undefined;
    for (const section of accordionData) {
      if (section.items.some((item) => item.id === itemId)) {
        return section.id;
      }
    }
    return undefined;
  };

  const parentSectionId = useMemo(
    () => findParentSectionId(selectedItemId),
    [selectedItemId]
  );

  // Filter accordion data based on search term
  const filteredData = useMemo(() => {
    if (!searchTerm) return accordionData;

    const lowerSearch = searchTerm.toLowerCase();

    return accordionData
      .map((section) => {
        const filteredItems = section.items.filter((item) =>
          item.title.toLowerCase().includes(lowerSearch)
        );
        const isSectionMatch = section.title
          .toLowerCase()
          .includes(lowerSearch);
        const isItemMatch = filteredItems.length > 0;
        if (isSectionMatch || isItemMatch) {
          return {
            ...section,
            items: filteredItems,
          };
        }
        return null;
      })
      .filter((section): section is AccordionSectionType => section !== null);
  }, [searchTerm]);

  // Automatically expand sections that have matching items during search
  useEffect(() => {
    if (!searchTerm) return;

    const matchingSectionIds = filteredData
      .filter((section) => section.items.length > 0)
      .map((section) => section.id);

    setExpandedSections((prev) => {
      // Merge existing expanded sections with newly matching sections
      const newExpanded = [...prev];
      matchingSectionIds.forEach((id) => {
        if (!newExpanded.includes(id)) newExpanded.push(id);
      });
      return newExpanded;
    });
  }, [searchTerm, filteredData]);

  // Handle item selection
  const handleItemClick = (item: AccordionItemType) => {
    onSelect(item.component, item.id, undefined);
  };

  // Handle standalone section click
  const handleSectionClick = (section: AccordionSectionType) => {
    if (section.component) {
      onSelect(section.component, undefined, section.id);
    }
  };

  // Toggle accordion section expansion
  const toggleSection = (sectionId: string) => {
    setExpandedSections((prev) => {
      if (prev.includes(sectionId)) {
        return prev.filter((id) => id !== sectionId);
      } else {
        return [...prev, sectionId];
      }
    });
  };

  // Check if a section is expanded
  const isSectionExpanded = (sectionId: string) =>
    expandedSections.includes(sectionId);

  return (
    <VStack
      bg='blackAlpha.500'
      h='full'
      w='15%'
      align='flex-start'
      overflowY='auto'
      p={4}
    >
      {/* Search Field */}
      <SearchInput
        placeholder='Search...'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        hideShortcuts
      />

      {/* Accordion List */}
      <Accordion allowMultiple w='full'>
        {filteredData.map((section) => (
          <AccordionItem
            key={section.id}
            isDisabled={section.items.length === 0 && !section.component}
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  onClick={() => {
                    if (section.items.length > 0) {
                      toggleSection(section.id);
                    } else if (section.component) {
                      handleSectionClick(section);
                    }
                  }}
                  _hover={{
                    bg:
                      section.items.length > 0
                        ? 'blackAlpha.600'
                        : 'blackAlpha.500',
                  }}
                  bg={
                    parentSectionId === section.id ||
                    selectedSectionId === section.id
                      ? 'blue.300'
                      : 'inherit'
                  }
                >
                  <Box as='span' flex='1' textAlign='left'>
                    {section.title}
                  </Box>
                  {section.items.length > 0 && <AccordionIcon />}
                </AccordionButton>
                {/* Always render AccordionPanel if there are items */}
                {section.items.length > 0 && (
                  <AccordionPanel pb={4} bg='blackAlpha.100' p={0}>
                    <VStack align='flex-start' spacing={1}>
                      {section.items.map((item) => (
                        <Box
                          key={item.id}
                          w='full'
                          p={2}
                          borderRadius='md'
                          bg={
                            selectedItemId === item.id
                              ? 'blue.500'
                              : 'transparent'
                          }
                          color={
                            selectedItemId === item.id ? 'white' : 'inherit'
                          }
                          _hover={{
                            bg:
                              selectedItemId === item.id
                                ? 'blue.600'
                                : 'gray.200',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleItemClick(item)}
                        >
                          {item.title}
                        </Box>
                      ))}
                    </VStack>
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>

      {/* Handle No Results Found */}
      {filteredData.length === 0 && (
        <Text color='gray.300' mt={4}>
          No results found.
        </Text>
      )}
    </VStack>
  );
};

export default LeftSection;
