interface AssetImage {
  pathName: string;
  url: string;
}

interface ImageGroup {
  imageLists?: AssetImage[];
  directory?: string[];
}

type RequireContext = {
  keys: () => string[];
  (id: string): { default: never };
};

const importAll = (r: RequireContext, pathName = '') => {
  const regx = new RegExp(`^\\.\\/${pathName}\\/([^/]*)`);

  let images: AssetImage[] = [];
  r.keys().forEach((item) => {
    if (pathName) {
      if (regx.test(item)) {
        images.push({
          pathName: item,
          url: r(item).default,
        });
      }
    } else {
      images.push({
        pathName: item,
        url: r(item).default,
      });
    }
  });
  return images;
};

export const buildImageGroup = (
  context: RequireContext,
  pathName = ''
): ImageGroup => {
  const imageLists = importAll(context, pathName);

  const L = pathName.split('/').filter(Boolean).length + 2;

  const regx = new RegExp(`^\\.\\/${pathName}\\/([^/]*)`);

  // Extract folder names from the context keys
  const folders = new Set<string>();
  context.keys().forEach((key) => {
    if (pathName) {
      if (regx.test(key)) {
        const pathParts = key.split('/');
        if (pathParts.length > L) {
          folders.add(pathParts[L - 1]); // Use the first subdirectory level
        }
      }
    } else {
      const pathParts = key.split('/');
      if (pathParts.length > L) {
        folders.add(pathParts[L - 1]); // Use the first subdirectory level
      }
    }
  });

  const directory = [...folders].sort((a, b) => a.localeCompare(b));

  return { imageLists, directory };
};
