import {
  ArchitectureInfo,
  AuthRolesInfo,
  FolderStructure,
  GeneralInfo,
  GraphQLInfo,
  WrappersInfo,
} from '../rightSection';
import ModulesInfo from '../rightSection/modulesInfo';
import InviteModule from '../rightSection/modulesInfo/invite';
import {
  AuthorizationWrapper,
  AuthWrapper,
  DashboardContainerWrapper,
} from '../rightSection/wrappersInfo';

export interface AccordionItemType {
  id: string;
  title: string;
  component?: React.ReactNode;
}

export interface AccordionSectionType {
  id: string;
  title: string;
  items: AccordionItemType[];
  component?: React.ReactNode;
}

export const accordionData: AccordionSectionType[] = [
  {
    id: 'general-project-info',
    title: 'General Project Info',
    items: [],
    component: <GeneralInfo />,
  },
  // {
  //   id: 'modules',
  //   title: 'Modules',
  //   component: <ModulesInfo />,
  //   items: [
  //     { id: 'module-invite', title: 'Invite', component: <InviteModule /> },
  //     { id: 'module-home', title: 'Home', component: <ModulesInfo /> },
  //     {
  //       id: 'module-task-dashboard',
  //       title: 'Task Dashboard',
  //       component: <></>,
  //     },
  //     { id: 'module-task-my-task', title: 'Task: My Tasks', component: <></> },
  //     {
  //       id: 'module-task-supervised-by-me',
  //       title: 'Task: Supervised by me',
  //       component: <></>,
  //     },
  //     { id: 'module-chat', title: 'Chat', component: <></> },
  //     {
  //       id: 'module-location-launcher-dashboard',
  //       title: 'Launcher: Dashboard',
  //       component: <></>,
  //     },
  //     {
  //       id: 'module-location-launcher-opening-task',
  //       title: 'Launcher: Opening tasks',
  //       component: <></>,
  //     },
  //     {
  //       id: 'module-knowledge-base',
  //       title: 'Knowledge Base',
  //       component: <></>,
  //     },
  //     {
  //       id: 'module-forms-checklist',
  //       title: 'Forms & Checklists',
  //       component: <></>,
  //     },
  //     { id: 'module-training', title: 'Training', component: <></> },
  //     {
  //       id: 'module-training-tracking',
  //       title: 'Training: Tracking',
  //       component: <></>,
  //     },
  //     {
  //       id: 'module-training-my-trainings',
  //       title: 'Training: My Trainings',
  //       component: <></>,
  //     },
  //     { id: 'module-members', title: 'Members', component: <></> },
  //     { id: 'module-jobs', title: 'Jobs', component: <></> },
  //     { id: 'module-locations', title: 'Locations', component: <></> },
  //     { id: 'module-setting', title: 'Setting', component: <></> },
  //   ],
  // },
  {
    id: 'graphql',
    title: 'GraphQL',
    items: [],
    component: <GraphQLInfo />,
  },
  {
    id: 'folderStructure',
    title: 'Folder Structure',
    items: [],
    component: <FolderStructure />,
  },
  {
    id: 'architecture',
    title: 'Architecture',
    items: [],
    component: <ArchitectureInfo />,
  },
  {
    id: 'wrappers',
    title: 'Wrappers',
    items: [
      {
        id: 'info-wrapper',
        title: 'Information',
        component: <WrappersInfo />,
      },
      { id: 'auth-wrapper', title: 'Auth Wrapper', component: <AuthWrapper /> },
      {
        id: 'authorization-wrapper',
        title: 'Authorization Wrapper',
        component: <AuthorizationWrapper />,
      },
      {
        id: 'dashboard-wrapper',
        title: 'Dashboard Wrapper',
        component: <DashboardContainerWrapper />,
      },
    ],
    component: <WrappersInfo />,
  },
  {
    id: 'misc',
    title: 'Misc',
    items: [],
    component: <></>,
  },
];
