import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Authorize } from '../../authorization';
import { canAccessNexus } from '../../configs';
import NexusDashboard from './NexusDashboard';
import NexusFinanceBoard from './NexusFinanceBoard';
import NexusCompliance from './Compliance/NexusCompliance';

const AuditRoute: FC = () => {
  let { path } = useRouteMatch();
  return (
    <Authorize
      canAccess={canAccessNexus}
      renderNoAccess={() => <Redirect to='/' />}
    >
      <Switch>
        <Route exact path={path} component={NexusDashboard} />
        <Route exact path={`${path}/finance`} component={NexusFinanceBoard} />
        <Route exact path={`${path}/documents`} component={NexusCompliance} />
        <Route
          exact
          path={`${path}/documents/create`}
          component={NexusCompliance}
        />
      </Switch>
    </Authorize>
  );
};

export default AuditRoute;
