import { Center, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useMemo } from 'react';
import {
  compareVisibilityHandler,
  ICompareVisibilityType,
} from '../../pages/Chapters/CreateEditSubFolder/components/helper/visibilityHelper';
import { IVisibility } from 'pages/Chapters/CreateChapterModal/UploadFile/UploadFile';
import { useVisibilityStatusInfoLogic } from './helper';
import { TypeChapter, TypeFolder, TypeSubFolder } from './components';
import { useReactiveVar } from '@apollo/client';
import { loginUserBranchRootObj, usersEntityObj } from 'sub-components/Header';
import { userObj } from 'sop-commons/src/client';
import { IRoleData } from 'sub-components/SettingDataCenter/RolesList/roles-list.types';
import { Loader } from 'sub-components';

export type IVisibilityStatusInfoType = 'folder' | 'subFolder' | 'chapter';

interface IProps {
  hitApi?: boolean;
  type?: IVisibilityStatusInfoType;
  parentDetails?: IVisibility;
  childDetails?: IVisibility;
  categoryId?: string;
  folderName: string;
  parentFolderName?: string;
  withReferenceTo?: IVisibilityStatusInfoType;
}

const VisibilityStatusInfo: FC<IProps> = ({
  hitApi = false,
  childDetails,
  parentDetails,
  type,
  categoryId,
  folderName,
  parentFolderName,
  withReferenceTo,
}) => {
  console.log({ VisibilityStatusInfo: { parentDetails } });
  const userData = useReactiveVar(userObj);
  const userBranchRoot: any = useReactiveVar(loginUserBranchRootObj);
  const membersData = useReactiveVar(usersEntityObj);
  const locationsList = useMemo(() => {
    return userBranchRoot?.descendantBranches || [];
  }, [userBranchRoot?.descendantBranches]);
  const rolesList = useMemo(() => {
    const roles = userData?.entity?.roles || [];
    let updatedRolesData: IRoleData[] = roles?.map<IRoleData>((role: any) => {
      return {
        ...role,
        label: role?.name,
        value: role?.name,
      };
    });
    return updatedRolesData || [];
  }, [userData?.entity?.roles]);
  const membersList = useMemo(() => {
    return membersData || [];
  }, [membersData]);
  const { _childDetails, _parentDetails, _status, loading } =
    useVisibilityStatusInfoLogic({
      locationsList,
      membersList,
      rolesList,
      childDetails,
      parentDetails,
      categoryId,
      type,
      hitApi,
    });

  console.log('_STATUS : ', _status);

  if (loading) {
    return (
      <Center w='full'>
        <Loader />
      </Center>
    );
  }

  const compRender = () => {
    switch (type) {
      case 'folder':
        return (
          <TypeFolder
            status={_status}
            childDetails={_childDetails}
            parentDetails={_parentDetails}
          />
        );
      case 'subFolder':
        return (
          <TypeSubFolder
            status={_status}
            childDetails={_childDetails}
            parentDetails={_parentDetails}
            folderName={folderName}
            parentFolderName={parentFolderName}
            withReferenceTo={withReferenceTo}
          />
        );
      case 'chapter':
        return (
          <TypeChapter
            status={_status}
            chapterName={folderName}
            parentFolderName={parentFolderName}
            childDetails={_childDetails}
            parentDetails={_parentDetails}
          />
        );
      default:
        return null;
    }
  };
  return compRender();
};

VisibilityStatusInfo.displayName = 'uc/VisibilityStatusInfo';

export default VisibilityStatusInfo;
