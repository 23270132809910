import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import WalkthroughDocsSection from 'sub-components/walkthrough-docs';

const WalkthroughDocs: FC = () => {
  const history = useHistory();

  if (process?.env?.REACT_APP_DEPLOYMENT_TYPE !== 'development') {
    history.push('/');
  }
  return <WalkthroughDocsSection />;
};

export default WalkthroughDocs;
