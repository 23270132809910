import { TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import MyTrainingList from 'pages/Training/TrainingList/MyTrainingList/MyTrainingList';
import React, { FC } from 'react';
import { CustomTab } from 'sub-components/nexus/NexusBase/components/LeftSection/components/LocationLeaderboard/components';
import MyTasksTab from 'sub-components/TasksBoard/components/MyTasksTab';
import AssignedToMeEmpty from 'sub-components/TrainingsBoard/AssignedToMe/AssignedToMeEmpty';

const AllottedTabs: FC = () => {
  return (
    <Tabs variant='unstyled' w='full'>
      <TabList w='full' gap={4}>
        <CustomTab key='myTasks' label='My tasks' minWidth='fit-content' />
        <CustomTab
          key='myTrainings'
          label='My trainings'
          minWidth='fit-content'
        />
      </TabList>
      <TabPanels>
        <TabPanel key={'myTasks'} px={0} py={4}>
          <MyTasksTab
            layout='simple'
            hideViewBtn
            setHideViewBtn={() => {}}
            tasksForTodayData
            setTasksForTodayData={() => {}}
          />
        </TabPanel>
        <TabPanel key={'myTrainings'} px={0} py={4}>
          <MyTrainingList
            layout='simple'
            tabSelected='assignedToMe'
            shouldHideCertificateDownload
            shouldHidePagination
            shouldHideActionLabel
            perPage={3}
            setShowViewAllBtn={() => {}}
            customEmptyStateComponent={<AssignedToMeEmpty />}
            backToTitle='Back to Home'
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default AllottedTabs;
