import {
  Box,
  Heading,
  ListItem,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';

const GithubInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Git/Github Info</Heading>
      <VStack align='flex-start' fontSize='16px'>
        <Box>We use github for version control management.</Box>
        <Box>There are 4 environments where the code is deployed:</Box>
        <UnorderedList pl={4}>
          <ListItem>
            <Box>
              <Box as='span' fontWeight={600}>
                staging-dev:
              </Box>
              &nbsp;
              <Box as='span'>Dev environment</Box>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Box as='span' fontWeight={600}>
                prod-pre-release:
              </Box>
              &nbsp;
              <Box as='span'>Staging environment</Box>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Box as='span' fontWeight={600}>
                pre-prod:
              </Box>
              &nbsp;
              <Box as='span'>Beta Production environment</Box>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Box as='span' fontWeight={600}>
                staging-no-training:
              </Box>
              &nbsp;
              <Box as='span'>Production environment</Box>
            </Box>
          </ListItem>
        </UnorderedList>
      </VStack>
      <Box>
        Whenever a PR is raised for ongoing feature, revieweres have to be Abhay
        and Divyanshu and if fix/feature is raised directly to above
        environments, Abhay, Divyanshu and Dilip have to be added as reviewers.
      </Box>
      <Box>
        We follow Git workflow for deployments, so try to keep frequency of PRs
        as low as possible so that monthly deployment minutes don't get
        exhausted.
      </Box>
      <Box>
        Whenever someone's code is merged to a PR, it becomes their duty to
        inform others to sync their branches with latest code on 'team-web'
        channel.
      </Box>
    </VStack>
  );
};

export default GithubInfo;
