import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { Dropdown, FlexCard } from '../../../atoms';
import GalleryImageList from './components/GalleryImageList';
import LinkGoogleImages from '../NexusBase/components/LeftSection/components/ImageGallery/LinkGoogleImages';
import { useControl } from './layers';
import { Loader } from 'sub-components';
import { GalleryViewType } from './components';

const GalleryContainer: FC = () => {
  const {
    filteredLocations,
    images,
    isAuditEnabled,
    loading,
    selectedLocation,
    viewType,
    setSelectedLocation,
    setViewType,
  } = useControl();

  const renderContent = () => {
    if (loading) {
      return (
        <Center height='80vh'>
          <Loader />
        </Center>
      );
    }

    if (images?.length === 0) {
      return (
        <Center width='full'>
          <Center height='60vh' width='60%'>
            <LinkGoogleImages
              title='Explore location images'
              description="View and manage photos uploaded by your team and customers once the superadmin links your location's Google Images."
              hideBtn
            />
          </Center>
        </Center>
      );
    }

    return <GalleryImageList colsNo={4} images={images} />;
  };

  return (
    <FlexCard flexDir='column' mt={6} gap={5} width='full'>
      <Flex
        align='center'
        justify={isAuditEnabled ? 'space-between' : 'flex-end'}
        width='full'
      >
        {isAuditEnabled && (
          <GalleryViewType value={viewType} onChange={setViewType} />
        )}
        <Box width='300px'>
          <Dropdown
            options={filteredLocations}
            value={selectedLocation}
            onChange={setSelectedLocation}
          />
        </Box>
      </Flex>
      {renderContent()}
    </FlexCard>
  );
};

GalleryContainer.displayName = 'sc/n/G/GalleryContainer';

export default GalleryContainer;
