import { ExportData, SelectOption } from '../types';

const initialData: ExportData = {
  attritionData: [],
  auditData: undefined,
  businessLocationsData: [],
  customerFeedback: [],
  taskData: undefined,
};

export const filteredDataFn = (
  selectedLocations: SelectOption[],
  data?: ExportData
) => {
  if (!data) {
    return initialData;
  }
  return {
    ...data,
    businessLocationsData: data.businessLocationsData.filter((loc) =>
      selectedLocations.some((selected) => selected.value === loc.eid)
    ),
    attritionData: data.attritionData.filter((attr) =>
      selectedLocations.some((selected) => selected.value === attr.eid)
    ),
    customerFeedback: data.customerFeedback.filter((feedback) =>
      selectedLocations.some(
        (selected) => selected.value === feedback.locationId
      )
    ),
    auditData: data.auditData
      ? Object.keys(data.auditData)
          .filter((locationId) =>
            selectedLocations.some((selected) => selected.value === locationId)
          )
          .reduce(
            (acc, locationId) => ({
              ...acc,
              [locationId]: data.auditData![locationId],
            }),
            {} as typeof data.auditData
          )
      : undefined,
    taskData: data.taskData
      ? Object.keys(data.taskData)
          .filter((locationId) =>
            selectedLocations.some((selected) => selected.value === locationId)
          )
          .reduce(
            (acc, locationId) => ({
              ...acc,
              [locationId]: data.taskData![locationId],
            }),
            {} as typeof data.taskData
          )
      : undefined,
  };
};
