import {
  ATTRITION_DATA,
  AUDIT_DATA,
  LEADERBOARD_DATA,
  LOCATIONS_ENTITY_DATA,
  TASK_DATA,
} from '../service/api.graphql';
import { ApolloError, useLazyQuery } from '@apollo/client';
import {
  AttritionResponse,
  AttritionVariables,
  AuditResponse,
  AuditVariables,
  LeaderboardResponse,
  LeaderboardVariables,
  TaskResponse,
  TaskVariables,
} from '../types';
import {
  LocationDetailsResponse,
  LocationDetailsVariable,
} from '../types/api-types/location-details.types';

export const useService = ({
  onAttritionSuccess,
  onAttritionError,
  onLeaderboardSuccess,
  onLeaderboardError,
  onLocationDetailsSuccess,
  onLocationDetailsError,
  onTaskSuccess,
  onTaskError,
  onAuditSuccess,
  onAuditError,
}: {
  onAttritionSuccess?: (data: AttritionResponse) => void;
  onAttritionError?: (error: ApolloError) => void;
  onLeaderboardSuccess?: (data: LeaderboardResponse) => void;
  onLeaderboardError?: (data: ApolloError) => void;
  onLocationDetailsSuccess?: (data: LocationDetailsResponse) => void;
  onLocationDetailsError?: (error: ApolloError) => void;
  onTaskSuccess?: (data: TaskResponse) => void;
  onTaskError?: (error: ApolloError) => void;
  onAuditSuccess?: (data: AuditResponse) => void;
  onAuditError?: (error: ApolloError) => void;
}) => {
  const [taskData, taskRes] = useLazyQuery<TaskResponse, TaskVariables>(
    TASK_DATA,
    {
      fetchPolicy: 'network-only',
      onCompleted: onTaskSuccess,
      onError: onTaskError,
    }
  );

  const [auditData, auditRes] = useLazyQuery<AuditResponse, AuditVariables>(
    AUDIT_DATA,
    {
      fetchPolicy: 'network-only',
      onCompleted: onAuditSuccess,
      onError: onAuditError,
    }
  );

  const [attritionData, attritionRes] = useLazyQuery<
    AttritionResponse,
    AttritionVariables
  >(ATTRITION_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: onAttritionSuccess,
    onError: onAttritionError,
  });

  const [locationDetailsData, locationDetailsRes] = useLazyQuery<
    LocationDetailsResponse,
    LocationDetailsVariable
  >(LOCATIONS_ENTITY_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: onLocationDetailsSuccess,
    onError: onLocationDetailsError,
  });

  const [leaderboardData, leaderboardRes] = useLazyQuery<
    LeaderboardResponse,
    LeaderboardVariables
  >(LEADERBOARD_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: onLeaderboardSuccess,
    onError: onLeaderboardError,
  });

  return {
    taskService: { taskData, taskRes },
    auditService: { auditData, auditRes },
    attritionService: { attritionData, attritionRes },
    leaderboardService: { leaderboardData, leaderboardRes },
    locationDetailsService: { locationDetailsData, locationDetailsRes },
  };
};
