import React, { FC, useEffect } from 'react';
import { Flex, Center } from '@chakra-ui/react';
import { useAuditTabContext } from '../../store/context';
import { auditsFilterChips } from '../../store/static-data';

const ChipsBadge: FC = () => {
  const chips = useAuditTabContext((state) => state.auditChipsFilter);
  const updateChips = useAuditTabContext(
    (state) => state.updateAuditChipsFilter
  );

  useEffect(() => {
    updateChips(
      auditsFilterChips.map((chip) => ({ ...chip, selected: false }))
    );
  }, []);

  const onChipUpdate = (index: number) => {
    const updatedChips = chips.map((chip, chipIndex) => ({
      ...chip,
      selected: chipIndex === index ? !chip.selected : false,
    }));
    updateChips(updatedChips);
  };

  return (
    <Flex gap={2}>
      {chips.map((chip, index) => (
        <Center
          key={index}
          cursor='pointer'
          fontWeight={600}
          color='#6F767E'
          border='1px solid #6F767E'
          padding='8px 14px'
          borderRadius='360px'
          aria-selected={chip.selected}
          onClick={() => onChipUpdate(index)}
          _selected={{
            borderColor: '#2A85FF',
            borderWidth: '2px',
            padding: '7px 13px',
            color: '#2A85FF',
          }}
        >
          {chip.label}
        </Center>
      ))}
    </Flex>
  );
};

ChipsBadge.displayName = 'sc/a/at/ma/c/ChipsBadge';

export default ChipsBadge;
