import { Box } from '@chakra-ui/react';
import { Dropdown, FlexCard, SelectOption } from 'atoms';
import React, { FC, useState } from 'react';
import { HeaderColors } from '../../../../../../../configs';
import { BoxHeader } from '../../../../../../../ui-components';
import { AllottedTabs, SupervisedTabs } from './components';

const TasksTraining: FC = () => {
  const [selectedOption, setSelectedOption] = useState<SelectOption>({
    label: 'Supervised by me',
    value: 'supervised',
  });

  return (
    <FlexCard w='full' flexDir='column' gap={4}>
      <BoxHeader title='Tasks and trainings' color={HeaderColors.Yellow} />
      <Box>
        <Dropdown
          options={[
            { label: 'Supervised by me', value: 'supervised' },
            { label: 'Assigned to me', value: 'allotted' },
          ]}
          value={selectedOption}
          onChange={(e) => setSelectedOption(e)}
        />
      </Box>
      {selectedOption?.value === 'supervised' && <SupervisedTabs />}
      {selectedOption?.value === 'allotted' && <AllottedTabs />}
    </FlexCard>
  );
};

export default TasksTraining;
