import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Center, Image } from '@chakra-ui/react';

import { Authorize, AuthRole } from 'authorization';

import { SharedProps } from './share-member.types';

import MemberEmpty from '../../assets/images/empty-state/member-empty-state.svg';
import SearchEmpty from '../../assets/images/Illustrations.svg';
import { TFuncKey } from 'i18next';

interface IProps extends Omit<SharedProps, 'onSendClick'> {
  search?: string;
  i18NotFoundKey: TFuncKey<('form' | 'location')[], undefined>;
  i18NotFoundDescKey: TFuncKey<('form' | 'location')[], undefined>;
  message: string;
}

const NoData: FC<IProps> = ({
  search,
  onClickHandler,
  i18NotFoundKey,
  i18NotFoundDescKey,
  formCreatorId,
  message,
}) => {
  const { t } = useTranslation(['common', 'form', 'header', 'location']);
  return (
    <Center flexDir='column'>
      <Center
        flexDir='column'
        maxW={search ? '280px' : '360px'}
        gap='8px'
        textAlign='center'
      >
        <Image src={search ? SearchEmpty : MemberEmpty} boxSize={'160px'} />

        <Box fontSize='15px' fontWeight='700' color='#303030'>
          {t(search ? message : i18NotFoundKey)}
        </Box>

        <Box fontSize='12px' fontWeight='500' color='#9E9E9E'>
          {t(search ? 'header:not_found_desc' : i18NotFoundDescKey)}
        </Box>

        {!search && formCreatorId && (
          <Authorize
            permittedFor='user'
            permittedRoles={AuthRole.SUPER_ADMIN}
            canAccess={(authUser) => authUser?.eid === formCreatorId}
          >
            <Button
              colorScheme='blue'
              mt={2}
              fontSize='14px'
              borderRadius='7px'
              onClick={() => onClickHandler?.('edit-visibility', true)}
            >
              {t('common:setVisibility')}
            </Button>
          </Authorize>
        )}
      </Center>
    </Center>
  );
};

export default NoData;
