import { Box, Heading, VStack } from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';

const navigateStateCode = `import { useHistory } from 'routes
const CompA: FC = () => {
  const history = useHistory();
  const navigate = () => {
    history.push({pathName: NAVIGATE_TO_COMPB, state: { backToTitle: 'Go Back to Different Component' }});
    ...
    return (...)
  }
}

.....
interface LocationState {
  backToTitle?: string;
}

const CompB: FC = () => {
  ...
  const locState = useLocation<LocationState>().state;
  ...
  return (
    <>
      <span
        onClick={() =>
          locState?.backToTitle
            ? history.goBack()
            : history.push('/home')
        }
      >
        &nbsp;{locState?.backToTitle ?? 'Back to Home'}
      </span>
    </>
  )
}
`;

const NavigationInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Navigation Info</Heading>
      <VStack align='flex-start' gap={4}>
        <VStack align='flex-start'>
          <Heading as='h3'>Using dynamic ids</Heading>
          <Box>
            To navigate to different pages, modules or components using
            'react-router-dom', you can use default 'useHistory()' but there is
            custom 'useHistory()' as well in the routes folder whose details you
            will get in the Folder Structure tab. Using that, you can pass
            dynamic ids thus keeping source of trugh in appRoutes.ts file.
          </Box>
        </VStack>
        <VStack align='flex-start'>
          <Heading as='h3'>Custom Go Back Navigation</Heading>
          <Box>
            If there is nested navigation from one component to another and in
            the header there is 'Go Back' navigation functionality, ideally
            'history.goBack()' works. But sometimes you have to interact between
            different components that don't have that simple navigation flow. So
            for that we use inbuild local state management provided by
            'react-router-dom'.
            <SyntaxHighlighter language='typescript' style={materialDark}>
              {navigateStateCode}
            </SyntaxHighlighter>
          </Box>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default NavigationInfo;
