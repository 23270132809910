import React, { FC, useEffect, useState } from 'react';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import './Home.css';
import { canAccessAudit } from '../../configs';
import { Authorize, AuthRole } from 'authorization';
import DashboardContainer from '../../sub-components/DashboardContainer';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';
import ContinueTraining from 'sub-components/ContinueTraining';
import CardPreviewCarousel from 'sub-components/CardPreviewCarousel';
import HomeFormResponse from 'sub-components/HomeFormResponse';
import { AnalyticsBoard, TasksBoard, TrainingBoard } from 'sub-components';
import { HomeActivityFeed, HomeHeading } from './components';
import { useRenderFlag } from 'sub-components/Sidebar/sections/helper';
import LOInProgress from './components/LOInProgress';
import { userObj } from 'sop-commons/src/client';
import {
  AmplitudeEventNames,
  CommonAmplitudeEvents,
  deployEvent,
} from 'shared/amplitudeEvents';
import AuditsHome from 'sub-components/audits/home';
import { useChatMessageCount } from 'hooks';
import { UserAnalyticsOverviewType } from 'sub-components/AnalyticsBoard/AnalyticsBoard';
import { usersEntityObj } from 'sub-components/Header';

export interface ITasksForToday {
  total: number;
  completed: number;
}

const GET_HOME_ANALYTICS = gql`
  query userAnalyticsOverview {
    userAnalyticsOverview
  }
`;

const HomeBase: FC = () => {
  const [showSingleUserChatModal, setShowSingleUserChatModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const chatMessageCount = useChatMessageCount();
  const [tasksForTodayData, setTasksForTodayData] = useState<ITasksForToday>({
    total: 0,
    completed: 0,
  });
  const userData = useReactiveVar(userObj);
  const entityUserData = useReactiveVar(usersEntityObj);
  const [eventSent, setEventSent] = useState(false);

  const setShowSingleUserChatModalHandler = (value: any) => {
    setShowSingleUserChatModal(value);
  };

  const setSelectedUserHandler = (user: any) => {
    setSelectedUser(user?.eid);
  };

  let flag = useRenderFlag();

  const { data: analyticsData, loading: analyticsDataLoading } =
    useQuery<UserAnalyticsOverviewType>(GET_HOME_ANALYTICS);

  useEffect(() => {
    const allDataLoaded = !analyticsDataLoading && userData && entityUserData;
    const totalOwners =
      entityUserData?.filter(
        (user) =>
          user?.type === 'user' &&
          [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(user?.authRole)
      )?.length || 0;
    const totalLOs =
      entityUserData?.filter(
        (user) =>
          user?.type === 'user' && user?.authRole === AuthRole.LOCATION_OWNER
      )?.length || 0;
    const totalWorkers =
      entityUserData?.filter(
        (user) => user?.type === 'user' && user?.authRole === AuthRole.WORKER
      )?.length || 0;
    if (allDataLoaded && analyticsData && !eventSent) {
      const bestLocationName =
        entityUserData
          ?.filter(
            (entity) =>
              analyticsData?.userAnalyticsOverview?.bestLocationTaskPerformance
                ?.locationId === entity?.eid
          )
          ?.map((loc) => loc?.name)?.[0] || '';
      const eventProperties = {
        from_where: 'Home Page',
        user_id: userData?.eid,
        company_id: userData?.entityId,
        user_persona: userData?.authRole,
        active_locations: analyticsData?.userAnalyticsOverview?.locationCount,
        active_members: analyticsData?.userAnalyticsOverview?.userCount,
        tasks_completed: analyticsData?.userAnalyticsOverview?.tasks?.complete,
        training_finished:
          analyticsData?.userAnalyticsOverview?.trainings?.complete,
        forms_filled: analyticsData?.userAnalyticsOverview?.formsFilled,
        company_average:
          analyticsData?.userAnalyticsOverview?.taskCompanyAvg?.rate,
        best_location: bestLocationName,
        tasks_for_today: tasksForTodayData?.completed,
        unread_messages: chatMessageCount,
        number_of_owners: totalOwners,
        number_of_location_owners: totalLOs,
        number_of_workers: totalWorkers,
      };
      deployEvent(AmplitudeEventNames.HOME_PAGE_VISIT, {
        ...eventProperties,
      });
      setEventSent(true);
    }
  }, [
    analyticsDataLoading,
    userData,
    tasksForTodayData,
    analyticsData,
    chatMessageCount,
    entityUserData,
  ]);
  return (
    <>
      <Box w='full'>
        <HomeHeading userData={userData} />
        {flag ? (
          <VStack align='flex-start' w='full'>
            <Authorize
              permittedFor={['user']}
              permittedRoles={[
                AuthRole.SUPER_ADMIN,
                AuthRole.ADMIN,
                AuthRole.LOCATION_OWNER,
              ]}
            >
              <Flex mb={4} h='auto' w='full'>
                <AnalyticsBoard
                  analyticsData={analyticsData}
                  loading={analyticsDataLoading}
                  tasksForTodayData={tasksForTodayData}
                />
              </Flex>
            </Authorize>
            <Flex justify='space-between' w='full'>
              <Box className='recommend-train-container' mr='1rem'>
                <Flex gap={4} direction={'column'}>
                  <Authorize canAccess={canAccessAudit}>
                    <AuditsHome />
                  </Authorize>
                  <TasksBoard
                    tasksForTodayData={tasksForTodayData}
                    setTasksForTodayData={setTasksForTodayData}
                    event={CommonAmplitudeEvents}
                  />
                  <TrainingBoard event={CommonAmplitudeEvents} />
                  <Authorize
                    permittedFor={['user']}
                    permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
                  >
                    <HomeFormResponse />
                  </Authorize>
                  <ContinueTraining />
                </Flex>
              </Box>
              <Authorize permittedRoles={[AuthRole.WORKER]}>
                <AnalyticsBoard
                  analyticsData={analyticsData}
                  loading={analyticsDataLoading}
                  tasksForTodayData={tasksForTodayData}
                />
              </Authorize>
              <Authorize permittedFor={'branch'}>
                <AnalyticsBoard
                  analyticsData={analyticsData}
                  loading={analyticsDataLoading}
                  tasksForTodayData={tasksForTodayData}
                />
              </Authorize>
              <HomeActivityFeed
                setShowSingleUserChatModalHandler={
                  setShowSingleUserChatModalHandler
                }
                setSelectedUserHandler={setSelectedUserHandler}
              />
            </Flex>
          </VStack>
        ) : (
          <HStack>
            <LOInProgress />
          </HStack>
        )}
        {showSingleUserChatModal && (
          <SingleUserChatModal
            onClose={() => setShowSingleUserChatModal(false)}
            selectedUser={selectedUser}
          />
        )}
      </Box>
      {selectedItem && (
        <CardPreviewCarousel
          deckSelected={selectedItem}
          onCloseClick={() => setSelectedItem(null)}
        />
      )}
    </>
  );
};

export default HomeBase;
