import {
  Box,
  Heading,
  ListItem,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';

const moduleRouteCode = `// ModuleRoute.tsx
import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Authorize } from '../../authorization';
...
import {
  ROUTE_ONE,
  ROUTE_TWO,
} from '../../appRoutes';
import {
  ROUTE_ONE_WRAPPER,
  ROUTE_TWO_WRAPPER,
} from '../../routeComponentDefinitions';
import { ROUTE_ONE_COMPONENT, ROUTE_TWO_COMPONENT } from '.';
import { canAccessModule } from '.';

const ModuleRoute: FC = () => {
  let { path } = useRouteMatch();
  return (
    <Authorize
      canAccess={canAccessModule}
      renderNoAccess={() => <Redirect to='/' />}
    >
      <Switch>
        <Route exact path={path} component={ROUTE_ONE_WRAPPER} />
        <Route path={ROUTE_ONE} component={ROUTE_ONE_WRAPPER} />
        <Route
          path={\`\${path}\/ROUTE_TWO/:id1/:id2\`}
          component={ROUTE_TWO_WRAPPER}
        />
      </Switch>
    </Authorize>
  );
};

export default ModuleRoute;`;

const RoutesInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Routes Info</Heading>
      <Box fontSize='16px'>
        Staring from routes, there are 3 files to keep in consideration.
        <UnorderedList>
          <ListItem>
            appRoutes.ts: will contain definition for all the routes in the
            project.
          </ListItem>
          <ListItem>
            RouteComponentsRender.tsx: will have the declaration of routes using
            Switch & Route from 'react-router-dom'.
          </ListItem>
          <ListItem>
            routeComponentDefinitions.tsx: will contain the components wrappers
            that are used along with {'<Route />'}.
          </ListItem>
        </UnorderedList>
        A new process is introduced where for each module separate route file
        will be created having nomenclature as : {'<MODULE NAME>Route.tsx'},
        which will contain something like below:
        <SyntaxHighlighter language='typescript' style={materialDark}>
          {moduleRouteCode}
        </SyntaxHighlighter>
        A function is being imported: "canAccessModule" which will have the
        logic if this module can be accessed by logged in user based on the auth
        role primarily.
      </Box>
    </VStack>
  );
};

export default RoutesInfo;
