import { useUserEntity } from 'hooks';
import { useEffect, useMemo } from 'react';
import { useService } from 'sub-components/nexus/NexusBase/components/LeftSection/components/CustomerFeedback/layers';
import { useService as useGalleryService } from 'sub-components/nexus/NexusBase/components/LeftSection/components/ImageGallery/layers';
import { RatingData } from 'sub-components/nexus/NexusBase/components/LeftSection/components/CustomerFeedback/types';
import { useGetLocationIds } from 'sub-components/nexus/NexusBase/utils';
import { LocationRating } from '../types';

export const useControl = () => {
  const locationIds = useGetLocationIds(true);
  const locations = useUserEntity((entity) => entity?.locations);
  const {
    locationRating: { locationRating, locationRatingRes },
  } = useService();
  const {
    locPhotos: { locPhotos, locPhotosRes },
  } = useGalleryService();
  useEffect(() => {
    locationRating({
      variables: {
        page: 1,
        perPage: locationIds?.length,
        filter: {
          locationIds: locationIds,
        },
      },
    });
    locPhotos({
      variables: {
        locationIds: locationIds,
      },
    });
  }, [locationIds]);

  const locationFind = useMemo(() => {
    return (locId: string) => {
      return locations?.find((loc) => loc?.eid === locId);
    };
  }, [locations]);

  const graphData = useMemo<LocationRating[]>(() => {
    const data = locationRatingRes?.data;
    const galleryData = locPhotosRes?.data?.LocationGooglePhotos;
    if (!data || data?.LocationMetaPagination?.items?.length === 0) {
      return [];
    }

    let res = data?.LocationMetaPagination?.items?.map((item) => ({
      location: locationFind(item?.locationId)?.name || 'Unknown location',
      googleRating: item?.google?.googleRating,
      galleryData:
        galleryData?.filter(
          (gallery) => gallery?.locationId === item?.locationId
        ) || [],
    }));
    return res;
  }, [locationRatingRes?.data, locationFind, locPhotosRes?.data]);

  return { graphData, loading: locationRatingRes?.loading };
};
