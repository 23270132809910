import React, { FC, useEffect, useMemo, useState } from 'react';
import { Flex, Checkbox, Text, Divider } from '@chakra-ui/react';
import { SelectedMember } from '../../ComplianceListContainer';
import SearchInput from '../../../../../../atoms/SearchInput';
import MemberList from './MemberList';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { AuthRole } from 'authorization';
import DefaultTableEmptyState from '../EmptyStates/DefaultTableEmptyState';
import { UserEntityData } from 'shared/graphql/shared-types';

interface MembersFilterProps {
  selectedUsers: SelectedMember[];
  onChange: (selectedUsers: SelectedMember[]) => void;
}

const MembersFilter: FC<MembersFilterProps> = ({ selectedUsers, onChange }) => {
  const entityData = useReactiveVar(usersEntityObj);
  const [filterTextField, setFilterTextField] = useState<string>('');

  const allMembers: UserEntityData[] = useMemo(() => {
    return entityData?.filter(
      (entity) =>
        entity?.type === 'user' &&
        [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(entity?.authRole)
    );
  }, [entityData]);

  const filteredMembers = useMemo(() => {
    return allMembers.filter((member) =>
      member.name.toLowerCase().includes(filterTextField.toLowerCase())
    );
  }, [filterTextField, allMembers]);

  // Determine if all filtered members are selected
  const isAllSelected = useMemo(() => {
    if (filteredMembers.length === 0) return false;
    return filteredMembers.every((member) =>
      selectedUsers.some((user) => user.eid === member.eid)
    );
  }, [filteredMembers, selectedUsers]);

  // Handle "Select All" toggle
  const handleSelectAllChange = (isChecked: boolean) => {
    if (isChecked) {
      // Add all filtered members to selectedUsers
      const newSelected = [
        ...selectedUsers,
        ...filteredMembers.filter(
          (member) => !selectedUsers.some((user) => user.eid === member.eid)
        ),
      ];
      onChange(newSelected);
    } else {
      // Remove all filtered members from selectedUsers
      const newSelected = selectedUsers.filter(
        (user) => !filteredMembers.some((member) => member.eid === user.eid)
      );
      onChange(newSelected);
    }
  };

  // Handle individual member selection
  const handleMemberChange = (eid: string) => {
    if (selectedUsers.some((user) => user.eid === eid)) {
      onChange(selectedUsers.filter((user) => user.eid !== eid));
    } else {
      const newUser = allMembers.find((user) => user.eid === eid);
      if (newUser) {
        onChange([...selectedUsers, newUser]);
      }
    }
  };
  return (
    <Flex
      bgColor='#FCFCFC'
      borderRadius='12px'
      p='12px 10px'
      border='1px solid #EFEFEF'
      flexDir='column'
    >
      <SearchInput
        onChange={(e) => setFilterTextField(e.target.value)}
        placeholder='Search by name'
        hideShortcuts
      />

      <Flex justifyContent='space-between' alignItems='center' mt={4}>
        <Text fontWeight={600} fontSize='13px' color='#6F767E'>
          Select All
        </Text>
        <Checkbox
          isChecked={isAllSelected}
          onChange={(e) => handleSelectAllChange(e.target.checked)}
          isDisabled={allMembers.length === 0}
          iconSize={'24px'}
          size={'lg'}
        />
      </Flex>
      <Divider my={2} />

      {filteredMembers.length > 0 ? (
        <MemberList
          members={filteredMembers}
          selectedUsers={selectedUsers}
          onMemberChange={handleMemberChange}
        />
      ) : (
        <Flex justifyContent='center' alignItems='center' h='250px'>
          <DefaultTableEmptyState
            filters={true}
            searchFieldText={filterTextField}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default MembersFilter;
