import React, { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp91 } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import GenericColorHeader from 'sub-components/GenericColorHeader';

import { usePerformanceOverViewData } from '../../../sub-components/tasks/performance/overview';

import { PerformanceEntity } from 'sub-components/tasks/performance';
import { ASSIGNEE_PERFORMANCE } from '../../../appRoutes';
import PerformanceOverviewList from './PerformanceOverviewList';

const MAX_LIST_LENGTH = 13;

interface IProps {}

const PerformanceOverview: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'task']);
  const history = useHistory();

  const { performanceEntities, loading } = usePerformanceOverViewData();

  const performanceList = useMemo(() => {
    return performanceEntities.slice(0, MAX_LIST_LENGTH);
  }, [performanceEntities]);

  const clickedHandler = (user: PerformanceEntity) => {
    return history.push('/tasks/performance/' + user.eid, {
      name: user.name,
    });
  };

  const onViewAllClick = () => history.push(ASSIGNEE_PERFORMANCE);

  return (
    <Flex
      bg='white'
      borderRadius='12px'
      p={6}
      gap='20px'
      flexDir='column'
      w='full'
    >
      <Flex align='center' justify='space-between'>
        <GenericColorHeader
          title={t('task:performanceOverview')}
          color='#CABDFF'
        />
        {/*<FontAwesomeIcon*/}
        {/*  icon={faArrowUp91 as IconProp}*/}
        {/*  cursor='pointer'*/}
        {/*  fontSize='20px'*/}
        {/*/>*/}
      </Flex>

      <PerformanceOverviewList
        loading={loading}
        performanceList={performanceList}
        clickedHandler={clickedHandler}
        onViewAllClick={onViewAllClick}
      />
    </Flex>
  );
};

PerformanceOverview.displayName = 'p/TDB/LOG/PerformanceOverview';

export default PerformanceOverview;
