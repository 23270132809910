import React, { FC } from 'react';
import {
  Badge,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ReactComponent as FilterIcon } from 'assets/images/nexus/compliance/filled.svg';
import BaseFilter from './BaseFilter';
import { Filter } from '../../ComplianceListContainer';

interface FilterContainerProps {
  appliedFilter: Filter;
  setAppliedFilter: React.Dispatch<React.SetStateAction<Filter>>;
}

const FilterContainer: FC<FilterContainerProps> = ({
  appliedFilter,
  setAppliedFilter,
}) => {
  const filterCount = appliedFilter.filterby.length;

  return (
    <Popover placement='left-end' isLazy>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            {filterCount > 0 ? (
              <Box position='relative' display='inline-block'>
                <Badge
                  position='absolute'
                  top='-6px'
                  right='-5px'
                  bgColor='#2A85FF'
                  borderRadius='full'
                  textAlign='center'
                  w='18px'
                  fontSize='12px'
                  fontWeight={600}
                  color='white'
                >
                  {filterCount}
                </Badge>
                <FilterIcon cursor='pointer' />
              </Box>
            ) : (
              <FilterIcon cursor='pointer' />
            )}
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <BaseFilter
                initialFilter={appliedFilter}
                onApply={setAppliedFilter}
                onClose={onClose}
              />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default FilterContainer;
