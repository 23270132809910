import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const LocalesInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Locales Overview</Heading>
      <Box>
        This folder will contain all the language json strings which will be
        used with "i18next" package.
      </Box>
    </VStack>
  );
};

export default LocalesInfo;
