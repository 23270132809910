import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const AppFeatureConfig: FC = () => {
  return (
    <VStack align='flex-start' gap={4}>
      <Heading as='h1'>app.features.config.ts</Heading>
      <VStack align='flex-start'>
        <Heading as='h2'>Purpose</Heading>
        <Box>
          Defines utility functions that determine whether an authenticated user
          has access to specific application features based on their roles and
          user types. This file centralizes feature access logic, making it
          easier to manage permissions across the application.
        </Box>
      </VStack>
      <VStack align='flex-start'>
        <Heading as='h2'>NOTE:</Heading>
        <Box>
          The module access configuration logic will be used along with routes
          for module. See the 'Architecture' tab for complete flow.
        </Box>
      </VStack>
    </VStack>
  );
};

export default AppFeatureConfig;
