export const useQueryCode = `
import React from 'react';
import { gql, useQuery } from '@apollo/client';

const GET_USERS = gql\`
  query GetUsers {
    users {
      id
      name
    }
  }
\`;

interface User {
  id: string;
  name: string;
}

interface GetUsersData {
  users: User[];
}

const UsersList: React.FC = () => {
  const { loading, error, data } = useQuery<GetUsersData>(GET_USERS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <ul>
      {data?.users.map((user) => (
        <li key={user.id}>{user.name}</li>
      ))}
    </ul>
  );
};

export default UsersList;
`;

export const useQueryCodeWithVars = `
import React from 'react';
import { gql, useQuery } from '@apollo/client';

const GET_USER_BY_ID = gql\`
  query GetUserById($id: ID!) {
    user(id: $id) {
      id
      name
      email
    }
  }
\`;

interface User {
  id: string;
  name: string;
  email: string;
}

interface GetUserByIdData {
  user: User;
}

interface GetUserByIdVars {
  id: string;
}

const UserDetails: React.FC<{ userId: string }> = ({ userId }) => {
  const { loading, error, data } = useQuery<GetUserByIdData, GetUserByIdVars>(
    GET_USER_BY_ID,
    {
      variables: { id: userId },
    }
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :</p>;

  return (
    <div>
      <h1>{data?.user.name}</h1>
      <p>{data?.user.email}</p>
    </div>
  );
};
export default UserDetails;
`;

export const useQueryCodeAdvanced = `
import React from 'react';
import { gql, useQuery } from '@apollo/client';

const GET_POSTS = gql\`
  query GetPosts($limit: Int!) {
    posts(limit: $limit) {
      id
      title
      content
    }
  }
\`;

interface Post {
  id: string;
  title: string;
  content: string;
}

interface GetPostsData {
  posts: Post[];
}

interface GetPostsVars {
  limit: number;
}

const PostsList: React.FC = () => {
  const { loading, error, data, refetch, networkStatus } = useQuery<
    GetPostsData,
    GetPostsVars
  >(GET_POSTS, {
    variables: { limit: 10 },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    skip: false,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      console.log('Data fetched:', data);
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    },
    context: { headers: { 'X-Custom-Header': 'value' } },
    pollInterval: 5000,
    returnPartialData: true,
    partialRefetch: true,
  });

  if (networkStatus === 4) return <p>Refetching!</p>;
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div>
      <button onClick={() => refetch({ limit: 20 })}>Load More</button>
      {data?.posts.map((post) => (
        <div key={post.id}>
          <h2>{post.title}</h2>
          <p>{post.content}</p>
        </div>
      ))}
    </div>
  );
};

export default PostsList;

`;

export const useLazyQueryExampleBasic = `
import React from 'react';
import { gql, useLazyQuery } from '@apollo/client';

const SEARCH_USERS = gql\`
  query SearchUsers($name: String!) {
    searchUsers(name: $name) {
      id
      name
    }
  }
\`;

interface User {
  id: string;
  name: string;
}

interface SearchUsersData {
  searchUsers: User[];
}

interface SearchUsersVars {
  name: string;
}

const UserSearch: React.FC = () => {
  const [name, setName] = React.useState('');
  const [searchUsers, { loading, data, error }] = useLazyQuery<
    SearchUsersData,
    SearchUsersVars
  >(SEARCH_USERS);

  const handleSearch = () => {
    searchUsers({ variables: { name } });
  };

  return (
    <div>
      <input
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder='Search Users'
      />
      <button onClick={handleSearch}>Search</button>

      {loading && <p>Loading...</p>}
      {error && <p>Error : {error.message}</p>}
      {data && (
        <ul>
          {data.searchUsers.map((user) => (
            <li key={user.id}>{user.name}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default UserSearch;
`;

export const useLazyQueryExampleAdvanced = `
import React from 'react';
import { gql, useLazyQuery } from '@apollo/client';

const GET_ITEMS = gql\`
  query GetItems($category: String!) {
    items(category: $category) {
      id
      name
    }
  }
\`;

interface Item {
  id: string;
  name: string;
}

interface GetItemsData {
  items: Item[];
}

interface GetItemsVars {
  category: string;
}

const ItemList: React.FC = () => {
  const [category, setCategory] = React.useState('all');
  const [getItems, { loading, data, error, called }] = useLazyQuery<
    GetItemsData,
    GetItemsVars
  >(GET_ITEMS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log('Items fetched:', data.items);
    },
    onError: (error) => {
      console.error('Error fetching items:', error);
    },
    context: { headers: { 'X-Special-Header': 'value' } },
  });

  React.useEffect(() => {
    if (category) {
      getItems({ variables: { category } });
    }
  }, [category, getItems]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
  if (!called) return <p>Select a category to load items.</p>;

  return (
    <div>
      <select value={category} onChange={(e) => setCategory(e.target.value)}>
        <option value="all">All</option>
        <option value="books">Books</option>
        <option value="electronics">Electronics</option>
      </select>

      {data && (
        <ul>
          {data.items.map((item) => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ItemList;
`;

export const useMutationExampleBasic = `
import React from 'react';
import { gql, useMutation } from '@apollo/client';

const ADD_USER = gql\`
  mutation AddUser($name: String!, $email: String!) {
    addUser(name: $name, email: $email) {
      id
      name
    }
  }
\`;

interface User {
  id: string;
  name: string;
}

interface AddUserData {
  addUser: User;
}

interface AddUserVars {
  name: string;
  email: string;
}

const AddUserForm: React.FC = () => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [addUser, { data, loading, error }] = useMutation<
    AddUserData,
    AddUserVars
  >(ADD_USER);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    addUser({ variables: { name, email } });
  };

  if (loading) return <p>Submitting...</p>;
  if (error) return <p>Error : {error.message}</p>;
  if (data) return <p>User {data.addUser.name} added successfully!</p>;

  return (
    <form onSubmit={handleSubmit}>
      <input
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder='Name'
        required
      />
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder='Email'
        required
      />
      <button type='submit'>Add User</button>
    </form>
  );
};

export default AddUserForm;
`;

export const useMutationExampleAdvanced = `
import React from 'react';
import { gql, useMutation } from '@apollo/client';

const ADD_TODO = gql\`
  mutation AddTodo($text: String!) {
    addTodo(text: $text) {
      id
      text
      completed
    }
  }
\`;

const GET_TODOS = gql\`
  query GetTodos {
    todos {
      id
      text
      completed
    }
  }
\`;

interface Todo {
  id: string;
  text: string;
  completed: boolean;
}

interface AddTodoData {
  addTodo: Todo;
}

interface AddTodoVars {
  text: string;
}

interface GetTodosData {
  todos: Todo[];
}

const AddTodoForm: React.FC = () => {
  const [text, setText] = React.useState('');
  const [addTodo, { loading, error }] = useMutation<AddTodoData, AddTodoVars>(
    ADD_TODO,
    {
      variables: { text },
      refetchQueries: [{ query: GET_TODOS }],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        console.log('Todo added:', data.addTodo);
        setText('');
      },
      onError: (error) => {
        console.error('Error adding todo:', error);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingTodos = cache.readQuery<GetTodosData>({
          query: GET_TODOS,
        });
        if (existingTodos && existingTodos.todos) {
          cache.writeQuery<GetTodosData>({
            query: GET_TODOS,
            data: { todos: [...existingTodos.todos, data.addTodo] },
          });
        }
      },
      optimisticResponse: {
        addTodo: {
          __typename: 'Todo',
          id: 'temp-id',
          text,
          completed: false,
        },
      },
      context: { headers: { 'X-Special-Header': 'value' } },
    }
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    addTodo();
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder='Add new todo'
        required
      />
      <button type='submit' disabled={loading}>
        Add Todo
      </button>
      {error && <p>Error : {error.message}</p>}
    </form>
  );
};

export default AddTodoForm;
`;
