import React, { FC } from 'react';
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Spacer,
  useBoolean,
  useModalContext,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { chakraComponents } from 'chakra-react-select';
import { useApolloClient } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import { LogEditor } from 'delightree-editor';
import { userObj } from 'sop-commons/src/client';

import { FormInput } from '../../../../../../../atoms';
import Dropdown from '../../../../../../../atoms/Dropdown';
import { Loader } from '../../../../../../../sub-components';

import TitleHeader from '../../../../../../../sub-components/CardEditor/TitleHeader';

import { NotesType } from './data';
import { validationSchema } from './validationSchema';
import LogDateInput from './LogDateInput';
import LogTimeInput from './LogTimeInput';
import { IFormInput, useSubmitHandler } from './useSubmitHandler';
import LogFormFooter from './LogFormFooter';
import { CONVERSATION_LOGS_FIND, LogEditResponse } from './create-log.graphql';
import { editableDataConvert } from './log-content.helper';
import { EditorWrapper } from './logeditor-wrapper.styles';

interface IProps {
  locationId: string;
  noteId?: string;
  onSuccess?: () => void;
}

const CreateLogsContent: FC<IProps> = ({ locationId, noteId, onSuccess }) => {
  const [initialising, action] = useBoolean(true);
  const { onClose } = useModalContext();

  const client = useApolloClient();

  const { control, handleSubmit } = useForm<IFormInput>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    defaultValues: async () => {
      action.on();
      if (noteId) {
        const response = await client.query<LogEditResponse>({
          query: CONVERSATION_LOGS_FIND,
          fetchPolicy: 'network-only',
          variables: {
            eid: noteId,
          },
        });
        setTimeout(action.off, 500);
        if (response.errors) {
          return Promise.reject(response.errors);
        }

        return editableDataConvert(response.data?.conversationLogsFindById);
      }
      setTimeout(action.off);

      return Promise.resolve({
        occurrenceDate: new Date(),
        creator: userObj().name,
      });
    },
  });

  const submitHandler = useSubmitHandler({
    locationId: locationId,
  });

  const onSubmit = async (values: IFormInput) => {
    await submitHandler(values);
    onSuccess?.();
    setTimeout(onClose, 100);
  };

  const isReadOnly = initialising || !!noteId;

  return (
    // @ts-ignore
    <Flex flex={1} position='relative' maxH='calc(100vh - 7.5rem)'>
      {initialising && (
        <Center
          position='absolute'
          zIndex={101}
          borderRadius='12px'
          bg='blackAlpha.600'
          boxSize='full'
        >
          <Loader size='lg' />
        </Center>
      )}

      <EditorWrapper>
        <Controller
          name='content'
          control={control}
          render={({ field }) => {
            return (
              // @ts-ignore
              <FormControl display='flex' mt={2}>
                <LogEditor
                  value={field.value}
                  onChange={(data) => {
                    field.onChange(data);
                  }}
                />
              </FormControl>
            );
          }}
        />
      </EditorWrapper>

      <Flex
        width='440px'
        flexDir='column'
        gap='16px'
        bg='#F9F9F9'
        padding='30px'
        borderRightRadius='12px'
      >
        <Box fontSize='15px' fontWeight='600' color='#000000' pb={1}>
          CONVERSATION DETAILS
        </Box>

        <Controller
          name='occurrenceDate'
          control={control}
          render={({ field, fieldState }) => {
            return (
              // @ts-ignore
              <FormControl isInvalid={!!fieldState?.error}>
                <Flex gap='16px'>
                  <Flex flexDir='column' flex={9} gap={2}>
                    <TitleHeader title='Took place on' isRequired />

                    <LogDateInput {...field} readOnly={isReadOnly} />
                  </Flex>

                  <Flex flexDir='column' flex={6} gap={2}>
                    <TitleHeader title='Took place at' isRequired />

                    <LogTimeInput {...field} readOnly={isReadOnly} />
                  </Flex>
                </Flex>

                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Box>
          <TitleHeader title='Type of conversation log' />

          <Controller
            name='category'
            control={control}
            render={({ field, fieldState }) => {
              return (
                // @ts-ignore
                <FormControl mt={2} isInvalid={!!fieldState?.error}>
                  <Dropdown
                    options={NotesType}
                    placeholder='Select note category'
                    size='lg'
                    {...field}
                    inputStyle={{
                      border: '1px solid',
                      borderColor: 'var(--chakra-colors-gray-200)',
                      backgroundColor: 'white',
                      borderRadius: '12px',
                      fontSize: '15px',
                      outline: 'none',
                    }}
                    components={{
                      DropdownIndicator: (indicatorProps) => (
                        <chakraComponents.DropdownIndicator {...indicatorProps}>
                          <FontAwesomeIcon
                            icon={faCaretDown as IconProp}
                            color='#6F767E'
                            size='sm'
                          />
                        </chakraComponents.DropdownIndicator>
                      ),
                    }}
                  />

                  <FormErrorMessage>
                    {fieldState?.error?.message}
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </Box>

        <Box opacity='0.7'>
          <TitleHeader title='Creator' />

          <Controller
            name='creator'
            control={control}
            defaultValue=''
            render={({ field, fieldState }) => {
              return (
                // @ts-ignore
                <FormControl mt={2} isInvalid={!!fieldState?.error}>
                  <FormInput
                    size='lg'
                    placeholder='Logs creator name'
                    variant='outline'
                    {...field}
                    isReadOnly
                  />

                  <FormErrorMessage>
                    {fieldState?.error?.message}
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </Box>

        <Spacer />

        <LogFormFooter
          locationId={locationId}
          noteId={noteId}
          control={control}
          onClose={onClose}
          onSubmitClick={handleSubmit(onSubmit)}
        />
      </Flex>
    </Flex>
  );
};

export default CreateLogsContent;
