import { useMutation } from '@apollo/client';
import { Box, Button, Flex, Input, Skeleton } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useRef, useState } from 'react';
import Loader from 'sub-components/Loader';
import {
  ADD_NEW_COMPLIANCE_CATEGORY,
  DELETE_COMPLIANCE_CATEGORY,
  RENAME_COMPLIANCE_CATEGORY,
} from '../../query/compliance.graphql';
import { EntityComplianceCategoriesType } from '../../types/compliance.types';
import { getDefaultCategoryName } from '../helper';
import CategoryCard from './CategoryCard';

interface IProps {
  complianceCategory: EntityComplianceCategoriesType[];
  isRenameFieldVisible: boolean;
  setIsRenameFieldVisible: React.Dispatch<React.SetStateAction<boolean>>;
  getComplianceCategories: any;
  getComplianceListing: any;
  getComplianceFilters: any;
  complianceLoader: any;
  complianceCategoryLoader: any;
  loggedInUserId: string;
  userAuthRole: string;
}

const CategoryListing: FC<IProps> = ({
  complianceCategory,
  isRenameFieldVisible,
  setIsRenameFieldVisible,
  getComplianceCategories,
  getComplianceListing,
  getComplianceFilters,
  complianceLoader,
  complianceCategoryLoader,
  loggedInUserId,
  userAuthRole,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [activeCategory, setActiveCategory] = useState<string>('');
  const [showInputFieldForEid, setShowInputFieldForEid] = useState<string>('');
  const [showAddCategoryField, setShowAddCategoryField] =
    useState<boolean>(false);
  const categorySliderRef = useRef(null);
  const categorySliderContainerRef = useRef(null);
  const addCategoryRef = useRef(null);

  const handleCategorySlider = (scrollDirection: string) => {
    if (!categorySliderRef.current) {
      return undefined;
    }
    if (scrollDirection === 'left') {
      // @ts-ignore
      categorySliderRef.current.scrollLeft -= 100;
    } else if (scrollDirection === 'right') {
      // @ts-ignore
      categorySliderRef.current.scrollLeft += 100;
    }
  };

  const [deleteComplianceCategory, { loading: deleteLoader }] = useMutation(
    DELETE_COMPLIANCE_CATEGORY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        getComplianceCategories();
        // setIsPopoverOpen(false);
        getComplianceListing({
          variables: getComplianceFilters(),
        });
      },
    }
  );

  const [addComplianceCategory, { loading: addComplianceCategoryLoader }] =
    useMutation(ADD_NEW_COMPLIANCE_CATEGORY, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        getComplianceCategories();
        setShowAddCategoryField(false);
      },
    });

  const [
    renameComplianceCategory,
    { loading: renameComplianceCategoryLoader },
  ] = useMutation(RENAME_COMPLIANCE_CATEGORY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log(data);
      getComplianceCategories();
    },
  });

  const handleOperation = (
    operation: string,
    eid: string,
    renameFieldVisibility?: boolean,
    newNameForCategory?: string
  ) => {
    if (operation === 'Rename') {
      setShowInputFieldForEid(newNameForCategory ? '' : eid);

      newNameForCategory &&
        renameComplianceCategory({
          variables: {
            eid: eid,
            category: newNameForCategory,
          },
        });
    } else if (operation === 'Delete') {
      setIsPopoverOpen(false);
      deleteComplianceCategory({
        variables: {
          eid: eid,
        },
      });
    }
  };

  const handleAddCategory = (categoryName: string) => {
    if (!categoryName?.trim()) {
      categoryName = getDefaultCategoryName(complianceCategory);
    }
    addComplianceCategory({
      variables: {
        category: categoryName,
      },
    });
    setShowAddCategoryField(false);
  };

  return (
    <Flex
      ref={categorySliderContainerRef}
      justifyContent={'space-between'}
      gap={2}
      border={'1px solid #EFEFEF'}
      px={'10px'}
      alignItems={'center'}
      backgroundColor={'#FCFCFC'}
      borderRadius={'12px'}
      height={'56px'}
    >
      {/* @ts-ignore */}
      {categorySliderRef.current?.scrollWidth >
        //@ts-ignore
        categorySliderContainerRef.current?.scrollWidth - 150 && (
        <Button
          onClick={() => handleCategorySlider('left')}
          border={'1px solid #EFEFEF'}
          bgColor={'#FCFCFC'}
        >
          <FontAwesomeIcon icon={faChevronLeft as IconProp} />
        </Button>
      )}

      <Flex
        style={{ scrollBehavior: 'smooth' }}
        ref={categorySliderRef}
        overflowX={'auto'}
        gap={2}
      >
        {complianceCategoryLoader ? (
          <Flex gap={2} alignItems={'center'}>
            {Array(8)
              ?.fill('')
              ?.map((_, i) => (
                <Flex
                  height={'45px'}
                  flexDir='column'
                  justifyContent={'center'}
                  width={'150px'}
                  gap={3}
                  pt='6px'
                  key={i}
                >
                  <Skeleton width='full' height='46px' borderRadius='12px' />
                </Flex>
              ))}
          </Flex>
        ) : (
          <>
            {complianceCategory?.map((category) => {
              return (
                <Box minW={'fit-content'} key={category?.eid}>
                  {category?.eid === showInputFieldForEid ? (
                    <>
                      <Input
                        width={'125px'}
                        borderRadius={'8px'}
                        backgroundColor={'#EFEFEF'}
                        focusBorderColor='transparent'
                        outline={'none'}
                        border={'1px solid #6F767E'}
                        defaultValue={category?.category}
                        onKeyUp={(e) => {
                          if (e?.keyCode === 13) {
                            let newCatName = e?.target?.value
                              ? e?.target?.value
                              : category?.category;
                            handleOperation(
                              'Rename',
                              category?.eid,
                              false,
                              newCatName
                            );
                          }
                        }}
                        autoFocus
                        onBlur={(e) => {
                          let newCatName = e?.target?.value
                            ? e?.target?.value
                            : category?.category;
                          handleOperation(
                            'Rename',
                            category?.eid,
                            false,
                            newCatName
                          );
                        }}
                        type={'text'}
                        placeholder={category?.category}
                      />
                    </>
                  ) : (
                    <CategoryCard
                      userAuthRole={userAuthRole}
                      loggedInUserId={loggedInUserId}
                      category={category}
                      handleOperation={handleOperation}
                      isRenameFieldVisible={isRenameFieldVisible}
                      setIsRenameFieldVisible={setIsRenameFieldVisible}
                      isPopoverOpen={isPopoverOpen}
                      setIsPopoverOpen={setIsPopoverOpen}
                      activeCategory={activeCategory}
                      setActiveCategory={setActiveCategory}
                      key={category?.eid}
                      eid={category?.eid}
                      title={category?.category}
                      count={category?.count ? category?.count : 0}
                      deleteLoader={deleteLoader}
                      complianceLoader={complianceLoader}
                      getComplianceListing={getComplianceListing}
                      getComplianceFilters={getComplianceFilters}
                    />
                  )}
                </Box>
              );
            })}
          </>
        )}

        {addComplianceCategoryLoader && (
          <Flex
            border={'1px solid #F4F4F4'}
            justifyContent={'center'}
            style={{
              borderRadius: '12px',
              padding: '6px',
            }}
            width={'150px'}
            backgroundColor={'#EFEFEF'}
          >
            <Loader />
          </Flex>
        )}
        {showAddCategoryField && (
          <Flex alignItems={'center'}>
            <Input
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  handleAddCategory(e?.target?.value);
                }
              }}
              onBlur={(e) => handleAddCategory(e?.target?.value)}
              width={'150px'}
              borderRadius={'8px'}
              backgroundColor={'#EFEFEF'}
              focusBorderColor='transparent'
              outline={'none'}
              border={'1px solid #6F767E'}
              autoFocus
              ref={addCategoryRef}
              placeholder='New category'
            />
          </Flex>
        )}
      </Flex>

      {/* @ts-ignore */}
      {categorySliderRef.current?.scrollWidth >
        //@ts-ignore
        categorySliderContainerRef.current?.scrollWidth - 150 && (
        <Button
          onClick={() => handleCategorySlider('right')}
          border={'1px solid #EFEFEF'}
          bgColor={'#FCFCFC'}
        >
          <FontAwesomeIcon icon={faChevronRight as IconProp} />
        </Button>
      )}
      <Button
        disabled={showAddCategoryField}
        ml={'1.5rem'}
        mr={'10px'}
        //@ts-ignore
        _active={'#FCFCFC'}
        //@ts-ignore
        _hover={'#FCFCFC'}
        color={'#2A85FF'}
        backgroundColor={'#FCFCFC'}
        leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
        onClick={() => setShowAddCategoryField(true)}
      >
        Add Category
      </Button>
    </Flex>
  );
};

export default CategoryListing;
