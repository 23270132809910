import { gql } from '@apollo/client';

export const SIGN_IN = gql`
  mutation SignInMutation($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`;

export const GET_USER = gql`
  query GET_USER {
    user {
      email
      name
      phone
      profilePic
      preferredLanguage
      authRole
      onboarded
      onboardedAt
      role
      isDemo
      username
      status
      account {
        verification {
          verified
        }
        verificationCode
        onboardingStage
      }
      updatedAt
      createdAt
      eid
      locations {
        eid
        name
        launchId
        onboarded
        locationStatus
        address {
          address
          city
          state
          zipCode
        }
      }
      entity {
        eid
        name
        analytics
        address
        brandColor
        chatConfig {
          locationChat
          newChannel {
            authRoles
            members
            restrictType
            roles
          }
          restrictions
          startDM {
            restrictType
            roles
            authRoles
            members
          }
        }
        profilePic
        isActive
        headquarters
        email
        phone
        isDemo
        launcher {
          eid
          published
          entityId
          contents {
            eid
            category
            tasks {
              eid
              description
              docRequired
              dependency
              duration
              steps {
                stepId
                title
                type
                formId
              }
              title
              triggerTask
              completed
            }
          }
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
        roles {
          createdAt
          createdBy
          name
          status
          color
          eid
          updatedAt
        }
        bundles {
          name
          status
          color
        }
        category {
          children {
            eid
            name
            status
            icon
            color
            createdBy
            visibility
            visibleTo {
              condition
              locations
              roles
              users
            }
          }
          eid
          name
          status
          icon
          color
          visibility
          visibleTo {
            condition
            locations
            roles
            users
          }
        }
        updatedAt
        createdAt
        locations {
          name
          isRoot
          eid
          createdAt
          launchId
          locationStatus
        }
        locationFields {
          eid
          fieldName
          fieldType
          options
          validations
          createdAt
          oldLocationSupport
          visibleToLocations
          createdBy {
            eid
            name
            profilePic
          }
        }
        features {
          aiSearch
          chat
          launcher
          audit
        }
      }
      type
      entityId
      timezone

      archivedChats {
        type
        uid
      }

      signatures {
        font
        text
      }
    }
  }
`;
