import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const HeaderColorsInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Header Colors Info</Heading>
      <Box fontSize='16px'>
        This folder contains the common color codes used across the project
        which is exported from "config" folder's "index.ts" for readability and
        one source of truth.
      </Box>
    </VStack>
  );
};

export default HeaderColorsInfo;
