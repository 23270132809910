import React, { useCallback, useRef, useState } from 'react';
import Carousel from './Carousel';
import { Flex, AlertDialogCloseButton, useToast, Box } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { LocationRating } from '../types';
import { useConfirm, UseConfirm } from 'ui-components';
import { LocationGooglePhotos } from 'sub-components/nexus/NexusBase/components/LeftSection/components/ImageGallery/types/api.types';

interface IProps {
  galleryData: LocationGooglePhotos[];
  locationName: string;
}

type Carousel = (props: IProps) => void;

export const useCarrouselModal = (): Carousel => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  const toast = useToast();

  return useCallback(({ galleryData, locationName }) => {
    confirm({
      content: (
        <Carousel galleryData={galleryData} locationName={locationName} />
      ),
      isCentered: true,
      contentProps: {
        minW: '1076px',
        py: '16px',
        px: '8px',
        borderRadius: '16px',
      },
      footer: null,
      okText: null,
      onOK: undefined,
      cancelText: null,
    });
  }, []);
};
