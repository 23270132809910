import { gql } from '@apollo/client';

export const GET_COMPLIANCE_CATEGORIES = gql`
  query EntityComplianceCategories {
    EntityComplianceCategories {
      category
      createdAt
      createdBy
      eid
      entityId
      updatedAt
      count
    }
  }
`;

export const GET_COMPLIANCE_LISTING = gql`
  query EntityCompliancePagination(
    $page: Int
    $perPage: Int
    $sort: SortFindManyEntityComplianceInput
    $filter: FilterFindManyEntityComplianceInput
  ) {
    EntityCompliancePagination(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        category {
          category
          eid
        }
        categoryId
        createdAt
        createdBy
        eid
        entityId
        expiryDate
        lastUpdateBy
        locationId
        title
        updatedAt
        reminder {
          remindBefore
          remindDate
          remindType
        }
        createdByUser {
          eid
          name
          profilePic
          role
        }
        location {
          name
          eid
        }
      }
    }
  }
`;

export const GET_DOCUMENT_VERSION = gql`
  query EntityComplianceById($eid: String!) {
    EntityComplianceById(eid: $eid) {
      files {
        createdAt
        url
        type
        name
        updatedAt
        createdBy {
          name
          profilePic
        }
      }
      category {
        category
      }
      location {
        name
      }
      eid
    }
  }
`;

export const DELETE_COMPLIANCE_CATEGORY = gql`
  mutation DeleteEntityComplianceCategory($eid: String!) {
    DeleteEntityComplianceCategory(eid: $eid) {
      succeed
    }
  }
`;

export const ADD_NEW_COMPLIANCE_CATEGORY = gql`
  mutation AddEntityComplianceCategory($category: String!) {
    AddEntityComplianceCategory(category: $category) {
      category
      eid
    }
  }
`;

export const DELETE_COMPLIANCE_DOCUMENT = gql`
  mutation DeleteEntityCompliance($eid: String!) {
    DeleteEntityCompliance(eid: $eid) {
      succeed
    }
  }
`;

export const RENAME_COMPLIANCE_CATEGORY = gql`
  mutation UpdateEntityComplianceCategory($eid: String!, $category: String!) {
    UpdateEntityComplianceCategory(eid: $eid, category: $category) {
      eid
      category
    }
  }
`;
