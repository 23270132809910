import React, { FC, useMemo, useState } from 'react';
import { Box, Flex, Text, Tooltip as ChakraToolTip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsMaximize,
  faArrowUp91,
  faArrowDown19,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { BoxHeader } from '../../../../ui-components';
import { LOCATION_PERFORMANCE } from '../../../../appRoutes';

import { useLocationGraphData } from 'sub-components/tasks/performance';
import LocationBarGraph from './LocationBarGraph';

const LocationPerformanceBarGraph: FC = () => {
  const history = useHistory();
  const { t } = useTranslation(['task']);
  const [sortByAsc, setSortByAsc] = useState(true);

  const { graphData: _graphData, loading } = useLocationGraphData();

  const onFullViewClick = () => history.push(LOCATION_PERFORMANCE);

  const graphData = useMemo(() => {
    return _graphData
      .sort((a, b) => {
        if (sortByAsc) {
          return b.completeRate - a.completeRate;
        }
        return a.completeRate - b.completeRate;
      })
      .slice(0, 12);
  }, [_graphData, sortByAsc]);

  return (
    <Flex
      flex={1}
      flexDir='column'
      borderRadius='10px'
      padding='16px 20px'
      bg='white'
      gap='24px'
    >
      <Flex flexDir='column' gap='10px'>
        <Flex justify='space-between'>
          <BoxHeader
            title={t('task:locationPerformance')}
            fontSize='18px'
            color='#CABDFF'
          />

          <Flex gap={2} align='center'>
            <FontAwesomeIcon
              icon={(sortByAsc ? faArrowUp91 : faArrowDown19) as IconProp}
              cursor='pointer'
              fontSize='20px'
              onClick={() => setSortByAsc((s) => !s)}
            />
            <Text color='#D9D9D9' size='20px'>
              &bull;
            </Text>

            <ChakraToolTip
              label={t('task:fullView')}
              shouldWrapChildren
              hasArrow
              padding='6px 10px'
              borderRadius='4px'
            >
              <FontAwesomeIcon
                icon={faArrowsMaximize as IconProp}
                cursor='pointer'
                fontSize='20px'
                color='#504F54'
                onClick={onFullViewClick}
              />
            </ChakraToolTip>
          </Flex>
        </Flex>

        <Flex align='center' gap='10px'>
          <Box boxSize='8px' borderRadius='full' bg='#5542F6' />
          <Box color='#2E2C34' fontSize='14px'>
            {t('task:taskCompletionRate')}
          </Box>
        </Flex>
      </Flex>

      <LocationBarGraph isLoading={loading} height={400} data={graphData} />
    </Flex>
  );
};

LocationPerformanceBarGraph.displayName =
  'p/TD/C/g/LocationPerformanceBarGraph';

export default LocationPerformanceBarGraph;
