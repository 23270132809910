import React, { FC, ReactNode, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/react';
import { useRouteMatch } from 'utils';
import { ReactComponent as CheveronIcon } from '../../assets/images/cheveronIcon.svg';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { SidebarBaseItem } from './common';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';
import { feedbackIntegration } from '@sentry/react';

export interface SubItem {
  name: string;
  value: string;
  status: string;
  icon: string;
}

interface IProps {
  icon: typeof CheveronIcon;
  title: string;
  isMenu?: boolean;
  isSelected: boolean;
  link: string;
  isChat?: boolean;
  countElement?: ReactNode;
  chatMessageCount?: number;
  hasList?: boolean;
  list?: SubItem[];
  isExpand?: boolean;
  type?: 'handbook' | 'training';
  isHelp?: boolean;
  externalLink?: boolean;
}

const SidebarItem: FC<IProps> = ({
  icon,
  title,
  isMenu,
  isSelected,
  link,
  isChat,
  countElement,
  hasList,
  list = [],
  isExpand,
  type,
  isHelp,
  externalLink = false,
}) => {
  const history = useHistory();
  const dashboardCtx = useContext(DashboardDataContext);
  const routeMatch = useRouteMatch<{ category: string }>();

  const selectedCategory = routeMatch?.params?.category;

  const [isExpanded, setIsExpanded] = useState(!!selectedCategory || isExpand);

  const onExpandStateChange = () => {
    setIsExpanded(!isExpanded);
  };

  const feedback = feedbackIntegration({
    autoInject: false,
  });

  const onClickNavigationHandler = (routeLink = link) => {
    deployEvent(`${AmplitudeEventNames.NAVIGATE_ROUTE}: ${routeLink}`);
    history.push(routeLink);
    dashboardCtx?.resetNavigationPersistDataHandler();
  };

  const openFeedbackDialog = () => {
    const existingDialog = document?.querySelector('#sentry-feedback');
    if (existingDialog && existingDialog?.parentNode) {
      existingDialog.parentNode.removeChild(existingDialog);
    }

    feedback?.openDialog();
    setTimeout(injectStyles, 0);
  };

  const injectStyles = () => {
    const feedbackElement = document?.getElementById('sentry-feedback');
    if (feedbackElement && feedbackElement?.shadowRoot) {
      const style = document?.createElement('style');
      style.textContent = `
        .dialog__content {
          left: 20px !important;
        }
      `;
      feedbackElement?.shadowRoot?.appendChild(style);
    }
  };

  if (!hasList) {
    return (
      <SidebarBaseItem
        icon={icon}
        title={title}
        link={link}
        isSelected={isSelected && !isHelp}
        // id={isHelp ? 'my_custom_link' : ''}
        id={isHelp ? 'sentry_link' : ''}
        externalLink={externalLink}
        onClick={() => {
          if (isHelp) {
            openFeedbackDialog();
          }
        }}
      >
        {isMenu && (
          <div className='sidebar-item-menu-container'>
            <CheveronIcon />
          </div>
        )}
        {isChat && countElement}
      </SidebarBaseItem>
    );
  }

  return (
    <>
      <SidebarBaseItem
        icon={icon}
        title={title}
        link={link}
        isSelected={isSelected && !isHelp}
        onClick={onExpandStateChange}
      >
        {isMenu && list?.length > 0 && (
          <div className='sidebar-item-menu-container'>
            <CheveronIcon />
          </div>
        )}
      </SidebarBaseItem>

      {isExpanded && list?.length > 0 && (
        <div
          className='sidebar-item-list-container'
          style={{ marginBottom: '20px' }}
        >
          {list?.map((item, index: number) => {
            const getClassName = () => {
              if (selectedCategory === item?.name) {
                return `sidebar-item-list-item-container selected`;
              }
              return `sidebar-item-list-item-container`;
            };
            return (
              <div
                key={index}
                onClick={() => {
                  let _link =
                    type === 'handbook'
                      ? `/handbook/${item?.name}`
                      : item?.value === 'active_path'
                      ? '/training'
                      : '/drafts/list';
                  deployEvent(AmplitudeEventNames.TRAINING_VISIT, {
                    from_where: 'Navigation Panel',
                  });
                  onClickNavigationHandler(_link);
                }}
              >
                <Flex pos='relative' height='6vh' w='100%'>
                  {index + 1 < list.length && (
                    <div
                      style={{
                        border: '1px solid #efefef',
                        height: '100%',
                        position: 'absolute',
                      }}
                    />
                  )}
                  <div
                    style={{
                      border: '2px solid #efefef',
                      borderBottomLeftRadius: '10px',
                      borderRight: 'none',
                      height: '50%',
                      width: '10px',
                      borderTop: 'none',
                    }}
                  />
                  <div
                    className={getClassName()}
                    style={{
                      flex: 1,
                      padding: '7px 12px',
                      borderRadius: '12px',
                      height: '100%',
                      marginLeft: 1,
                      overflow: 'hidden',
                    }}
                  >
                    <Text as='span' isTruncated style={{ width: '100%' }}>
                      {item?.name}
                    </Text>
                  </div>
                </Flex>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SidebarItem;
