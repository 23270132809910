import { Box, Heading, VStack } from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';

const RouteHooksInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>routeHooks.ts</Heading>
      <Box>
        This file contains "useSearchParam" hook which simplifies extracting
        query parameters from the URL's search string and allows them to be
        typed. It's useful in React applications where you need to access URL
        parameters directly within a component without repeatedly parsing them.
      </Box>
      <Heading as='h2'>routeHooks.ts</Heading>
      <SyntaxHighlighter language='typescript' style={materialDark}>
        {`import React from 'react';
import { useSearchParam } from './hooks/useSearchParam';

const UserProfile: React.FC = () => {
  // Define the expected parameters with types
  const params = useSearchParam<{ name?: string; age?: string; role?: string }>();

  return (
    <div>
      <h1>User Profile</h1>
      <p>Name: {params.name}</p>
      <p>Age: {params.age}</p>
      <p>Role: {params.role}</p>
    </div>
  );
};

export default UserProfile;
`}
      </SyntaxHighlighter>
    </VStack>
  );
};

export default RouteHooksInfo;
