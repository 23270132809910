import React, { FC, useContext } from 'react';
import GenericModal from 'ui-components/GenericModal';
import DashboardDataContext from './DashboardStore/DashboardData/dashboard-data-context';

interface IProps {}

const DashboardModal: FC<IProps> = () => {
  const dashboardCtx = useContext(DashboardDataContext);

  return (
    <GenericModal
      isOpen={dashboardCtx.showErrorModal}
      onClose={dashboardCtx.hideErrorModalHandler}
      title={dashboardCtx.modalTitle}
      closeIcon={dashboardCtx.CloseIcon}
      lineColor={dashboardCtx.lineColor}
      isCentered={true}
      buttonsObject={[
        {
          mt: 6,
          type: 'button',
          size: 'lg',
          isLoading: false,
          disabled: false,
          title: 'OK',
          leftIcon: null,
          rightIcon: null,
          onClick: dashboardCtx.hideErrorModalHandler,
          style: { width: '160px' },
          variant: 'solid',
          colorScheme: 'blue',
          className: null,
          value: null,
        },
      ]}
      buttonsPosition='right'
      paddingLeft={30}
      paddingRight={30}
      paddingTop={30}
      paddingBottom={20}
      modalSizes={{
        sm: '600px',
        md: '600px',
        base: '600px',
        xl: '600px',
        ['2xl']: '600px',
      }}
      modalMinSizes={{
        sm: '600px',
        md: '600px',
        base: '600px',
        xl: '600px',
        ['2xl']: '600px',
      }}
    >
      <div
        style={{
          marginTop: '13px',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '1.63',
          letterSpacing: '-0.16px',
          marginLeft: '20px',
          width: '93%',
        }}
      >
        {dashboardCtx.errorMessage}
        {/* Looks like you don&apos;t have visibility access to this deck / card. Please contact your manager to get access! */}
      </div>
    </GenericModal>
  );
};

export default DashboardModal;
