import {
  Box,
  Flex,
  Heading,
  ListItem,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';

const AuthorizationUtils: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>authorization.utils.ts</Heading>
      <VStack align='flex-start'>
        <Heading as='h2'>Purpose</Heading>
        <Box>
          Provides utility functions to support the authorization logic,
          ensuring consistent data handling across the module.
        </Box>
        <Heading as='h2'>Key Components</Heading>
        <UnorderedList pl={4}>
          <ListItem>
            <Flex gap={2}>
              <Box>{'MayArray<T>'}:</Box>
              <Flex flexDir='column'>
                <Box>
                  A generic type that can be a single item or an array of items.
                </Box>
              </Flex>
            </Flex>
            <SyntaxHighlighter language='typescript' style={materialDark}>
              {`export type MayArray<T> = T | T[];`}
            </SyntaxHighlighter>
          </ListItem>
          <ListItem>
            <Box>
              <Box as='span'>{'toArray<T>(data: T | T[]): T[]'}:</Box>&nbsp;
              <Box as='span'>
                Converts a value to an array. If the input is already an array,
                it returns it as-is; otherwise, it wraps the input in an array.
                If the input is null or undefined, it returns an empty array.
              </Box>
            </Box>
          </ListItem>
        </UnorderedList>
        <Heading as='h2'>Example</Heading>
        <Box as='span'>
          Ensuring that a permission check function always deals with arrays
          simplifies the logic:
        </Box>
        <SyntaxHighlighter language='typescript' style={materialDark}>
          {`const userRoles = toArray(authUser.authRole);
// Now, userRoles is guaranteed to be an array`}
        </SyntaxHighlighter>
      </VStack>
    </VStack>
  );
};

export default AuthorizationUtils;
