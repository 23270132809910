import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { searchRegExp } from 'utils';
import { ContainerWrapper } from '../common';
import PerformanceList from './PerformanceList';
import { PerformanceEntity, usePerformanceOverViewData } from '../assignee';
import SingleUserChatModal from '../../../../../ui-components/SingleUserChatModal';
import PreviewHeader from '../../../../forms/component/PreviewHeader';
import SearchAndFilter from '../assignee/SearchAndFilter';

interface IProps {}

const ListContainer: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'task']);
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState<string>();
  const [chatUserId, setChatUserId] = useState<string>();

  const { performanceEntities, loading } = usePerformanceOverViewData();

  const dataList = useMemo(() => {
    if (searchQuery) {
      const reg = searchRegExp(searchQuery, 'gi');
      return performanceEntities.filter((value) => {
        return (
          value.name?.match(reg) ||
          value.authRole?.match(reg) ||
          value.role?.match(reg) ||
          value?.locations?.[0]?.name?.match(reg)
        );
      });
    }

    return performanceEntities;
  }, [performanceEntities, searchQuery]);

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const clickedHandler = (clickedItem: string, member: PerformanceEntity) => {
    switch (clickedItem) {
      case 'message':
        return setChatUserId(member.eid);
      case 'open':
        return history.push('/tasks/performance/' + member.eid, {
          name: member.name,
        });
      default:
        console.log(clickedItem);
    }
  };

  return (
    <ContainerWrapper>
      <Flex flexDir='column' gap='20px'>
        <PreviewHeader title={t('task:membersPerformance')} color='#FFD88D' />

        <SearchAndFilter
          onSearch={onSearch}
          searchQuery={searchQuery}
          // onSortChange={onSortChange}
          // sortBy={sortBy}
          placeholder={t('task:placeholder.searchMemberJob')}
        />
      </Flex>

      <PerformanceList
        dataList={dataList}
        loading={loading}
        currentPage={1}
        onPageChange={() => null}
        onClickedHandler={clickedHandler}
      />

      {chatUserId && (
        <SingleUserChatModal
          selectedUser={chatUserId}
          onClose={() => setChatUserId(undefined)}
        />
      )}
    </ContainerWrapper>
  );
};

ListContainer.displayName = 'sc/t/p/o/j/ListContainer';

export default ListContainer;
