import { SelectOption } from 'atoms';
import { AuthRole } from 'authorization';
import { useSafeState, useUserDataSelector, useUserEntity } from 'hooks';
import { useEffect, useMemo } from 'react';
import { useService } from 'sub-components/nexus/NexusBase/components/LeftSection/components/ImageGallery/layers';
import { useGetLocationIds } from 'sub-components/nexus/NexusBase/utils';
import { ViewType } from '../components/GalleryViewType';
import { ImageItem } from '../types';

const DEFAULT_SELECTION = {
  label: 'All locations',
  value: 'allLocations',
};

export const useControl = () => {
  const [selectedLocation, setSelectedLocation] =
    useSafeState(DEFAULT_SELECTION);
  const {
    locPhotos: { locPhotos, locPhotosRes },
    internalPhotos: { internalPhotos, internalPhotosRes },
  } = useService();
  const locationIds = useGetLocationIds(true);
  const { isAuditEnabled, locations } = useUserEntity((entity) => ({
    isAuditEnabled: entity?.features?.audit,
    locations: entity?.locations,
  }));
  const [viewType, setViewType] = useSafeState<ViewType>(
    isAuditEnabled ? 'internal' : 'customer'
  );
  const { loggedInUserAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      loggedInUserAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
    })
  );

  const filteredLocations = useMemo((): SelectOption[] => {
    let initialOption: SelectOption = DEFAULT_SELECTION;
    if (locationIds?.length === 0 && locations?.length === 0) {
      return [];
    }
    return [
      initialOption,
      ...(([AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(
        loggedInUserAuthRole
      )
        ? locations?.map((loc) => ({ eid: loc?.eid, name: loc?.name }))
        : (loggedInUserLocations || [])?.map((loc) => ({
            eid: loc?.eid,
            name: loc?.name,
          }))
      )
        ?.filter((location) => locationIds?.some((id) => location?.eid === id))
        ?.map((loc) => ({ label: loc?.name, value: loc?.eid })) || []),
    ];
  }, [locationIds, locations, loggedInUserAuthRole, loggedInUserLocations]);

  const images: ImageItem[] = useMemo(() => {
    if (viewType === 'internal') {
      if (internalPhotosRes?.data?.LocationInternalPhotos?.length === 0) {
        return [];
      }
      return (
        internalPhotosRes?.data?.LocationInternalPhotos?.map((item) => ({
          title: item?.name,
          description: item?.name,
          url: item?.url,
          source: 'internal' as 'internal',
        })) || []
      );
    } else {
      if (locPhotosRes?.data?.LocationGooglePhotos?.length === 0) {
        return [];
      }
      return (
        locPhotosRes?.data?.LocationGooglePhotos?.map((item) => ({
          title: item?.name,
          description: item?.name,
          url: item?.photoUrl,
          source: 'locPhotos' as 'locPhotos',
        })) || []
      );
    }
  }, [
    locPhotosRes?.data,
    internalPhotosRes?.data,
    viewType,
    selectedLocation,
    locationIds,
  ]);

  const handleViewTypeChange = (newViewType: ViewType) => {
    setViewType(newViewType);
    setSelectedLocation(DEFAULT_SELECTION);
  };

  useEffect(() => {
    const locationIdsVar =
      selectedLocation?.value !== DEFAULT_SELECTION['value'] &&
      selectedLocation?.value
        ? [selectedLocation?.value]
        : locationIds;
    if (viewType === 'customer') {
      locPhotos({
        variables: {
          locationIds: locationIdsVar,
        },
      });
    } else if (viewType === 'internal') {
      internalPhotos({
        variables: {
          locationIds: locationIdsVar,
        },
      });
    }
  }, [locationIds, selectedLocation, viewType]);

  return {
    images,
    isAuditEnabled,
    loading:
      viewType === 'customer'
        ? locPhotosRes?.loading
        : internalPhotosRes?.loading,
    DEFAULT_SELECTION,
    filteredLocations,
    selectedLocation,
    viewType,
    setSelectedLocation,
    setViewType: handleViewTypeChange,
  };
};
