import { Box, Heading, VStack } from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';

const ChatInitializeHookInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>useChatInitialize.ts</Heading>
      <Box>
        This hook is used to initialize the comet chat sdk into our project at
        the root level. Go through the code for understanding as there is no
        example to show.
      </Box>
    </VStack>
  );
};

export default ChatInitializeHookInfo;
