import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Text, SimpleGrid } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCertificate } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowUpRightFromSquare,
  faRocket,
  faHourglassHalf,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LAUNCHER_DASHBOARD, LOCATION_DETAIL, LOCATIONS_PAGE } from 'appRoutes';
import { FlexCard } from 'atoms';
import { AuthRole } from 'authorization';
import { HeaderColors } from 'configs';
import { useUserDataSelector } from 'hooks';
import moment from 'moment';
import { ISelectedFilterTab } from 'pages/Teams/locations/LocationListContainer';
import React, { FC, useMemo } from 'react';
import { useHistory } from 'routes';
import { useNexusBoardContext } from 'sub-components/nexus/NexusBase/store/context';
import { LocationInfo } from 'sub-components/nexus/NexusBase/types/api-types/location-details.types';
import { BoxHeader } from 'ui-components';

interface LocationCardData {
  icon: IconProp;
  label: string;
  count: string | number;
  color?: string;
  totalCount: number;
  onClick?: () => void;
}

const LocationSection: FC = () => {
  const history = useHistory();
  const {
    inDevLocationCount,
    openLocationCount,
    preLaunchLocationCount,
    locations,
  } = useNexusBoardContext((state) => state.locationDetailsData);

  const { loggedInUserAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      loggedInUserAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
    })
  );

  const getLatestLocation = (details: LocationInfo[]): LocationInfo | null => {
    if (!details || details.length === 0) {
      return null;
    }

    let _details = [...details];
    if (AuthRole.LOCATION_OWNER === loggedInUserAuthRole) {
      _details = [...details]?.filter((detail) =>
        loggedInUserLocations?.some((loc) => loc?.eid === detail?.eid)
      );
    }

    if (_details.length === 1) {
      return _details[0];
    }

    return _details.reduce((latest, current) => {
      return moment(current.createdAt).isAfter(moment(latest.createdAt))
        ? current
        : latest;
    }, _details[0]);
  };

  const locationsData = useMemo(() => {
    const latestLocation = getLatestLocation(locations);
    return {
      inDevLocationCount,
      openLocationCount,
      preLaunchLocationCount,
      latestLocation,
    };
  }, [
    inDevLocationCount,
    openLocationCount,
    preLaunchLocationCount,
    locations,
  ]);

  const getLocationsCount = (type: 'open' | 'launching' | 'preLaunch') => {
    if (type === 'open') {
      return [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(
        loggedInUserAuthRole
      )
        ? locationsData?.openLocationCount
        : (loggedInUserLocations || [])?.filter(
            (loc) => loc?.locationStatus === 'open'
          )?.length || 0;
    } else if (type === 'launching') {
      return [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(
        loggedInUserAuthRole
      )
        ? locationsData?.inDevLocationCount
        : (loggedInUserLocations || [])?.filter(
            (loc) => loc?.locationStatus === 'development'
          )?.length || 0;
    } else if (type === 'preLaunch') {
      return [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(
        loggedInUserAuthRole
      )
        ? locationsData?.preLaunchLocationCount
        : (loggedInUserLocations || [])?.filter(
            (loc) => loc?.locationStatus === 'preLaunch'
          )?.length || 0;
    } else {
      return 0;
    }
  };

  const potentialCards: LocationCardData[] = [
    {
      icon: faArrowUpRightFromSquare as IconProp,
      label: 'Open',
      count: getLocationsCount('open'),
      totalCount:
        getLocationsCount('open') +
        getLocationsCount('launching') +
        getLocationsCount('preLaunch'),
      color: HeaderColors.Gray.Primary,
      onClick: () =>
        history.push({
          pathname: LOCATIONS_PAGE,
          state: {
            tab: 'open' as ISelectedFilterTab,
          },
        }),
    },
    {
      icon: faRocket as IconProp,
      label: 'Launching',
      count: getLocationsCount('launching'),
      totalCount:
        getLocationsCount('open') +
        getLocationsCount('launching') +
        getLocationsCount('preLaunch'),
      color: HeaderColors.Gray.Primary,
      onClick: () =>
        history.push({
          pathname: LOCATIONS_PAGE,
          state: {
            tab: 'development' as ISelectedFilterTab,
          },
        }),
    },
    {
      icon: faHourglassHalf as IconProp,
      label: 'Pre-launch',
      count: getLocationsCount('preLaunch'),
      totalCount:
        getLocationsCount('open') +
        getLocationsCount('launching') +
        getLocationsCount('preLaunch'),
      color: HeaderColors.Gray.Primary,
      onClick: () =>
        history.push({
          pathname: LOCATIONS_PAGE,
          state: {
            tab: 'preLaunch' as ISelectedFilterTab,
          },
        }),
    },
  ];

  if (locationsData.latestLocation) {
    potentialCards.push({
      icon: faCertificate as IconProp,
      label: 'Latest addition',
      count: locationsData.latestLocation.name || 'N/A',
      totalCount:
        getLocationsCount('open') +
        getLocationsCount('launching') +
        getLocationsCount('preLaunch'),
      color: HeaderColors.Gray.Primary,
      onClick: () =>
        locationsData?.latestLocation?.eid
          ? history.push({
              pathname: LOCATION_DETAIL,
              params: {
                locationId: locationsData?.latestLocation?.eid,
              },
              state: {
                backToTitle: 'Back to Home Page',
              },
            })
          : {},
    });
  }

  const activeCards = useMemo(() => {
    const totalLocations =
      locationsData.inDevLocationCount +
      locationsData.openLocationCount +
      locationsData.preLaunchLocationCount;

    if (totalLocations === 1 && getLocationsCount('open') === 1) {
      return potentialCards.filter((card) => card.label === 'Open');
    }

    return potentialCards.filter((card) => {
      if (card.label === 'Latest addition') {
        return !!locationsData.latestLocation;
      }
      return card.count > 0;
    });
  }, [locationsData, loggedInUserAuthRole, loggedInUserLocations]);

  // const activeCards = potentialCards.filter((card) => {
  //   if (card.label === 'Latest addition') {
  //     return !!locationsData.latestLocation;
  //   }
  //   return card.count > 0;
  // });

  if (AuthRole.LOCATION_OWNER === loggedInUserAuthRole) {
    return null;
  }

  return (
    <FlexCard flexDir='column'>
      <BoxHeader color={HeaderColors.Purple} title='My Locations' />
      <Box p={4}>
        {activeCards.length === 0 ? (
          <Center height='200px'>
            <Text color={HeaderColors.Gray.Primary} fontSize='lg'>
              No locations available.
            </Text>
          </Center>
        ) : (
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 3, lg: activeCards.length }}
            spacing={4}
          >
            {activeCards.map((card, index) => (
              <LocationCard key={index} {...card} />
            ))}
          </SimpleGrid>
        )}
      </Box>
    </FlexCard>
  );
};

const LocationCard: FC<LocationCardData> = ({
  icon,
  label,
  count,
  color,
  totalCount,
  onClick,
}) => (
  <FlexCard
    border='1px solid #EFEFEF'
    borderRadius='12px'
    bg='#FCFCFC'
    flexDir='column'
    p={4}
    cursor='pointer'
    onClick={onClick}
  >
    <Flex align='flex-start' mb={4}>
      <Center
        minW='24px'
        maxW='24px'
        minH='24px'
        maxH='24px'
        bg={color || HeaderColors.Gray.Primary}
        borderRadius='50%'
      >
        <FontAwesomeIcon icon={icon} color='white' />
      </Center>
      <Text ml={2} color={color || HeaderColors.Gray.Primary}>
        {label}
      </Text>
    </Flex>
    <Flex align='center' justify='space-between' flex='1'>
      <Text fontSize='20px' fontWeight='bold'>
        {label === 'Open' && totalCount === 1 ? 'Headquarters' : count}
      </Text>
      <ArrowForwardIcon color={HeaderColors.Gray.Primary} />
    </Flex>
  </FlexCard>
);

export default LocationSection;
