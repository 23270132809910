import React, { FC } from 'react';
import { ReactComponent as NotFound } from 'assets/images/nexus/compliance/not_found.svg';
import { ReactComponent as EmptyTable } from 'assets/images/nexus/compliance/table_empty_state.svg';
import { Flex, Text } from '@chakra-ui/react';
import { ActionButton } from 'ui-components';
import { useComplianceUpload } from 'sub-components/nexus/Compliance';
import { AddIcon } from '@chakra-ui/icons';
import { Authorize, AuthRole } from 'authorization';

interface IProps {
  searchFieldText?: string;
  filters?: boolean;
  complianceCategoryId: string;
}
const DefaultTableEmptyState: FC<IProps> = ({
  searchFieldText,
  filters,
  complianceCategoryId,
}) => {
  const complianceUpload = useComplianceUpload();

  let title, description;

  if (searchFieldText) {
    title = `No results found for '${searchFieldText}'`;
    description = 'Please check the spelling and try again.';
  } else if (filters) {
    title = `No results found`;
    description =
      'Your selected filters didn’t match any items. Try adjusting your filters.';
  } else if (complianceCategoryId) {
    title = 'Add your documents';
    description = 'No documents available in this category.';
  } else {
    title = 'Add your documents';
    description = 'No documents available for any location';
  }

  const renderEmptyStateIcon = () => {
    if (searchFieldText || filters) {
      return <NotFound />;
    }
    return <EmptyTable />;
  };

  return (
    <Flex alignItems={'center'} justifyContent={'center'} h={'50vh'}>
      <Flex alignItems={'center'} flexDir={'column'}>
        {renderEmptyStateIcon()}
        <Text mt={'20px'} fontSize={'20px'} fontWeight={600} color={'#33383F'}>
          {title}
        </Text>
        <Text mt={'8px'} fontSize={'14px'} fontWeight={500} color={'#6F767E'}>
          {description}
        </Text>
        {!searchFieldText && !filters && (
          <Authorize
            permittedFor={'user'}
            permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          >
            <ActionButton
              mt={'20px'}
              colorScheme='blue'
              leftIcon={<AddIcon />}
              actionFn={() => complianceUpload()}
            >
              Add doc
            </ActionButton>
          </Authorize>
        )}
      </Flex>
    </Flex>
  );
};

export default DefaultTableEmptyState;
