import React, { FC } from 'react';
import { Box, Flex, Radio, Text } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TFuncKey } from 'i18next';

import { Dropdown, Input, SelectOption } from '../../../../../../../atoms';
import { IDocumentInput } from '../compliance.input';

interface IOption {
  label: TFuncKey<'task', undefined>;
  value: string;
}

const options: IOption[] = [
  { label: 'days', value: 'days' },
  { label: 'months', value: 'months' },
  { label: 'years', value: 'years' },
];

function isNumber(value: string) {
  return value.match(/[0-9]+/);
}

const RemindBeforeInput: FC = () => {
  return (
    <Controller<IDocumentInput, 'reminder.remindBefore'>
      name='reminder.remindBefore'
      rules={{
        validate: (value, values) => {
          if (values?.reminder?.reminderType === 'before') {
            return value > 0 ? undefined : 'Enter value';
          }
        },
      }}
      render={({ field, fieldState }) => (
        <Input
          variant='auditOutline'
          bg='gray.100'
          width='64px'
          textAlign='center'
          isInvalid={fieldState.invalid}
          {...field}
          onKeyPressCapture={(event) => {
            if (!isNumber(event.key)) {
              event.preventDefault();
            }
          }}
        />
      )}
    />
  );
};

const toValue = (value?: string): SelectOption | undefined =>
  value ? { value: value, label: value } : undefined;

interface IProps {}

const RemindBefore: FC<IProps> = () => {
  const { t } = useTranslation('task');

  return (
    <Flex align='center' gap={3} justify='flex-start' mb={2}>
      <Radio size='lg' value='before' id='remidn-before' />
      <RemindBeforeInput />
      <Controller<IDocumentInput, 'reminder.remindType'>
        name='reminder.remindType'
        render={({ field }) => (
          <Box width='180px'>
            <Dropdown
              options={options}
              key='remindType'
              {...field}
              value={toValue(field.value)}
              isDisabled={field.disabled}
              onChange={(newValue) => field.onChange(newValue.value)}
              getOptionLabel={(option) => t(option.label)}
              selectStyles={{
                control: {
                  borderWidth: '2px',
                },
                menuList: {
                  minWidth: 'auto',
                  padding: '12px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                  borderRadius: '10px',
                },
                option: {
                  borderRadius: '8px',
                  // textTransform: 'capitalize'
                },
                singleValue: {
                  fontSize: '14px',
                  // textTransform: 'capitalize'
                },
              }}
            />
          </Box>
        )}
      />
      <Text fontSize='12px' color='#6F767E'>
        before this document expires
      </Text>
    </Flex>
  );
};

export default RemindBefore;
