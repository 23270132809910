import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const EventEmitInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Event Emit Info</Heading>
      <Box>
        This folder handles the event bus based architecture used in some places
        across the application.
      </Box>
      <Box>
        The unique component based event names are defined in "defaults.ts"
        file.
      </Box>
    </VStack>
  );
};

export default EventEmitInfo;
