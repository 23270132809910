import { Box, Heading, VStack } from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';

const codeOne = `import { makeVar } from '@apollo/client';
export const isLoggedInVar = makeVar<boolean>(false);
`;

const codeTwo = `import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from '../cache';

const LoginStatus: React.FC = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  return (
    <div>
      {isLoggedIn ? <p>Welcome back!</p> : <p>Please log in.</p>}
      <button onClick={() => isLoggedInVar(!isLoggedIn)}>
        {isLoggedIn ? 'Log out' : 'Log in'}
      </button>
    </div>
  );
};

export default LoginStatus;
`;

const codeThree = `// cache.tsx
import { makeVar } from '@apollo/client';

interface CartItem {
  id: string;
  name: string;
  price: number;
}

export const cartItemsVar = makeVar<CartItem[]>([]);
`;

const codeFour = `// Cart.tsx
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { cartItemsVar } from '../cache';

const Cart: React.FC = () => {
  const cartItems = useReactiveVar(cartItemsVar);

  const total = cartItems.reduce((sum, item) => sum + item.price, 0);

  return (
    <div>
      <h2>Your Cart</h2>
      {cartItems.length === 0 && <p>Cart is empty.</p>}
      <ul>
        {cartItems.map((item) => (
          <li key={item.id}>
            {item.name}: \${item.price.toFixed(2)}\{' '}
            <button
              onClick={() =>
                cartItemsVar(cartItems.filter((i) => i.id !== item.id))
              }
            >
              Remove
            </button>
          </li>
        ))}
      </ul>
      <p>Total: \${total.toFixed(2)}\</p>
    </div>
  );
};

export default Cart;
`;

const codeFive = `// ProductList.tsx
import React from 'react';
import { cartItemsVar } from '../cache';

interface Product {
  id: string;
  name: string;
  price: number;
}

const products: Product[] = [
  { id: '1', name: 'Laptop', price: 999.99 },
  { id: '2', name: 'Smartphone', price: 499.99 },
];

const ProductList: React.FC = () => {
  const addToCart = (product: Product) => {
    const currentCart = cartItemsVar();
    cartItemsVar([...currentCart, product]);
  };

  return (
    <div>
      <h2>Products</h2>
      <ul>
        {products.map((product) => (
          <li key={product.id}>
            {product.name}: \${product.price.toFixed(2)}\{' '}
            <button onClick={() => addToCart(product)}>Add to Cart</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductList;
`;

const ReactiveVariables: FC = () => {
  return (
    <VStack align='flex-start' gap={2}>
      <VStack align='flex-start'>
        <Heading as='h2'>Reactive variables</Heading>
        <Box>makeVar is a function that creates a reactive variable.</Box>
        <Box>
          useReactiveVar is a hook that allows React components to read and
          respond to changes in reactive variables.
        </Box>
        <Box>
          Reactive variables are a way to manage local state that can be shared
          across your application and automatically updates any components that
          read from them when they change.
        </Box>
      </VStack>
      <VStack align='flex-start'>
        <Heading as='h3'>
          Example 1: Basic Usage of makeVar and useReactiveVar
        </Heading>
        <Box>
          1. Creating a Reactive Variable
          <br />
          First, you create a reactive variable using makeVar. This is typically
          done outside of your React components, so the variable persists across
          renders.
        </Box>
        <SyntaxHighlighter language='typescript' style={materialDark}>
          {codeOne}
        </SyntaxHighlighter>
        <Box>
          2. Using useReactiveVar in Components
          <br />
          Now, you can use useReactiveVar to read the value of the reactive
          variable in your components.
        </Box>
        <SyntaxHighlighter language='typescript' style={materialDark}>
          {codeTwo}
        </SyntaxHighlighter>
      </VStack>
      <VStack align='flex-start'>
        <Heading as='h3'>Example 2: Managing a Reactive Variable Array</Heading>
        <Box>
          Suppose you want to manage a list of items, like a shopping cart.
        </Box>
        <Box>1. Creating the Reactive Variable</Box>
        <SyntaxHighlighter language='typescript' style={materialDark}>
          {codeThree}
        </SyntaxHighlighter>
        <Box>2. Using useReactiveVar to Display Cart Items</Box>
        <SyntaxHighlighter language='typescript' style={materialDark}>
          {codeFour}
        </SyntaxHighlighter>
        <Box>3. Adding Items to the Cart</Box>
        <SyntaxHighlighter language='typescript' style={materialDark}>
          {codeFive}
        </SyntaxHighlighter>
      </VStack>
    </VStack>
  );
};

export default ReactiveVariables;
