import React, { FC } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { gql, useQuery } from '@apollo/client';
import Loader from '../../../Loader';
import RecentSearchList from './RecentSearchList';
import PopularSearches from './PopularSearches';

const RECENT_SEARCH = gql`
  query AiSearchPagination($perPage: Int, $sort: SortFindManyaiSearchInput) {
    aiSearchPagination(perPage: $perPage, sort: $sort) {
      items {
        eid
        documentId
        query
        response
        recordType
      }
    }
  }
`;

interface IProps {
  onClick?: (value: string) => void;
}

const RecentAISearch: FC<IProps> = ({ onClick }) => {
  const { data, loading } = useQuery(RECENT_SEARCH, {
    fetchPolicy: 'network-only',
    variables: {
      perPage: 5,
      sort: '_ID_DESC',
    },
  });

  if (loading) {
    return (
      <Center minH='300px'>
        <Loader size='xl' />
      </Center>
    );
  }

  if (!data?.aiSearchPagination?.items?.length) {
    return <PopularSearches onClick={onClick} />;
  }

  return (
    <>
      <Box fontSize='12px' fontWeight='500' color='#6F767E'>
        Recent search:
      </Box>

      <RecentSearchList results={data?.aiSearchPagination?.items} />
    </>
  );
};

export default RecentAISearch;
