import React, { FC, useCallback, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { AI_SEARCH_INTRO, canAccessAiSearch } from '../../../configs';
import { Authorize } from '../../../authorization';
import { useUserDataSelector } from '../../../hooks';

import Command from './Command';
import AiSearchTag from './AiSearchTag';
import { useAiSearchIntro } from './useAiSearchIntro';

import { ReactComponent as SearchIcon } from 'assets/images/searchIcon.svg';

const SearchWrapper = styled(Flex)`
  width: 100%;
  max-width: 390px;
  height: 48px;
  border-radius: 12px;
  border: 1px solid #efefef;

  > div {
    padding-inline: 12px;
    align-items: center;
  }

  :not(:has(> div:only-of-type)) {
    max-width: calc(390px + 164px);
  }

  > div:first-of-type {
    border-top-left-radius: 11px;
    border-bottom-left-radius: 11px;
  }
  > div:last-of-type {
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;
  }
`;

interface IProps {
  onClick?: () => void;
  placeholder?: string;
}

const HeaderSearchInput: FC<IProps> = ({ onClick, placeholder }) => {
  const aiSearchIntro = useAiSearchIntro();
  const aiSearchEnabled = useUserDataSelector(canAccessAiSearch);

  const openSearch = useCallback(() => {
    if (!aiSearchEnabled) {
      return onClick?.();
    }

    if (localStorage.getItem(AI_SEARCH_INTRO)) {
      return onClick?.();
    } else {
      return aiSearchIntro({
        onContinue: onClick,
      });
    }
  }, [onClick, aiSearchEnabled]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.keyCode === 75 && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        openSearch();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [openSearch]);

  return (
    <SearchWrapper bg='#EFEFEF' onClick={openSearch}>
      <Flex position='relative' flex={1} gap={3}>
        <SearchIcon />
        <Box color='#9A9FA5' fontSize='15px' fontWeight='500' isTruncated>
          {placeholder}
        </Box>
        <Command />
      </Flex>

      <Authorize canAccess={canAccessAiSearch}>
        <AiSearchTag />
      </Authorize>
    </SearchWrapper>
  );
};

export default HeaderSearchInput;
