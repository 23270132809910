import React from 'react';
import {
  forwardRef,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useControllableState,
} from '@chakra-ui/react';

import { SelectOption } from '../../../../../../../atoms';
import CategoryLists from './CategoryLists';
import CategorySelectButton from './CategorySelectButton';

interface IProps {
  options?: SelectOption[];
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
  placeholder?: string;
}

const CategorySelect = forwardRef<IProps, 'div'>(
  ({ options, value, onChange, placeholder }, ref) => {
    const [_value, updateValue] = useControllableState<SelectOption>({
      value: value,
      onChange: onChange,
    });

    return (
      <Popover isLazy matchWidth>
        {/*// @ts-ignore */}
        <PopoverTrigger>
          <CategorySelectButton
            ref={ref}
            value={_value}
            placeholder={placeholder}
          />
        </PopoverTrigger>

        <PopoverContent
          overflow='hidden'
          width='inherit'
          boxShadow='md'
          _focus={{
            outline: '2px solid transparent',
            outlineOffset: '2px',
          }}
        >
          <PopoverBody paddingBlock={3}>
            <CategoryLists
              options={options}
              value={_value}
              onChange={updateValue}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
);

export default CategorySelect;
