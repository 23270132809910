import React, { FC } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { FormInput } from '../../../../../../../atoms';
import { ActionButton } from '../../../../../../../ui-components';
import { eventBus } from '../../../../../../../shared/eventEmit';
import {
  ADD_NEW_COMPLIANCE_CATEGORY,
  GET_COMPLIANCE_CATEGORIES,
} from '../../../../query/compliance.graphql';

interface IFormInput {
  category: string;
}

interface IProps {
  close: () => void;
}

const AddCategoryForm: FC<IProps> = ({ close }) => {
  const { t } = useTranslation(['common']);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  const { control, handleSubmit, reset } = useForm<IFormInput>();

  const [addCategory] = useMutation(ADD_NEW_COMPLIANCE_CATEGORY, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_COMPLIANCE_CATEGORIES,
      },
    ],
    onCompleted: () => {
      eventBus.emit('refetchCategory');
      return setTimeout(close);
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common:error'),
        description: 'Category creation failed!',
      });
    },
  });

  const onSubmit = async (values: IFormInput) => {
    const response = await addCategory({
      variables: {
        category: values.category,
      },
    });

    if (response.errors) {
      return Promise.reject(response.errors);
    }
  };

  return (
    <Controller
      name='category'
      control={control}
      rules={{
        required: 'Category name is required',

        validate: (value) => {
          if (!value?.trim()?.length) {
            return 'Please enter valid category name';
          }
        },
      }}
      render={({ field, fieldState }) => {
        const isDirty = fieldState.isDirty;
        return (
          <FormControl isInvalid={fieldState.invalid}>
            <FormInput
              size='lg'
              variant='auditOutline'
              placeholder='Type category name'
              {...field}
              paddingRight={isDirty ? '102px' : 4}
              rightElementProps={{
                width: 'auto',
                pr: '6px',
              }}
              rightIcon={
                isDirty ? (
                  <Flex minW='min-content' gap={1}>
                    <ActionButton
                      leftIcon={<CheckIcon />}
                      size='xs'
                      fontSize='12px'
                      colorScheme='blue'
                      actionFn={handleSubmit(onSubmit)}
                    >
                      Save
                    </ActionButton>
                    <IconButton
                      size='xs'
                      variant='ghost'
                      aria-label='clear'
                      icon={<CloseIcon />}
                      onClick={() => reset({ category: '' })}
                    />
                  </Flex>
                ) : null
              }
            />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default AddCategoryForm;
