import React, { FC, useEffect, useMemo, useState } from 'react';
import { Stack, Checkbox, Flex, Text, Button, Spacer } from '@chakra-ui/react';
import { Filter, SelectedMember } from '../../ComplianceListContainer';
import MembersFilter from './Filter';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { AuthRole } from 'sop-commons/src/client';
import { BoxHeader } from 'ui-components';
import { HeaderColors } from 'configs';

interface BaseFilterProps {
  initialFilter: Filter;
  onApply: (filter: Filter) => void;
  onClose: () => void;
}

interface UploadedBySectionProps {
  isExpanded: boolean;
  handleUsersToggle: () => void;
  children: React.ReactNode;
}
const UploadedBySection: FC<UploadedBySectionProps> = ({
  isExpanded,
  handleUsersToggle,
  children,
}) => {
  return (
    <Flex
      gap={2}
      bgColor={isExpanded ? '#EFEFEF' : 'transparent'}
      borderRadius={isExpanded ? '12px' : 'none'}
      p={isExpanded ? '10px 10px' : '0'}
      m={'10px'}
      border={isExpanded ? '1px solid #EFEFEF' : 'none'}
      flexDir='column'
      w={'full'}
    >
      <Flex justifyContent={'space-between'} flexDir={'row'} w={'full'}>
        <Text
          fontWeight={600}
          lineHeight='24px'
          fontSize='15px'
          color='#6F767E'
        >
          Uploaded by
        </Text>
        <Checkbox
          size='lg'
          iconSize={'24px'}
          onChange={handleUsersToggle}
          isChecked={isExpanded}
        />
      </Flex>
      {isExpanded && children}
    </Flex>
  );
};

const BaseFilter: FC<BaseFilterProps> = ({
  initialFilter,
  onApply,
  onClose,
}) => {
  const [localFilter, setLocalFilter] = useState<Filter>(initialFilter);
  const [isApplyDisabled, setIsApplyDisabled] = useState<boolean>(true);
  const entityData = useReactiveVar(usersEntityObj);
  const allMembers: SelectedMember[] = useMemo(() => {
    return entityData?.filter(
      (entity) =>
        entity?.type === 'user' &&
        [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(entity?.authRole)
    );
  }, [entityData]);

  useEffect(() => {
    // Compare localFilter with initialFilter to determine if Apply should be enabled
    const isEqual =
      localFilter.filterby.length === initialFilter.filterby.length &&
      localFilter.filterby.every((val) =>
        initialFilter.filterby.includes(val)
      ) &&
      localFilter.users.length === initialFilter.users.length &&
      localFilter.users.every((user, index) => {
        const initialUser = initialFilter.users[index];
        return user.eid === initialUser.eid && user.name === initialUser.name;
      });

    setIsApplyDisabled(isEqual);
  }, [localFilter, initialFilter]);

  useEffect(() => {
    if (
      localFilter.filterby.includes('users') &&
      localFilter.users.length === 0
    ) {
      setLocalFilter((prev) => ({
        ...prev,
        users: allMembers,
      }));
    }
  }, [localFilter.filterby, allMembers]);

  const handleExpiredToggle = () => {
    setLocalFilter((prev) => {
      const isExpired = prev.filterby.includes('expired');
      return {
        ...prev,
        filterby: isExpired
          ? prev.filterby.filter((item) => item !== 'expired')
          : [...prev.filterby, 'expired'],
      };
    });
  };

  const handleUsersToggle = () => {
    setLocalFilter((prev) => {
      const isUsers = prev.filterby.includes('users');
      return {
        ...prev,
        filterby: isUsers
          ? prev.filterby.filter((item) => item !== 'users')
          : [...prev.filterby, 'users'],
        users: isUsers ? [] : prev.users, // Clear users if unselecting
      };
    });
  };

  const handleApplyFilter = () => {
    let updatedFilter = { ...localFilter };

    if (
      updatedFilter.filterby.includes('users') &&
      updatedFilter.users.length === 0
    ) {
      updatedFilter.filterby = updatedFilter.filterby.filter(
        (item) => item !== 'users'
      );
    }

    onApply(updatedFilter);
    onClose();
  };

  const handleUsersChange = (selectedUsers: SelectedMember[]) => {
    setLocalFilter((prev) => ({
      ...prev,
      users: selectedUsers,
    }));
  };

  return (
    <Flex gap={4} flexDir='column'>
      <BoxHeader title='Filter' color={HeaderColors.Purple} />
      <Stack spacing={4} p='8px 10px'>
        <Flex justifyContent='space-between' alignItems='center'>
          <Text
            fontWeight={600}
            lineHeight='24px'
            fontSize='15px'
            color={
              localFilter.filterby.includes('expired') ? '#272B30' : '#6F767E'
            }
          >
            Expired documents only
          </Text>
          <Checkbox
            size='lg'
            onChange={handleExpiredToggle}
            isChecked={localFilter.filterby.includes('expired')}
            iconSize={'24px'}
          />
        </Flex>

        <UploadedBySection
          isExpanded={localFilter.filterby.includes('users')}
          handleUsersToggle={handleUsersToggle}
        >
          <MembersFilter
            selectedUsers={localFilter.users}
            onChange={handleUsersChange}
          />
        </UploadedBySection>

        <Button
          variant='solid'
          p='8px 16px'
          borderRadius='12px'
          bgColor='#2A85FF'
          _hover={{ bgColor: '#2A85FF' }}
          mt={2}
          disabled={isApplyDisabled}
          onClick={handleApplyFilter}
        >
          <Text color='#FCFCFC' fontWeight={700} fontSize='15px'>
            Apply
          </Text>
        </Button>
      </Stack>
    </Flex>
  );
};

export default BaseFilter;
