import {
  Box,
  Flex,
  Heading,
  ListItem,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';

const WithAuthorization: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>withAuthorization.tsx</Heading>
      <VStack align='flex-start'>
        <Heading as='h2'>Purpose</Heading>
        <Box>
          Offers a Higher-Order Component (HOC), withAuthorization, which wraps
          around other components to enforce authorization rules. It can
          redirect unauthorized users or execute callback functions as needed.
        </Box>
        <Heading as='h2'>Key Components</Heading>
        <UnorderedList pl={4}>
          <ListItem>
            <Flex gap={2}>
              <Box>AuthorizationType:</Box>
              <Flex flexDir='column'>
                <Box>
                  Extends Permission with an optional redirectTo property for
                  redirecting unauthorized users.
                </Box>
              </Flex>
            </Flex>
          </ListItem>
          <ListItem>
            <Box>
              <Box as='span'>withAuthorization:</Box>
              &nbsp;
              <Box as='span'>
                The HOC that takes authorization and an optional callback, and
                returns a new component that enforces the specified
                authorization rules.
              </Box>
            </Box>
          </ListItem>
        </UnorderedList>
        <Heading as='h2'>Example</Heading>
        <Box as='span'>
          Protecting a route component to allow only admins and redirect others
          to an unauthorized page:
        </Box>
        <SyntaxHighlighter language='typescript' style={materialDark}>
          {`import React from 'react';
import { withAuthorization } from './withAuthorization';
import { AuthRole } from './authorization.types';
import AdminDashboard from './AdminDashboard';

const AdminDashboardWithAuth = withAuthorization({
  permittedRoles: [AuthRole.ADMIN],
  redirectTo: '/unauthorized',
})(AdminDashboard);

export default AdminDashboardWithAuth;
`}
        </SyntaxHighlighter>
        <VStack align='flex-start'>
          In this example:
          <UnorderedList>
            <ListItem>
              Authorized Users: Users with the ADMIN auth role can access the
              AdminDashboard.
            </ListItem>
            <ListItem>
              Unauthorized Users: Users without the ADMIN auth role are
              redirected to /unauthorized.
            </ListItem>
          </UnorderedList>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default WithAuthorization;
