import { Box, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICompareVisibilityType } from 'pages/Chapters/CreateEditSubFolder/components/helper/visibilityHelper';
import { faCircleCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import React, { FC } from 'react';
import { IDetailedVisibility } from '../helper/useVisibilityStatusInfoLogic';
import { IVisibilityStatusInfoType } from '../VisibilityStatusInfo';
import useCombinedStore from 'zustandStore/store';

interface IProps {
  status: ICompareVisibilityType;
  childDetails: IDetailedVisibility | undefined;
  parentDetails: IDetailedVisibility | undefined;
  folderName: string;
  parentFolderName?: string;
  withReferenceTo?: IVisibilityStatusInfoType;
}

const SubFolderWRTFolder: FC<IProps> = ({
  status,
  childDetails,
  parentDetails,
  folderName,
  parentFolderName,
  withReferenceTo,
}) => {
  const { operationType } = useCombinedStore();
  console.log('Type Sub Folder : ', {
    status,
    childDetails,
    parentDetails,
    withReferenceTo,
    operationType,
  });
  if (
    operationType === 'add' &&
    (status === 'parentChildSameVisibleSameLength' ||
      status === 'parentPublicChildPublic')
  ) {
    return null;
  }

  const renderHandler = () => {
    if (parentDetails) {
      if (
        parentDetails?.locations?.length > 0 &&
        parentDetails?.roles?.length > 0 &&
        parentDetails?.users?.length > 0
      ) {
        return (
          <Flex flexDir='column'>
            <Flex gap='5px'>
              <Text>1.</Text>
              <Flex flexWrap='wrap' align='center'>
                People who have role{' '}
                <Text fontWeight={700} mx='5px'>
                  {parentDetails?.roles?.join(' ')}
                </Text>{' '}
                and are in location&nbsp;
                <Text fontWeight={700}>
                  {parentDetails?.locations
                    ?.map((loc) => loc?.name)
                    ?.join(' and ')}
                  .
                </Text>
              </Flex>
            </Flex>
            <Flex gap='5px'>
              <Text>2.</Text>
              <Text fontWeight={700}>
                {parentDetails?.users?.map((user) => user?.name)?.join(', ')}.
              </Text>
            </Flex>
          </Flex>
        );
      } else if (
        parentDetails?.locations?.length > 0 &&
        parentDetails?.roles?.length > 0
      ) {
        return (
          <Flex flexDir='column'>
            <Flex gap='5px'>
              <Flex flexWrap='wrap' align='center'>
                People who have role{' '}
                <Text fontWeight={700} mx='5px'>
                  {parentDetails?.roles?.join(' ')}
                </Text>{' '}
                and are in location&nbsp;
                <Text fontWeight={700}>
                  {parentDetails?.locations
                    ?.map((loc) => loc?.name)
                    ?.join(' and ')}
                  .
                </Text>
              </Flex>
            </Flex>
          </Flex>
        );
      } else if (
        parentDetails?.locations?.length > 0 &&
        parentDetails?.users?.length > 0
      ) {
        return (
          <Flex flexDir='column'>
            <Flex gap='5px'>
              <Text>1.</Text>
              <Flex flexWrap='wrap' align='center'>
                People who are in locations&nbsp;
                <Text fontWeight={700}>
                  {parentDetails?.locations
                    ?.map((loc) => loc?.name)
                    ?.join(' and ')}
                  .
                </Text>{' '}
              </Flex>
            </Flex>
            <Flex gap='5px'>
              <Text>2.</Text>
              <Text fontWeight={700}>
                {parentDetails?.users?.map((user) => user?.name)?.join(', ')}.
              </Text>
            </Flex>
          </Flex>
        );
      } else if (parentDetails?.locations?.length > 0) {
        return (
          <Flex flexDir='column'>
            <Flex gap='5px'>
              <Flex flexWrap='wrap' align='center'>
                People who are in locations&nbsp;
                <Text fontWeight={700}>
                  {parentDetails?.locations
                    ?.map((loc) => loc?.name)
                    ?.join(' and ')}
                  .
                </Text>{' '}
              </Flex>
            </Flex>
          </Flex>
        );
      } else if (
        parentDetails?.roles?.length > 0 &&
        parentDetails?.users?.length > 0
      ) {
        return (
          <Flex flexDir='column'>
            <Flex gap='5px'>
              <Text>1.</Text>
              <Flex flexWrap='wrap' align='center'>
                People who have role&nbsp;
                {parentDetails?.roles?.join(' ')}
              </Flex>
            </Flex>
            <Flex gap='5px'>
              <Text>2.</Text>
              <Text fontWeight={700}>
                {parentDetails?.users?.map((user) => user?.name)?.join(', ')}.
              </Text>
            </Flex>
          </Flex>
        );
      } else if (parentDetails?.roles?.length > 0) {
        return (
          <Flex flexDir='column'>
            <Flex gap='5px'>
              <Flex flexWrap='wrap' align='center'>
                People who have role&nbsp;
                {parentDetails?.roles?.join(' ')}
              </Flex>
            </Flex>
          </Flex>
        );
      } else if (parentDetails?.users?.length > 0) {
        return (
          <Flex gap='5px'>
            <Text fontWeight={700}>
              {parentDetails?.users?.map((user) => user?.name)?.join(', ')}.
            </Text>
          </Flex>
        );
      }
    }
  };
  switch (status) {
    case 'parentChildSameLengthDifferentVisibility':
      return (
        <Flex
          bg='#FFD88D80'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color='#FFAA00'
          />
          Contents of parent folder of this sub folder{' '}
          {folderName ? `(${folderName})` : null} are visible to more members
          than those mentioned above. Since the parent folder is not visible to
          all those members, they will find the subfolder via Search.
        </Flex>
      );
    case 'parentChildSameVisibleSameLength':
    case 'parentPublicChildPublic':
      return (
        <Flex
          bg='#B5E4CA99'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
        >
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            color='#83BF6E'
            style={{ marginTop: '5px' }}
          />
          <Text>
            The parent folder of this sub folder{' '}
            {folderName ? `(${folderName})` : null} share the same visibility as
            mentioned above.
          </Text>
        </Flex>
      );
    case 'parentLessVisibleThanChild':
      return (
        <Flex
          bg='#FFD88D80'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color='#FFAA00'
          />
          <Box>
            <Flex wrap='wrap' columnGap='4px'>
              {/* {wrappedText()} */}
              <Box as='span'>
                The parent folder{' '}
                {parentFolderName ? `(${parentFolderName})` : null} is visible
                to fewer members than those mentioned above.
              </Box>
              <Box>
                <Box as='span'>Therefore</Box>{' '}
                <Box as='span' style={{ fontWeight: 700 }}>
                  {folderName}
                </Box>
              </Box>
              <Box as='span'>
                will only be visible in the knowledge base to -
              </Box>
            </Flex>
            <Flex flexDir='column'>{renderHandler()}</Flex>
            <Flex>
              <Text>Everyone else will find it via search.</Text>
            </Flex>
          </Box>
        </Flex>
      );
    case 'parentMoreVisibleThanChild':
      return (
        <Flex
          bg='#B5E4CA99'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
          justify='flex-start'
        >
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            color='#83BF6E'
            style={{ marginTop: '5px' }}
          />
          <Flex flexDir='column'>
            <Text>
              The parent folder{' '}
              {parentFolderName ? `(${parentFolderName})` : null} is visible to
              more members than those mentioned above.
            </Text>
            <div style={{ width: '100%' }}>
              <span>Therefore,</span>
              <span style={{ fontWeight: '700', margin: '0px 5px' }}>
                {folderName || 'subfolder'}
              </span>
              will be visible to everyone mentioned above.
            </div>
          </Flex>
        </Flex>
      );
    case 'parentChildNoIntersection':
      return (
        <Flex
          bg='#FF6A5533'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
        >
          <FontAwesomeIcon icon={faXmark as IconProp} color='#FF6A55' />
          <Flex align='center' flexDir='column'>
            <div style={{ width: '100%' }}>
              <span>
                Visibility of parent folder{' '}
                {parentFolderName ? `(${parentFolderName})` : null} of this
                subfolder is limited. Thus
              </span>
              <span style={{ fontWeight: '700', margin: '0px 5px' }}>
                {parentFolderName}
              </span>
              will be not be visible to anyone for above visibility settings
            </div>
          </Flex>
        </Flex>
      );
    default:
      return (
        <Flex
          bg='#FF6A5533'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
        >
          <FontAwesomeIcon icon={faXmark as IconProp} color='#FF6A55' />
          <Flex align='center' flexDir='column'>
            <div style={{ width: '100%' }}>
              <span>
                Visibility of parent folder of this subfolder is limited. Thus
              </span>
              <span style={{ fontWeight: '700', margin: '0px 5px' }}>
                {parentFolderName}
              </span>
              will be not be visible to anyone for above visibility settings
            </div>
          </Flex>
        </Flex>
      );
  }
};

SubFolderWRTFolder.displayName = 'uc/VSI/c/SubFolderWRTFolder';

export default SubFolderWRTFolder;
