import React, { FC, useState } from 'react';
import { Box, Center, Flex, Spinner, useToast } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faFiles } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useReactToPrint } from 'react-to-print';

interface ExportInitialModalProps {
  exportRef: React.RefObject<HTMLElement>;
  onClose: () => void;
  onGenerateCustomReport: () => void;
}

const ExportInitialModal: FC<ExportInitialModalProps> = ({
  exportRef,
  onClose,
  onGenerateCustomReport,
}) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });

  const handlePrint = useReactToPrint({
    content: () => exportRef.current,
    pageStyle: `
      @page {
        size: A3 portrait; /* Set page size and orientation */
        margin-left: 1mm;
        margin-right: 1mm;
        margin-top: 1mm;
        margin-bottom: 0mm;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
        }
        /* Scale the content */
        .printable-content {
          transform: scale(0.50);
          width: 100%;
          height: auto;
          /* overflow: hidden; */
        }
        /* .location-leaderboard-container {
          margin-top: 10rem;
        } */
      }
    `,
    onBeforePrint: () => {
      setLoading(true);
    },
    onAfterPrint: () => {
      setLoading(false);
    },
    onPrintError: (error) => {
      setLoading(false);
      onClose();
      toast({
        status: 'error',
        title: 'Error',
        description: 'Snapshot could not be generated',
      });
      console.error('Print Error:', error);
    },
  });

  const handleExportPDF = () => {
    setLoading(true);
    handlePrint();
  };

  return (
    <Flex w='full' flexDir='column' gap={4}>
      <Center gap={4} py='128px' pos='relative'>
        {/* Export PDF Section */}
        <Center
          w='50%'
          bg='rgba(181, 228, 202, 0.3)'
          borderRadius='12px'
          p='20px 24px'
          flexDir='column'
          h='239px'
          gap={2}
          alignItems='flex-start'
          cursor='pointer'
          onClick={handleExportPDF}
          position='relative'
        >
          <Box mb={2}>
            <FontAwesomeIcon
              icon={faCamera as IconProp}
              color='rgba(131, 191, 110, 1)'
              size='3x'
            />
          </Box>
          <Box as='span' fontWeight={600} fontSize='18px'>
            Export Dashboard Snapshot (PDF)
          </Box>
          <Box as='span' fontWeight={500} color='rgba(111, 118, 126, 1)'>
            Download the current dashboard view
          </Box>

          {loading && (
            <Center
              position='absolute'
              top={0}
              left={0}
              w='100%'
              h='100%'
              bg='rgba(255, 255, 255, 0.8)'
              zIndex={1}
              borderRadius='12px'
              flexDir='column'
            >
              <Spinner size='lg' />
              <Box mt={2} fontSize='14px' fontWeight='500'>
                Generating PDF...
              </Box>
            </Center>
          )}
        </Center>

        {/* Generate Custom Report Section */}
        <Center
          w='50%'
          bg='rgba(202, 189, 255, 0.3)'
          borderRadius='12px'
          p='20px 24px'
          flexDir='column'
          h='239px'
          gap={2}
          alignItems='flex-start'
          cursor={loading ? 'not-allowed' : 'pointer'}
          onClick={!loading ? onGenerateCustomReport : undefined}
        >
          <Box mb={2}>
            <FontAwesomeIcon
              icon={faFiles as IconProp}
              color='rgba(142, 89, 255, 1)'
              size='3x'
            />
          </Box>
          <Box as='span' fontWeight={600} fontSize='18px'>
            Generate Custom Report (XLS)
          </Box>
          <Box as='span' fontWeight={500} color='rgba(111, 118, 126, 1)'>
            Choose specific metrics and export a detailed report
          </Box>
        </Center>
      </Center>
    </Flex>
  );
};

export default ExportInitialModal;
