import React, { FC } from 'react';
import NoCustomerFeedbackImg from 'assets/images/empty-state/no-customer-feedback.svg';
import { Box, Center, Flex, HStack, Image, VStack } from '@chakra-ui/react';

const NoCustomerFeedback: FC = () => {
  return (
    <Center h='30vh'>
      <HStack>
        <Image src={NoCustomerFeedbackImg} />
        <VStack align='flex-start'>
          <Box fontWeight={600} fontSize='20px'>
            People are yet to send in reviews!
          </Box>
          <Box color='rgba(111, 118, 126, 1)' fontWeight={500}>
            Once your customers start sending in reviews, they will be reflected
            here
          </Box>
        </VStack>
      </HStack>
    </Center>
  );
};

export default NoCustomerFeedback;
