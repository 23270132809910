import { Box, Flex, Heading, HStack, VStack } from '@chakra-ui/react';
import { CarryOutOutlined, FileOutlined } from '@ant-design/icons';
import { Tree, TreeDataNode } from 'antd';
import React, { FC, useState } from 'react';
import {
  ArchitectureOverview,
  EventBusEmitInfo,
  ExtensionsInfo,
  GithubInfo,
  IssueTrackInfo,
  ModalCreationInfo,
  ModuleCreationInfo,
  MVCInfo,
  NavigationInfo,
  RoutesInfo,
  ZustandEmitInfo,
  ZustandGlobalInfo,
  ZustandModuleInfo,
} from './components';
import { DataNode } from 'antd/es/tree';

export interface CustomTreeNode extends DataNode {
  component?: React.ReactNode;
}

const treeData: CustomTreeNode[] = [
  {
    title: 'Architecture',
    key: '0-0',
    icon: <CarryOutOutlined />,
    component: <ArchitectureOverview />,
    children: [
      {
        title: 'Extensions',
        key: 'extensions',
        icon: <FileOutlined />,
        switcherIcon: <FileOutlined />,
        component: <ExtensionsInfo />,
      },
      {
        title: 'Git/Github',
        key: 'git-github',
        icon: <FileOutlined />,
        switcherIcon: <FileOutlined />,
        component: <GithubInfo />,
      },
      {
        title: 'Issue Tracking Info',
        key: 'issue-tracking-info',
        icon: <FileOutlined />,
        switcherIcon: <FileOutlined />,
        component: <IssueTrackInfo />,
      },
      {
        title: 'Module Creation',
        key: 'module-creation',
        icon: <FileOutlined />,
        switcherIcon: <FileOutlined />,
        component: <ModuleCreationInfo />,
      },
      {
        title: 'Routes',
        key: 'routes-info',
        icon: <FileOutlined />,
        switcherIcon: <FileOutlined />,
        component: <RoutesInfo />,
      },
      {
        title: 'Navigation',
        key: 'navigation-info',
        icon: <FileOutlined />,
        switcherIcon: <FileOutlined />,
        component: <NavigationInfo />,
      },
      {
        title: 'MVC Architecture',
        key: 'mvc-architecture-info',
        icon: <FileOutlined />,
        switcherIcon: <FileOutlined />,
        component: <MVCInfo />,
      },
      {
        title: 'Modal Creation',
        key: 'modal-creation-info',
        icon: <FileOutlined />,
        switcherIcon: <FileOutlined />,
        component: <ModalCreationInfo />,
      },
      {
        title: 'Zustand Global Management',
        key: 'zustand-global-management-info',
        icon: <FileOutlined />,
        switcherIcon: <FileOutlined />,
        component: <ZustandGlobalInfo />,
      },
      {
        title: 'Zustand Module Management',
        key: 'zustand-module-management-info',
        icon: <FileOutlined />,
        switcherIcon: <FileOutlined />,
        component: <ZustandModuleInfo />,
      },
      {
        title: 'Event Emit with eventBus',
        key: 'event-emit-with-eventbus-info',
        icon: <FileOutlined />,
        switcherIcon: <FileOutlined />,
        component: <EventBusEmitInfo />,
      },
      {
        title: 'Event Emit with zustand',
        key: 'event-emit-with-zustand-info',
        icon: <FileOutlined />,
        switcherIcon: <FileOutlined />,
        component: <ZustandEmitInfo />,
      },
    ] as CustomTreeNode[],
  },
];

const ArchitectureInfo: FC = () => {
  const [showLine, setShowLine] = useState<boolean>(true);
  const [showLeafIcon, setShowLeafIcon] = useState<React.ReactNode>(true);
  const [selectedComponent, setSelectedComponent] =
    useState<React.ReactNode | null>(null);

  const onSelect = (selectedKeys: React.Key[], info: any) => {
    console.log('selected', selectedKeys, info);

    const node = info.node as CustomTreeNode;
    if (node && node.component) {
      setSelectedComponent(node.component);
    } else {
      setSelectedComponent(null);
    }
  };
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Delightree Frontend Architecture</Heading>
      <Box>
        Below is the frontend architecture tree on the left side and on the
        right side, the selected details will be shown.
      </Box>
      <HStack w='full' h='88vh' gap={4}>
        <Flex
          p={2}
          overflowY='scroll'
          h='88vh'
          border='1px solid gray'
          minW='300px'
          maxW='fit-content'
        >
          <Tree<CustomTreeNode>
            showLine={showLine ? { showLeafIcon } : false}
            showIcon={false}
            defaultExpandedKeys={['0-0']}
            treeData={treeData}
            onSelect={onSelect}
          />
        </Flex>
        <Flex
          h='full'
          flex={1}
          p={4}
          border='1px solid gray'
          overflow='scroll'
          maxH='87vh'
        >
          {selectedComponent ? (
            selectedComponent
          ) : (
            <div>Select a node to display its component.</div>
          )}
        </Flex>
      </HStack>
    </VStack>
  );
};

export default ArchitectureInfo;
