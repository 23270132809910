import {
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Box,
  Image,
  Text,
  Center,
  Tooltip,
} from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import {
  Searchbox,
  CategoryListing,
  ComplianceTable,
  VersionHistoryModal,
  DefaultTableEmptyState,
} from './components';
import { FilterChip, FilterContainer } from './components/Filter';

import { useLazyQuery } from '@apollo/client';

import {
  GET_COMPLIANCE_CATEGORIES,
  GET_COMPLIANCE_LISTING,
} from '../query/compliance.graphql';
import {
  EntityComplianceCategoriesType,
  TableDataType,
} from '../types/compliance.types';
import { AuthRole } from '../../../../authorization';

import { generateComplianceTableData, reverseArr } from './helper';
import { eventBus } from '../../../../shared/eventEmit';
import { useLatest, useUserDataSelector } from '../../../../hooks';
import { ReactComponent as LocationIcon } from 'assets/images/location-green.svg';
import { ReactComponent as DocumentIcon } from 'assets/images/nexus/compliance/document.svg';
import type { TableProps } from 'antd';
import { getImage } from 'utils';
import ComplianceUpdate from './components/ComplianceUpdate';
import DeleteDocumentModal from './components/DeleteDocumentModal';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownShortWide } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import EmptyStateCompliance from 'sub-components/nexus/Compliance/EmptyStateCompliance';
import Loader from 'sub-components/Loader';

export interface Filter {
  filterby: ('expired' | 'users')[];
  users: SelectedMember[];
}
export type SelectedMember = {
  eid: string;
  name: string;
};
const ComplianceListContainer: FC = () => {
  const [isRenameFieldVisible, setIsRenameFieldVisible] =
    useState<boolean>(false);

  const [complianceCategory, setComplianceCategory] = useState<
    EntityComplianceCategoriesType[]
  >([]);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(0);
  const [tableData, setTableData] = useState<TableDataType[]>([]);
  const [searchFieldText, setSearchFieldText] = useState<string>('');
  const [selectedExpiryFilter, setSelectedExpiryFilter] = useState<string>('');
  const [complianceCategoryId, setComplianceCategoryId] = useState<string>('');

  const [appliedFilter, setAppliedFilter] = useState<Filter>({
    filterby: [],
    users: [],
  });

  const { userAuthRole, loggedInUserLocations, loggedInUserId } =
    useUserDataSelector((state) => ({
      userAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
      loggedInUserId: state?.eid,
    }));
  let loggedInUserLocationIds = loggedInUserLocations?.map((loc) => loc?.eid);

  const [getComplianceCategories, { loading: complianceCategoryLoader }] =
    useLazyQuery(GET_COMPLIANCE_CATEGORIES, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        let sortedData = reverseArr(data?.EntityComplianceCategories);
        setComplianceCategory(sortedData);
      },
    });

  const [getComplianceListing, { loading: complianceLoader }] = useLazyQuery(
    GET_COMPLIANCE_LISTING,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setTotalRowsCount(data?.EntityCompliancePagination?.count);
        const complianceTableData = generateComplianceTableData(
          data?.EntityCompliancePagination?.items
        );
        setTableData(complianceTableData);
      },
    }
  );

  const getComplianceFilters = (
    pageIndex: number = 1,
    categoryId: string = '',
    sort: string = '_ID_DESC'
  ) => {
    let filter: {
      query?: string;
      locationId?: string;
      categoryId?: string;
      expiryDate?: string;
      createdBy?: string[];
      locationIds?: string[];
    } = {
      query: '',
    };
    if (appliedFilter.filterby.length > 0) {
      const { filterby, users } = appliedFilter;
      if (filterby.includes('expired')) {
        filter.expiryDate = new Date().toISOString();
      }
      if (users.length > 0) {
        const createdByUserIds = users.map((user) => user.eid);
        filter.createdBy = createdByUserIds;
      }
      if (filterby.includes('expired') && users.length > 0) {
        filter.expiryDate = new Date().toISOString();
        filter.createdBy = users.map((user) => user.eid);
      }
    }
    if (searchFieldText) {
      filter.query = searchFieldText;
    }

    if (categoryId) {
      setComplianceCategoryId(categoryId);
      filter.categoryId = categoryId;
    } else {
      setComplianceCategoryId('');
    }

    if (userAuthRole === AuthRole?.LOCATION_OWNER) {
      filter.locationIds = loggedInUserLocationIds;
    }

    return {
      filter: filter,
      sort: sort,
      page: pageIndex,
      perPage: 10,
    };
  };

  const handleExpirySort = (filterType: string) => {
    getComplianceListing({
      variables: getComplianceFilters(1, '', filterType),
    });
  };

  const columns: TableProps<TableDataType>['columns'] = [
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Location name
        </Text>
      ),
      dataIndex: 'location',
      key: 'location',
      render: (_, values) => {
        return (
          <Flex>
            <Flex
              gap={2}
              borderRadius={'10px'}
              p={'5px 10px'}
              minW={'fit-content'}
              bgColor={values?.location ? '#B5E4CA66' : '#EFEFEF'}
            >
              {values?.location && (
                <LocationIcon height={'24px'} width={'24px'} />
              )}
              <Tooltip label={values?.location ? values?.location : 'HQ'}>
                <Text isTruncated width={'150px'}>
                  {values?.location ? values?.location : 'HQ'}
                </Text>
              </Tooltip>
              {!values?.location && <Box height={'24px'} width={'24px'}></Box>}
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Document name
        </Text>
      ),
      dataIndex: 'title',
      key: 'title',
      render: (_, values) => {
        return (
          <Flex>
            <Flex alignItems={'center'} gap={2}>
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                borderRadius={'50%'}
                p={'5px'}
                bgColor={values?.location ? '#B5E4CA66' : '#EFEFEF'}
              >
                <DocumentIcon />
              </Flex>
              <Tooltip label={values?.title}>
                <Text width={'200px'} isTruncated>
                  {values?.title}
                </Text>
              </Tooltip>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Uploaded by
        </Text>
      ),
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
      render: (_, values) => {
        return (
          <Flex gap={2}>
            <Image
              borderRadius={'5px'}
              fit={'cover'}
              w={'24px'}
              h={'24px'}
              src={getImage(values?.uploadedBy[2], values?.uploadedBy[0])}
            />
            <Flex>
              <Tooltip label={values?.uploadedBy[0]}>
                <Text maxWidth={'100px'} isTruncated fontWeight={600}>
                  {values?.uploadedBy[0]}, &nbsp;
                </Text>
              </Tooltip>
              <Text color={'#6F767E'}> {values?.uploadedBy[1]}</Text>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Expiry date
          </Text>
          <Popover placement='bottom'>
            <PopoverTrigger>
              <Box>
                <FontAwesomeIcon
                  color={selectedExpiryFilter ? '#2A85FF' : '#6F767E'}
                  cursor={'pointer'}
                  icon={faArrowDownShortWide as IconProp}
                />
              </Box>
            </PopoverTrigger>
            <PopoverContent borderRadius={'10px'} w={'220px'}>
              <PopoverArrow />
              <PopoverBody>
                <Flex gap={2} flexDir={'column'}>
                  <Text
                    onClick={() => {
                      setSelectedExpiryFilter('');
                      handleExpirySort('_ID_DESC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                    color={'#6F767E'}
                  >
                    No sort
                  </Text>
                  <Text
                    backgroundColor={
                      selectedExpiryFilter === 'older' ? '#EFEFEF' : 'white'
                    }
                    color={
                      selectedExpiryFilter === 'older' ? 'black' : '#6F767E'
                    }
                    onClick={() => {
                      setSelectedExpiryFilter('older');
                      handleExpirySort('EXPIRYDATE_ASC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                  >
                    Oldest expiry date first
                  </Text>
                  <Text
                    backgroundColor={
                      selectedExpiryFilter === 'newest' ? '#EFEFEF' : 'white'
                    }
                    color={
                      selectedExpiryFilter === 'newest' ? 'black' : '#6F767E'
                    }
                    onClick={() => {
                      setSelectedExpiryFilter('newest');
                      handleExpirySort('EXPIRYDATE_DESC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                  >
                    Newest expiry date first
                  </Text>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      ),
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (_, values) => {
        return (
          <Flex flexDir={'column'}>
            <Text
              fontWeight={values?.isExpiring && 600}
              color={values?.isExpiring ? '#FF2047' : '#33383F'}
            >
              {values?.expiryDate && moment(values?.expiryDate)?.isValid()
                ? moment(values?.expiryDate).format('DD MMM YYYY')
                : '--'}
            </Text>
            <Text color={'#6F767E'} fontSize={'12px'}>
              {values?.expiryDate && moment(values?.expiryDate)?.isValid()
                ? moment(values?.expiryDate).format('hh:mm A')
                : '--'}
            </Text>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Actions
        </Text>
      ),
      key: 'action',
      render: (values) => {
        return (
          <Flex gap={2}>
            {userAuthRole === AuthRole?.SUPER_ADMIN ? (
              <>
                <VersionHistoryModal complianceId={values?.key} />
                <ComplianceUpdate complianceId={values.eid} />
                <DeleteDocumentModal
                  getComplianceFilters={getComplianceFilters}
                  getComplianceListing={getComplianceListing}
                  eid={values?.key}
                />
              </>
            ) : userAuthRole === AuthRole?.ADMIN &&
              loggedInUserId === values?.createdBy ? (
              <>
                <VersionHistoryModal complianceId={values?.key} />
                <ComplianceUpdate complianceId={values.eid} />
                <DeleteDocumentModal
                  getComplianceFilters={getComplianceFilters}
                  getComplianceListing={getComplianceListing}
                  eid={values?.key}
                />
              </>
            ) : (
              <>
                <VersionHistoryModal complianceId={values?.key} />
                <ComplianceUpdate disabled={true} complianceId={values.eid} />
                <DeleteDocumentModal
                  disabled={true}
                  getComplianceFilters={getComplianceFilters}
                  getComplianceListing={getComplianceListing}
                  eid={values?.key}
                />
              </>
            )}
          </Flex>
        );
      },
    },
  ];

  let locationOwnerColumns: TableProps<TableDataType>['columns'] = [
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Document name
        </Text>
      ),
      dataIndex: 'title',
      key: 'title',
      render: (_, values) => {
        return (
          <Flex>
            <Flex alignItems={'center'} gap={2}>
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                borderRadius={'50%'}
                p={'5px'}
                bgColor={values?.location ? '#B5E4CA66' : '#EFEFEF'}
              >
                <DocumentIcon />
              </Flex>
              <Text>{values?.title}</Text>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Location name
        </Text>
      ),
      dataIndex: 'location',
      key: 'location',
      render: (_, values) => {
        return (
          <Flex>
            <Flex
              gap={2}
              borderRadius={'10px'}
              p={'5px 10px'}
              minW={'fit-content'}
              bgColor={values?.location ? '#B5E4CA66' : '#EFEFEF'}
            >
              {values?.location && (
                <LocationIcon height={'24px'} width={'24px'} />
              )}
              <Text isTruncated width={'150px'}>
                {values?.location ? values?.location : 'HQ'}
              </Text>
              {!values?.location && <Box height={'24px'} width={'24px'}></Box>}
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Uploaded by
        </Text>
      ),
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
      render: (_, values) => {
        return (
          <Flex gap={2}>
            <Image
              borderRadius={'5px'}
              fit={'cover'}
              w={'24px'}
              h={'24px'}
              src={getImage(values?.uploadedBy[2], values?.uploadedBy[0])}
            />
            <Flex>
              <Text fontWeight={600}>{values?.uploadedBy[0]}, &nbsp;</Text>
              <Text color={'#6F767E'}> {values?.uploadedBy[1]}</Text>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: (
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={500} color={'#6F767E'}>
            Expiry date
          </Text>
          <Popover placement='bottom'>
            <PopoverTrigger>
              <Box>
                <FontAwesomeIcon
                  color={selectedExpiryFilter ? '#2A85FF' : '#6F767E'}
                  cursor={'pointer'}
                  icon={faArrowDownShortWide as IconProp}
                />
              </Box>
            </PopoverTrigger>
            <PopoverContent borderRadius={'10px'} w={'220px'}>
              <PopoverArrow />
              <PopoverBody>
                <Flex gap={2} flexDir={'column'}>
                  <Text
                    onClick={() => {
                      setSelectedExpiryFilter('');
                      handleExpirySort('_ID_DESC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                    color={'#6F767E'}
                  >
                    No sort
                  </Text>
                  <Text
                    backgroundColor={
                      selectedExpiryFilter === 'older' ? '#EFEFEF' : 'white'
                    }
                    color={
                      selectedExpiryFilter === 'older' ? 'black' : '#6F767E'
                    }
                    onClick={() => {
                      setSelectedExpiryFilter('older');
                      handleExpirySort('EXPIRYDATE_ASC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                  >
                    Oldest expiry date first
                  </Text>
                  <Text
                    backgroundColor={
                      selectedExpiryFilter === 'newest' ? '#EFEFEF' : 'white'
                    }
                    color={
                      selectedExpiryFilter === 'newest' ? 'black' : '#6F767E'
                    }
                    onClick={() => {
                      setSelectedExpiryFilter('newest');
                      handleExpirySort('EXPIRYDATE_DESC');
                    }}
                    cursor={'pointer'}
                    borderRadius='12px'
                    padding='6px 12px'
                    _hover={{
                      bg: '#EFEFEF',
                      color: 'black',
                    }}
                  >
                    Newest expiry date first
                  </Text>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      ),
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (_, values) => {
        return (
          <Flex flexDir={'column'}>
            <Text
              fontWeight={values?.isExpiring && 600}
              color={values?.isExpiring ? '#FF2047' : '#33383F'}
            >
              {moment(values?.expiryDate)?.isValid()
                ? moment(values?.expiryDate).format('DD MMM YYYY')
                : '--'}
            </Text>
            <Text color={'#6F767E'} fontSize={'12px'}>
              {moment(values?.expiryDate)?.isValid()
                ? moment(values?.expiryDate).format('hh:mm A')
                : '--'}
            </Text>
          </Flex>
        );
      },
    },
    {
      title: (
        <Text fontWeight={500} color={'#6F767E'}>
          Actions
        </Text>
      ),
      key: 'action',
      render: (values) => {
        return (
          <Flex gap={2}>
            <VersionHistoryModal complianceId={values?.key} />
          </Flex>
        );
      },
    },
  ];

  locationOwnerColumns =
    //@ts-ignore
    loggedInUserLocationIds?.length > 1
      ? locationOwnerColumns
      : locationOwnerColumns?.filter((item, i) => i !== 1);

  useEffect(() => {
    getComplianceCategories();
  }, []);

  useEffect(() => {
    getComplianceListing({
      variables: getComplianceFilters(),
    });
  }, [appliedFilter]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getComplianceListing({
        variables: getComplianceFilters(),
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchFieldText]);

  const filterRef = useLatest(getComplianceFilters);

  useEffect(() => {
    const catListener = eventBus.on('refetchCategory', () => {
      getComplianceCategories();
    });
    const listener = eventBus.on('refetchCompliance', () => {
      getComplianceListing({
        variables: filterRef.current(),
      });
    });
    return () => {
      listener();
      catListener();
    };
  }, []);

  return (
    <>
      {complianceCategory.length > 0 ? (
        <Flex gap={8} flexDir='column'>
          <Flex justifyContent='space-between' gap={4} alignItems='center'>
            <Searchbox
              searchFieldText={searchFieldText}
              setSearchFieldText={setSearchFieldText}
              placeholder='Search by document name'
            />

            <FilterContainer
              appliedFilter={appliedFilter}
              setAppliedFilter={setAppliedFilter}
            />
          </Flex>

          {(appliedFilter.filterby.includes('expired') ||
            appliedFilter.users.length > 0) && (
            <FilterChip
              appliedFilter={appliedFilter}
              setAppliedFilter={setAppliedFilter}
            />
          )}

          {(userAuthRole === AuthRole?.SUPER_ADMIN ||
            userAuthRole === AuthRole?.ADMIN) && (
            <CategoryListing
              userAuthRole={userAuthRole}
              loggedInUserId={loggedInUserId}
              isRenameFieldVisible={isRenameFieldVisible}
              setIsRenameFieldVisible={setIsRenameFieldVisible}
              complianceCategory={complianceCategory}
              getComplianceCategories={getComplianceCategories}
              getComplianceFilters={getComplianceFilters}
              getComplianceListing={getComplianceListing}
              complianceLoader={complianceLoader}
              complianceCategoryLoader={complianceCategoryLoader}
            />
          )}
          <ComplianceTable
            complianceCategoryId={complianceCategoryId}
            complianceLoader={complianceLoader}
            tableData={tableData}
            searchFieldText={searchFieldText}
            filters={appliedFilter?.filterby?.length > 0 ? true : false}
            totalRowsCount={totalRowsCount}
            getComplianceListing={getComplianceListing}
            getComplianceFilters={getComplianceFilters}
            columns={
              userAuthRole === AuthRole?.LOCATION_OWNER
                ? locationOwnerColumns
                : columns
            }
          />
        </Flex>
      ) : (
        <>
          {complianceCategoryLoader ? (
            <Flex justifyContent={'center'} height={'80vh'}>
              <Center>
                <Loader />
              </Center>
            </Flex>
          ) : (
            <EmptyStateCompliance userAuthRole={userAuthRole} />
          )}
        </>
      )}
    </>
  );
};

export default ComplianceListContainer;
