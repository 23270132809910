import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { BaseAiContent } from './BaseAiContent';
import ResponseFooter from './ResponseFooter';
import { AiResponse } from './ai-search.types';

const DEFAULT =
  'Our AI couldn’t find what you’re looking for. Try refining your search or feel free to reach out to our customer service for assistance.';

interface IProps {
  data?: AiResponse;
  onRegenerate?: () => void;
}

const NoResultFound: FC<IProps> = ({ data, onRegenerate }) => {
  return (
    <BaseAiContent flexDir='column'>
      <Box color='#1A1D1F' pb={6}>
        <Box fontSize='13px' fontWeight='600' pb={1}>
          Oops!
        </Box>
        <Box as='span' fontSize='12px' fontWeight='500'>
          {data?.response || DEFAULT}
        </Box>
      </Box>

      <ResponseFooter searchId={data?.eid} onRegenerate={onRegenerate} />
    </BaseAiContent>
  );
};

export default NoResultFound;
