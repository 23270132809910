import {
  Box,
  Flex,
  Heading,
  ListItem,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';

const CheckPermission: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>checkPermission.ts</Heading>
      <VStack align='flex-start'>
        <Heading as='h2'>Purpose</Heading>
        <Box>
          Contains the core logic to determine whether a user has the necessary
          permissions based on their roles and types. It evaluates both
          permitted and restricted roles and user types.
        </Box>
        <Heading as='h2'>Key Components</Heading>
        <UnorderedList pl={4}>
          <ListItem>
            <Flex gap={2}>
              <Box>
                {
                  'checkPermittedRoles<T>(userRole: T | T[], permittedRoles: T[]): boolean'
                }
                :
              </Box>
              <Flex flexDir='column'>
                <Box>
                  Checks if the user's role(s) include any of the permitted
                  roles.
                </Box>
              </Flex>
            </Flex>
          </ListItem>
          <ListItem>
            <Box>
              <Box as='span'>
                {
                  'checkRestrictedRoles<T>(userRole: T | T[], restrictedRoles: T[]): boolean'
                }
                :
              </Box>
              &nbsp;
              <Box as='span'>
                Checks if the user's role(s) include any of the restricted
                roles.
              </Box>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Box as='span'>
                {
                  'checkPermission(authUser: AuthUser, permission: Permission): boolean'
                }
                :
              </Box>
              &nbsp;
              <Box as='span'>
                Aggregates the above checks to determine overall permission
                status.
              </Box>
            </Box>
          </ListItem>
        </UnorderedList>
        <Heading as='h2'>Example</Heading>
        <Box as='span'>
          Determining if a user with roles ['admin', 'worker'] can access a
          component:
        </Box>
        <SyntaxHighlighter language='typescript' style={materialDark}>
          {`const permission: Permission = {
  permittedRoles: [AuthRole.ADMIN],
  restrictedRoles: [AuthRole.WORKER],
};

const canAccess = checkPermission(authUser, permission);
// canAccess would be false because 'worker' is a restricted role
`}
        </SyntaxHighlighter>
      </VStack>
    </VStack>
  );
};

export default CheckPermission;
