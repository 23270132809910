import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import NewDocumentContent from './NewDocumentContent';
import UpdateCompliance from './UpdateCompliance';

interface IProps {
  complianceId?: string;
}

type ComplianceUpload = (props?: IProps) => void;

export const useComplianceUpload = (): ComplianceUpload => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(({ complianceId } = {}) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex justify='space-between' gap={3}>
          <BoxHeader
            title={complianceId ? 'Update document' : 'Add Document'}
            color={HeaderColors.Green}
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: complianceId ? (
        <UpdateCompliance complianceId={complianceId} />
      ) : (
        <NewDocumentContent />
      ),
      isCentered: false,
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '12px',
        minW: '660px',
      },
      footer: null,
    });
  }, []);
};
