import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const SubComponentsFolder: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Sub Components Info</Heading>
      <Box>
        This folder contains all the modules and all components for that module.
        This folder is used along with pages folder where pages keeps root
        container and all components are inside this folder.
      </Box>
    </VStack>
  );
};

export default SubComponentsFolder;
