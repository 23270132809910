import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const ModuleCreationInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Module Creation Info</Heading>
      <Box fontSize='16px'>
        To create a module, routes process is given below. But first you have to
        look at 'pages' and 'sub-components' folders. In the 'pages' folder,
        Page component for the module will be created (name should be in
        camelCase) whose role is just to wrap all the components that will be
        present in the 'sub-components' folder (Kebab case).
      </Box>
    </VStack>
  );
};

export default ModuleCreationInfo;
