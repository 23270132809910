import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const AtomsInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Atoms Folder Overview</Heading>
      <Box fontSize='16px'>
        This folder contains atomic level components which can be used in bigger
        components or modules.
      </Box>
    </VStack>
  );
};

export default AtomsInfo;
