import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { Flex, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import Image from '../../../../ui-components/Image';
import { LocationsEntity } from '../team.types';
import LocationListModal from './LocationListModal';
import locationIcon from '../../../../assets/images/location-green.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 25px;

  .avatar-list {
    position: relative;
    display: flex;
    width: max-content;

    .training-item {
      border-radius: 7px;
      padding: 5px 10px;

      background: rgba(255, 188, 153, 0.4);
      max-width: 150px;
      overflow: hidden;
    }

    img {
      width: 24px;
      height: 24px;
      border-radius: 5px;
    }
  }

  .more-button {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #000000;
    border-radius: 7px;
    border: 1px solid #dddddd;
    padding: 5px 10px;
    cursor: pointer;
  }
`;

interface IProps {
  value?: LocationsEntity[];
  memberName?: string;
  background?: string;
}

const LocationCell: FC<IProps> = ({ value = [], background, memberName }) => {
  const history = useHistory();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const [data, moreLocations] = useMemo(() => {
    return [value.slice(0, 1), value.slice(1)];
  }, [value]);

  const onClick = (location: LocationsEntity) => {
    history.push(`/locations/${location?.eid}`);
  };

  return (
    <Wrapper>
      <Tooltip
        label={data.map((it) => it.name)?.join(', ')}
        hasArrow
        p='8px 12px'
        borderRadius='8px'
      >
        <div className='avatar-list'>
          {data.map((location) => {
            return (
              <Flex
                className='training-item'
                key={location.eid}
                gap='7px'
                align='center'
                cursor='pointer'
                bg={background}
                onClick={() => onClick(location)}
              >
                <Image width={24} height={24} src={locationIcon} />
                <Text isTruncated maxW='100px' fontWeight='600' color='#6F767E'>
                  {location.name}
                </Text>
              </Flex>
            );
          })}
        </div>
      </Tooltip>
      {moreLocations.length > 0 && (
        <Tooltip
          label={moreLocations.map((it) => it.name)?.join(', ')}
          hasArrow
          p='8px 12px'
          borderRadius='8px'
        >
          <div className='more-button' onClick={onOpen}>
            + {moreLocations.length}
          </div>
        </Tooltip>
      )}
      <LocationListModal
        isOpen={isOpen}
        onClose={onClose}
        locations={value}
        memberName={memberName}
        onClick={onClick}
      />
    </Wrapper>
  );
};

LocationCell.defaultProps = {
  value: [],
};

export default LocationCell;
