import React, { MouseEvent, useState } from 'react';
import { useMountedRef } from 'hooks';
import { isThenable } from 'utils';
import { Button, ButtonProps } from 'atoms';
import { Any } from '../../types';
import { forwardRef } from '@chakra-ui/react';

interface IProps extends Omit<ButtonProps, 'onClick'> {
  close?: Function;
  actionFn?: (...args: Any[]) => Any | PromiseLike<Any>;
}

const ActionButton = forwardRef<IProps, 'button'>(
  ({ children, close, actionFn, isLoading: nativeLoad, ...props }, ref) => {
    const [isLoading, setIsLoading] = useState(false);

    const mounted = useMountedRef();

    const internalClose = (...args: Any[]) => {
      close?.(...args);
    };

    const handlePromise = (returnValue: PromiseLike<Any>) => {
      if (!isThenable(returnValue)) {
        return;
      }
      setIsLoading(true);
      returnValue?.then(
        (...args: Any[]) => {
          mounted.current && setIsLoading(false);
          internalClose(...args);
        },
        (e: Error) => {
          mounted.current && setIsLoading(false);
          // eslint-disable-next-line no-console
          console.error(e);
        }
      );
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onClick = (_: MouseEvent<HTMLButtonElement>) => {
      if (!actionFn) {
        internalClose();
        return;
      }
      let returnValue;
      if (actionFn.length) {
        returnValue = actionFn(close);
      } else {
        returnValue = actionFn();
        if (!returnValue) {
          internalClose();
          return;
        }
      }
      handlePromise(returnValue);
    };

    const loading = isLoading || nativeLoad;

    return (
      <Button
        ref={ref}
        {...props}
        isLoading={loading}
        onClick={loading ? undefined : onClick}
      >
        {children}
      </Button>
    );
  }
);

ActionButton.displayName = 'ui-components/Confirm/ActionButton';

export default ActionButton;
