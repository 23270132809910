import React, { FC, useEffect, useRef } from 'react';
import { Flex, usePopoverContext } from '@chakra-ui/react';
import { SelectOption } from '../../../../../../../atoms';
import AddCategory from './AddCategory';
import CategoryItem from './CategoryItem';

interface IProps {
  options?: SelectOption[];
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
}

const CategoryLists: FC<IProps> = ({ options, value, onChange }) => {
  const { onClose } = usePopoverContext();

  const selectedRef = useRef<HTMLDivElement>(null);

  const onClick = (option: SelectOption) => {
    setTimeout(onClose);
    onChange?.(option);
  };

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      });
    }
  }, []);

  return (
    <Flex role='list' flexDir='column' gap='4px' maxH='300px' overflowY='auto'>
      <Flex padding='2px'>
        <AddCategory />
      </Flex>
      {options?.map((option) => (
        <CategoryItem
          key={option.value}
          option={option}
          onClick={() => onClick(option)}
          isSelected={value?.value === option?.value}
          ref={value?.value === option?.value ? selectedRef : undefined}
        />
      ))}
    </Flex>
  );
};

export default CategoryLists;
