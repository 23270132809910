import { AuthRole, AuthUser } from '../authorization';

/**
 * This file store the feature access check function
 *
 */

export const canAccessAiSearch = (authUser: AuthUser): boolean => {
  return authUser?.entity?.features?.aiSearch;
};

export const canAccessAudit = (authUser: AuthUser): boolean => {
  return (
    authUser?.entity?.features?.audit && authUser?.authRole !== AuthRole.WORKER
  );
};

export const canAccessChat = (authUser: AuthUser): boolean => {
  return authUser?.entity?.features?.chat;
};

export const canAccessNexus = (authUser: AuthUser): boolean => {
  return (
    (authUser?.authRole === AuthRole?.SUPER_ADMIN ||
      authUser?.authRole === AuthRole?.ADMIN ||
      authUser?.authRole === AuthRole?.LOCATION_OWNER) &&
    authUser?.type === 'user'
  );
};
