import { useLazyQuery } from '@apollo/client';
import {
  INTERNAL_LOCATION_PHOTOS,
  LOCATION_GOOGLE_PHOTOS,
} from '../api/api.graphql';
import {
  InternalPhotosResponse,
  InternalPhotosVariables,
  LocationGooglePhotosResponse,
  LocationGooglePhotosVariables,
} from '../types/api.types';

export const useService = () => {
  const [locPhotos, locPhotosRes] = useLazyQuery<
    LocationGooglePhotosResponse,
    LocationGooglePhotosVariables
  >(LOCATION_GOOGLE_PHOTOS, {
    fetchPolicy: 'network-only',
  });

  const [internalPhotos, internalPhotosRes] = useLazyQuery<
    InternalPhotosResponse,
    InternalPhotosVariables
  >(INTERNAL_LOCATION_PHOTOS, {
    fetchPolicy: 'network-only',
  });

  return {
    locPhotos: {
      locPhotos,
      locPhotosRes,
    },
    internalPhotos: {
      internalPhotos,
      internalPhotosRes,
    },
  };
};
