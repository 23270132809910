import React, { FC, useMemo } from 'react';
import { Button, Center, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAdd, faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HeaderColors } from '../../../../../../../configs';
import { BoxHeader } from '../../../../../../../ui-components';
import { useQuery } from '@apollo/client';
import {
  GetExpiringDocsInputType,
  GetExpiringDocsResponseType,
  GET_EXPIRING_DOCS,
} from './query/documents.graphql';
import Loader from 'sub-components/Loader';
import { isComplianceDocumentExpiring } from 'sub-components/nexus/Compliance/Listing/helper';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';
import { ArrowForwardIcon } from '@chakra-ui/icons';

interface NoDocsExpiringProps {
  totalDocs: number | undefined;
}
const NoDocsExpiring: FC<NoDocsExpiringProps> = ({ totalDocs }) => {
  const userAuthRole = useUserDataSelector((state) => state.authRole);
  const { t } = useTranslation(['nexus', 'common']);
  const history = useHistory();
  return (
    <Flex flexDir='column' gap={4}>
      <Flex
        gap={2}
        justifyContent={'flex-start'}
        alignItems={'center'}
        fontSize={'15px'}
        color={'#6F767E'}
        fontWeight={600}
        mb={1}
        mt={4}
      >
        {totalDocs && totalDocs !== 0
          ? t('nexus:documents.noDocsExp')
          : `${t('nexus:documents.add')}`}
        {totalDocs && totalDocs !== 0 ? (
          <FontAwesomeIcon icon={faCheckCircle as IconProp} />
        ) : (
          <></>
        )}
      </Flex>
      <Button
        variant={
          userAuthRole === AuthRole.LOCATION_OWNER && totalDocs === 0
            ? 'outline'
            : 'solid'
        }
        fontWeight={700}
        isFullWidth
        onClick={() =>
          userAuthRole === AuthRole.LOCATION_OWNER
            ? totalDocs && totalDocs !== 0
              ? history.push('/nexus/documents')
              : null
            : history.push('/nexus/documents')
        }
        leftIcon={
          userAuthRole === AuthRole.LOCATION_OWNER ? (
            <></>
          ) : totalDocs && totalDocs !== 0 ? (
            <></>
          ) : (
            <FontAwesomeIcon icon={faAdd as IconProp} />
          )
        }
        cursor={
          userAuthRole === AuthRole.LOCATION_OWNER && totalDocs === 0
            ? 'not-allowed'
            : 'pointer'
        }
        color={'#1A1D1F'}
        borderColor={
          userAuthRole === AuthRole.LOCATION_OWNER && totalDocs === 0
            ? '#CABDFF'
            : 'none'
        }
        rightIcon={
          totalDocs && totalDocs !== 0 ? (
            <ArrowForwardIcon fontSize='20px' />
          ) : (
            <></>
          )
        }
      >
        {userAuthRole === AuthRole.LOCATION_OWNER && totalDocs === 0
          ? `Coming soon`
          : totalDocs && totalDocs !== 0
          ? `${t('common:viewAll')}`
          : t('nexus:compliances.buttons.addDoc')}
      </Button>
    </Flex>
  );
};

interface ExpiringSoonStateProps {
  docs: string[];
}
const ExpiringSoonState: FC<ExpiringSoonStateProps> = ({ docs }) => {
  const { t } = useTranslation(['common', 'nexus']);
  const history = useHistory();
  const renderDocsMsg = React.useCallback(() => {
    if (docs.length === 1) {
      return `${docs[0]} is expiring soon`;
    } else if (docs.length === 2) {
      return `${docs[0]}, ${docs[1]} are expiring soon`;
    } else if (docs.length > 2) {
      return `${docs[0]}, ${docs[1]}  +${
        docs.length - 2
      } more are expiring soon`;
    }
  }, [docs]);
  const renderMsg = renderDocsMsg();
  return (
    <Center w='full' flexDir='column' gap={4} h='full'>
      <Flex flexDir='column' gap={4} w={'full'} h='full' mt={4}>
        <Flex
          gap={2}
          justifyContent={'flex-start'}
          fontSize={'15px'}
          color={'#6F767E'}
          fontWeight={600}
          mb={1}
        >
          {renderMsg}
        </Flex>
        <Button
          variant={'solid'}
          fontWeight={700}
          isFullWidth
          onClick={() => history.push('/nexus/documents')}
          color={'#1A1D1F'}
          rightIcon={<ArrowForwardIcon fontSize='20px' />}
        >
          {`${t('common:viewAll')}`}
        </Button>
      </Flex>
    </Center>
  );
};
const AllDocuments: FC = () => {
  const { userAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      userAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
    })
  );
  const loggedInUserLocationIds = loggedInUserLocations?.map((loc) => loc?.eid);
  const getComplianceFilters = () => {
    let filter: {
      query?: string;
      locationId?: string;
      categoryId?: string;
      expiryDate?: string;
      createdBy?: string[];
      locationIds?: string[];
    } = {};

    if (userAuthRole === AuthRole?.LOCATION_OWNER) {
      filter.locationIds = loggedInUserLocationIds;
    }

    return {
      filter: filter,
      perPage: 1000,
    };
  };
  const { data: complianceDocs, loading } = useQuery<
    GetExpiringDocsResponseType,
    GetExpiringDocsInputType
  >(GET_EXPIRING_DOCS, {
    fetchPolicy: 'network-only',
    variables: getComplianceFilters(),
  });

  const expiringSoonDocs = useMemo(() => {
    const docs = complianceDocs?.EntityCompliancePagination.items
      .filter((item) => {
        if (
          isComplianceDocumentExpiring(
            item.expiryDate,
            item.reminder,
            'expiringSoon'
          )
        ) {
          return item;
        }
      })
      .map((soonExpDoc) => soonExpDoc.title);
    return docs || [];
  }, [complianceDocs]);
  return (
    <Flex flexDir={'column'}>
      <Flex alignItems={'flex-start'}>
        <BoxHeader title='All Documents' color={HeaderColors.Purple} />
      </Flex>
      {loading ? (
        <Center>
          <Loader />
        </Center>
      ) : expiringSoonDocs.length > 0 ? (
        <ExpiringSoonState docs={expiringSoonDocs} />
      ) : (
        <NoDocsExpiring
          totalDocs={complianceDocs?.EntityCompliancePagination?.items.length}
        />
      )}
    </Flex>
  );
};

export default AllDocuments;
