import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AssetsPage from './AssetsPage';

interface IProps {}

const AssetRoute: FC<IProps> = () => {
  const path = useRouteMatch().path;

  return (
    <Switch>
      <Route exact path={path} component={AssetsPage} />
      <Route path={`${path}/*`} component={AssetsPage} />
    </Switch>
  );
};

export default AssetRoute;
