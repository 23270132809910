import { Badge, HStack, Tab, Text } from '@chakra-ui/react';
import React from 'react';

interface CustomTabProps {
  label: string;
  minWidth: string;
  hasNewBadge?: boolean;
}

const CustomTab: React.FC<CustomTabProps> = ({
  label,
  minWidth,
  hasNewBadge,
}) => {
  const adjustedMinWidth = hasNewBadge ? `calc(${minWidth} + 58px)` : minWidth;

  return (
    <Tab
      minW={adjustedMinWidth}
      borderRadius='8px'
      py='8px'
      px='16px'
      bg='#FCFCFC'
      color='#6F767E'
      _selected={{
        bg: '#EFEFEF',
        color: 'black',
      }}
      fontSize='15px'
      fontWeight='600'
      display='flex'
      alignItems='center'
      justifyContent='center'
      whiteSpace='nowrap'
    >
      <HStack spacing={2}>
        <Text>{label}</Text>
        {hasNewBadge && (
          <Badge
            bg='#CABDFF'
            borderRadius='6px'
            fontSize='12px'
            fontWeight='bold'
            px='8px'
            py='2px'
          >
            NEW!
          </Badge>
        )}
      </HStack>
    </Tab>
  );
};
export default CustomTab;
