import { Box, Flex, HStack } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { RatingData } from '../types';

interface SingleLocationCardProps {
  location: RatingData;
}

const SingleLocationCard: FC<SingleLocationCardProps> = ({ location }) => {
  return (
    <HStack
      align='center'
      bg='rgba(244, 244, 244, 1)'
      borderRadius='12px'
      p='16px 20px'
      justify='space-between'
      h='110px'
    >
      <Box fontSize='16px' fontWeight={600}>
        {location?.location}
      </Box>
      <Flex
        bg='rgba(252, 252, 252, 1)'
        border='1px solid rgba(239, 239, 239, 1)'
        borderRadius='12px'
        p='12px 20px'
        flexDir='column'
        w='150px'
      >
        <Box fontSize='13px' fontWeight={500} color='rgba(51, 56, 63, 1)'>
          Google Rating
        </Box>
        <Flex align='center' gap='5px'>
          <Box fontSize='20px' fontWeight={600}>
            {location?.googleRating}
          </Box>
          <FontAwesomeIcon
            icon={faStar as IconProp}
            color='rgba(244, 228, 105, 1)'
          />
        </Flex>
      </Flex>
    </HStack>
  );
};

export default SingleLocationCard;
