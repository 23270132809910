import React, { FC } from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';

interface IProps {}

const AssetBreadcrumb: FC<IProps> = () => {
  const history = useHistory();

  const data = history.location.pathname.split('/').filter(Boolean);

  const navigateTo = (index: number) => {
    if (data.length === index + 1) {
      return null;
    }

    history.replace('/' + data.slice(0, index + 1).join('/'));
  };

  if (data.length < 2) {
    return null;
  }

  return (
    <div>
      <Breadcrumb
        spacing='8px'
        separator={<ChevronRightIcon color='gray.500' />}
      >
        {data.map((page, index) => {
          return (
            <BreadcrumbItem
              key={index}
              isCurrentPage={data.length === index + 1}
            >
              <BreadcrumbLink as='div' onClick={() => navigateTo(index)}>
                {page}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default AssetBreadcrumb;
