import { useMemo } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import {
  MemberVariable,
  PERFORMANCE_MEMBER,
  PerformanceEntity,
  PerformanceResponse,
} from './performance.graphql';

import { toArray } from '../../../../../utils/utils';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';
import { usersEntityObj } from 'sub-components/Header';

interface ReturnedData {
  performanceEntities: PerformanceEntity[];
  loading: boolean;
}

export const usePerformanceOverViewData = (): ReturnedData => {
  const entityId = useReactiveVar(userObj)?.entityId;
  const entityData = useReactiveVar(usersEntityObj);
  const { loggedInUserAuthRole, loggedInUserLocations } = useUserDataSelector(
    (state) => ({
      loggedInUserAuthRole: state?.authRole,
      loggedInUserLocations: state?.locations,
    })
  );

  const { loading, data } = useQuery<PerformanceResponse, MemberVariable>(
    PERFORMANCE_MEMBER,
    {
      fetchPolicy: 'network-only',
      skip: !entityId,
      variables: {
        entityId: entityId,
        status: ['active', 'inactive'],
        type: ['user'],
      },
    }
  );

  const performanceEntities = useMemo(() => {
    if (!data) return [];

    let entityUsers = toArray(data.EntityUser);

    const isLocationOwner = loggedInUserAuthRole === AuthRole.LOCATION_OWNER;

    if (isLocationOwner && loggedInUserLocations) {
      const loLocationIds = loggedInUserLocations.map((loc) => loc.eid);

      entityUsers = entityUsers.filter((user) =>
        user.locations.some((loc) => loLocationIds.includes(loc.eid))
      );
    }

    const userAnalytics = data.TaskSupervisedUserAnalytics || {};

    return entityUsers
      .reduce<PerformanceEntity[]>((acc, value) => {
        const analytic = userAnalytics[value.eid];
        if (analytic) {
          acc.push({
            ...value,
            ...analytic,
            completionRate: analytic.total
              ? Math.round((analytic.complete / analytic.total) * 100)
              : 0,
          });
        }

        return acc;
      }, [])
      .sort((a, b) => b.completionRate - a.completionRate);
  }, [data, entityData, loggedInUserLocations, loggedInUserAuthRole]);

  return {
    performanceEntities,
    loading,
  };
};
