import { Center, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import DashboardContainer from 'sub-components/DashboardContainer';
import Loader from 'sub-components/Loader';
import LeftSection from './components/LeftSection';
import RightSection from './components/RightSection';
import { NexusBoardProvider } from './store/context';
import { useControl } from './layers';

const NexusBaseRoot: FC = () => {
  const { exportRef, leaderboardLoading, locationDetailsLoading } =
    useControl();

  const isLoading = leaderboardLoading || locationDetailsLoading;

  return (
    <>
      {isLoading ? (
        <Center h='70vh'>
          <Loader />
        </Center>
      ) : (
        <Flex id='NDB' w='full' gap={4} ref={exportRef}>
          <Flex w='70%'>
            <LeftSection exportRef={exportRef} />
          </Flex>
          <Flex w='30%'>
            <RightSection />
          </Flex>
        </Flex>
      )}
    </>
  );
};

const NexusBase: FC = () => {
  return (
    <NexusBoardProvider>
      <NexusBaseRoot />
    </NexusBoardProvider>
  );
};

NexusBase.displayName = 'sub-components/nexus/NexusBase';
NexusBaseRoot.displayName = 'sub-components/nexus/NexusBase/NexusBaseRoot';

export default NexusBase;
