import moment from 'moment';
export function getDateRange(
  period:
    | 'lastMonth'
    | 'last3Months'
    | 'last6Months'
    | 'last12Months' = 'lastMonth'
) {
  let startDate: string;
  let endDate: string;

  switch (period) {
    case 'last3Months':
      startDate = moment
        ?.utc()
        .subtract(3, 'months')
        .startOf('month')
        .toISOString();
      endDate = moment
        ?.utc()
        .subtract(1, 'months')
        .endOf('month')
        .toISOString();
      break;
    case 'last6Months':
      startDate = moment
        ?.utc()
        .subtract(6, 'months')
        .startOf('month')
        .toISOString();
      endDate = moment
        ?.utc()
        .subtract(1, 'months')
        .endOf('month')
        .toISOString();
      break;
    case 'last12Months':
      startDate = moment
        ?.utc()
        .subtract(12, 'months')
        .startOf('month')
        .toISOString();
      endDate = moment
        ?.utc()
        .subtract(1, 'months')
        .endOf('month')
        .toISOString();
      break;
    case 'lastMonth':
    default:
      startDate = moment
        ?.utc()
        .subtract(1, 'months')
        .startOf('month')
        .toISOString();
      endDate = moment
        ?.utc()
        .subtract(1, 'months')
        .endOf('month')
        .toISOString();
      break;
  }

  return { startDate, endDate };
}
