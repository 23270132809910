import { LOCATIONS_PAGE } from 'appRoutes';
import { Button, FlexCard } from 'atoms';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'routes';
import EmptyState from 'sub-components/EmptyState';
import useCombinedStore from 'zustandStore/store';
import { useAddLocation } from './useAddLocation';

const SingleLocationState: FC = () => {
  const addLocation = useAddLocation();
  const history = useHistory();
  const clearEvent = useCombinedStore((state) => state.clearEvent);
  const bulkUploadSuccessEvent = useCombinedStore(
    (state) => state.events['bulkUploadLocationsModal']['onBulkUploadSuccess']
  );
  const bulkUploadErrorEvent = useCombinedStore(
    (state) => state.events['bulkUploadLocationsModal']['onBulkUploadError']
  );
  useEffect(() => {
    if (bulkUploadSuccessEvent) {
      clearEvent('bulkUploadLocationsModal', 'onBulkUploadSuccess');
      history.push({
        pathname: LOCATIONS_PAGE,
      });
    }
    if (bulkUploadErrorEvent) {
      clearEvent('bulkUploadLocationsModal', 'onBulkUploadError');
    }
  }, [bulkUploadSuccessEvent, bulkUploadErrorEvent]);
  return (
    <FlexCard alignItems='center' justifyContent='center' p='90px'>
      <EmptyState
        image='SingleLocation'
        title='No new locations added'
        description='Add locations to view and track performance of all your locations in one place!'
        showButton
        buttonProps={
          <Button
            variant='solid'
            colorScheme='blue'
            size='sm'
            onClick={() => addLocation({})}
          >
            Start adding locations now
          </Button>
        }
      />
    </FlexCard>
  );
};

export default SingleLocationState;
