// components/DateRangeSelector.tsx
import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { SelectOption } from '../types';

interface DateRangeSelectorProps {
  rangeOptions: SelectOption[];
  selectedDataRange: SelectOption;
  setSelectedDataRange: (option: SelectOption) => void;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  rangeOptions,
  selectedDataRange,
  setSelectedDataRange,
}) => {
  return (
    <Flex align='center' gap='10px'>
      {rangeOptions.map((option) => (
        <Box
          key={option.value}
          borderRadius='12px'
          p='6px 8px'
          fontWeight={500}
          cursor='pointer'
          bg={
            selectedDataRange.value === option.value
              ? 'rgba(29, 25, 43, 0.12)'
              : 'rgba(232, 222, 248, 1)'
          }
          onClick={() => setSelectedDataRange(option)}
        >
          {option.label}
        </Box>
      ))}
    </Flex>
  );
};

export default DateRangeSelector;
