import React, { FC, useRef } from 'react';
import { Flex } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { FormInput } from '../../atoms';
import { ActionButton } from '../../ui-components';
import { Authorize } from '../../authorization';
import { canAccessAiSearch } from '../../configs';

import SearchTypeSelect from './SearchTypeSelect';
import { AiSearchContent } from './ai-search';
import { AiSearchRef } from './ai-search/AiSearchContent';

const Wrapper = styled.div`
  img {
    margin: unset;
  }
  ul {
    padding-left: initial;
  }
`;

interface IProps {
  searchQuery?: string;
  onChange?: (value: string) => void;

  searchType?: string;
  onTypeChange?: (value: string) => void;
}

const AI_SEARCH_ID = 'ai-search-start';

const AiSearchModalData: FC<IProps> = ({
  searchQuery,
  onChange,
  searchType,
  onTypeChange,
}) => {
  const { t } = useTranslation(['common', 'header']);

  const aiSearchRef = useRef<AiSearchRef>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onQuerySelect = (value: string) => {
    onChange?.(value);

    const elm = document.getElementById(AI_SEARCH_ID);

    if (elm) {
      setTimeout(() => {
        elm.click();
      });
    }
  };

  return (
    <Wrapper>
      <Flex flexDir='column' px='32px' gap={3}>
        <FormInput
          size='lg'
          placeholder={t('header:jump_to')}
          value={searchQuery}
          onChange={(e) => onChange?.(e?.target?.value)}
          variant='filled'
          autoFocus
          rightIcon={
            <ActionButton
              ref={buttonRef}
              id={AI_SEARCH_ID}
              padding='0'
              size='sm'
              aria-label='next'
              variant='solid'
              colorScheme='blue'
              isDisabled={!searchQuery?.trim()}
              actionFn={() => aiSearchRef.current?.start(searchQuery!)}
            >
              <ArrowForwardIcon boxSize='22px' />
            </ActionButton>
          }
        />

        <Authorize canAccess={canAccessAiSearch}>
          <SearchTypeSelect value={searchType} onChange={onTypeChange} />
        </Authorize>
      </Flex>

      <AiSearchContent
        ref={aiSearchRef}
        searchQuery={searchQuery}
        updateSearchQuery={onQuerySelect}
      />
    </Wrapper>
  );
};

export default AiSearchModalData;
