import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { GOOGLE_COLOR, NPS_COLOR, YELP_COLOR } from './CustomerFeedbackChart';

const LegendComponent: FC = () => {
  const legends = [
    { color: GOOGLE_COLOR, label: 'Google Rating' },
    // { color: YELP_COLOR, label: 'Yelp Rating' },
    // { color: NPS_COLOR, label: 'NPS Rating' },
  ];

  return (
    <Flex>
      {legends.map((legend) => (
        <HStack key={legend.label} alignItems='flex-start' pos='relative'>
          <Box
            pos='absolute'
            top='2px'
            left='-20px'
            boxSize='20px'
            bg={legend.color}
          />
          <Box as='span' m={0} p={0}>
            {legend.label}
          </Box>
        </HStack>
      ))}
    </Flex>
  );
};

export default LegendComponent;
