import { Box, Heading, VStack } from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';
import {
  useLazyQueryExampleAdvanced,
  useLazyQueryExampleBasic,
} from './examples';

const useLazyQueryExamples: FC = () => {
  return (
    <VStack align='flex-start' w='full'>
      <Heading as='h2'>useLazyQuery Examples</Heading>
      <Box as='span'>
        The useLazyQuery hook is similar to useQuery, but it defers query
        execution until you manually trigger it.
      </Box>
      <Heading as='h3'>Example 1 (Basic)</Heading>
      {/* @ts-ignore */}
      <SyntaxHighlighter language='typescript' style={materialDark}>
        {useLazyQueryExampleBasic}
      </SyntaxHighlighter>
      <Heading as='h3'>Example 2 (Advanced)</Heading>
      {/* @ts-ignore */}
      <SyntaxHighlighter language='typescript' style={materialDark}>
        {useLazyQueryExampleAdvanced}
      </SyntaxHighlighter>
    </VStack>
  );
};

export default useLazyQueryExamples;
