import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { SelectOption } from '../../../../../../../../atoms';
import { AuthRole } from '../../../../../../../../authorization';
import { usersEntityObj } from '../../../../../../../Header';

interface RemindUserData {
  options: SelectOption[];
  adminObj: Record<string, SelectOption>;
}

export const useRemindUserData = (): RemindUserData => {
  const entityObjData = useReactiveVar(usersEntityObj);

  return useMemo(() => {
    if (entityObjData) {
      return entityObjData.reduce<RemindUserData>(
        (acc, user) => {
          if (
            [AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(user.authRole) &&
            ['active', 'inactive'].includes(user.status) &&
            user.type === 'user'
          ) {
            const item = {
              label: user.name,
              value: user.eid,
              authRole: user.authRole,
              profilePic: user?.profilePic,
              locations: user?.locations,
              role: user?.role,
            };

            acc.options.push(item);
            acc.adminObj[item.value] = item;
          }

          return acc;
        },
        {
          options: [],
          adminObj: {},
        }
      );
    }
    return {
      options: [],
      adminObj: {},
    };
  }, [entityObjData]);
};
