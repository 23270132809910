import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import LocationPerformanceGraph from './LocationsPerformanceGraph';
import LocationPerformanceBarGraph from '../Components/graphs/LocationPerformanceBarGraph';
import JobPerformanceBarGraph from '../Components/graphs/JobPerformanceBarGraph';
import { Flex } from '@chakra-ui/react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  GET_ALL_LOCATIONS,
  TASK_RANKING_DATA,
} from './services/admin-sa-graphs.graphql';
import { userObj } from 'sop-commons/src/client';

export interface ITaskRanking {
  TaskRankingOverview: {
    locRanking: {
      complete: number;
      completedPercent: string;
      eid: string;
      incomplete: number;
      name: string;
      total: number;
    }[];
    roleRanking: {
      [key: string]: {
        complete: number;
        completedPercent: string;
        incomplete: number;
        role: string;
        total: number;
      };
    }[];
  };
}
import { JOB_PERFORMANCE } from '../../../appRoutes';
import { LocationCompleteRateGraph } from 'sub-components/tasks/performance/overview';
import PerformanceOverview from '../LocationOwnerGraphs/PerformanceOverview';

const AdminSAGraphContainer: FC = () => {
  const history = useHistory();
  const userData = useReactiveVar(userObj);

  const [
    getTaskRankingData,
    { data: taskRankingData, loading: taskRankingLoading },
  ] = useLazyQuery<ITaskRanking, { entityId: string }>(TASK_RANKING_DATA, {
    fetchPolicy: 'network-only',
  });

  const [getAllLocations, { data: allLocationsData }] = useLazyQuery(
    GET_ALL_LOCATIONS,
    { fetchPolicy: 'network-only' }
  );

  useEffect(() => {
    if (!userData?.entityId) return;
    getTaskRankingData({
      variables: {
        entityId: userData?.entityId,
      },
    });

    getAllLocations({
      variables: {
        entityId: userData?.entityId,
        type: 'branch',
      },
    });
  }, [userData?.entityId]);

  const onFullViewClick = () => history.push(JOB_PERFORMANCE);

  return (
    <Flex flexDirection='column' gap='20px'>
      <LocationPerformanceGraph
        taskRankingData={taskRankingData}
        loading={taskRankingLoading}
      />
      <Flex flexDir='column' gap='20px'>
        <Flex gap='20px'>
          <Flex flex={3} flexDir='column' gap='20px'>
            <LocationPerformanceBarGraph />
            <JobPerformanceBarGraph onFullViewClick={onFullViewClick} />
          </Flex>
          <Flex flex={3} overflow='hidden'>
            <PerformanceOverview />
          </Flex>
        </Flex>
      </Flex>
      <LocationCompleteRateGraph />
    </Flex>
  );
};

AdminSAGraphContainer.displayName = 'pages/TasksDashboard/AdminSAGraphs';

export default AdminSAGraphContainer;
