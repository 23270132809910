import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const HooksFolderInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Hooks Folder Overview</Heading>
      <Box fontSize='16px'>
        This folder contains various hooks used extensively across the project.
        So go through each hook thouroughly so that there is proper
        understanding of basic hooks used and what are the usecases.
      </Box>
    </VStack>
  );
};

export default HooksFolderInfo;
