import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import DashboardContainer from '../../../sub-components/DashboardContainer';
import PlayComponent from './PlayComponent';
import TrainingPlayHeader from './TrainingPlayHeader';
import TrainingProgressBar from './TrainingProgressBar';
import { TrainingPlayProvider, UserData } from './context';
import { NextButton, PrevButton } from './PrevNextButton';
import TrainingAccessibleWrapper from './TrainingAccessibleWrapper';

interface IProps {}

const TrainingPlay: FC<IProps> = () => {
  const { trainingId } = useParams<{ trainingId: string }>();
  const locationState = useLocation<{ userData?: UserData }>()?.state;
  const history = useHistory();

  return (
    <DashboardContainer>
      <TrainingPlayProvider
        trainingId={trainingId}
        userData={locationState?.userData}
        onFinish={() => history.goBack()}
      >
        <TrainingAccessibleWrapper>
          <Flex gap={4} py={1} h='full' px={3} justify='center'>
            <Flex align='center' minW='42px'>
              <PrevButton />
            </Flex>
            <Flex
              flexDir='column'
              gap={8}
              flex={5}
              overflow='hidden'
              borderRadius={8}
              maxW='962px'
            >
              <TrainingPlayHeader />
              <TrainingProgressBar />
              <PlayComponent />
            </Flex>
            <Flex align='center' minW='42px'>
              <NextButton />
            </Flex>
          </Flex>
        </TrainingAccessibleWrapper>
      </TrainingPlayProvider>
    </DashboardContainer>
  );
};

export default TrainingPlay;
