import {
  Box,
  Flex,
  Heading,
  ListItem,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';

const AuthorizationHook: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>useAuthorization.ts</Heading>
      <VStack align='flex-start'>
        <Heading as='h2'>Purpose</Heading>
        <Box>
          Provides a custom React hook, useAuthorization, which simplifies the
          process of checking permissions within functional components. It
          returns a function that can be used to conditionally render content
          based on permissions.
        </Box>
        <Heading as='h2'>Key Components</Heading>
        <UnorderedList pl={4}>
          <ListItem>
            <Flex gap={2}>
              <Box>AuthFunction:</Box>
              <Flex flexDir='column'>
                <Box>
                  A generic function type that takes Permission, permittedValue,
                  and restrictedValue, returning a value based on permission
                  checks.
                </Box>
              </Flex>
            </Flex>
          </ListItem>
          <ListItem>
            <Box>
              <Box as='span'>
                useAuthorization(rootPermission: Permission): AuthFunction:
              </Box>
              &nbsp;
              <Box as='span'>
                The custom hook that merges root and specific permissions,
                checks them, and returns the appropriate value.
              </Box>
            </Box>
          </ListItem>
        </UnorderedList>
        <Heading as='h2'>Example</Heading>
        <Box as='span'>
          Using the useAuthorization hook within a component to conditionally
          render content:
        </Box>
        <SyntaxHighlighter language='typescript' style={materialDark}>
          {`import React from 'react';
import { useAuthorization } from './useAuthorization';
import { AuthRole } from './authorization.types';

const MyComponent: React.FC = () => {
  const authorize = useAuthorization({ permittedRoles: [AuthRole.ADMIN] });

  const content = authorize(
    {},
    <div>Admin Content</div>,
    <div>Access Denied</div>
  );

  return <>{content}</>;
};
`}
        </SyntaxHighlighter>
        <Box>
          In this example, MyComponent will display "Admin Content" only if the
          user has the ADMIN role; otherwise, it shows "Access Denied".
        </Box>
      </VStack>
    </VStack>
  );
};

export default AuthorizationHook;
