import { useReactiveVar } from '@apollo/client';
import { useUserEntity } from 'hooks';
import moment, { Moment } from 'moment';
import { UserEntityData } from 'shared/graphql/shared-types';
import { EntityLocations } from 'sop-commons/src/client';
import { usersEntityObj } from 'sub-components/Header';
import { ExportData, LocationData, SelectOption } from '../types';

interface ProcessExportDataParams {
  exportData: ExportData;
  dateRange: SelectOption;
  selectedLocationIds: string[];
  businessLocations: EntityLocations[];
}

export function processExportData({
  exportData,
  dateRange,
  selectedLocationIds,
  businessLocations,
}: ProcessExportDataParams): LocationData[] {
  const { attritionData, auditData, taskData } = exportData;

  console.log({ attritionData, auditData, taskData });

  const { startDate, endDate } = getDateRange(dateRange);

  return businessLocations
    .filter((item) => selectedLocationIds.includes(item.eid))
    .map((item) => {
      const locationId = item.eid;

      // Task Completion
      const taskCompletion = calculateTaskCompletion(
        taskData,
        locationId,
        startDate,
        endDate
      );

      // Attrition
      let foundAttrition = attritionData?.find(
        (attrition) => locationId === attrition?.eid
      );
      const attritionRate = parseFloat(
        (foundAttrition?.attrition || 0)?.toFixed(2)
      );

      // Age
      const age = calculateAge(item?.createdAt);

      // Google Rating
      const googleRating = calculateGoogleRating(exportData, locationId);

      // Audit Score
      const auditScore = calculateAuditScore(
        auditData,
        locationId,
        startDate,
        endDate
      );

      return {
        location: item.name,
        taskCompletion,
        attrition: attritionRate,
        age,
        googleRating,
        auditScore,
      };
    });
}

function getDateRange(dateRange: SelectOption): {
  startDate: Moment;
  endDate: Moment;
} {
  let startDate: Moment;
  // Set endDate to the end of the previous month to exclude the current month
  const endDate = moment().subtract(1, 'months').endOf('month');

  switch (dateRange.value) {
    case 'lastMonth':
      startDate = moment().subtract(1, 'months').startOf('month');
      break;
    case 'last3Months':
      startDate = moment().subtract(3, 'months').startOf('month');
      break;
    case 'last6Months':
      startDate = moment().subtract(6, 'months').startOf('month');
      break;
    case 'last12Months':
      startDate = moment().subtract(12, 'months').startOf('month');
      break;
    default:
      startDate = moment().subtract(1, 'months').startOf('month');
  }

  return { startDate, endDate };
}

function calculateTaskCompletion(
  taskData: ExportData['taskData'],
  locationId: string,
  startDate: Moment,
  endDate: Moment
): number {
  console.log({ taskData, locationId, startDate, endDate });
  if (!taskData || !taskData[locationId]) return 0;

  const locationTaskData = taskData[locationId];
  const filteredMonths = Object.values(locationTaskData).filter((monthData) => {
    const monthStart = moment(monthData.start);
    return monthStart.isBetween(startDate, endDate, 'day', '[]');
  });
  console.log('filtered months : for task : ', filteredMonths);

  let totalComplete = 0;
  let totalIncomplete = 0;

  filteredMonths.forEach((monthData) => {
    totalComplete += monthData.complete;
    totalIncomplete += monthData.inComplete;
  });

  const totalTasks = totalComplete + totalIncomplete;
  return totalTasks > 0 ? (totalComplete / totalTasks) * 100 : 0;
}

// function calculateAttritionRate(
//   attritionData: EntityAttritionAnalyticsItem[],
//   locationName: string
// ): number {
//   const attritionItem = attritionData.find(
//     (item) => item.name.toLowerCase() === locationName.toLowerCase()
//   );
//   return attritionItem ? parseFloat(attritionItem.attrition.toFixed(2)) : 0;
// }

function calculateAge(createdAt: string): string {
  const createdMoment = moment(createdAt);
  const now = moment();
  const duration = moment.duration(now.diff(createdMoment));

  const years = Math.floor(duration.asYears());
  const months = Math.floor(duration.asMonths()) % 12;
  const days = Math.floor(duration.asDays()) % 30;

  let age = '';
  if (years > 0) age += `${years} year${years > 1 ? 's' : ''} `;
  if (months > 0) age += `${months} month${months > 1 ? 's' : ''} `;
  if (age === '') age = `${days} day${days > 1 ? 's' : ''}`;

  return age.trim();
}

function calculateGoogleRating(
  exportData: ExportData,
  locationId: string
): number {
  const { customerFeedback } = exportData;
  const feedback = customerFeedback.find((fb) => fb.locationId === locationId);
  console.log('feedback : ', feedback);
  return feedback?.google?.googleRating || 0;
}

function calculateAuditScore(
  auditData: ExportData['auditData'],
  locationId: string,
  startDate: Moment,
  endDate: Moment
): number {
  if (!auditData || !auditData[locationId]) return 0;

  const locationAuditData = auditData[locationId];
  const filteredMonths = Object.values(locationAuditData).filter(
    (monthData) => {
      const monthStart = moment(monthData.start);
      return monthStart.isBetween(startDate, endDate, 'day', '[]');
    }
  );

  let totalScoreSum = 0;
  let totalMaxScoreSum = 0;

  filteredMonths.forEach((monthData) => {
    totalScoreSum += monthData.totalScoreSum;
    totalMaxScoreSum += monthData.maxScoreSum;
  });

  return totalMaxScoreSum > 0
    ? parseFloat(((totalScoreSum / totalMaxScoreSum) * 100).toFixed(2))
    : 0;
}
