import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Center,
  Checkbox,
  Flex,
  IconButton,
  Spacer,
  Text,
  usePopoverContext,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useWatch } from 'react-hook-form';

import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { searchRegExp } from 'utils';
import { PrimaryButton, SearchInput, SelectOption } from '../../../../../atoms';

import { AssignedLocationsType, IFormInput } from '../../task.types';
import { faMapMarkerAlt, faX } from '@fortawesome/pro-solid-svg-icons';
import { HeaderColors } from 'configs';

interface ItemProps {
  isSelected?: boolean;
  data: SelectOption;
  onClick?: (data: SelectOption) => void;
}

const LocationItem: FC<ItemProps> = ({ data, onClick, isSelected }) => {
  return (
    <Flex
      flexDir='column'
      _last={{
        '.list-divider': {
          display: 'none',
        },
      }}
    >
      <Flex
        align='center'
        gap={'12px'}
        padding={3}
        borderRadius='8px'
        aria-selected={isSelected}
        _hover={{ bg: '#EEEEEE' }}
      >
        <Box bg={HeaderColors.Yellow} borderRadius={'44.8px'} py={2} px={3}>
          <FontAwesomeIcon
            icon={faMapMarkerAlt as IconProp}
            color='#ffffff'
            size='lg'
          />
        </Box>

        <Text
          noOfLines={1}
          fontSize='15px'
          fontWeight='semibold'
          color='#1A1D1F'
        >
          {data.label}
        </Text>
        <Spacer />
        <Checkbox
          colorScheme='blue'
          isChecked={isSelected}
          onChange={() => onClick?.(data)}
        />
      </Flex>
      <Box className='list-divider' bg='#EAEBED' height='1px' mt={1} />
    </Flex>
  );
};

const TransData: Record<
  AssignedLocationsType,
  | 'whichLocationToAssign'
  | 'selectLocForOwner'
  | 'selectLocForWorker'
  | 'selectLocForAll'
> = {
  locationUser: 'whichLocationToAssign',
  locationOwner: 'selectLocForOwner',
  worker: 'selectLocForWorker',
  everyone: 'selectLocForAll',
};

const HintTransData: Record<
  AssignedLocationsType,
  | 'whichLocationToHint'
  | 'selectLocForOwnerHint'
  | 'selectLocForWorkerHint'
  | 'selectLocForAllHint'
> = {
  locationUser: 'whichLocationToHint',
  locationOwner: 'selectLocForOwnerHint',
  worker: 'selectLocForWorkerHint',
  everyone: 'selectLocForAllHint',
};

interface IProps {
  selectedType: AssignedLocationsType;
  value?: string[];
  onChange?: (value: string[]) => void;
  locations: SelectOption[];
  onBackPress?: () => void;
}

const LocationListContainer: FC<IProps> = ({
  selectedType,
  locations = [],
  value = [],
  onChange,
  onBackPress,
}) => {
  const { onClose } = usePopoverContext();
  const { t } = useTranslation(['common', 'task']);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [innerValue, setInnerValue] = useState<string[]>([]);

  const locationUserType = useWatch<IFormInput, 'assignedLocationsType'>({
    name: 'assignedLocationsType',
  });

  useEffect(() => {
    setInnerValue(value);
  }, []);

  const filteredLocation = useMemo(() => {
    if (searchQuery) {
      const reg = searchRegExp(searchQuery, 'gi');
      return locations.filter((value) => {
        return value.label?.match(reg);
      });
    }

    return locations;
  }, [locations, searchQuery]);

  const _onChange = (option: SelectOption) => {
    setInnerValue((prevState) => {
      if (prevState.includes(option.value)) {
        return [...prevState.filter((it) => it !== option.value)];
      }
      return [...prevState, option.value];
    });
  };

  const onSelectPress = () => {
    onChange?.(innerValue);
    onClose();
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const showHints = locationUserType && locationUserType !== selectedType;

  const onSelectAll = () => {
    if (innerValue.length === locations.length) {
      setInnerValue([]);
    } else {
      setInnerValue(locations.map((location) => location.value));
    }
  };

  return (
    <Flex flexDir='column' gap={6} px='28px'>
      <Flex align='center' justifyContent={'space-between'}>
        <Flex gap={3} align='center'>
          <Center height='32px' cursor='pointer' onClick={onBackPress}>
            <ArrowBackIcon color='#272B30' boxSize='22px' />
          </Center>

          <Box fontSize='15px' fontWeight='600' color='#1A1D1F'>
            {t(`task:${TransData[selectedType]}`)}
          </Box>
        </Flex>

        <Box>
          <IconButton
            variant='subtle'
            aria-label='action'
            borderRadius='full'
            bg='#6F767E'
            size='xs'
            alignSelf='center'
            icon={
              <FontAwesomeIcon
                size={'1x'}
                icon={faX as IconProp}
                color='white'
              />
            }
            onClick={onClose}
          />
        </Box>
      </Flex>

      <SearchInput
        size='lg'
        hideShortcuts
        placeholder={t('task:placeholder.searchLocations')}
        value={searchQuery}
        onChange={onSearch}
      />
      <Flex align='center' justifyContent='space-between' px={4}>
        <Text fontSize='13px' fontWeight='500' color='#6F767E'>
          SELECT ALL
        </Text>
        <Checkbox
          colorScheme='blue'
          isChecked={innerValue.length === locations.length}
          onChange={onSelectAll}
        />
      </Flex>

      <Flex flexDir='column' maxH='max(40vh, 320px)' overflowY='auto' gap={1}>
        {filteredLocation.map((location) => (
          <LocationItem
            key={location.value}
            data={location}
            onClick={_onChange}
            isSelected={innerValue.includes(location.value)}
          />
        ))}
      </Flex>

      {showHints && (
        <Flex align='center' gap='10px' pb={2}>
          <FontAwesomeIcon
            icon={faCircleExclamation as IconProp}
            color='#6F767E'
          />
          <Box fontSize='12px' color='#6F767E'>
            {t(`task:${HintTransData[selectedType]}`)}
          </Box>
        </Flex>
      )}

      <Flex gap={2}>
        {showHints && (
          <PrimaryButton
            width='160px'
            size='lg'
            variant='outline'
            title={t('common:cancel')}
            onClick={onBackPress}
          />
        )}

        <PrimaryButton
          size='lg'
          colorScheme='blue'
          title={t('common:select')}
          disabled={value.length === 0 && innerValue.length === 0}
          onClick={onSelectPress}
        />
      </Flex>
    </Flex>
  );
};

export default LocationListContainer;
