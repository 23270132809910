import React, { FC } from 'react';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import {
  ControllerRenderProps,
  useController,
  useWatch,
} from 'react-hook-form';
import { Button } from '../../../../../../../../atoms';

import { useRemindOtherUser } from './useRemindOtherUser';
import { IDocumentInput } from '../../compliance.input';
import RemindsUserLists from './RemindsUserLists';

import { ReactComponent as EditIcon } from 'assets/images/edit-dark.svg';

interface IProps {
  visible?: boolean;
}

const ReminderWillSentTo: FC<IProps> = ({ visible }) => {
  const remindOtherUser = useRemindOtherUser();

  const locationId = useWatch<IDocumentInput, 'location.value'>({
    name: 'location.value',
  });

  const admin = useController<IDocumentInput, 'reminder.remindAdmins'>({
    name: 'reminder.remindAdmins',
  });

  const owner = useController<IDocumentInput, 'reminder.remindOwners'>({
    name: 'reminder.remindOwners',
  });

  function onRemoveAdmin(
    this:
      | ControllerRenderProps<IDocumentInput, 'reminder.remindAdmins'>
      | ControllerRenderProps<IDocumentInput, 'reminder.remindOwners'>,
    userId: string
  ) {
    this.onChange(this.value?.filter((U) => U !== userId) || []);
  }

  if (!visible) {
    return null;
  }

  const isInvalid = admin?.fieldState?.invalid || owner?.fieldState?.invalid;
  const error = admin?.fieldState?.error || owner?.fieldState?.error;

  return (
    <Flex flexDir='column'>
      <Flex justify='space-between' align='center' minH='32px'>
        <Box fontSize='12px' fontWeight={500} color='#6F767E'>
          Reminder will be sent to
        </Box>
        <Button
          size='sm'
          variant='link'
          colorScheme='blue'
          fontSize='12px'
          leftIcon={<EditIcon width='16' height='16' color='#2A85FF' />}
          onClick={() => remindOtherUser()}
        >
          Edit recipients
        </Button>
      </Flex>

      <FormControl isInvalid={isInvalid}>
        <RemindsUserLists
          admins={admin.field.value}
          owners={owner.field.value}
          removeAdmin={onRemoveAdmin.bind(admin.field)}
          removeOwner={onRemoveAdmin.bind(owner.field)}
          locationId={locationId}
        />
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    </Flex>
  );
};

export default ReminderWillSentTo;
