import {
  Box,
  Heading,
  ListItem,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';

const CommonGraphQL: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Common GraphQL Info</Heading>
      <Box fontSize='16px'>
        This folder contains the common graphql queries used in the project.
        Mainly those are:
        <UnorderedList pl={4}>
          <ListItem>
            <Box>
              <Box as='span' fontWeight={600}>
                EntityUser:
              </Box>
              &nbsp;
              <Box as='span'>
                Fetches complete list of locations and members for the business
                which is used to list down members/locations based on certain
                auth role.
              </Box>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Box as='span' fontWeight={600}>
                EntityCategoryById:
              </Box>
              &nbsp;
              <Box as='span'>Fetches details of folder/subfolder by id.</Box>
            </Box>
          </ListItem>
        </UnorderedList>
      </Box>
      <Box fontSize='16px'>
        It also contains the common types used in the project. Mainly those are:
        <UnorderedList pl={4}>
          <ListItem>
            <Box>
              <Box as='span' fontWeight={600}>
                UserEntityData:
              </Box>
              &nbsp;
              <Box as='span'>Used as interface for entity data.</Box>
            </Box>
          </ListItem>
        </UnorderedList>
      </Box>
      <Box fontSize='16px'>
        This folder also contains the common queries used in the project. Mainly
        those are:
        <UnorderedList pl={4}>
          <ListItem>
            <Box>
              <Box as='span' fontWeight={600}>
                getLoggedInUserDataHandler:
              </Box>
              &nbsp;
              <Box as='span'>
                Fetches the data for logged in user. This is done at the initial
                phase of project loading which is then stored in reactive
                variable named "userObj". In same query, another reactive
                variable is also there "roleObj" which stores the list of jobs.
              </Box>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Box as='span' fontWeight={600}>
                getUsersEntityHandler:
              </Box>
              &nbsp;
              <Box as='span'>
                Fetches the entity data which is stored in reactive variable
                named "usersEntityObj" which is fetched along with user data at
                the initial phase of project.
              </Box>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Box as='span' fontWeight={600}>
                getFoldersList:
              </Box>
              &nbsp;
              <Box as='span'>
                Fetches the folders list which are visible to the logged in user
                and are stored in reactive variable "foldersListVar".
              </Box>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Box as='span' fontWeight={600}>
                getChaptersList:
              </Box>
              &nbsp;
              <Box as='span'>
                Fetches the chapters list (rendered in knowledge base) which are
                visible to the logged in user and are stored in reactive
                variable "chaptersListVar".
              </Box>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Box as='span' fontWeight={600}>
                getFolderById:
              </Box>
              &nbsp;
              <Box as='span'>Fetches the folder details by id.</Box>
            </Box>
          </ListItem>
        </UnorderedList>
      </Box>
    </VStack>
  );
};

export default CommonGraphQL;
