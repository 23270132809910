import { gql } from '@apollo/client';

export const LOCATION_GOOGLE_PHOTOS = gql`
  query LocationGooglePhotos($locationIds: [String]!) {
    LocationGooglePhotos(locationIds: $locationIds) {
      authorAttributions
      eid
      heightPx
      locationId
      photoUrl
      name
      widthPx
    }
  }
`;

export const INTERNAL_LOCATION_PHOTOS = gql`
  query Query($locationIds: [String]!) {
    LocationInternalPhotos(locationIds: $locationIds)
  }
`;
