import { Grid, GridItem } from '@chakra-ui/react';
import { useUserDataSelector } from 'hooks';
import { FC } from 'react';
import ScoreCard from './ScoreCard';
import { ILocationPerformance } from '../types';
import { ViewType } from './ViewAll/types';

interface IProps {
  completeData: ILocationPerformance[];
  type: ViewType;
}

const RenderScoreCards: FC<IProps> = ({ completeData, type }) => {
  const highestPerforming = completeData?.slice(0, 3);
  const topLocation = highestPerforming?.[0];
  const secondLocation = highestPerforming?.[1];
  const thirdLocation = highestPerforming?.[2];

  if (highestPerforming.length === 3) {
    return (
      <Grid
        id='t-c-grid-container'
        templateColumns='repeat(3, 1fr)'
        gap={6}
        alignItems='end'
      >
        <GridItem id='t-c-grid-item'>
          <ScoreCard
            score={`${secondLocation?.rate?.toFixed(2)}`}
            label={secondLocation?.locationName || 'Unknown'}
            locationId={secondLocation?.locationId}
            position={2}
            type={type}
          />
        </GridItem>
        <GridItem id='t-c-grid-item'>
          <ScoreCard
            score={`${topLocation?.rate?.toFixed(2)}`}
            label={topLocation?.locationName || 'Unknown'}
            locationId={topLocation?.locationId}
            position={1}
            isCrown
            type={type}
          />
        </GridItem>
        <GridItem id='t-c-grid-item'>
          <ScoreCard
            score={`${thirdLocation?.rate?.toFixed(2)}`}
            label={thirdLocation?.locationName || 'Unknown'}
            locationId={thirdLocation?.locationId}
            position={3}
            type={type}
          />
        </GridItem>
      </Grid>
    );
  } else if (highestPerforming.length === 2) {
    return (
      <Grid templateColumns='repeat(2, 1fr)' gap={6} alignItems='baseline'>
        <GridItem>
          <ScoreCard
            score={`${topLocation?.rate?.toFixed(2)}`}
            label={topLocation?.locationName || 'Unknown'}
            locationId={topLocation?.locationId}
            position={1}
            isCrown
            type={type}
          />
        </GridItem>
        <GridItem>
          <ScoreCard
            score={`${secondLocation?.rate?.toFixed(2)}`}
            label={secondLocation?.locationName || 'Unknown'}
            locationId={secondLocation?.locationId}
            position={2}
            type={type}
          />
        </GridItem>
      </Grid>
    );
  } else if (highestPerforming.length === 1) {
    return (
      <Grid templateColumns='repeat(2, 1fr)' gap={6} alignItems='baseline'>
        <GridItem colSpan={2}>
          <ScoreCard
            score={`${topLocation?.rate?.toFixed(2)}`}
            label={topLocation?.locationName || 'Unknown'}
            locationId={topLocation?.locationId}
            hideRank
            position={1}
            type={type}
          />
        </GridItem>
      </Grid>
    );
  }
  return null;
};

export default RenderScoreCards;
