import React, { FC } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Box,
  Center,
  Flex,
  useAccordionItemState,
} from '@chakra-ui/react';

import { AiResponse } from '../ai-search.types';

import { ReactComponent as AiIcon } from '../../../../assets/images/AI.svg';

interface IProps {
  data: AiResponse;
}

const ResultContent: FC<IProps> = ({ data }) => {
  const { isOpen } = useAccordionItemState();
  return (
    <Flex flex={1} align='start' gap='10px' overflow='hidden'>
      <Center
        boxSize='48px'
        border='1px solid #DDDDDD'
        bg='#FFFFFF'
        borderRadius='8px'
      >
        <AiIcon />
      </Center>
      <Flex
        flex={1}
        flexDir='column'
        overflow='hidden'
        textAlign='start'
        paddingTop='5px'
      >
        <Box fontSize='12px' fontWeight='500' color='#6F767E'>
          {data.query}
        </Box>
        <Box
          maxW='100%'
          fontSize='12px'
          fontWeight='600'
          color='#1A1D1F'
          whiteSpace='pre-line'
          isTruncated={!isOpen}
        >
          {data.response}
        </Box>
      </Flex>
    </Flex>
  );
};

const ResultItem: FC<IProps> = ({ data }) => {
  return (
    <AccordionItem
      borderTopWidth='0'
      borderColor='#EEEEEE'
      borderBottomWidth='1px'
      py={3}
      _first={{
        paddingTop: 0,
      }}
      _last={{
        borderBottomWidth: 0,
      }}
    >
      <AccordionButton
        borderRadius='12px'
        alignItems='start'
        gap='10px'
        _hover={{}}
        _expanded={{ bg: '#F6F3FF', py: 4 }}
      >
        <ResultContent data={data} />
        <Flex h='48px' alignItems='center'>
          <AccordionIcon color='#9A9FA5' />
        </Flex>
      </AccordionButton>
    </AccordionItem>
  );
};

export default ResultItem;
