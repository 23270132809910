import { Box, Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const AmplitudeEventsInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Amplitude Events Info</Heading>
      <Box>
        This folder handles all the events that are being tracked and captured
        in mixpanel and amplitude.
      </Box>
      <Box>Events are defined in "amplitude-events-types.ts".</Box>
      <Box>
        The logic to capture and send the events is in "AmplitudeEvents.ts".
      </Box>
    </VStack>
  );
};

export default AmplitudeEventsInfo;
