import { Heading, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';

const ZustandGlobalInfo: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>Zustand Global Management Info</Heading>
    </VStack>
  );
};

export default ZustandGlobalInfo;
