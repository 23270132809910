import { createStore } from 'zustandStore';
import { immer } from 'zustandStore/middleware';
import { LocationsRatingResponse } from '../components/LeftSection/components/CustomerFeedback/types';
import {
  TaskSupervisedLocationWiseTrend,
  AuditLocationsAnalytics,
  EntityAttritionAnalyticsItem,
  ErrorType,
} from '../types';
import { LocationDetails } from '../types/api-types/location-details.types';

export interface NexusBoardState {
  taskData: TaskSupervisedLocationWiseTrend | undefined;
  auditData: AuditLocationsAnalytics | undefined;
  attritionData: EntityAttritionAnalyticsItem[];
  locationDetailsData: LocationDetails;
  locationRatingData: LocationsRatingResponse['LocationMetaPagination']['items'];
  error: ErrorType;
}

export interface NexusBoardAction extends NexusBoardState {
  updateTaskData: (data: TaskSupervisedLocationWiseTrend) => void;
  updateAuditData: (data: AuditLocationsAnalytics) => void;
  udpateAttritionData: (data: EntityAttritionAnalyticsItem[]) => void;
  updateLocationDetails: (data: LocationDetails) => void;
  updateLocationRatingData: (
    data: LocationsRatingResponse['LocationMetaPagination']['items']
  ) => void;
  setError: (error: ErrorType) => void;
}

export const useNexusBoardStore = createStore<NexusBoardAction>();

export const createNexusBoardStore = () => {
  return createStore<NexusBoardAction>()(
    immer((set) => ({
      taskData: undefined as unknown as TaskSupervisedLocationWiseTrend,
      auditData: undefined as unknown as AuditLocationsAnalytics,
      attritionData: [] as EntityAttritionAnalyticsItem[],
      error: null as ErrorType,
      locationDetailsData: {} as LocationDetails,
      locationRatingData:
        [] as LocationsRatingResponse['LocationMetaPagination']['items'],
      updateTaskData: (data) => {
        set((state) => {
          state.taskData = data;
        });
      },
      updateAuditData: (data) => {
        set((state) => {
          state.auditData = data;
        });
      },
      udpateAttritionData: (data) => {
        set((state) => {
          state.attritionData = data;
        });
      },
      setError: (error) => {
        set((state) => {
          state.error = error;
        });
      },
      updateLocationDetails: (data) => {
        set((state) => {
          state.locationDetailsData = data;
        });
      },
      updateLocationRatingData: (data) => {
        set((state) => {
          state.locationRatingData = data;
        });
      },
    }))
  );
};
