import { Box, Heading, VStack } from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';
import {
  useMutationExampleAdvanced,
  useMutationExampleBasic,
} from './examples';

const useMutationExamples: FC = () => {
  return (
    <VStack align='flex-start' w='full'>
      <Heading as='h2'>useMutation Examples</Heading>
      <Box as='span'>
        The useMutation hook is used to execute GraphQL mutations and manage
        their results.
      </Box>
      <Heading as='h3'>Example 1 (Basic)</Heading>
      {/* @ts-ignore */}
      <SyntaxHighlighter language='typescript' style={materialDark}>
        {useMutationExampleBasic}
      </SyntaxHighlighter>
      <Heading as='h3'>Example 2 (Advanced)</Heading>
      {/* @ts-ignore */}
      <SyntaxHighlighter language='typescript' style={materialDark}>
        {useMutationExampleAdvanced}
      </SyntaxHighlighter>
    </VStack>
  );
};

export default useMutationExamples;
