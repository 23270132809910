import React, { FC } from 'react';
import { HeaderColors } from 'configs';
import { FlexCard } from 'atoms';
import { BoxHeader } from 'ui-components';
import { useControl } from './layers';
import { CustomerFeedbackChart, NoCustomerFeedback } from './components';
import { Box, HStack } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { LOCATIONS_RATING } from 'appRoutes';

const CustomerFeedback: FC = () => {
  const history = useHistory();
  const { graphData } = useControl();

  return (
    <FlexCard flexDir='column' gap={4}>
      <HStack w='full' justify='space-between' align='center'>
        <BoxHeader title='Customer feedback' color={HeaderColors.Green} />
        {graphData?.length > 1 && (
          <Box
            fontSize='15px'
            fontWeight={700}
            color={HeaderColors.DarkBlue}
            cursor='pointer'
            onClick={() => history.push(LOCATIONS_RATING)}
          >
            View more <ArrowForwardIcon />
          </Box>
        )}
      </HStack>
      {graphData?.length === 0 ? (
        <NoCustomerFeedback />
      ) : (
        <CustomerFeedbackChart data={graphData} />
      )}
    </FlexCard>
  );
};

export default CustomerFeedback;
