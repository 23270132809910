import { Center, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

const RoyaltyPanel: FC = () => (
  <Center
    flexDir='column'
    bg='#FCFCFC'
    pt='56px'
    pb='16px'
    px='24px'
    borderRadius='20px'
  >
    <Text>Royalty Content Goes Here</Text>
  </Center>
);

export default RoyaltyPanel;
