import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import amplitude from 'amplitude-js';
import mixpanel from 'mixpanel-browser';
import { LocationsEntity } from 'sop-commons/src/client';
import { useUserDataSelector, useUserEntity } from '../../hooks';
import { toArray } from '../../utils';

const mapLocation = (loc: LocationsEntity) => {
  return { eid: loc?.eid, name: loc?.name };
};

export const useAnalyticsSetup = (): void => {
  const pathname = useLocation().pathname;

  const userObject = useUserDataSelector((state) => ({
    eid: state.eid,
    name: state.name,
    email: state.email,
    role: state.role,
    authRole: state.authRole,
    type: state.type,
    locations: toArray(state?.locations).map(mapLocation),
  }));

  const business = useUserEntity((entity) => ({
    analyticsEnabled: entity?.analytics,
    name: entity?.name,
    isDemo: entity?.isDemo,
  }));

  const analyticsEnabled = useUserEntity((entity) => entity?.analytics);

  const amplitudeKey = process.env?.REACT_APP_AMPLITUDE_KEY;
  const mixpanelKey = process.env?.REACT_APP_MIXPANEL_KEY;

  const isProduction = process?.env?.REACT_APP_DEPLOYMENT_TYPE === 'production';

  const [isAmplitudeInitialized, setIsAmplitudeInitialized] = useState(false);
  const [isMixpanelInitialized, setIsMixpanelInitialized] = useState(false);

  const checkBotFlag = () => {
    return pathname === '/create-sop' || pathname === '/search-sop';
  };

  useEffect(() => {
    Sentry.setUser({
      email: userObject?.email,
      fullName: userObject?.name,
      username: userObject?.name,
      id: userObject?.eid,
    });
  }, [userObject]);

  useEffect(() => {
    if (
      isProduction &&
      analyticsEnabled &&
      mixpanelKey &&
      amplitudeKey &&
      !checkBotFlag()
    ) {
      if (userObject?.eid) {
        // Amplitude initialization
        amplitude.getInstance().init(amplitudeKey, userObject?.eid);
        setIsAmplitudeInitialized(true);
        sessionStorage.setItem('amplitudeInit', '1');

        // Mix Panel Initialization
        mixpanel.init(mixpanelKey, {
          debug: false,
          track_pageview: true,
          persistence: 'localStorage',
        });
        mixpanel.identify(userObject?.eid);
        setIsMixpanelInitialized(true);
        sessionStorage.setItem('mixPanelInit', '1');
      } else {
        amplitude.getInstance().init(amplitudeKey);
        setIsAmplitudeInitialized(true);
        sessionStorage.setItem('amplitudeInit', '1');
        console.warn(
          '[Mixpanel] User ID is missing. Mixpanel will not be initialized.'
        );
      }

      const identify = new amplitude.Identify();
      let properties = {
        name: userObject?.name,
        email: userObject?.email,
        role: userObject?.role,
        authRole: userObject?.authRole,
        companyName: business?.name,
        isTestUser: !analyticsEnabled,
        userType: userObject?.type,
        locations: userObject?.locations,
        ...(business?.isDemo ? { AUTODEMO: true } : {}),
      };
      Object.entries(properties).forEach(([key, value]) => {
        if (value !== null) {
          identify.set(key, value);
        }
      });
      mixpanel?.people?.set({
        $name: userObject?.name,
        $email: userObject?.email,
        $role: userObject?.role,
        $authRole: userObject?.authRole,
        $companyName: business?.name,
        $isTestUser: !analyticsEnabled,
        $userType: userObject?.type,
        $locations: userObject?.locations,
        ...(business?.isDemo ? { $AUTODEMO: true } : {}),
      });
      amplitude.getInstance().identify(identify);
    } else {
      if (isMixpanelInitialized) {
        mixpanel.reset();
      }
      setIsMixpanelInitialized(false);
      setIsAmplitudeInitialized(false);
      sessionStorage.setItem('amplitudeInit', '0');
      sessionStorage.setItem('mixPanelInit', '0');
    }
  }, [userObject, business, amplitudeKey, mixpanelKey]);

  useEffect(() => {
    if (
      isProduction &&
      isAmplitudeInitialized &&
      isMixpanelInitialized &&
      !checkBotFlag()
    ) {
      if (isAmplitudeInitialized) {
        amplitude.getInstance().logEvent('Route Changed', { url: pathname });
      }
      if (isMixpanelInitialized) {
        mixpanel.track('Route Changed', { url: pathname });
      }
    }
  }, [pathname, isAmplitudeInitialized, isMixpanelInitialized]);
};
