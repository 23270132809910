import { useLazyQuery } from '@apollo/client';
import { CUSTOMER_RATING, LOCATIONS_RATING } from '../service/api.graphql';
import {
  CustomerRatingResponse,
  CustomerRatingVariables,
  LocationsRatingResponse,
  LocationsRatingVariables,
} from '../types';

export const useService = () => {
  const [locationRating, locationRatingRes] = useLazyQuery<
    LocationsRatingResponse,
    LocationsRatingVariables
  >(LOCATIONS_RATING, {
    fetchPolicy: 'network-only',
  });

  return {
    locationRating: { locationRating, locationRatingRes },
  };
};
