import { cloneDeep } from '@apollo/client/utilities';
import {
  Box,
  Center,
  Flex,
  List as ChakraList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFolder, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Authorize, AuthRole } from 'authorization';
import { useUserData } from 'hooks';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';
import { CategoryEntity } from 'sop-commons/src/client';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import AddCategory from 'sub-components/Sidebar/category/AddCategory';
import { AddCategoryRef } from 'sub-components/Sidebar/category/types';
import useCombinedStore from 'zustandStore/store';
import { IListSortBy } from '../../FolderList';
import ListItem from './ListItem';

interface IProps {
  height: number;
  foldersList: CategoryEntity[];
  listSortBy: IListSortBy;
  viewType: 'list' | 'grid' | undefined;
  getChaptersList?: () => void;
}

export interface ICustomCategoryEntity extends CategoryEntity {
  subFolder?: boolean;
  isExpanded?: boolean;
}

const List: FC<IProps> = ({
  height,
  foldersList,
  listSortBy,
  viewType,
  getChaptersList,
}) => {
  const routeMatch = useRouteMatch<{
    folderId: string;
    subFolderId?: string;
  }>();
  const { reset, updateVisibilityScope, updateOperationType } =
    useCombinedStore();
  const selectedFolderId = routeMatch?.params?.folderId;
  const selectedSubFolderId = routeMatch?.params?.subFolderId;
  const dashboardCtx = useContext(DashboardDataContext);
  const history = useHistory();
  const addCategoryRef = useRef<AddCategoryRef>(null);
  const [openNewCategory, setOpenNewCategory] = useState(false);
  const [statedFoldersList, setStatedFoldersList] = useState<
    ICustomCategoryEntity[]
  >([]);
  const [selectedSubFolder, setSelectedSubFolder] = useState('');

  useEffect(() => {
    if (selectedSubFolderId) {
      setSelectedSubFolder(selectedSubFolderId);
    }
  }, [selectedSubFolderId]);

  let navigationData = dashboardCtx?.navigationPersistData;
  let knowledgeBaseData = dashboardCtx?.navigationPersistData?.knowledgeBase;

  const resetKnowledgeBasePageCount = () => {
    dashboardCtx?.navigationPersistDataHandler({
      ...navigationData,
      knowledgeBase: { ...knowledgeBaseData, selectedPage: 1 },
    });
  };

  const closeHandler = () => {
    setOpenNewCategory(false);
  };

  useEffect(() => {
    let _foldersList: ICustomCategoryEntity[] = cloneDeep(foldersList || []);
    _foldersList?.forEach((folder) => {
      if (folder?.eid === selectedFolderId && folder?.children?.length > 0) {
        folder.isExpanded = true;
      } else {
        folder.isExpanded = false;
      }
    });
    if (listSortBy === 'asc') {
      _foldersList.sort((a, b) => a.name.localeCompare(b.name));
    } else if (listSortBy === 'desc') {
      _foldersList.sort((a, b) => b.name.localeCompare(a.name));
    }
    setStatedFoldersList(_foldersList);
  }, [foldersList, listSortBy, selectedFolderId]);

  return (
    <Flex w='full'>
      <ChakraList overflowY='auto' minH='400px' maxH={height} w='full' p={0}>
        <Flex
          align='center'
          justify='space-between'
          w='full'
          _hover={{ bg: '#EFEFEF', borderRadius: '5px' }}
          fontWeight={!selectedFolderId ? 600 : 500}
          color={!selectedFolderId ? '#272B30' : '#6F767E'}
          borderRadius={!selectedFolderId ? '5px' : ''}
          bg={!selectedFolderId ? '#EFEFEF' : ''}
          onClick={() => {
            deployEvent(
              AmplitudeEventNames.NAVIGATE_ALL_FOLDER_FROM_FOLDER_LIST
            );
            history.push('/folders');
          }}
          cursor='pointer'
          p='10px 12px'
        >
          <Flex align='center' justify='flex-start' gap={2}>
            <FontAwesomeIcon icon={faFolder as IconProp} color='#6F767E' />
            <Text>All</Text>
          </Flex>
          <Authorize
            permittedFor='user'
            permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          >
            <Tooltip label='Create new folder' hasArrow>
              <Center
                boxSize='28px'
                bg='#EFEFEF'
                borderRadius='6px'
                cursor='pointer'
                onClick={(e) => {
                  e.stopPropagation();
                  reset();
                  deployEvent(AmplitudeEventNames.CREATE_FOLDER_TOP_BUTTON);
                  setOpenNewCategory(true);
                  updateVisibilityScope('folder');
                  updateOperationType('add');
                }}
              >
                <FontAwesomeIcon
                  icon={faPlus as IconProp}
                  color='#2A85FF'
                  fontSize='14px'
                />
              </Center>
            </Tooltip>
          </Authorize>
        </Flex>
        {statedFoldersList?.map((folder, index) => (
          <ListItem
            key={index}
            dashboardCtx={dashboardCtx}
            folder={folder}
            history={history}
            index={index}
            getChaptersList={getChaptersList}
            resetKnowledgeBasePageCount={resetKnowledgeBasePageCount}
            selectedFolderId={selectedFolderId}
            selectedSubFolder={selectedSubFolder}
            setSelectedSubFolder={setSelectedSubFolder}
            setStatedFoldersList={setStatedFoldersList}
            statedFoldersList={statedFoldersList}
            subFolderList={folder?.children}
          />
        ))}
        <Authorize
          permittedFor='user'
          permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        >
          <Flex
            align='center'
            justify='flex-start'
            gap={2}
            p='10px 7.5px'
            cursor='pointer'
            fontWeight={600}
            color='#2A85FF'
            _hover={{ bg: '#EFEFEF', borderRadius: '5px' }}
            onClick={() => {
              reset();
              deployEvent(AmplitudeEventNames.CREATE_FOLDER_BOTTOM_BUTTON);
              updateVisibilityScope('folder');
              updateOperationType('add');
              setOpenNewCategory(true);
            }}
          >
            <FontAwesomeIcon icon={faFolder as IconProp} />
            <Text fontSize='14px' p={0} m={0}>
              Create new folder
            </Text>
          </Flex>
        </Authorize>
      </ChakraList>
      <Authorize
        permittedFor='user'
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <AddCategory
          ref={addCategoryRef}
          open={openNewCategory}
          onClose={closeHandler}
          type='folder'
        />
      </Authorize>
    </Flex>
  );
};

List.displayName = 'p/C/FL/c/L/List';

export default List;
