import { useCallback, useRef } from 'react';
import { useAiSearchPrefer, useLatest } from '../../../hooks';
import { UseConfirm, useConfirm } from '../../../ui-components';
import AiSearchIntro from './AiSearchIntro';

export const useAiSearchIntro = () => {
  const [, updateValue] = useAiSearchPrefer();
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  const updateRef = useLatest(updateValue);

  return useCallback(({ onContinue }) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    const _onContinue = async (value: boolean) => {
      updateRef.current(value);
      onContinue?.();
    };

    confirmRef.current = confirm({
      content: <AiSearchIntro onContinue={_onContinue} />,
      contentProps: {
        py: '16px',
        px: '8px',
        minW: '580px',
        borderRadius: '9px',
      },
      // isCentered: true,
      footer: null,
    });
  }, []);
};
