// utils/generateExcel.ts
import { SelectOption } from 'atoms';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

interface GenerateExcelParams {
  data: LocationData[];
  selectedColumns: string[];
  reportingPeriod: string;
  selectedDataRange: SelectOption;
}

interface LocationData {
  location: string;
  taskCompletion?: number; // Percentage (0-100)
  attrition?: number; // Percentage (0-100)
  age?: string; // Duration since last opened (e.g., "2 years")
  googleRating?: number; // Rating out of 5 (e.g., 4.5)
  auditScore?: number; // Score out of 100
}

const generateExcel = async ({
  data,
  selectedColumns,
  reportingPeriod,
  selectedDataRange,
}: GenerateExcelParams) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('By Performance');

  // Determine the total number of columns:
  // Column A: Location
  // Other Columns: Based on selected metrics
  const totalColumns = 1 + selectedColumns.length;

  // ----------------------------
  // 1. Add Report Details
  // ----------------------------

  // Row 1: Reporting Period
  const reportingPeriodText = `Reporting period: ${reportingPeriod} (Duration: ${selectedDataRange?.label})`;
  worksheet.mergeCells(`A1:${getExcelColumnLetter(totalColumns)}1`);
  const row1 = worksheet.getRow(1);
  row1.getCell(1).value = reportingPeriodText;
  row1.getCell(1).font = { bold: true, size: 14 };
  row1.getCell(1).alignment = { horizontal: 'left' }; // Changed from 'center' to 'left'

  // Row 2: Created On
  const currentDate = moment().format('MMMM Do, YYYY');
  const createdOnText = `Created on ${currentDate}`;
  worksheet.mergeCells(`A2:${getExcelColumnLetter(totalColumns)}2`);
  const row2 = worksheet.getRow(2);
  row2.getCell(1).value = createdOnText;
  row2.getCell(1).font = { color: { argb: '808080' }, size: 12 };
  row2.getCell(1).alignment = { horizontal: 'left' }; // Changed from 'center' to 'left'

  // ----------------------------
  // 2. Add Headers
  // ----------------------------

  // Row 3 & 4: Headers
  const row3 = worksheet.getRow(3);
  const row4 = worksheet.getRow(4);

  // Column A: 'Location' merged across Rows 3 and 4
  worksheet.mergeCells('A3:A4');
  const locationHeader = row3.getCell(1);
  locationHeader.value = 'Location';
  styleHeaderCell(locationHeader);

  // Initialize current column index starting from 2 (Column B)
  let currentColIndex = 2;

  // Iterate through selected metrics to set headers
  selectedColumns.forEach((metric) => {
    if (metric === 'Customer Feedback') {
      // Row 3: 'Customer Feedback' in the current column
      const customerFeedbackCell = row3.getCell(currentColIndex);
      customerFeedbackCell.value = 'Customer Feedback';
      styleHeaderCell(customerFeedbackCell);

      // Row 4: 'Google' in the same column
      const googleCell = row4.getCell(currentColIndex);
      googleCell.value = 'Google';
      styleSubHeaderCell(googleCell);

      currentColIndex += 1; // Move to next column
    } else {
      // For other metrics, merge headers across Rows 3 and 4
      const colLetter = getExcelColumnLetter(currentColIndex);
      worksheet.mergeCells(`${colLetter}3:${colLetter}4`);
      const headerCell = row3.getCell(currentColIndex);
      headerCell.value = metric;
      styleHeaderCell(headerCell);

      currentColIndex += 1; // Move to next column
    }
  });

  // ----------------------------
  // 3. Populate Data
  // ----------------------------

  const dataStartRow = 5;

  data.forEach((locationData, index) => {
    const rowNumber = dataStartRow + index;
    const row = worksheet.getRow(rowNumber);

    // Column A: Location Name
    row.getCell(1).value = locationData.location;

    // Populate metrics
    let colIndex = 2; // Start from Column B
    selectedColumns.forEach((metric) => {
      if (metric === 'Customer Feedback') {
        // 'Google' Rating in the same column
        row.getCell(colIndex).value = locationData.googleRating ?? 0;
        row.getCell(colIndex).numFmt = '0.0';
      } else if (metric === 'Task Completion') {
        row.getCell(colIndex).value = locationData?.taskCompletion ?? 0;
        row.getCell(colIndex).numFmt = '0.00"%"';
      } else if (metric === 'Attrition') {
        row.getCell(colIndex).value = locationData?.attrition ?? 0;
        row.getCell(colIndex).numFmt = '0.00"%"';
      } else if (metric === 'Audit Score') {
        row.getCell(colIndex).value = locationData?.auditScore ?? 0;
        row.getCell(colIndex).numFmt = '0.00"%"';
      } else {
        // Other metrics
        const value = (locationData as any)[camelCase(metric)] ?? 0;
        row.getCell(colIndex).value = value;
      }
      colIndex += 1;
    });
  });

  // ----------------------------
  // 4. Apply Conditional Formatting
  // ----------------------------

  applyConditionalFormatting(
    worksheet,
    data,
    selectedColumns,
    dataStartRow,
    dataStartRow + data.length - 1
  );

  // ----------------------------
  // 5. Styling Enhancements
  // ----------------------------

  // Alignments and Borders
  worksheet.eachRow({ includeEmpty: false }, (row) => {
    row.eachCell({ includeEmpty: false }, (cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  });

  // Set Column Widths for Better Readability
  worksheet.columns.forEach((column) => {
    column.width = 20;
  });

  // Freeze Top 4 Rows (Report Details and Headers)
  worksheet.views = [
    {
      state: 'frozen',
      ySplit: 4,
      activeCell: 'A5',
    },
  ];

  // ----------------------------
  // 6. Generate and Download Excel File
  // ----------------------------

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(
    blob,
    `Comparative_analysis_of_locations_${moment().format(
      'YYYYMMDD_HHmmss'
    )}.xlsx`
  );
};

// ----------------------------
// Helper Functions
// ----------------------------

// Convert a number to its corresponding Excel column letter (1-based index)
const getExcelColumnLetter = (col: number): string => {
  let temp = col;
  let letter = '';
  while (temp > 0) {
    const mod = (temp - 1) % 26;
    letter = String.fromCharCode(65 + mod) + letter;
    temp = Math.floor((temp - mod) / 26);
  }
  return letter;
};

// Convert a string to camelCase
const camelCase = (str: string): string => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '');
};

// Style for merged header cells (Row 3)
const styleHeaderCell = (cell: ExcelJS.Cell) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '000000' }, // Black background
  };
  cell.font = {
    color: { argb: 'FFFFFF' }, // White text
    bold: true,
  };
  cell.alignment = { vertical: 'middle', horizontal: 'center' };
};

// Style for sub-header cells (Row 4)
const styleSubHeaderCell = (cell: ExcelJS.Cell) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '000000' }, // Black background to match main header
  };
  cell.font = {
    color: { argb: 'FFFFFF' }, // White text
    bold: true,
  };
  cell.alignment = { vertical: 'middle', horizontal: 'center' };
};

// Apply Conditional Formatting based on the requirements
const applyConditionalFormatting = (
  worksheet: ExcelJS.Worksheet,
  data: LocationData[],
  selectedColumns: string[],
  startRow: number,
  endRow: number
) => {
  if (data.length <= 1) {
    return;
  }
  // Mapping of metric names to their column indices
  const metricColumnMap: { [key: string]: number } = {};
  selectedColumns.forEach((metric, idx) => {
    metricColumnMap[metric] = idx + 2; // Assuming columns start from B (2)
  });

  selectedColumns.forEach((metric) => {
    const colIndex = metricColumnMap[metric];
    let values: number[] = [];

    if (metric === 'Customer Feedback') {
      values = data.map((d) => d.googleRating ?? 0);
    } else {
      values = data.map((d) => (d as any)[camelCase(metric)] ?? 0);
    }

    // Check if all values are zero
    const allZero = values.every((value) => value === 0);
    if (allZero) {
      // Skip formatting if all values are zero
      return;
    }

    const highest = Math.max(...values);
    const lowest = Math.min(...values);

    for (let row = startRow; row <= endRow; row++) {
      const cell = worksheet.getRow(row).getCell(colIndex);
      const cellValue = typeof cell.value === 'number' ? cell.value : 0;

      // Determine the style based on the metric and cell value
      if (metric === 'Customer Feedback') {
        if (cellValue === highest) {
          // Highest value: Green Bold
          cell.font = { color: { argb: '008000' }, bold: true };
        } else if (cellValue === lowest) {
          // Lowest value: Red Bold
          cell.font = { color: { argb: 'FF0000' }, bold: true };
        }
      } else if (metric === 'Attrition') {
        if (cellValue === highest) {
          // Highest Attrition: Red Bold
          cell.font = { color: { argb: 'FF0000' }, bold: true };
        } else if (cellValue === lowest) {
          // Lowest Attrition: Green Bold
          cell.font = { color: { argb: '008000' }, bold: true };
        }
      } else {
        if (cellValue === highest) {
          // Highest Value: Green Bold
          cell.font = { color: { argb: '008000' }, bold: true };
        } else if (cellValue === lowest) {
          // Lowest Value: Red Bold
          cell.font = { color: { argb: 'FF0000' }, bold: true };
        }
      }
    }
  });
};

// const applyConditionalFormatting = (
//   worksheet: ExcelJS.Worksheet,
//   data: LocationData[],
//   selectedColumns: string[],
//   startRow: number,
//   endRow: number
// ) => {
//   // Mapping of metric names to their column indices
//   const metricColumnMap: { [key: string]: number } = {};
//   selectedColumns?.forEach(
//     (metric, idx) => (metricColumnMap[metric] = idx + 2)
//   );
//   // selectedColumns.forEach((metric, idx) => {
//   //   if (metric === 'Customer Feedback') {
//   //     metricColumnMap[metric] = 2; // Column B
//   //   } else {
//   //     metricColumnMap[metric] = idx + 2; // Columns C onwards
//   //   }
//   // });

//   selectedColumns.forEach((metric) => {
//     if (metric === 'Customer Feedback') {
//       const colIndex = metricColumnMap[metric];
//       const values = data.map((d) => d.googleRating ?? 0);
//       const highest = Math.max(...values);
//       const lowest = Math.min(...values);

//       for (let row = startRow; row <= endRow; row++) {
//         const cell = worksheet.getRow(row).getCell(colIndex);
//         const cellValue = cell.value as number;

//         if (cellValue === highest) {
//           // Highest value: Green Bold
//           cell.font = { color: { argb: '008000' }, bold: true };
//         } else if (cellValue === lowest) {
//           // Lowest value: Red Bold
//           cell.font = { color: { argb: 'FF0000' }, bold: true };
//         }
//       }
//     } else {
//       const colIndex = metricColumnMap[metric];
//       const values = data.map((d) => (d as any)[camelCase(metric)] ?? 0);
//       const highest = Math.max(...values);
//       const lowest = Math.min(...values);

//       for (let row = startRow; row <= endRow; row++) {
//         const cell = worksheet.getRow(row).getCell(colIndex);
//         const cellValue = cell.value as number;

//         if (metric === 'Attrition') {
//           if (cellValue === highest) {
//             // Highest Attrition: Red Bold
//             cell.font = { color: { argb: 'FF0000' }, bold: true };
//           } else if (cellValue === lowest) {
//             // Lowest Attrition: Green Bold
//             cell.font = { color: { argb: '008000' }, bold: true };
//           }
//         } else {
//           if (cellValue === highest) {
//             // Highest Value: Green Bold
//             cell.font = { color: { argb: '008000' }, bold: true };
//           } else if (cellValue === lowest) {
//             // Lowest Value: Red Bold
//             cell.font = { color: { argb: 'FF0000' }, bold: true };
//           }
//         }
//       }
//     }
//   });
// };

export default generateExcel;
