import { Box, Center } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeaderColors } from 'configs';
import React, { FC } from 'react';

interface IProps {
  title?: string;
  desc?: string;
}

const AllClear: FC<IProps> = ({
  title = 'All clear!',
  desc = 'Your location is performing excellently',
}) => {
  return (
    <Center w='full' flexDir='column' gap={4} h='full'>
      <FontAwesomeIcon
        icon={faCircleCheck as IconProp}
        color={HeaderColors.Green}
        fontSize='53.3px'
      />
      <Center flexDir='column' gap={2}>
        <Box fontSize='13px' fontWeight={600}>
          {title}
        </Box>
        <Box fontSize='12px' fontWeight={600} color='#6f767e'>
          {desc}
        </Box>
      </Center>
    </Center>
  );
};

export default AllClear;
