import { Badge, Box, Center, Flex, Stack, Text } from '@chakra-ui/react';
import { HeaderColors } from 'configs';
import React from 'react';

interface LocationItemProps {
  location: string;
  score: React.ReactNode | string;
  rank: number;
}

const LocationItem: React.FC<LocationItemProps> = ({
  location,
  score,
  rank,
}) => {
  const scoreRender = () =>
    typeof score === 'string' ? (
      <Box color={HeaderColors.Red.Mid} fontSize='13px' fontWeight={600}>
        {score}
      </Box>
    ) : (
      score
    );

  return (
    <Stack
      direction='row'
      alignItems='center'
      py='12px'
      pl='12px'
      pr='24px'
      bg='white'
      borderRadius='12px'
    >
      <Flex
        minW='32px'
        maxW='32px'
        minH='32px'
        maxH='32px'
        justify='center'
        align='center'
        borderRadius='50%'
        bg='#6f767e'
      >
        <Flex color='white' fontSize='15px' fontWeight={600} lineHeight='24px'>
          {rank}
        </Flex>
      </Flex>
      <Flex justify='space-between' w='full' align='center'>
        <Box fontSize='13px' fontWeight={600}>
          {location}
        </Box>
        {scoreRender()}
      </Flex>
    </Stack>
  );
};
export default LocationItem;
