import React, { FC, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { getRandomNumber } from '../../../utils';
import { useSafeState } from '../../../hooks';
import { ReactComponent as AiIcon } from 'assets/images/AI.svg';
import { BaseAiContent } from './BaseAiContent';

const TextData = [
  'Searching through your files',
  'Searching through your training paths',
  'Searching through your forms',
  'Searching through your Knowledge base',
  'Searching through your image gallery',
];

const MAX = TextData.length - 1;

interface IProps {}

const FetchingResult: FC<IProps> = () => {
  const [random, updateRandom] = useSafeState(getRandomNumber(0, MAX));

  useEffect(() => {
    const x = setInterval(() => {
      updateRandom(getRandomNumber(0, MAX));
    }, 2400);

    return () => clearInterval(x);
  }, []);

  return (
    <BaseAiContent gap={2} alignItems='center'>
      <AiIcon />
      <Box
        fontSize='12px'
        fontWeight='500'
        color='#1A1D1F'
        lineHeight='20px'
        paddingTop='2px'
        transform='all 200ms ease'
      >
        {TextData[random]}
      </Box>
      <Flex gap={1} align='center'>
        <Box
          boxSize={1}
          borderRadius='full'
          bg='linear-gradient(90deg, #6F767E 0%, #C9D7E4 150%)'
        />
        <Box
          boxSize={1}
          borderRadius='full'
          bg='linear-gradient(90deg, #A8B3BE 0%, #C9D7E4 100%)'
        />
        <Box
          boxSize={1}
          borderRadius='full'
          bg='linear-gradient(90deg, #C8D5E3 0%, rgba(201, 215, 228, 0.1) 66.67%)'
        />
      </Flex>
    </BaseAiContent>
  );
};

export default React.memo(FetchingResult);
