import { useConfirm, UseConfirm } from 'ui-components';
import React, { useCallback, useRef, useState } from 'react';
import { Flex, AlertDialogCloseButton, useToast, Box } from '@chakra-ui/react';
import { ExportCustomReportModal, ExportInitialModal } from './components';
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  AuditLocationsAnalytics,
  EntityAttritionAnalyticsItem,
  TaskSupervisedLocationWiseTrend,
} from 'sub-components/nexus/NexusBase/types';
import { UserEntityData } from 'shared/graphql/shared-types';
import { LocationsRating } from '../CustomerFeedback/types';
import { ApolloProvider, useApolloClient } from '@apollo/client';

export interface ExportData {
  attritionData: EntityAttritionAnalyticsItem[];
  auditData: AuditLocationsAnalytics | undefined;
  businessLocationsData: UserEntityData[];
  customerFeedback: LocationsRating[];
  taskData: TaskSupervisedLocationWiseTrend | undefined;
}

interface IProps {
  customerFeedback: LocationsRating[];
  exportRef: React.RefObject<HTMLElement>;
}

type Export = (props: IProps) => void;

export const useExport = (): Export => {
  const initialConfirmRef = useRef<ReturnType<UseConfirm>>();
  const reportConfirmRef = useRef<ReturnType<UseConfirm>>();
  const initialConfirm = useConfirm();
  const reportConfirm = useConfirm();
  const apolloClient = useApolloClient();

  const toast = useToast();

  return useCallback(
    ({ customerFeedback, exportRef }) => {
      const closeInitial = () => {
        if (initialConfirmRef.current) {
          initialConfirmRef.current.destroy();
        }
      };

      const closeReport = () => {
        if (reportConfirmRef.current) {
          reportConfirmRef.current.destroy();
        }
      };

      const openCustomReportModal = () => {
        initialConfirmRef?.current?.destroy();
        reportConfirmRef.current = reportConfirm({
          Wrapper: ({ children }: any) => {
            return (
              <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
            );
          },
          title: (
            <Flex flex={1} align='center' justify='space-between' w='full'>
              <Flex align='center' gap='5px'>
                <ArrowBackIcon
                  fontSize='24px'
                  color='rgba(111, 118, 126, 1)'
                  cursor='pointer'
                  onClick={openInitialModal}
                />
                <Box as='span' fontWeight={600} fontSize='20px'>
                  Generate custom report
                </Box>
              </Flex>
              <AlertDialogCloseButton
                pos='relative'
                borderRadius='full'
                top='0'
                right='0'
              />
            </Flex>
          ),
          content: (
            <ExportCustomReportModal
              customerFeedback={customerFeedback}
              onBack={openInitialModal}
              onClose={closeReport}
            />
          ),
          size: '4xl',
          isCentered: true,
          contentProps: {
            py: '12px',
            px: '8px',
            minWidth: '1000px',
            borderRadius: '16px',
          },
          footer: null,
          okText: null,
          onOK: undefined,
          cancelText: null,
        });
      };

      const openInitialModal = () => {
        reportConfirmRef?.current?.destroy();
        initialConfirmRef.current = initialConfirm({
          title: (
            <Flex flex={1} align='center' justify='space-between' w='full'>
              <Box as='span' fontWeight={600} fontSize='20px'>
                Export report
              </Box>
              <AlertDialogCloseButton
                pos='relative'
                borderRadius='full'
                top='0'
                right='0'
              />
            </Flex>
          ),
          content: (
            <ExportInitialModal
              exportRef={exportRef}
              onClose={closeInitial}
              onGenerateCustomReport={openCustomReportModal}
            />
          ),
          size: '4xl',
          isCentered: true,
          contentProps: {
            py: '16px',
            px: '8px',
            borderRadius: '16px',
          },
          footer: null,
          okText: null,
          onOK: undefined,
          cancelText: null,
        });
      };

      openInitialModal();
    },
    [initialConfirm, reportConfirm, toast]
  );
};
