import { gql } from '@apollo/client';

export type NexusComplianceResponseType = {
  EntityCompliancePagination: {
    count: number;
    items: {
      eid: string;
    }[];
  };
};
export const GET_NEXUS_COMPLIANCES = gql`
  query EntityCompliancePagination {
    EntityCompliancePagination {
      count
      items {
        eid
      }
    }
  }
`;
export type NexusComplianceCategoryResponseType = {
  EntityComplianceCategories: {
    category: string;
    eid: string;
  }[];
};

export const GET_NEXUS_COMPLIANCES_CATEGORY = gql`
  query EntityComplianceCategories {
    EntityComplianceCategories {
      category
      eid
    }
  }
`;
