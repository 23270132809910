import { useCallback, useEffect } from 'react';

export const useScrollHandler = (
  handleResize: (ref: HTMLElement) => void,
  target: (() => HTMLElement | null) | HTMLElement | null
): void => {
  const handleThumbPosition = useCallback(
    (event) => {
      handleResize(event.currentTarget);
    },
    [handleResize]
  );

  useEffect(() => {
    const element = typeof target === 'function' ? target() : target;

    if (!element) {
      return () => {};
    }

    const observer = new ResizeObserver(() => {
      handleResize(element!);
    });

    element && observer.observe(element);

    element?.addEventListener('scroll', handleThumbPosition);
    return () => {
      element && observer.unobserve(element);
      element?.removeEventListener('scroll', handleThumbPosition);
    };
  }, [handleThumbPosition, handleResize, target]);
};
