import { Box, Flex, Image } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Button } from '../../../../../../../atoms';

import emptyImageGallery from 'assets/images/placeholder/empty-image-gallery.svg';

interface IProps {
  title?: string;
  description?: string;
  hideBtn?: boolean;
}

const LinkGoogleImages: FC<IProps> = ({
  title = 'Explore location images',
  description = 'Connect your Google Images account to view and manage photos here uploaded by your team and customers',
  hideBtn,
}) => {
  return (
    <Flex gap={5} pt={3}>
      <Image src={emptyImageGallery} />
      <Flex flexDir='column' flex={1} gap={5} justify='center'>
        <Flex flexDir='column' gap={2}>
          <Box fontSize='20px' fontWeight='600' color='#33383F'>
            {title}
          </Box>
          <Box fontWeight='500' color='#6F767E'>
            {description}
          </Box>
        </Flex>
        {!hideBtn && (
          <Button variant='outline' bg='#FCFCFC'>
            Link Google images
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default LinkGoogleImages;
