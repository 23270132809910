import { Box, Heading, VStack } from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import React, { FC } from 'react';

const AuditConfig: FC = () => {
  return (
    <VStack align='flex-start'>
      <Heading as='h1'>audit.config.ts</Heading>
      <Heading as='h2'>Purpose</Heading>
      <Box>
        Manages audit-related events within the application using an event bus
        pattern. This configuration facilitates tracking and handling of audit
        actions, such as saving data, by providing a helper class to emit and
        listen for specific audit events.
      </Box>
      <Heading as='h2'>Example</Heading>
      <SyntaxHighlighter language='typescript' style={materialDark}>
        {`import { useEffect } from 'react';
import { AuditHelper } from './config/audit.config';

const SaveButton: React.FC = () => {
  useEffect(() => {
    const unregister = AuditHelper.onSave(async () => {
      // Perform audit save operations, e.g., logging
      console.log('Audit save event triggered.');
    });

    // Cleanup on component unmount
    return () => {
      unregister();
    };
  }, []);

  return <button onClick={AuditHelper.save(() => console.log('Save button clicked'))}>Save</button>;
};

export default SaveButton;
`}
      </SyntaxHighlighter>
    </VStack>
  );
};

export default AuditConfig;
